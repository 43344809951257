import {get, getT, post, token} from './api.js'
import log from '../log'

export default {
	
	/**
	 * Получение SSI продукта
	 * 
	 * @param {*} productId 
	 * @returns 
	 */
	async getSSI(productId) {
		log.d(`Api | SSI | getSSI`)
		let response = await getT(`html/ssi/${productId}`, {}, {readBody: true})
		return response
	},
	
}