import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import clinicApi from '../../api/clinic.api'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

const _name = 'ClinicSales'

// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],
	
	clinicSales: {},
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadClinicSales({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | loadClinicSales`, reqObj)

		commit('w_loadingPlus')
		
		let req = await clinicApi.getСlinicSells(reqObj.id)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('loadClinicSales', data)
		
		commit('w_loadingMinus')
	},

}


// mutations
const mutations = {
	loadClinicSales(state, data) {
		log.d(`Store | ${_name} | Mutations | loadClinicSales`, data)
		state.clinicSales={}

		for(let pId in data) {
			let product = data[pId]

			Vue.set(state.clinicSales, product.id, product.clinics[router.currentRoute.params.id])
		}
	},

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}