<template>
	<div class="page-content pt-0 bg-white">
		<div class="page-intro pt-3">
			<div class="container">
				<BtnBackArrow></BtnBackArrow>
				<div class="row align-items-center justify-content-between mt-3 mb-4">
					<div class="col-md-6">
						<h1 class="my-2">Продажи</h1>
					</div>
					<div class="col-md-6 col-lg-5 my-2">
						<RegionsSelectSingle v-model="selectedRegion" :options="userRegions" customClass="multiselect-single"></RegionsSelectSingle>
					</div>
				</div>

				<div class="fw-bold mb-5">2021 на текущий момент (упаковки)</div>
				<div class="row">
					<div class="col-lg-10">
						<div class="row">
							<div class="col-md-4 col-lg-3 my-2">
								<div>Китруда&nbsp;(факт)</div>
								<div class="h1 mt-1 mb-3 text-nowrap">{{sales.actual | digitSpace1k}}</div>
							</div>
							<div class="col-md-4 col-lg-3 my-2 text-primary-light">
								<div>Китруда&nbsp;(план)</div>
								<div class="h1 mt-1 mb-3 text-nowrap">{{sales.plan | digitSpace1k}}</div>
							</div>
							<div class="col-md-4 col-lg-3 my-2 opacity-24">
								<div>Остаток</div>
								<div class="h1 mt-1 mb-3 text-nowrap">{{sales.plan - sales.actual | digitSpace1k}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div>
				<div v-if="true">
					<div class="tab-content">
						<div class="sales-table" v-if="selectedHalfYear==0">
							<div class="sales-table__head">
								<div>1-е полугодие</div>
								<div>Январь</div>
								<div>Февраль</div>
								<div>Март</div>
								<div>Апрель</div>
								<div>Май</div>
								<div>Июнь</div>
								<button class="sales-table__btn" type="button" @click="halfYearSwitch">
									<svg width="8" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 13l6-6-6-6" stroke="#0C2340" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</button>
							</div>
							<div class="sales-table__row align-items-center plan">
								<div>План</div>
								<div v-for="month of [1,2,3,4,5,6]" :key="month" class="input-group">
									<input
										class="form-control"
										type="number"
										name=""
										id=""
										required=""
										placeholder="0"
										:value="getYearPlan(month)"
										@input="(e)=>{setYearPlan(month, e.target.value)}"
										>
									<label class="input-group-text">уп.</label>
								</div>
							</div>
							<div class="sales-table__row align-items-end">
								<div>
									<div>Факт:</div>
									<div class="fw-bold mt-1">{{sales.actual}}</div>
								</div>
								<div v-for="month of [1,2,3,4,5,6]" :key="month">
									<span v-if="sales[month]">{{sales[month].actual_month}}</span>
									<span v-else>0</span>
								</div>
							</div>
						</div>
						
						<div class="sales-table" v-if="selectedHalfYear==1">
							<div class="sales-table__head">
								<div>2-е полугодие</div>
								<div>Июль</div>
								<div>Август</div>
								<div>Сентябрь</div>
								<div>Октябрь</div>
								<div>Ноябрь</div>
								<div>Декабрь</div>
								<button class="sales-table__btn" type="button" @click="halfYearSwitch">
									<svg width="8" height="14" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)">
										<path d="M1 13l6-6-6-6" stroke="#0C2340" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</button>
							</div>
							<div class="sales-table__row align-items-center plan">
								<div>План</div>
								<div v-for="month of [7,8,9,10,11,12]" :key="month" class="input-group">
									<input
										class="form-control"
										type="number"
										name=""
										id=""
										required=""
										placeholder="0"
										:value="getYearPlan(month)"
										@input="(e)=>{setYearPlan(month, e.target.value)}"
										>
										<label class="input-group-text">уп.</label>
								</div>
							</div>
							<div class="sales-table__row align-items-end">
								<div>
									<div>Факт:</div>
									<div class="fw-bold mt-1">{{sales.actual}}</div>
								</div>
								<div v-for="month of [7,8,9,10,11,12]" :key="month">
									<span v-if="sales[month]">{{sales[month].actual_month}}</span>
									<span v-else>0</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="text-center">Данных пока нет</div>
			</div>
			
			<RegionTenderStat tableClass="table-tender-stats-reverse"></RegionTenderStat>

		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../log'
import BtnBackArrow from './blocks/BtnBackArrow'
import SalesTable from './blocks/SalesTable'
import RegionsSelectSingle from './blocks/RegionsSelectSingle.vue'
import RegionTenderStat from './blocks/RegionTenderStat.vue'


export default {
	name: "UserOncoSales",

	components: {
		BtnBackArrow,
        SalesTable,
		RegionsSelectSingle,
		RegionTenderStat,
	},

	async beforeMount() {
		log.d(`UserOncoSales | beforeMount`)
		if (this.selectedUserRegion?.id) {
			await this.loadRegionUserCustomSalesPlan({regions: this.selectedUserRegion.id})
		} else if (this.userRegions && this.userRegions[0]) {
			await this.loadRegionUserCustomSalesPlan({regions: this.userRegions[0].id})
		}
		await this.loadRegionsPVA()
	},
	data() {
		return {
			activeTab: 0,
			planAll: 0,
			actualAll: 0,
			selectedHalfYear: (new Date().getMonth()>5)?1:0,
		}
	},
	computed: {
		...mapState('common', [
			'userRegions',
			'selectedUserRegion',
		]),
		...mapState('regionUserCustomSalesPlan', [
			'users',
			'plans',
		]),
		...mapGetters('regionUserCustomSalesPlan', [
			'w_isLoading',
			'w_isError'
		]),
		...mapGetters('regionsRegionsPVA', {
			regionPVA_w_isLoading: 'w_isLoading',
			regionPVA_w_isError: 'w_isError',
		}),
		...mapState('regionsRegionsPVA', [
			'regionsPVA',
		]),
		...mapState('auth', {
			userId :'id',
		}),
		selectedRegion: {
			get() {
				return this.selectedUserRegion
			},
			set(value) {
				return this.setselectedUserRegion(value)
			}
		},
		getUsersIdFromSelectedPlan() {
			try {
				let users = this.plans[this.selectedUserRegion.id]?.product[7]?.users
				if (users) {
					let usersKeys = Object.keys(users)
					return usersKeys
				}
			} catch(e) {}
			return []
		},
		sales() {
			let obj = {}
			if (this.selectedUserRegion && this.selectedUserRegion.id) {
				this.planAll = 0
				this.actualAll = 0
	
				if (this.regionsPVA[7] && this.regionsPVA[7].regions[this.selectedUserRegion.id]) {
					this.planAll += parseFloat(this.regionsPVA[7].regions[this.selectedUserRegion.id].plan)
					this.actualAll += parseFloat(this.regionsPVA[7].regions[this.selectedUserRegion.id].actual)

					if (this.regionsPVA[7].regions[this.selectedUserRegion.id].months) { // TODO: проверить на наличие ключей
						let months = this.regionsPVA[7].regions[this.selectedUserRegion.id].months;
						for (let monthId of Object.keys(months)) {
							let month = months[monthId]

							if (!obj.hasOwnProperty(monthId)) {
	
								obj[monthId] = {
									plan: 0,
									actual: 0,
									actual_month: 0,
								}
							}
	
							if (month.plan) obj[monthId].plan += parseFloat(month.plan)
							if (month.actual) obj[monthId].actual += parseFloat(month.actual)
							if (month.actual_month) obj[monthId].actual_month += parseFloat(month.actual_month)
							// obj[monthId].plan = this.planAll 
						}
					}
	
				}
	
				obj.actual = this.actualAll
				obj.plan = this.planAll 
			}

			return obj
		},
	},
	methods: {
		...mapMutations('common', [
			'setselectedUserRegion',
		]),
		...mapActions('regionUserCustomSalesPlan', [
			'loadRegionUserCustomSalesPlan',
			'saveUserCustomRegionSellsPlan',
		]),
		...mapActions('regionsRegionsPVA', [
			'loadRegionsPVA'
		]),
		halfYearSwitch() {
			this.selectedHalfYear = !this.selectedHalfYear
		},
		getYearPlan(month) {
			return this.plans[this.selectedUserRegion.id]?.product[7]?.users[this.userId]['yr'][new Date().getFullYear()][month]
		},
		setYearPlan(month, value) {
			console.debug(`planChange`, month, value)
			this.saveUserCustomRegionSellsPlan({
				userId: this.userId,
				regionId: this.selectedUserRegion.id,
				year: new Date().getFullYear(),
				month: month,
				count: value,
			})
		},
	},
	watch: {
        selectedUserRegion() {
			if (this.selectedUserRegion && this.selectedUserRegion.id) {
				this.loadRegionUserCustomSalesPlan({regions: this.selectedUserRegion.id})
			}
        },
    }

}
</script>

<style>

</style>

<style scoped>

</style>