import Vue from 'vue'
import router from '../../router'
import authApi from '../../api/auth.api'
import userApi from '../../api/user.api'
import log from './../../log'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	loadingCount: 0,
	users: {},

	id: 0,
	name: '',
	email: '',
	manager: '',
	managerEmail: '',
	regions: [],
	role: '',
	msdRole: '',
	unit: '',
	divisions: '',
}

// getters
const getters = {
	isLoading: state => {
		if (state.loadingCount>0) {return true}
		return false
	}
}

// actions
const actions = {
	async getUserById({state, commit, dispatch}, id) {
		log.d(`Store | User | Actions | getUserById` , id)
		if (id) {
			if (state.users[id]) {return}
		}

		commit('loadingPlus')
		let req = await userApi.getUser(id)
		if (!req.ok) {
			// TODO: throw error?
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}
		commit('loadUser', data.user)
		commit('loadingMinus')
	},

	// async loginByEmail({commit, dispatch}, loginData) {
	// 	commit('loadingPlus')
	// 	let req = await authApi.loginByEmail(loginData.email, loginData.password)
	// 	if(req.ok) {
	// 		let data = req.json.data
	// 		if(data && (req.json.status === 'ok' || req.json.status === 'success')) {
	// 			await dispatch('saveAuthData', {
	// 				id: data.user.id, 
	// 				key: data.token, 
	// 				name: data.user.nm,
	// 				email: data.user.email,
	// 				msdRole: data.user.role_code,
	// 			})
	// 			router.push({path:'/'}).catch(()=>{})
	// 		} else {
	// 			commit('setMessage', 'Ошибка при авторизации')
	// 		}
	// 	}
	// 	commit('loadingMinus')
	// },

	// async checkIsAuth({dispatch, getters, state, commit}) {
	// 	console.log(`Store | Auth | Actions | checkIsAuth`)
	// 	if (getters.isAuth && state.key && state.key!=='undefined') {
	// 		commit('loadingPlus')
	// 		let req = await authApi.getUser(state.key)
	// 		if(req.ok) {
	// 			let data = req.json.data
	// 			if(data && req.json.status === 'ok') {
	// 				await dispatch('saveAuthData', {
	// 					id: data.user.id, 
	// 					key: state.key, 
	// 					name: data.user.nm,
	// 					email: data.user.email,
	// 					msdRole: data.user.role_code,
	// 				})
	// 				commit('loadingMinus')
	// 				return true
	// 			}
	// 		}
	// 	}
	// 	await dispatch('setAuthData')
	// 	commit('loadingMinus')
	// 	return false
	// },

	

	

}

// mutations
const mutations = {
	wipeUserList(state) {
		state.users = []
	},
	loadUser(state, user) {
		log.d(`Store | User | Mutations | loadUser` , user)
		user.msdRole = user.roleMSDmed
		Vue.set(state.users, user.id, user);
		// state.users[user.id] = user;
	},
	addError(state, error) {
		state.errors.push(error)
	},
	loadingPlus(state) {
		log.d(`Store | User | Mutations | loadingPlus` , state.loadingCount)
		state.loadingCount++;
	},
	loadingMinus(state) {
		log.d(`Store | User | Mutations | loadingMinus` , state.loadingCount)
		state.loadingCount--;
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}