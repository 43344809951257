<template>
	<div class="page-filter">
		<!-- :isLoading="w_isLoading" :isError="w_isError" :errors="w_errors" -->
		<div class="font-weight-bold mb-2">Регионы</div>
		<div class="row align-items-center">
			<div v-if="Object.keys(userRegionsObj).length && Object.keys(userRegionsObj).length>showHideAllRegionsLevel" class="col-md-4 col-lg-12 my-2">
				<div class="input-check input-check-sm input-check-align-center">
					<input type="checkbox" id="region_select_all" @change="toggleRegions" :checked="Object.keys(userRegionsObj).length === selectedRegions.length"/>
					<label v-if="Object.keys(userRegionsObj).length === selectedRegions.length" for="region_select_all">Снять все</label>
					<label v-else for="region_select_all">Выбрать все</label>
				</div>
			</div>
			<div
				class="col-md-4 col-lg-12 my-2"
				v-for="region in userRegionsObj" :key="region.id"
				>
				<div class="input-check input-check-sm input-check-align-center">
					<input
						type="checkbox" 
						v-model="selectedRegions"
						:id="'region_'+region.id"
						:value="region.id"
						/>
					<label
						:for="'region_'+region.id"
						>{{region.nm}}</label>
				</div>
			</div>
		</div>
		
		<hr />
		
		<div v-if="divisionVaccines">
			<div class="font-weight-bold mt-4 mb-2">Фильтры</div>
			<div class="row align-items-center">
				<div class="col-md-4 col-lg-12 my-2">
					<div class="input-check input-check-sm input-check-align-center">
						<input 
							type="checkbox"
							id="licence"
							v-model="isVaccinationLiscense"
							/>
						<label for="licence">Лицензия на вакцинацию</label>
					</div>
				</div>
				<div class="col-md-4 col-lg-12 my-2">
					<div class="input-check input-check-sm input-check-align-center">
						<input
							type="checkbox"
							id="msd_cover"
							v-model="isMSDPartner"
							/>
						<label for="msd_cover">Покрытие MSD</label>
					</div>
				</div>
			</div>
			
			<hr />
		</div>
		

		<div class="font-weight-bold mt-4 mb-2">Личные списки</div>
		<button class="btn btn-link pl-0" @click="createListReady" data-toggle="modal" data-target="#createListModal" type="button">
			<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12 5v14M5 12h14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
			Создать
		</button>
		<div class="row align-items-center">
			<div class="col-md-4 col-lg-12 my-2 listElement" v-for="list in lists" :key="list.id">
				<div class="input-check input-check-sm input-check-align-center">
					<input type="checkbox" v-model="selectedUserList" :id="'user-list_' + list.id" :value="list.id" />
					<label :for="'user-list_' + list.id">{{ list.nm }}</label>
				</div>
				<div
					class="color-sample ml-auto"
					v-if="list.color != 16777215"
					:style="{backgroundColor: '#' + list.color.toString(16)}"
					></div>
			</div>
		</div>
		<button class="btn btn-link pl-0" @click="resetFilters" type="button" data-toggle="modal" data-target="#userListModal">редактировать</button>
		
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import config from '../../../config.loader'
import log from '../../../log'

export default {
	name: "ClinicsFilterWidget",
	components: {
	},
	async beforeMount() {
		await this.loadLists()
	},
	data() {
		return {
		}
	},
	computed: {
		selectedRegions: {
			get() {
				return this.selectedUserRegionsId
			},
			set(value) {
				this.setSearchTimeout()
				return this.setSelectedRegionsId(value)
			}
		},
		isVaccinationLiscense: {
			get() {
				return this.filterIsVaccinationLicense
			},
			set(value) {
				return this.setVaccinationLicenseFilter(value)
			}
		},
		isMSDPartner: {
			get() {
				return this.filterIsMSDPartner
			},
			set(value) {
				return this.setMSDPartnerFilter(value)
			}
		},
		selectedUserList: {
			get() {
				return this.filterSelectedUserList
			},
			set(value) {
				return this.setSelectedUserListFilter(value)
			}
		},
		showHideAllRegionsLevel() {
            return config.showHideAllRegionsLevel
        }, 
		
		...mapState('common', [
			'selectedUserRegionsId',
		]),
		...mapGetters('common', [
			'userRegionsObj',
		]),
		...mapState('clinics', [
			'clinics',
			'filterIsVaccinationLicense',
			'filterIsMSDPartner',
			'filterSelectedUserList',

			'w_errors',
		]),
		...mapGetters('clinics', [
			'w_isLoading',
			'w_isError',
		]),

		...mapState('userLists', [
			'lists',
		]),
	},
	methods: {
		createListReady() {
			this.resetNewList()
			this.resetFilters()
		},
		toggleRegions(e) {
			this.setSearchTimeout()
			if (e.target.checked) {
				this.setSelectedRegionsId(Object.keys(this.userRegionsObj).map(el => parseInt(el)))
			} else {
				this.setSelectedRegionsId([])
			}
		},
		...mapActions('common', [
			'setSelectedRegionsId'
		]),
		...mapActions('clinics', [
			'setVaccinationLicenseFilter',
			'setMSDPartnerFilter',
			'setSelectedUserListFilter',
			'setSearchTimeout',
			'resetFilters'
		]),
		...mapActions('userLists', [
			'addToFav',
			'removeFromFav',
			'loadLists',
		]),
		...mapMutations('userLists', [
			'resetNewList'
		]),
	},
}
</script>

<style>

</style>

<style scoped>
	.listElement {
		display: flex;
		align-items: center;
	}
</style>