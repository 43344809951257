<template>
	<div class="page-auth">
		<div class="page-auth__section">
			<div id="register-form" class="page-form  pb-7 validate justify-content-between">
				<!--autocomplete="off" action="/register.php" method="POST"-->
				<div class="page-form-header">
					<img src="./../assets/images/logo-msd-commerce.svg" />
					<!-- <a href="#" class="btn-close"></a> -->
				</div>
				
				<div v-if="page==1">
					<div class="page-form-body mb-8">
						<div class="d-flex justify-content-between align-items-center mb-2">
							<h1 class="page-form-title">Регистрация</h1>
						</div>
						
						<div>1 / <span class="text-muted">2</span></div>

						<div class="fsz-sm text-danger mt-3 mb-3">
							<div class="fsz-sm text-danger" v-if="email && !isMerckEmail">Введен не корпоративный email</div>
							<div class="fsz-sm text-danger" v-if="(firstName || lastName) && !inputsValid.firstName">Введите Имя</div>
							<div class="fsz-sm text-danger" v-if="(firstName || lastName) && !inputsValid.lastName">Введите Фамилию</div>
							<div class="fsz-sm text-danger">{{message}}</div>
						</div>
						<div class="fsz-sm text-muted mb-6">Поля, отмеченные * обязательны к заполнению</div>
						<div class="page-form-group">
							<input
								name="email"
								type="email"
								id="register-email"
								class="page-form-control"
								:class="{'has-value' : email, 'valid': inputsValid.email && isMerckEmail}"
								required
								autocomplete="off"
								@keyup="e=>{checkValidity(e); checkEmail(e)}"
								v-model="email"
							>
							<label class="page-form-label" for="register-email">E-mail</label>
						</div>
						<div class="page-form-group">
							<input
								name="lastName"
								type="text"
								id="lastName"
								class="page-form-control"
								:class="{'has-value' : lastName, 'valid': inputsValid.lastName}"
								required
								autocomplete="off"
								@keyup="checkValidity"
								v-model="lastName"
							>
							<label class="page-form-label" for="lastName">Фамилия</label>
						</div>
						<div class="page-form-group">
							<input
								name="firstName"
								type="text"
								id="firstName"
								class="page-form-control"
								:class="{'has-value' : firstName, 'valid': inputsValid.firstName}"
								required
								autocomplete="off"
								@keyup="checkValidity"
								v-model="firstName"
							>
							<label class="page-form-label" for="firstName">Имя</label>
						</div>
						<div class="page-form-group mb-4">
							<input
								name="middleName"
								type="text"
								id="middleName"
								class="page-form-control"
								:class="{'has-value' : middleName, 'valid': inputsValid.middleName}"
								autocomplete="off"
								v-model="middleName"
							>
							<label class="page-form-label" for="middleName">Отчество</label>
						</div>

						<!-- <div class="input-check input-check-sm input-check-align-center mb-1">
							<input name="adSubscription" type="checkbox" id="adSubscription" v-model="adSubscription">
							<label for="adSubscription">Даю <a href="#" target="_blank">согласие</a> на получение от MSD рекламной информации<br>по указанным каналам связи.</label>
						</div> -->
						
						<div class="input-check input-check-sm input-check-align-center">
							<input name="terms" type="checkbox" id="terms" @change="checkValidity" v-model="terms" required>
							<label for="terms">Принимаю <a href="https://www.msd.ru/static/pdf/PDP_policy.pdf" target="_blank">Условия использования Сайта и обработки персональных данных *</a></label>
						</div>

						<div class="fsz-sm text-muted mt-3">
							Поставив галочку в окне выше, вы подтверждаете, что принимаете <a href="https://www.msd.ru/static/pdf/PDP_policy.pdf" target="_blank">Условия использования Сайта и обработки персональных данных</a> и ознакомились с <a href="https://www.msdprivacy.com/ru/ru/" target="_blank">Политикой конфиденциальности</a>.
						</div>
					</div>
					<button class="btn btn-primary w-100 btn-form-ready" :disabled="!form1Valid" type="button" @click="setPage(2)">Далее</button>
				</div>

				<div v-if="page==2">
					<div class="page-form-body mb-8">
						<div class="d-flex justify-content-between align-items-center mb-2">
							<h1 class="page-form-title">Установите пароль</h1>
						</div>
						
						<div>2 / <span class="text-muted">2</span></div>

						<div class="fsz-sm text-danger mb-6">{{message}}</div>
						<div class="fsz-sm text-muted mb-6">Поля, отмеченные * обязательны к заполнению</div>

						<div class="page-form-group">
							<input
								name="registerPassword"
								type="password"
								id="register-password"
								class="page-form-control"
								:class="{'has-value' : registerPassword, 'valid': inputsValid.registerPassword}"
								required
								autocomplete="off"
								@keyup="checkValidity"
								v-model="registerPassword"
							>
							<label class="page-form-label" for="register-password">Пароль</label>
						</div>

						<div class="page-form-group">
							<input
								name="registerPasswordRepeat"
								type="password"
								id="register-password-repeat"
								class="page-form-control"
								:class="{'has-value' : registerPasswordRepeat, 'valid': inputsValid.registerPasswordRepeat}"
								required
								autocomplete="off"
								@keyup="checkValidity"
								v-model="registerPasswordRepeat"
							>
							<label class="page-form-label" for="register-password-repeat">Подтверждение пароля</label>
						</div>
					</div>
					<button
						class="btn btn-primary w-100 btn-form-ready"
						:disabled="!form2Valid"
						type="button"
						@click="signup"
						data-tracking-datalayer='{ "event_category" : "registration",  "event_action" : "click", "event_label": "button name"}'
						>Далее</button>
				</div>

				<div v-if="page=='confirm'">
					<div class="page-form-body mb-8">
						<h1 class="mt-2 mb-5">Почти готово!</h1>
						<p>Мы отправили 
							<span v-if="email">
								на адрес <span class="text-primary">{{email}}</span>
							</span>
							письмо для завершения регистрации.</p>
						<p>Перейдите по ссылке из письма, чтобы подтвердить указанные данные и получить доступ к сайту.</p>
					
						<div class="text-center mt-5" v-if="email">
							<a
								v-if="sendConfirmSended==0"
								href="javascript:void(0)"
								class="page-form-link"
								@click="sendConfirmAgain"
								>отправить ещё раз</a>
								<span v-else>Отправлено</span>
						</div>
					</div>
					

					<router-link to="/login" class="btn btn-primary w-100 btn-form-ready">Хорошо</router-link>
				</div>

				<!-- footer -->
				<div class="page-form-footer mt-0">
					<div class="text-center mt-5">
						<router-link to="/login" class="page-form-link">войти в существующий аккаунт</router-link>
					</div>
					<div class="text-center mt-12">
						<a href="mailto:support@msdmed.ru" class="page-form-link">Техподдержка: <span class="td-underline">support@msdmed.ru</span></a>
					</div>
				</div>

			</div>
		</div>
  	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
	name: "Register",
	data() {
		return {
			page: 1,
			
			email: '',
			lastName: '',
			firstName: '',
			middleName: '',
			
			registerPassword: '',
			registerPasswordRepeat: '',

			adSubscription: false,
			terms: false,

			inputsValid: {
				email: false,
				lastName: false,
				firstName: false,
				registerPassword: false,
				registerPasswordRepeat: false,
				terms: false,
			},

			form1message: '',
			isMerckEmail: false,
			sendConfirmSended: 0,
		}
	},
	computed: {
		form1Valid() {
			return this.inputsValid.email && this.inputsValid.lastName && this.inputsValid.firstName && this.inputsValid.terms && this.isMerckEmail
		},
		form2Valid() {
			return this.inputsValid.registerPassword && this.inputsValid.registerPasswordRepeat && this.isPasswordMatch
		},
		formValid() {
			return this.inputsValid.email && this.inputsValid.lastName && this.inputsValid.firstName &&
			this.inputsValid.registerPassword && this.inputsValid.registerPasswordRepeat && this.isPasswordMatch &&
			this.inputsValid.terms
		},
		isPasswordMatch() {
			return this.registerPassword === this.registerPasswordRepeat
		},
		...mapState('auth', [
			'message'
		]),
		...mapGetters('auth', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		async signup() {
			let result = await this.signupToMSD({
				email: this.email,
				first_name: this.firstName,
				patronymic: this.middleName,
				last_name: this.lastName,
				//TODO рекламная информация
				password: this.registerPassword
			})
			if (result == 'confirm') {
				this.setPage('confirm')
			}
		},
		async sendConfirmAgain() {
			this.sendConfirmSended++
			let result = await this.sendConfirmAgainToMSD(this.email)
			setInterval(()=>{this.sendConfirmSended=0}, 5000)
		},
		checkEmail(e) {
			this.isMerckEmail = e.target.value.toLowerCase().indexOf('merck.com') != -1
		},
		checkValidity(event) {
			this.inputsValid[event.target.name] = event.target.validity.valid
		},
		setPage(value) {
			this.page = value
		},
		...mapActions('auth', [
			'signupToMSD',
			'sendConfirmAgainToMSD'
		])
	},
}
</script>

<style>

</style>

<style scoped>

</style>