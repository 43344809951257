<template>
    <div class="page-content" :class="{ 'bg-white' : clinics.length != 0 }">
        <div class="container">
            <div class="page-row">
                <div class="page-main">
                    <h1 
                        class="mb-3"
                    >
                        Клиники: 
                        <span v-if="w_isLoading">...</span>
                        <span v-else>{{clinicsCounter | digitSpace1k}}</span>
                    </h1>

                    <div class="row align-items-center mb-4">
                        <div class="col-lg-6 my-2">
                            <SearchString
                                v-model="searchStringValue"
                                @submit="submitSearchString"
                                ></SearchString>
                        </div>
                        <div class="col-lg-6">
                            <ClinicsSortWidget></ClinicsSortWidget>
                        </div>
                    </div>

                    <ClinicsListWidget
                        v-if="clinics.length != 0 || w_isLoading"
                        :search-string="searchStringValue"
                        ></ClinicsListWidget>

                    <div
                        v-else
                        class="text-center mt-11"
                        ><ClinicsNotFoundMessageBlock></ClinicsNotFoundMessageBlock></div>

                    <a
                        class="page-btn"
                        v-if="clinicsCounter !== clinics.length"
                        @click="getClinicsToAdd"
                        >Показать ещё</a>
                </div>
                
                <ClinicsFilterWidget></ClinicsFilterWidget>

            </div>
        </div>
        <UserListsModalWidget></UserListsModalWidget>
        <CreateUserListModalWidget></CreateUserListModalWidget>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import SortControl from './blocks/SortControl'
import SearchString from './blocks/SearchString'
import Notification from './blocks/Notification'
import ClinicsNotFoundMessageBlock from './blocks/ClinicsNotFoundMessageBlock'
import ClinicsFilterWidget from './widgets/Clinics/ClinicsFilters.widget'
import ClinicsSortWidget from './widgets/Clinics/ClinicsSort.widget'
import ClinicsListWidget from './widgets/Clinics/ClinicsList.widget'
import UserListsModalWidget from './widgets/Clinics/UserListsModal.widget'
import CreateUserListModalWidget from './widgets/Clinics/CreateUserListModal.widget'

export default {
    name: "Clinics",
    components: {
        SortControl,
        SearchString,
        Notification,
        ClinicsFilterWidget,
        ClinicsSortWidget,
        ClinicsListWidget,
        UserListsModalWidget,
        CreateUserListModalWidget,
        Notification,
        ClinicsNotFoundMessageBlock,
    },
    async beforeMount() {
        await this.getClinics()
	},
    computed: {
        searchStringValue: {
            get() {
                return this.searchString
            },
            set(value) {
                return this.setSearchString(value)
            }
        },
        ...mapState('clinics', [
            'searchString',
            'clinics',
            'clinicsCounter',
            
            'w_errors',
        ]),
        ...mapGetters('clinics', [
			'w_isLoading',
			'w_isError',
		]),
    },
    methods: {
        submitSearchString() {
            this.clearSearchTimeout()
            this.handleSearchTimeout()
        },
        ...mapActions('clinics', [
            'getClinics',
            'setSearchString',
            'clearSearchTimeout',
            'handleSearchTimeout',
            'getClinicsToAdd',
        ]),
    }
}
</script>

<style>
    
</style>

<style scoped>

</style>