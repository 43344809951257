<template>
	<a href="javascript:void(0);" @click="$router.go(-1)" class="btn-back">
		<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.293.707a1 1 0 111.414 1.416L3.83 7H15a1 1 0 110 2H3.83l4.877 4.877a1 1 0 01-1.415 1.415L0 8 7.293.707z" fill="#0C2340"/>
		</svg>
		{{ text }}
	</a>
</template>

<script>
export default {
	name: "BtnBackArrow",
	props: {
		text: {
            type: String,
            default: null,
        }
	}
}
</script>