import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import authApi from '../../api/auth.api'
import clinicApi from '../../api/clinic.api'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
} from '../widgetHelper'

// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],

	clinicCountByRegions: {},
}

// getters
const getters = {
	w_isLoading,
	w_isError,

	selectedRegionsClinics: (state, getters, rootState) => {
		return rootState.common.selectedUserRegionsId.map(regionId => {
			if (state.clinicCountByRegions && regionId && state.clinicCountByRegions[regionId]) {
				return state.clinicCountByRegions[regionId]
			}
		}).filter(el => el != undefined)
	},
	allClinicsCountByRegions: (state, getters, rootState) => {
		return getters.selectedRegionsClinics.reduce((clinicSum, region) => {
			if (region && region.count) {
				return clinicSum + region.count
			}
			return clinicSum
		}, 0);
	}
}

// actions
const actions = {
	async loadClinicCountByRegions({state, commit, dispatch, rootState}, reqObj={}) {
		log.d(`Store | ClinicCountByRegions | Actions | loadClinicCountByRegions`, reqObj)
		commit('w_loadingPlus')

		if (Object.keys(state.clinicCountByRegions).length === 0) {
			let req = await clinicApi.getClinicCountByRegions(reqObj.regions)
			if (!req.ok) {
				// TODO: throw error?
				commit('w_addError', 'Ошибка при загрузке данных')
				return
			}
			let data = req.json.data
			if(req.json.status !== 'ok') {
				// TODO: throw error?
			}
			commit('setClinicCountByRegions', data)
		}
		
		commit('w_loadingMinus')
	}
}

// mutations
const mutations = {
	setClinicCountByRegions(state, data) {
		log.d(`Store | ClinicCountByRegions | Mutations | setClinicCountByRegions`, data)
		state.clinicCountByRegions = {}
		data.forEach(el => {
			state.clinicCountByRegions[el.id] = (el)
		})
	},

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}