import {getT, postT} from './api.js'
import log from '../log'

export default {

	/**
	 * Получение списка регионов
	 * 
	 * @param {string} mode - тип списка: all/user/unit
	 * @returns 
	 */
	async getRegions(mode='user') {
		log.d(`Api | Common | getRegions`, mode)
		let response = await getT(`regions`, {
			mode: mode,
		})
		return response
	},
	
	/**
	 * Получение списка продуктов
	 * 
	 * @param {string} mode - тип списка: all/user/unit
	 * @returns 
	 */
	async getProducts(mode='user') {
		log.d(`Api | Common | getProducts`, mode)
		let response = await getT(`products`, {
			mode: mode,
		})
		return response
	},
	
	/**
	 * Получение объектов для заполнения анкет
	 * 
	 * @returns 
	 */
	async getFormObjects() {
		log.d(`Api | Common | getFormObjects`)
		let response = await getT(`clinic/form/objects`)
		return response
	},


}