import Vue from 'vue'
import VueRouter from 'vue-router'
import babelPolyfill from 'babel-polyfill'
import Multiselect from 'vue-multiselect'
import vSelect from 'vue-select'
import router from './router'
import store from './store'
import './filters'
import log from './log'
import division from './division'
import userRolesMixin from './userRoles'

import errorApi from './api/error.api'

import MainComponent from './components/Main'

const Tealium = {
    install(Vue, options) {
        Vue.sendForData = (data) => {
            if(Object.prototype.hasOwnProperty.call(window, '$DataTracking')){
                if(typeof window.$DataTracking.SendForData == 'function'){
                    window.$DataTracking.SendForData(data);
                }
            }
        }

        Vue.prototype.sendForData = (data) => {
            if(Object.prototype.hasOwnProperty.call(window, '$DataTracking')){
                if(typeof window.$DataTracking.SendForData == 'function'){
                    window.$DataTracking.SendForData(data);
                }
            }
        }
    }
}
Vue.use(Tealium)
Vue.use(division)
Vue.use(userRolesMixin)

Vue.use(require('vue-moment'));

Vue.component('app', MainComponent);
Vue.component('multiselect', Multiselect)
Vue.component('v-select', vSelect)

Vue.directive('scroll', {
    inserted: function (el, binding) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f)
            }
        }
        window.addEventListener('scroll', f)
    }
})

window.onerror = function(message, source, lineno, colno, error) {
    log.e(`Message: ${message}\nSource: ${source}\nLineno: ${lineno}\nColno: ${colno}\nError: ${error}`)
    errorApi.sendError('global error', `Message: ${message} \nSource: ${source} \nLineno: ${lineno} \nColno: ${colno} \nError: ${error}`)
};

Vue.config.errorHandler = function(err, vm, info) {
    log.e(`Error: ${err.toString()}\nInfo: ${info}`)
    errorApi.sendError('error', `Message: ${err.toString()} \nInfo: ${info}`)
}

Vue.config.warnHandler = function(err, vm, info) {
    log.w(`Warning: ${err.toString()}\nInfo: ${info}`)
    errorApi.sendError('warning', `Message: ${err.toString()} \nInfo: ${info}`)
}

window.app = new Vue({
    el: "#app",
    router: router,
    store,
    render: h => h(MainComponent)
})
