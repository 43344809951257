import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import clinicApi from '../../api/clinic.api'
import config from '../../config.loader'
import tealium from '../../tealium'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	w_name: 'ClinicQuestOnco',
	w_loadingCount: 0,
	w_errors: [],
	w_netStatus: {},
	
	questOncoDiagnostics: {},
	questOncoKAMStrategies: {},
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadClinicQuestOncoDiagnostics({state, commit, dispatch, rootState}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | loadClinicQuestOncoDiagnostics`, reqObj)
		commit('w_loadingPlus')
		
		let req = await clinicApi.getQuestOncoDiagnostics(reqObj.id)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('loadClinicQuestOncoDiagnostics', {clinicId: reqObj.id, ...data})

		commit('w_loadingMinus')
	},

	async loadClinicQuestOncoKAMStrategies({state, commit, dispatch, rootState}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | loadClinicQuestOncoKAMStrategies`, reqObj)
		commit('w_loadingPlus')
		
		let req = await clinicApi.getQuestOncoKAMStrategies(reqObj.id)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('loadClinicQuestOncoKAMStrategies', {clinicId: reqObj.id, ...data})
		
		commit('w_loadingMinus')
	},

	async saveDiagnosticsData({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | saveDiagnosticsData`, reqObj)
		commit('saveDiagnosticsData', reqObj)
		clinicApi.postQuestOncoDiagnostics(reqObj.clinicId, {datum: {[reqObj.parameter]: reqObj.value}})
	},

	async saveDiagnosticsDataTimer({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | saveDiagnosticsDataTimer`, reqObj)
		commit('saveDiagnosticsData', reqObj)
		commit('w_setTimer', {
			id: reqObj.clinicId + reqObj.parameter,
			action: ()=>{
				clinicApi.postQuestOncoDiagnostics(reqObj.clinicId, {datum: {[reqObj.parameter]: reqObj.value}})
			},
		})
	},

	async saveKAMStrategiesData({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | saveDiagnosticsData`, reqObj)
		commit('saveKAMStrategiesData', reqObj)
		clinicApi.postQuestOncoKAMStrategies(reqObj.clinicId, {datum: {[reqObj.parameter]: reqObj.value}})
	},

	async saveKAMStrategiesDataTimer({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | saveDiagnosticsDataTimer`, reqObj)
		commit('saveKAMStrategiesData', reqObj)
		commit('w_setTimer', {
			id: reqObj.clinicId + reqObj.parameter,
			action: ()=>{
			clinicApi.postQuestOncoKAMStrategies(reqObj.clinicId, {datum: {[reqObj.parameter]: reqObj.value}})
			},
		})
	},
}


// mutations
const mutations = {
	loadClinicQuestOncoDiagnostics(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | loadClinicQuestOncoDiagnostics`, d)

		let result = d

		Vue.set(state.questOncoDiagnostics, d.clinicId, result)
	},

	loadClinicQuestOncoKAMStrategies(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | loadClinicQuestOncoKAMStrategies`, d)

		let result = d

		Vue.set(state.questOncoKAMStrategies, d.clinicId, result)
	},

	saveDiagnosticsData(state, d)  {
		log.d(`Store | ${state.w_name} | Mutations | saveDiagnosticsData`, d)
		let res = state.questOncoDiagnostics[d.clinicId]
		res[d.parameter] = d.value
		Vue.set(state.questOncoDiagnostics, d.clinicId, res)
	},

	saveKAMStrategiesData(state, d)  {
		log.d(`Store | ${state.w_name} | Mutations | saveKAMStrategiesData`, d)
		let res = state.questOncoKAMStrategies [d.clinicId]
		res[d.parameter] = d.value
		Vue.set(state.questOncoKAMStrategies, d.clinicId, res)
	},



	setIsKindergartenExamination(state, d)  {
		log.d(`Store | ${state.w_name} | Mutations | setIsKindergartenExamination`, d)
		if (d.value === null) {
			if (state.questOnco[d.clinicId].patients.kindergartenExaminationCnt > 0 || state.questOnco[d.clinicId].patients.kindergartenVaccinationLevel) {
				state.isKindergartenExamination = 1
			} else {
				state.isKindergartenExamination = 0
			}
		} else {
			state.isKindergartenExamination = d.value
		}
	},

	saveHeadPosition(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | saveHeadPosition`, d)
		Vue.set(state.questOnco[d.id].sales, 'headPositionId', d.headPositionId)
	},

	

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}