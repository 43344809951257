<template>
	<Widget
        title="Продажи китруды"
        titleClass="text-muted"
        :isLoading="w_isLoading"
        :isError="w_isError"
        :errors="w_errors"
        url="sells"
        >
        <div class="row">
            <div class="col-lg-10">
                <div class="btn-switch mb-4" :class="{'pr-lg-7': offsetRight}">
                    <button :class="{'active': graphActiveTab==0}" @click="setGraphActiveTab(0)" type="button">месяц</button>
                    <button :class="{'active': graphActiveTab==1}" @click="setGraphActiveTab(1)" type="button">год</button>
                </div>
                <div class="row mb-3">
                    <div class="col-4 text-primary">
                        <span>План:</span>
                        <span class="h2 m-0 ml-3">721</span>
                    </div>
                    <div class="col-6">
                        <span>Факт:</span>
                        <span class="h2 m-0 ml-3">721</span>
                    </div>
                </div>
                <div class="chart-container" :class="{'pr-lg-7': offsetRight}">
                    <VaccineSalesChart v-if="graphActiveTab==0" :chartData="monthsSales" half=1></VaccineSalesChart>
                    <VaccineSalesChart v-if="graphActiveTab==1" :chartData="monthsSales" half=2></VaccineSalesChart>
                </div>
            </div>
        </div>
    </Widget>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Widget from '../Widget'
import VaccineSalesChart from '../../blocks/VaccineSalesChart'
import log from '../../../log'

export default {
    name: "VaccineSales",
	components: {
		Widget,
        VaccineSalesChart
	},
    props: {
        offsetRight: {
            type: Boolean,
            default: false
        }
    },
    async beforeMount() {
		log.d(`VaccineSales | beforeMount`, this.$route.params.id)
		await this.loadClinicSales({id: this.$route.params.id})

        if (this.products.length == 0) {
            this.products = Object.keys(this.MSDuserProducts)
        }

        this.graphActiveTab = (new Date().getMonth() + 1) <= 6 ? 0 : 1
	},
    data() {
        return {
            products: [],
            graphActiveTab: 0,
        }
    },
	computed: {
        MSDuserProducts() {
			let resultProducts = {}
			for (let prId in this.userProducts) {
				let el = this.userProducts[prId]
				if (el.is_msd_product) {
					resultProducts[el.id] = el
				}
			}
			return resultProducts
		},
        planSum() {
            return this.products.reduce((sum, pId) => {
                if (this.clinicSales[pId]) {
                    return sum + parseInt(this.clinicSales[pId].plan)
                }
                return sum
            }, 0)
        },
        actualSum() {
            return this.products.reduce((sum, pId) => {
                if (this.clinicSales[pId]) {
                    return sum + parseInt(this.clinicSales[pId].actual)
                }
                return sum
            }, 0)
        },
        monthsSales() {
            let obj = {
                actual: 0,
                plan: 0,
            }

            this.products.forEach(pId => {
                if (this.clinicSales[pId] == undefined) {return}
                let months = Object.values(this.clinicSales[pId].months);
                months.forEach(month => {
                    if (!obj.hasOwnProperty(month.month)) {
                        obj[month.month] = {
                            plan: 0,
                            actual: 0
                        }
                    }
                    if (month.plan) obj[month.month].plan += parseInt(month.plan)
                    if (month.actual) obj[month.month].actual += parseInt(month.actual)
                })
                obj.actual += parseInt(this.clinicSales[pId].actual)
                obj.plan += parseInt(this.clinicSales[pId].plan)
            })

            return obj
        },
		...mapState('common', [
			'userProducts'
		]),
		...mapState('clinicSales', [
			'clinicSales',
            'w_errors',
		]),
        ...mapGetters('clinicSales', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
        setGraphActiveTab(value) {
            this.graphActiveTab = value
        },
		...mapActions('clinicSales', [
			'loadClinicSales',
		]),
	},
    watch: {
        userProducts() {
            if(Object.keys(this.MSDuserProducts).length>0 && this.products.length==0) {
                this.products = Object.keys(this.MSDuserProducts)
            }
        },
    },
}
</script>

<style>
    .chart-container #line-chart {
		height: 100% !important;
    }
</style>

<style scoped>
    .chart-container {
        display: flex;
        justify-content: flex-end;
        position: relative;
        left: -12px;
        width: 100%;
        max-width: 400px;
        height: 160px;
        overflow: auto;
    }
    .chart-container > div {
        flex-shrink: 0;
        max-width: 400px;
        width: 100%;
    }
</style>