<template>
  <div class="page-content pt-3 bg-white">
		<div class="container">
			<BtnBackArrow></BtnBackArrow>
			<h1 class="mt-3 mb-4">Новости</h1>
			<div class="table-responsive">
				<table class="table table-news">
					<tr>
						<th>Дата и время</th>
						<th>Наименование ЛПУ</th>
						<th>Город</th>
						<th>Адрес</th>
						<th>Статус</th>
					</tr>
					<tr v-for="newsEl in news" :key="newsEl.id">
						<td>{{ newsEl.timestamp | moment("DD.MM.YY, HH:mm") }}</td>
						<td class="font-weight-bold fsz-base">{{newsEl.clinic.name}}</td>
						<td>{{newsEl.clinic.city}}</td>
						<td>{{newsEl.clinic.address}}</td>
						<td>
							<span
								v-if="newsEl.status == 'new'"
								class="status text-primary"
								>добавлена</span>
							<span
								v-if="newsEl.status == 'notUpdated'"
								class="status text-danger"
								>
								<ClinicDeletionType
									:visitCount="newsEl.clinic.visitCount"></ClinicDeletionType>
							</span>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import log from './../log'
import BtnBackArrow from './blocks/BtnBackArrow'
import ClinicDeletionType from './blocks/ClinicDeletionType'

export default {
	name: "News",

	components: {
		BtnBackArrow,
		ClinicDeletionType,
	},

	async beforeMount() {
		log.d(`News | beforeMount`)
		await this.loadNews({count:10})
	},
	data() {
		return {
			
		}
	},
	computed: {
		...mapState('userNews', [
			'news',
			'w_errors',
		]),
		...mapGetters('userNews', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		...mapActions('userNews', [
			'loadNews'
		]),
	},

}
</script>

<style>

</style>

<style scoped>

</style>