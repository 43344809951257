<template>
	<div class="page-auth">
		<div id="loginForm" class="page-auth__section">
			<div id="register-form" class="page-form  pb-7 validate justify-content-between">
				<!--autocomplete="off" action="/register.php" method="POST"-->
				<div class="page-form-header">
					<img src="./../assets/images/logo-msd-commerce.svg" />
					<!-- <a href="#" class="btn-close"></a> -->
				</div>
				<div class="page-form-body mb-5">
					<div class="d-flex justify-content-between align-items-center mb-2">
						<h1 class="page-form-title">Новый пароль</h1>
						<div>3 / <span class="text-muted">3</span></div>
					</div>
					<div class="fsz-sm text-muted mb-6">Пароль должен содержать:
						<br>
						<br>
						– минимум 8 символов<br>
						– заглавные и строчные буквы<br>
						– цифры или специальные символы, например !@#$%^&amp;*?/]</div>
					<div class="page-form-group">
						<input
							name="registerPassword"
							type="password"
							id="register-password"
							class="page-form-control"
							:class="{'has-value' : registerPassword}"
							required
							autocomplete="off"
							@keyup="checkValidity"
							v-model="registerPassword"
						>
						<label class="page-form-label" for="register-password">Пароль</label>
					</div>
					<div class="page-form-group">
						<input
							name="registerPasswordRepeat"
							type="password"
							id="register-password-repeat"
							class="page-form-control"
							:class="{'has-value' : registerPasswordRepeat}"
							required
							autocomplete="off"
							@keyup="checkValidity"
							v-model="registerPasswordRepeat"
						>
						<label class="page-form-label" for="register-password-repeat">Подтверждение пароля</label>
					</div>
				</div>
				<div class="page-form-footer mt-0">
					<router-link v-if="formValid" to="/register-confirm" class="btn btn-primary w-100">Далее</router-link>
					<span v-else class="btn btn-primary disabled w-100">Далее</span>
					<div class="text-center mt-5">
						<router-link to="/login" class="page-form-link">войти в существующий аккаунт</router-link>
					</div>
					<div class="text-center mt-12">
						<a href="mailto:support@msdmed.ru" class="page-form-link">Техподдержка: <span class="td-underline">support@msdmed.ru</span></a>
					</div>
				</div>
			</div>
		</div>
  	</div>
</template>

<script>
export default {
	name: "RegisterPassword",
	data() {
		return {
			registerPassword: '',
			registerPasswordRepeat: '',

			inputsValid: {
				registerPassword: false,
				registerPasswordRepeat: false
			}
		}
	},
	computed: {
		formValid() {
			return this.inputsValid.registerPassword && this.inputsValid.registerPasswordRepeat && this.isPasswordMatch
		},
		isPasswordMatch() {
			return this.registerPassword === this.registerPasswordRepeat
		}
	},
	methods: {
		checkValidity(event) {
			this.inputsValid[event.target.name] = event.target.validity.valid
		}
	}
}
</script>

<style>

</style>

<style scoped>

</style>