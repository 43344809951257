<template>
<Widget
	title="Покрытие"
	:isLoading="w_isLoading"
	:isError="w_isError"
	:showEmptyMessage="Object.keys(allCoverage).length == 0"
	>
    <div style="position: relative; width: 192px;height: 192px;">
		<div class="pie-chart__featured" v-if="Object.keys(allCoverage).length">
			{{ allCoverage[1].percent }}
			<span v-if="allCoverage[1].percent > 0">%</span>
		</div>
		<div v-else class="pie-chart__featured">-</div>
        <CoverChart v-if="Object.keys(allCoverage).length" :chartData="allCoverage"></CoverChart>
    </div>
    <div v-if="Object.keys(allCoverage).length" class="pie-chart-legend">
        <div class="pie-chart-legend__item">
            <div class="pie-chart-legend__label primary">MSD</div>
            <span class="font-weight-bold mr-3">{{ allCoverage[1].percent }}%</span>
            <span class="text-muted">{{ allCoverage[1].count | digitSpace1k }} ЛПУ</span>
        </div>
        <div class="pie-chart-legend__item">
            <div class="pie-chart-legend__label">без покрытия</div>
            <span class="font-weight-bold mr-3">{{ allCoverage[null].percent }}%</span>
            <span class="text-muted">{{ allCoverage[null].count | digitSpace1k }} ЛПУ</span>
        </div>
    </div>
</Widget>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../../../log'
import Widget from '../Widget'
import CoverChart from '../../blocks/CoverChart'

export default {
	name: "CoverWidget",
	components: {Widget, CoverChart},
	async beforeMount() {
		log.d(`CoverWidget | beforeMount`)
		await this.loadRegionPartners()
		this.setPartners()
	},
	data() {
		return {
			partners: []
		}
	},
	props: ['regions'],
	watch:{
		regions: function() {
			if (!this.w_isLoading) {
				this.setPartners()
			}
		}
	},
	computed: {
		allCoverage() {
			let obj = {
			}

			this.partners.forEach(el => {
				el.partners.forEach(partner => {
					if (!obj.hasOwnProperty(partner.partnerId)) {
						obj[partner.partnerId] = {
							count: 0,
							percent: 0
						}
					}
					obj[partner.partnerId].count += partner.count

					let percent = obj[partner.partnerId].count / this.allClinicsCountByRegions * 100;
					obj[partner.partnerId].percent = Math.round(percent * 10) / 10
				})
			})

			return obj
		},
        ...mapState('regionsCover', [
			'regionPartners',
			'w_errors',
		]),
		...mapGetters('regionsCover', [
			'w_isLoading',
			'w_isError',
		]),
		...mapGetters('regionsClinics', [
			'allClinicsCountByRegions'
		]),
	},
	methods: {
		setPartners() {
			this.partners = []
			this.regions.forEach(regionId => {
				this.partners.push(this.regionPartners[regionId])
			})
		},
		...mapActions('regionsCover', [
			'loadRegionPartners'
		])
	},
}
</script>

<style>

</style>

<style scoped>

</style>