<template>
  	<div class="page-content pb-6 d-flex flex-column">
	  	<div class="container">
			<h1 class="mb-5">Профиль</h1>
			<div class="box pt-6" :class="{ 'box_loading' : isLoading == true }">
				<div v-if="users && users[getSelectedUser]">
					<div class="row mb-4">
						<div class="col-md-6">
							<div>
								<div class="fsz-sm text-muted mb-3">Личные даные</div>
								<div class="mb-4">{{ users[getSelectedUser].name }}</div>
								<div class="mb-4">{{ users[getSelectedUser].email }}</div>
							</div>

							<div class="mt-6" v-if="cUser.manager">
								<div class="fsz-sm text-muted mb-3">Руководитель</div>
								<div class="mb-4">{{cUser.manager.name}}</div>
								<div class="mb-4">{{cUser.manager.email}}</div>
							</div>
						</div>

						<div class="col-md-6" v-if="cUser.regions">
							<div class="fsz-sm text-muted mb-3">Территория</div>
							<div
								class="mb-4"
								v-for="regionId of cUser.regions"
								:key="regionId"
							>
								<span v-if="userRegionsObj[regionId]">{{userRegionsObj[regionId].nm}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container mt-auto">
			<a @click="logout" class="btn btn-link btn-link-lg px-0">Выйти из аккаунта</a>
		</div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
	name: "Profile",
	props: ['id'],
	components: {},

	async mounted () {
		await this.$store.dispatch('user/getUserById', this.id)
	},

	computed: {
		cUser() {
			return this.users[this.getSelectedUser]
		},

		getSelectedUser() {
			return this.id || this.authId
		},


		...mapState('auth', {
			authId: state => state.id,
			authName: state => state.name,
			authEmail: state => state.email,
			authRole: state => state.role,
			authMsdRole: state => state.msdRole,
			authDivisions: state => state.divisions,
		}),

		...mapState('user', [
			'users',
		]),
		...mapGetters('user', [
			'isLoading',
		]),

		...mapGetters('common', [
			'userRegionsObj',
		]),
	},

	methods: {
		...mapActions('auth', [
			'logout'
		]),
	},
}
</script>

<style>

</style>

<style scoped>

</style>