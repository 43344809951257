<template>
<Widget
	title="Последние визиты"
	url="/visits"
	:showEmptyMessage="lastVisits.length == 0"
	:isLoading="w_isLoading"
	:isError="w_isError"
	:errors="w_errors"
	:isHiddenLoading="lastVisits && lastVisits.length>0"
	loadingMinHeight="200px"
	>
	<div class="box__body">
		<div v-for="lastVisit in lastVisits.slice(0, 3)" :key="lastVisit.id" class="row flex-nowrap mb-4">
			<div class="col fsz-sm">
				<router-link :to="`clinic/${lastVisit.clinic.id}/passport`">{{lastVisit.clinic.name}}</router-link>
				<div class="text-muted mt-2">{{ lastVisit.timestamp | moment("DD.MM.YY, HH:mm") }}</div>
			</div>
			<div class="col-auto box__label text-primary">рассчитано: {{lastVisit.calcCount | digitSpace1k}}</div>
		</div>
	</div>
</Widget>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Widget from './../Widget'

export default {
	name: "ListVisitsWidget",
	components: {Widget},
	async beforeMount() {
		// console.debug(`ListVisitsWidget | beforeMount`)
		await this.loadVisits({count:10})
	},
	data() {
		return {
			
		}
	},
	computed: {
		...mapState('userLastVisits', [
			'lastVisits',
			'w_errors',
		]),
		...mapGetters('userLastVisits', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		...mapActions('userLastVisits', [
			'loadVisits'
		]),
	},
}
</script>

<style>

</style>

<style scoped>

</style>