import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import clinicApi from '../../api/clinic.api'
import config from '../../config.loader'
import tealium from '../../tealium'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

const _name = 'ClinicQuestCommon'

// initial state
const state = {
	w_name: 'ClinicQuestCommon',
	w_loadingCount: 0,
	w_errors: [],
	w_netStatus: {},
	
	questCommons: {},
	isSchoolExamination: null,
	isKindergartenExamination: null,

	headPositionTimeout: null,
	injectionPriceTimeout: null,
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadClinicQuestCommon({state, commit, dispatch, rootState}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | loadClinicQuestCommon`, reqObj)
		commit('w_loadingPlus')
		
		let req = await clinicApi.getQuestCommon(reqObj.id)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		// if (req.json.status === 'ok') {
		// 	reqObj.loaded()
		// }

		commit('loadClinicQuestCommon', {clinicId: reqObj.id, ...data})
		commit('setIsSchoolExamination', {clinicId: reqObj.id, value: null})
		commit('setIsKindergartenExamination', {clinicId: reqObj.id, value: null})

		commit('w_loadingMinus')
	},

	async saveHeadPosition({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | saveHeadPosition`, reqObj)
		commit('saveHeadPosition', reqObj)
		clinicApi.postQuestCommon(reqObj.id, {sales: {head_position_id: reqObj.headPositionId}})
		tealium.sendForData({ "event_category" : "form",  "event_action" : "sumbit",  "event_label":"questionary"})
	},

	async saveInjectionPrice({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | saveInjectionPrice`, reqObj)
		commit('saveInjectionPrice', reqObj)

		commit('w_setTimer', {
			id: reqObj.id + reqObj.name,
			action: ()=>{
				clinicApi.postQuestCommon(reqObj.id, {sales: {injection_price: reqObj.injectionPrice}})
				tealium.sendForData({ "event_category" : "form",  "event_action" : "sumbit",  "event_label":"questionary"})
			},
		})
	},

	async saveDistributorId({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | saveDistributorId`, reqObj)
		commit('saveDistributorId', reqObj)
		clinicApi.postQuestCommon(reqObj.clinicId, {products_data: {[reqObj.productId]: {distributor_id: reqObj.value}}})
		tealium.sendForData({ "event_category" : "form",  "event_action" : "sumbit",  "event_label":"questionary"})
	},

	async saveDistributorName({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | saveDistributorName`, reqObj)
		commit('saveDistributorName', reqObj)

		commit('w_setTimer', {
			id: reqObj.productId + reqObj.value,
			action: ()=>{
				clinicApi.postQuestCommon(reqObj.clinicId, {products_data: {[reqObj.productId]: {distributor_nm: reqObj.value}}})
				tealium.sendForData({ "event_category" : "form",  "event_action" : "sumbit",  "event_label":"questionary"})
			},
		})
	},

	async saveDistributorPrice({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | saveDistributorPrice`, reqObj)
		commit('saveDistributorPrice', reqObj)
		
		commit('w_setTimer', {
			id: reqObj.productId,
			action: ()=>{
				clinicApi.postQuestCommon(reqObj.clinicId, {products_data: {[reqObj.productId]: {distributor_price: reqObj.value}}})
				tealium.sendForData({ "event_category" : "form",  "event_action" : "sumbit",  "event_label":"questionary"})
			},
		})
	},

	async saveCallPatients({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | saveCallPatients`, reqObj)
		commit('saveCallPatients', reqObj)
		clinicApi.postQuestCommon(reqObj.clinicId, {patients: {call_patients: reqObj.value}})
		tealium.sendForData({ "event_category" : "form",  "event_action" : "sumbit",  "event_label":"questionary"})
	},

	async saveMonthPatientFlow({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | saveMonthPatientFlow`, reqObj)
		commit('saveMonthPatientFlow', reqObj)

		commit('w_setTimer', {
			id: reqObj.clinicId + reqObj.name,
			action: ()=>{
				clinicApi.postQuestCommon(reqObj.clinicId, {patients: {month_patient_flow: reqObj.value}})
				tealium.sendForData({ "event_category" : "form",  "event_action" : "sumbit",  "event_label":"questionary"})
			},
		})
	},

	async saveSchoolExaminationCnt({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | saveSchoolExaminationCnt`, reqObj)
		commit('saveSchoolExaminationCnt', reqObj)
		commit('w_setTimer', {
			id: reqObj.clinicId + reqObj.name,
			action: ()=>{
				clinicApi.postQuestCommon(reqObj.clinicId, {patients: {school_examination_cnt: reqObj.value}})
				tealium.sendForData({ "event_category" : "form",  "event_action" : "sumbit",  "event_label":"questionary"})
			},
		})
	},
	
	async saveSchoolVaccinationLevel({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | saveSchoolVaccinationLevel`, reqObj)
		commit('saveSchoolVaccinationLevel', reqObj)
		clinicApi.postQuestCommon(reqObj.clinicId, {patients: {school_vaccination_level: reqObj.value}})
		tealium.sendForData({ "event_category" : "form",  "event_action" : "sumbit",  "event_label":"questionary"})
	},
	
	async saveKindergartenExaminationCnt({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | saveKindergartenExaminationCnt`, reqObj)
		commit('saveKindergartenExaminationCnt', reqObj)
		commit('w_setTimer', {
			id: reqObj.clinicId + reqObj.name,
			action: ()=>{
				clinicApi.postQuestCommon(reqObj.clinicId, {patients: {kindergarten_examination_cnt: reqObj.value}})
				tealium.sendForData({ "event_category" : "form",  "event_action" : "sumbit",  "event_label":"questionary"})
			},
		})
	},

	async saveKindergartenVaccinationLevel({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | saveKindergartenVaccinationLevel`, reqObj)
		commit('saveKindergartenVaccinationLevel', reqObj)
		clinicApi.postQuestCommon(reqObj.clinicId, {patients: {kindergarten_vaccination_level: reqObj.value}})
		tealium.sendForData({ "event_category" : "form",  "event_action" : "sumbit",  "event_label":"questionary"})
	},

}


// mutations
const mutations = {
	loadClinicQuestCommon(state, d) {
		log.d(`Store | ${_name} | Mutations | loadClinicQuestCommon`, d)

		let f = d.form
		let result = {
			patients: {
				monthPatientFlow: null,
				callPatients: null,
				schoolExaminationCnt: null,
				schoolVaccinationLevel: null,
				kindergartenExaminationCnt: null,
				kindergartenVaccinationLevel: null,
			},
			distributor: {},
			sales: {},
			productsData: [],
		}
		
		if (f.patients) {
			result.patients.monthPatientFlow = f.patients.monthPatientFlow
			result.patients.callPatients = f.patients.callPatients
			result.patients.schoolExaminationCnt = f.patients.schoolExaminationCnt
			result.patients.schoolVaccinationLevel = f.patients.schoolVaccinationLevel
			result.patients.kindergartenExaminationCnt = f.patients.kindergartenExaminationCnt
			result.patients.kindergartenVaccinationLevel = f.patients.kindergartenVaccinationLevel
		}

		result.distributor = f.distributor || null

		if (f.sales) {
			result.sales = {
				headName: f.sales.headName || null,
				headPositionId: f.sales.headPositionId || null,
				injectionPrice: f.sales.injectionPrice || null,
				wholesaleAllowance: f.sales.wholesaleAllowance || null,
				retailAllowance: f.sales.retailAllowance || null,
			}
		}
		result.productsData = f.productsData || []

		Vue.set(state.questCommons, d.clinicId, result)
	},
	setIsSchoolExamination(state, d)  {
		log.d(`Store | ${_name} | Mutations | setIsSchoolExamination`, d)
		if (d.value === null) {
			if (state.questCommons[d.clinicId].patients.schoolExaminationCnt > 0 || state.questCommons[d.clinicId].patients.schoolVaccinationLevel) {
				state.isSchoolExamination = 1
			} else {
				state.isSchoolExamination = 0
			}
		} else {
			state.isSchoolExamination = d.value
		}
	},
	setIsKindergartenExamination(state, d)  {
		log.d(`Store | ${_name} | Mutations | setIsKindergartenExamination`, d)
		if (d.value === null) {
			if (state.questCommons[d.clinicId].patients.kindergartenExaminationCnt > 0 || state.questCommons[d.clinicId].patients.kindergartenVaccinationLevel) {
				state.isKindergartenExamination = 1
			} else {
				state.isKindergartenExamination = 0
			}
		} else {
			state.isKindergartenExamination = d.value
		}
	},

	saveHeadPosition(state, d) {
		log.d(`Store | ${_name} | Mutations | saveHeadPosition`, d)
		Vue.set(state.questCommons[d.id].sales, 'headPositionId', d.headPositionId)
	},

	saveInjectionPrice(state, d) {
		log.d(`Store | ${_name} | Mutations | saveInjectionPrice`, d)
		state.questCommons[d.id].sales.injectionPrice = d.injectionPrice
	},

	saveDistributorId(state, d) {
		log.d(`Store | ${_name} | Mutations | saveDistributorId`, d)
		let questCommons = {...state.questCommons}
		let index = questCommons[d.clinicId].productsData.findIndex(el => el.productID == d.productId);
		
		questCommons[d.clinicId].productsData[index].distributorId = d.value
		state.questCommons = questCommons
	},

	saveDistributorName(state, d) {
		log.d(`Store | ${_name} | Mutations | saveDistributorName`, d)
		let questCommons = {...state.questCommons}
		
		let index = questCommons[d.clinicId].productsData.findIndex(el => el.productID == d.productId);

		questCommons[d.clinicId].productsData[index].distributorName = d.value
		state.questCommons = questCommons
	},
	
	saveDistributorPrice(state, d) {
		log.d(`Store | ${_name} | Mutations | saveDistributorPrice`, d)
		let index = state.questCommons[d.clinicId].productsData.findIndex(el => el.productID == d.productId)
		state.questCommons[d.clinicId].productsData[index].distributorPrice = d.value
	},

	saveCallPatients(state, d) {
		log.d(`Store | ${_name} | Mutations | saveCallPatients`, d)
		state.questCommons[d.clinicId].patients.callPatients = d.value
	},
	saveMonthPatientFlow(state, d) {
		log.d(`Store | ${_name} | Mutations | saveMonthPatientFlow`, d)
		state.questCommons[d.clinicId].patients.monthPatientFlow = d.value
	},
	saveSchoolExaminationCnt(state, d) {
		log.d(`Store | ${_name} | Mutations | saveSchoolExaminationCnt`, d)
		state.questCommons[d.clinicId].patients.schoolExaminationCnt = d.value
	},
	saveSchoolVaccinationLevel(state, d) {
		log.d(`Store | ${_name} | Mutations | saveSchoolVaccinationLevel`, d)
		state.questCommons[d.clinicId].patients.schoolVaccinationLevel = d.value
	},
	saveKindergartenExaminationCnt(state, d) {
		log.d(`Store | ${_name} | Mutations | saveKindergartenExaminationCnt`, d)
		state.questCommons[d.clinicId].patients.kindergartenExaminationCnt = d.value
	},
	saveKindergartenVaccinationLevel(state, d) {
		log.d(`Store | ${_name} | Mutations | saveKindergartenVaccinationLevel`, d)
		state.questCommons[d.clinicId].patients.kindergartenVaccinationLevel = d.value
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}