<template>
	<div class="page-wrap">
		<transition name="fade">
			<div class="global-error-wrapper" v-if="common_w_errors">
				<div class="global-error">
					<div
						class="alert alert-danger alert-dismissible fade show"
						v-for="err of common_w_errors">
						{{err}}
						<button type="button" class="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
			</div>
		</transition>
		
		<LoadingScreen :enabled="isLoading || common_w_isLoading"></LoadingScreen>

		<header class="page-header" v-if="isAuth">
			<div class="container">
				<div class="page-header__row">
					<router-link class="page-header__logo" to="/">
						<img src="./../assets/images/logo-msd-commerce.svg" />
					</router-link>
					<div id="mainNavCollapse" class="page-header__collapse" :class="{ 'expanded': menuExpanded }">
						<nav class="page-header__menu">
							<ul class="nav">
								<li @click="toggleMenu" class="nav-item">
									<router-link :class="{'active':isSubActive(['toVisit','visits','news'])}" class="nav-link" active-class="active" to="/activity">Активность</router-link>
								</li>
								
								<li @click="toggleMenu" class="nav-item">
									<router-link :class="{'active':isSubActive(['regionSales', 'userSales'])}" class="nav-link" active-class="active" to="/regions">Регионы</router-link>
								</li>

								<li @click="toggleMenu" class="nav-item">
									<router-link :class="{'active':isSubActive(['clinic'])}" class="nav-link" active-class="active" to="/clinics">Клиники</router-link>
								</li>

								<li @click="toggleMenu" class="nav-item">
									<router-link class="nav-link" active-class="active" to="/reports">Отчёты</router-link>
								</li>

							</ul>
						</nav>
						<div class="page-header__profile">
							<!-- <button class="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="16" cy="16" r="16" fill="#6ECEB2"/><path d="M24 26v-4a4 4 0 00-4-4h-8a4 4 0 00-4 4v4M16 16a5 5 0 100-10 5 5 0 000 10z" fill="#fff"/></svg>
							</button>
							<div class="dropdown-menu dropdown-menu-right">
								<router-link class="nav-link" to="/profile">{{authName}}</router-link>
								<router-link class="nav-link" to="/profile/2">2</router-link>
								<router-link class="nav-link" to="/profile/3">3</router-link>
							</div> -->
							<router-link class="nav-link" active-class="active" to="/profile">
								<svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="16" cy="16" r="16" fill="#6ECEB2"/><path d="M24 26v-4a4 4 0 00-4-4h-8a4 4 0 00-4 4v4M16 16a5 5 0 100-10 5 5 0 000 10z" fill="#fff"/></svg>
							</router-link>
						</div>
					</div>
					<div class="burger-container" :class="{ 'bar-opened': menuExpanded }" @click="toggleMenu">
						<div id="burger">
							<div class="bar topBar"></div>
							<div class="bar btmBar"></div>
						</div>
					</div>
				</div>
			</div>
		</header>
		
		<div
			class="bg-warning py-2"
			v-if="systemMessages && systemMessages.length>0"
			>
			<div
				class="container"
				v-for="systemMessage of systemMessages"
				:key="systemMessage.id || systemMessage"
				>{{systemMessage}}</div>
		</div>
		
		<!-- <keep-alive> -->
			<router-view :key="$route.fullPath"></router-view>
		<!-- </keep-alive> -->

		<Footer v-if="isAuth"></Footer>
		
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Footer from './blocks/Footer'
import LoadingScreen from './blocks/LoadingScreen'
import config from '../config.loader'

export default {
	name: "Main",
	mixins: [],
	components: {
		Footer,
		LoadingScreen,
	},
	
	data() {
		return {
			componentStatus: 'ok',
			menuExpanded: false
		}
	},

	async beforeMount() {
		await this.$store.dispatch('auth/loadFromStorage')
		await this.$store.dispatch('auth/checkIsAuth')
	},

	async updated() {
		// if (this.isAuth) {
		// 	console.debug('logged')
		// } else {
		// 	console.debug('notlogged')
		// }
	},
	mounted() {
		
	},

	computed: {
		...mapState('auth', {
			authId: state => state.id,
			authName: state => state.name,
			authEmail: state => state.email,
			authRole: state => state.role,
			authMessage: state => state.message,
			authRoles: state => state.roles,
		}),
		...mapGetters('auth', {
			authDisplayName: 'displayName',
		}),
		...mapGetters('auth', [
			'isAuth',
			'isLoading',
		]),
		...mapState('common', {
			common_w_errors: 'w_errors',
		}),
		...mapGetters('common', {
			common_w_isLoading: 'w_isLoading',
		}),
		...mapState('common', [
			'systemMessages'
		]),
	},

	methods: {
		...mapActions('auth', [
			'logout',
		]),
		toggleMenu() {
			if (window.matchMedia('(max-width: 992px)').matches) {
				this.menuExpanded = !this.menuExpanded
				$('body').toggleClass('main-nav-expanded');
			}
		},
		isSubActive(find) {
			for (let el of find) {
				if (this.$route.path.indexOf(el)==1) {return true}
			}
			return false
		}
	},
}
</script>

<style>
	[v-cloak] { display:none; }
</style>

<style scoped>
</style>