<template>
	<div class="box" id="quizCohorts" :class="{ 'box_loading' : w_isLoading == true, 'box_error' : w_isError == true }">
		<div class="row align-items-center mb-2">
			<div class="col-sm-7">
				<div class="h1 mb-3">Когорты <small class="opacity-3">(в месяц)</small></div>
			</div>
			<!-- <div class="col-sm-5 text-sm-right">
				<div class="switch">
					<input type="checkbox" data-period="" name="period" class="switch-input js-period-toggler" id="period">
					<label class="switch-label" for="period">в месяц</label>
					<label class="switch-label" for="period">в год</label>
					<label class="switch-appearance" for="period"></label>
				</div>
			</div> -->
		</div>
		<div
			class="row row-cols-2 row-cols-md-3 mb-3 pr-xl-10"
			v-if="orderedCPatientCategories"
			>
			<div
				class="col"
				v-for="category of orderedCPatientCategories"
				:key="category.id"
				>

				<InputBlock
					:disabled="!role_manager_editor"
					class="form-item-ui"
					inputClass="numeric"
					:value="getPatientCategoryCnt(category.id)"
					@input="e=>{setPatientCategory(category.id, e)}"
					unit="чел."
					>
						<label
							class="fsz-sm"
							slot="label"
							:for="`category-${category.id}-m`"
							>
								{{category.nm}}
								<span v-if="category.sex!=null && category.sex==0">Ж</span>
								<span v-if="category.sex!=null && category.sex==1">М</span>
						</label>
					</InputBlock>

<!-- 
				<div class="my-2 form-item-ui" :class="{'has-value': category.cnt}">
					<label
						class="fsz-sm"
						:for="`category-${category.id}-m`"
						>
							{{category.nm}}
							<span v-if="category.sex!=null && category.sex==0">Ж</span>
							<span v-if="category.sex!=null && category.sex==1">М</span>
					</label>
					<div class="input-group input-group--unit">
						<input
							type="text"
							class="form-control numeric"
							:id="`category-${category.id}-m`"
							:value="getPatientCategoryCnt(category.id)"
							:size="getPatientCategoryCnt(category.id).length"
							@input="e=>{setPatientCategory(category.id, e.target.value)}"
							>
						<label :for="`category-${category.id}-m`" class="input-group-text">
							<span v-if="category.cnt">чел.</span>
						</label>
					</div>
				</div>
 -->

			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../../../log'
import BoxCollapse from "../../blocks/BoxCollapse"
import InputBlock from '../../blocks/InputBlock'

export default {
	name: "QuestCohorts",
	components: {
		BoxCollapse,
		InputBlock,
	},
	data() {
		return {
			ccId: this.$route.params.id,
		}
	},
	async beforeMount() {
		log.d(`QuestCohorts | beforeMount`, this.$route.params.id)
		
		await this.loadPatientCategories({id: this.$route.params.id})
	},
	computed: {
		...mapState('clinicQuestPatientCategory', [
			'patientCategories',

			'w_errors',
		]),
		...mapGetters('clinicQuestPatientCategory', [
			'w_isLoading',
			'w_isError',
		]),

		...mapState('common', [
			'inputSendTimeout',
		]),
		...mapGetters('common', [

		]),


		/**
		 * Получение категорий для текущей клиники
		 */
		cPatientCategories() {
			if(this.patientCategories && this.patientCategories[this.ccId]) {
				return this.patientCategories[this.ccId]
			}
			return {}
		},
		
		/**
		 * Получение объекта patients анкеты для текущей клиники
		 */
		orderedCPatientCategories() {
			let result = []
			for (let el in this.cPatientCategories) {
				result.push(this.cPatientCategories[el])
			}
			return result.sort((a, b) => (a.orderby > b.orderby) ? 1 : -1)
		},

		/**
		 * Цена услуги
		 */
		cInjectionPrice: {
			get() {
				if (this.cQuestCommon && this.cQuestCommon.sales && this.cQuestCommon.sales.injectionPrice) {
					return this.cQuestCommon.sales.injectionPrice
				}
				return null
			},
			set(value) {
				clearTimeout(this.cInjectionPriceTimer)
				this.cInjectionPriceTimer = setTimeout(()=>{
					this.saveInjectionPrice({id: this.$route.params.id, injectionPrice: value})
				}, this.inputSendTimeout)
				return
			},
		},

		
	},
	methods: {

		...mapActions('clinicQuestPatientCategory', [
			'loadPatientCategories',
			'savePatientCategory',
		]),
		getPatientCategoryCnt(categoryId) {
			let cnt =  this.orderedCPatientCategories.find(category => category.id === categoryId).cnt

			return cnt
			// return this.$options.filters.digitSpace1k(cnt)
		},

		/**
		 * Сохранение данных о категории пациентов (когорты) в клинике
		 */
		setPatientCategory(categoryId, value) {
			this.savePatientCategory({clinicId: this.ccId, categoryId: categoryId, value: value.replace(/\s+/g,'')})
		},

	}
}
</script>

<style>

</style>

<style scoped>

</style>