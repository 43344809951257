<template>
<Widget
	title="Новости"
	url="/news"
	:showEmptyMessage="news.length==0"
	:isLoading="w_isLoading"
	:isError="w_isError"
	:errors="w_errors"
	:isHiddenLoading="news && news.length>0"
	loadingMinHeight="200px"
	>
	<div class="box__body">
		<div
			class="row flex-nowrap mb-3"
			v-for="newsEl in news.slice(0, 3)"
			:key="newsEl.id"
			>
			<div class="col fsz-sm">
				<span :class="{ 'text-deleted' : newsEl.status == 'notUpdated' }">{{newsEl.clinic.name}}</span>
				<div class="text-muted mt-2">{{ newsEl.timestamp | moment("DD.MM.YY, HH:mm") }}</div>
			</div>
			<div
				v-if="newsEl.status == 'new'"
				class="col-auto box__label text-primary"
				>добавлена</div>
			<div 
				v-if="newsEl.status == 'notUpdated'" 
				class="col-auto box__label text-danger"
				>
				<ClinicDeletionType
					:visitCount="newsEl.clinic.visitCount"></ClinicDeletionType>
			</div>
		</div>
	</div>
</Widget>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ClinicDeletionType from '../../blocks/ClinicDeletionType'
import Widget from './../Widget'

export default {
	name: "NewsWidget",
	components: {
		Widget,
		ClinicDeletionType,
	},
	async beforeMount() {
		// console.debug(`NewsWidget | beforeMount`)
		await this.loadNews({count:10})
	},
	data() {
		return {
			
		}
	},
	computed: {
		...mapState('userNews', [
			'news',
			'w_errors',
		]),
		...mapGetters('userNews', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		...mapActions('userNews', [
			'loadNews'
		]),
	},
}
</script>

<style>

</style>

<style scoped>

</style>