<template>
  <div class="page-content pt-3 bg-white">
		<div class="container">
			<BtnBackArrow></BtnBackArrow>
			<h1 class="mt-3 mb-4">К посещению</h1>
			<div class="table-responsive">
				<table class="table table-has-controls table-to-visit">
					<tr hidden>
						<td class="pl-0"><SearchString v-model="searchStringValue" @submit="submitSearchString"></SearchString></td>
						<td></td>
						<td></td>
						<td><SortControl label="продажи"></SortControl></td>
						<td></td>
					</tr>
					<tr>
						<th>Наименование ЛПУ</th>
						<th>Город</th>
						<th>Адрес</th>
						<th>Факт/план продаж (дозы)</th>
						<th></th>
					</tr>
					<tr v-for="toVisitsEl in toVisits" :key="toVisitsEl.id">
						<td class="font-weight-bold fsz-base">
							<router-link :to="{ path: `/clinic/${toVisitsEl.clinic.id}/passport` }">
								{{toVisitsEl.clinic.name}}
							</router-link>
						</td>
						<td>{{toVisitsEl.clinic.city}}</td>
						<td>{{toVisitsEl.clinic.address}}</td>
						<td class="fsz-base">
							{{toVisitsEl.sell.actual | int}}
							<span class="text-muted">/{{toVisitsEl.sell.plan | int}}</span>
						</td>
						<td class="fsz-base">
							<div v-if="divisionVaccines" class="d-flex align-items-center">
								<div v-if="toVisitsEl.sell && toVisitsEl.sell.mark" class="col-auto pr-2">
									<span v-if="toVisitsEl.sell.mark == 'high'">
										<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill="#FF0000" d="M7.138 12.472a.667.667 0 11.944-.944l3.251 3.252V7.333a.667.667 0 011.334 0v7.447l3.251-3.252a.667.667 0 11.944.944L12 17.332l-4.862-4.861z"/>
											<rect stroke="#FF0000" x=".5" y=".5" width="23" height="23" rx="11.5"/>
										</svg>
									</span>
									<span v-if="toVisitsEl.sell.mark == 'medium'">
										<svg style="transform: rotate(180deg);" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill="#FFCC33" d="M7.138 12.472a.667.667 0 11.944-.944l3.251 3.252V7.333a.667.667 0 011.334 0v7.447l3.251-3.252a.667.667 0 11.944.944L12 17.332l-4.862-4.861z"/>
											<rect stroke="#FFCC33" x=".5" y=".5" width="23" height="23" rx="11.5"/>
										</svg>
									</span>
									<span v-if="toVisitsEl.sell.mark == 'low'">
										<svg style="transform: rotate(180deg);" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill="#6ECEB2" d="M7.138 12.472a.667.667 0 11.944-.944l3.251 3.252V7.333a.667.667 0 011.334 0v7.447l3.251-3.252a.667.667 0 11.944.944L12 17.332l-4.862-4.861z"/>
											<rect stroke="#6ECEB2" x=".5" y=".5" width="23" height="23" rx="11.5"/>
										</svg>
									</span>
								</div>
								<div
									v-if="toVisitsEl.sell && toVisitsEl.sell.pva >= 0"
									class="col-auto pl-1 mb-0"
									:class="{
										'text-danger': toVisitsEl.sell.mark=='high',
										'text-warning': toVisitsEl.sell.mark=='medium',
										'text-green': toVisitsEl.sell.mark=='low',
									}"
									>{{toPercent(toVisitsEl.sell.pva) }} %</div>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import log from './../log'
import BtnBackArrow from './blocks/BtnBackArrow'
import SearchString from './blocks/SearchString'
import ClinicDeletionType from './blocks/ClinicDeletionType'
import ClinicsSortWidget from './widgets/Clinics/ClinicsSort.widget'
import SortControl from './blocks/SortControl'

export default {
	name: "ClinicsToVisit",

	components: {
		BtnBackArrow,
		SearchString,
		ClinicDeletionType,
		ClinicsSortWidget,
		SortControl
	},

	async beforeMount() {
		log.d(`ClinicsToVisit | beforeMount`)
		await this.loadToVisits({count:10})
	},
	data() {
		return {
			
		}
	},
	computed: {
		searchStringValue: {
            get() {
                return this.searchString
            },
            set(value) {
                return this.setSearchString(value)
            }
        },
		...mapState('userToVisit', [
			'toVisits',
			'w_errors',
		]),
		...mapGetters('userToVisit', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		submitSearchString() {
            this.clearSearchTimeout()
            this.handleSearchTimeout()
        },
		toPercent(value=0) {
			return parseInt(value*100)
		},
		...mapActions('userToVisit', [
			'loadToVisits'
		]),
	},

}
</script>

<style>

</style>

<style scoped>
	.text-green {
		color: #6ECEB2 !important;
	}
</style>