import {getT, postT, putT} from './api.js'
import log from '../log'

const _name = 'Clinic'

export default {
    /**
	 * Получение клиники
	 * API
	 * 
	 * @param {number|null} id клиника
	 * @returns 
	 */
	async getClinic(id) {
        log.d(`Api | ${_name} | getClinic`, id)
        let clinicString = ''
		if (id && id>0) {
			clinicString = `/${id}`
		}
		let response = await getT(`clinic${clinicString}`)
		return response
    },

	 /**
	 * Получение списка выбранных регионов с количеством клиник
	 * API
	 * 
	 * @param {number|null} id регион
	 * @returns 
	 */
	  async getClinicCountByRegions(regions) {
        log.d(`Api | ${_name} | getClinicCountByRegions`, regions)
		let response = await getT(`clinic/countByRegions`, regions)
		return response
    },

	/**
	 * Получение списка категорий пациентов(когорт) для клиники
	 * API
	 * 
	 * @param {*} id id клиники
	 * @returns 
	 */
	async getClinicPatientCategories(id) {
		log.d(`Api | ${_name} | getClinicCountByRegions`, id)
		let response = await getT(`clinic/${id}/patientCategory`)
		return response
	},

	/**
	 * Получение списка расчетов калькулятора
	 * API
	 * 
	 * @param {*} id 
	 * @returns 
	 */
	async getCalcHistoryList(id, direction=0) {
		log.d(`Api | ${_name} | getCalcHistoryList`, id)
		let response = await getT(`clinic/${id}/calcHistory/list`, {'direction': direction})
		return response
	},

	/**
	 * Получение расчетов калькулятора по ид визита
	 * API
	 * 
	 * @param {*} cid 
	 * @param {*} vid 
	 * @returns 
	 */
	async getCalcHistoryElement(cid, vid) {
		log.d(`Api | ${_name} | getCalcHistoryList`, cid, vid)
		let response = await getT(`clinic/${cid}/calcHistory/${vid}`)
		return response
	},

	/**
	 * Отправка расчетов калькулятора
	 * API
	 * 
	 * @param {*} cid - ID клиники
	 * @param {*} data - данные рассчетов
	 * product_id - ID продукта
	 * old - tinyint ?
	 * 
	 * essential-price - Зарег. цена ЖНВЛП
	 * wholesale-allowance - Опт. надбавка, %
	 * retail-allowance - Розн. надбавка, %
	 * retail-increase - Розн. надбавка, ₽
	 * retail-price-with-vat - Розн. цена с НДС, ₽
	 * 
	 * purchased-doses-now-year - Закупленных доз сейчас
	 * vaccination-coverage-now-year - Охват вакцинацией % сейчас
	 * vaccined-number-now-year - Число вакцинируемых сейчас
	 * vaccination-price-now-year - Цена услуги сейчас
	 * 
	 * purchased-doses-after-year - Закупленных доз после
	 * vaccination-coverage-after-year - Охват вакцинацией после
	 * vaccined-number-after-year - Число вакцинируемых после
	 * vaccination-price-after-year - Цена услуги после
	 * 
	 * benefit-now-year - Выгода сейчас сейчас
	 * benefit-after-year - Выгода после после
	 * lost-benefit-year - Упущенная прибыль
	 * 
	 */
	async postCalcHistoryElement(cid, productId, data) {
		log.d(`Api | ${_name} | postCalcHistoryElement`, cid, productId, data)
		let response = await postT(`clinic/${cid}/calcHistory`, 
			{
				datum: {
					'product_id': productId,
					'essential-price': data.essentialPrice || data.buyPrice,
					'wholesale-allowance': data.wholesaleAllowance,
					'retail-allowance': data.retailAllowance,
					'retail-increase': data.retailIncrease,
					'retail-price-with-vat': data.retailPriceWithVat,

					'purchased-doses-now-year': data.purchasedDosesNow,
					'vaccination-coverage-now-year': data.vaccinationCoverageNow,
					'vaccined-number-now-year': data.vaccinedNumberNowYear,
					'vaccination-price-now-year': data.vaccinationPriceNow,

					'purchased-doses-after-year': data.purchasedDosesAfter,
					'vaccination-coverage-after-year': data.vaccinationCoverageAfter,
					'vaccined-number-after-year': data.vaccinedNumberAfter,
					'vaccination-price-after-year': data.vaccinationPriceAfter,

					'benefit-now-year': data.benefitNow,
					'benefit-after-year': data.benefitAfter,
					'lost-benefit-year': data.lostBenefit,
				}	
			}
		)
		// TODO: запилить запрос
		return response
	},

	/**
	 * Получение ответов на общую анкету
	 * API
	 * 
	 * @param {*} id 
	 * @returns 
	 */
	async getQuestCommon(id) {
		log.d(`Api | ${_name} | getQuestCommon`, id)
		let response = await getT(`clinic/${id}/form/common`)
		return response
	},

	/**
	 * Отправка данных для общей анкеты
	 * API
	 * 
	 * @param {*} id 
	 * @param {*} data 
	 * @returns 
	 */
	async postQuestCommon(id, data) {
		log.d(`Api | ${_name} | postQuestCommon`, id, data)
		return await postT(`clinic/${id}/form/common`, data)
	},

	/**
	 * Получение списка продаж в клинике
	 * API
	 * 
	 * @param {*} id id клиники
	 * @returns 
	 */
	 async getСlinicSells(id) {
		log.d(`Api | ${_name} | getСlinicSells`, id)
		let response = await getT(`sells/clinicsPVA`, {'clinics': id, 'month':'true'})
		return response
	},

	/**
	 * Получение данных по количеству специалистов в клинике
	 * API
	 * 
	 * @param {*} id id клиники
	 * @returns 
	 */
	 async getСlinicPros(id) {
		log.d(`Api | ${_name} | getСlinicPros`, id)
		let response = await getT(`clinic/${id}/professionals`)
		return response
	},

	/**
	 * Получение всего списка когорт для клиники
	 * API 
	 * 
	 * @param {*} id id клиники
	 * @returns 
	 */
	async getPatientCategories(id) {
		log.d(`Api | ${_name} | getPatientCategories`, id)
		let response = await getT(`clinic/${id}/patientCategoryAll`)
		return response
	},

	/**
	 * Отправка данных о когорте
	 * API
	 * 
	 * @param {*} id - id клиники
	 * @param {*} data - данные о когорте {datum: {categoryId: value}}
	 * @returns 
	 */
	async postPatientCategory(id, data) {
		log.d(`Api | ${_name} | postPatientCategory`, id, data)
		//TODO: перенести сюда datum из стора и заменить на объект
		let response = await postT(`clinic/${id}/patientCategory`, data)
		return response
	},

	/**
	 * Получение продаж по клинике
	 * API
	 * 
	 * @param {*} id 
	 * @returns 
	 */
	async getClinicProductSales(id) {
		log.d(`Api | ${_name} | getClinicProductSales`, id)
		let response = await getT(`clinic/${id}/sells`)
		return response
	},

	/**
	 * Отправка данных о ручных продажах за текущий год
	 * API
	 * 
	 * @param {*} id 
	 * @param {*} data 
	 * @returns 
	 */
	async postClinicProductSales(id, data) {
		log.d(`Api | ${_name} | postClinicProductSales`, id, data)
		let response = await postT(`clinic/${id}/sells`, data)
		return response
	},

	/**
	 * Отправка данных о ручных продажах за указанный год (не текущий)
	 * API
	 * 
	 * @param {*} id 
	 * @param {*} data 
	 * @returns 
	 */
	async postClinicProductSalesForce(id, data) {
		log.d(`Api | ${_name} | postClinicProductSalesForce`, id, data)
		let response = await postT(`clinic/${id}/sellsForce`, data)
		return response
	},


	/**
	 * Получение ответов для анкеты по диагностике онко
	 * API
	 *
	 * @param {*} id
	 * @returns
	 */
	async getQuestOncoDiagnostics(id) {
		log.d(`Api | ${_name} | getQuestOncoDiagnostics`, id)
		let response = await getT(`clinic/${id}/form/onco/diagnostics`)
		return response
	},

	/**
	 * Отправка данных для анкет по диагностике онко
	 * API
	 *
	 * @param {*} id
	 * @param {*} data
	 * @returns
	 */
	async postQuestOncoDiagnostics(id, data) {
		log.d(`Api | ${_name} | getQuestOncoDiagnostics`, id, data)
		return await postT(`clinic/${id}/form/onco/diagnostics`, data)
	},

	/**
	 * Получение ответов по кам-стратегиям
	 * API
	 *
	 * @param {*} id
	 * @returns
	 */
	async getQuestOncoKAMStrategies(id) {
		log.d(`Api | ${_name} | getQuestOncoKAMStrategies`, id)
		let response = await getT(`clinic/${id}/form/onco/kam-strategies`)
		return response
	},

	/**
	 * Отправка данных по кам-стратегиям
	 * API
	 *
	 * @param {*} id
	 * @param {*} data
	 * @returns
	 */
	async postQuestOncoKAMStrategies(id, data) {
		log.d(`Api | ${_name} | postQuestOncoKAMStrategies`, id, data)
		return await postT(`clinic/${id}/form/onco/kam-strategies`, data)
	},

	/**
	 * Отправка данных о проблеме
	 * API
	 *
	 * @param {*} id
	 * @param {*} data
	 * @returns
	 */
	async postClinicProblem(id, data) {
		log.d(`Api | ${_name} | postClinicProblem`, id, data)
		return await postT(`clinic/${id}/form/problems`, data)
	},

	/**
	 * Получение ответов по кам-стратегиям
	 * API
	 *
	 * @param {*} id
	 * @returns
	 */
	async getClinicProblems(id) {
		log.d(`Api | ${_name} | getClinicProblems`, id)
		let response = await getT(`clinic/${id}/form/problems`)
		return response
	},

	/**
	 * Получение уровня лояльности
	 * API
	 *
	 * @param {*} id
	 * @param {*} data
	 * @returns
	 */
	async getClinicLoyalty(id) {
		log.d(`Api | ${_name} | getClinicLoyalty`, id)
		return await getT(`clinic/${id}/loyalty`)
	},

	/**
	 * Отправка уровня лояльности
	 * API
	 *
	 * @param {*} id
	 * @param {*} data
	 * @returns
	 */
	async postClinicLoyalty(id, data) {
		log.d(`Api | ${_name} | postClinicLoyalty`, id, data)
		return await postT(`clinic/${id}/loyalty`, data)
	},

	/**
	 * Получение плана клиники
	 * API
	 *
	 * @param {*} id
	 * @param {*} data
	 * @returns
	 */
	async getClinicPlan(id) {
		log.d(`Api | ${_name} | getClinicPlan`, id)
		return await getT(`clinic/${id}/plan`)
	},

	/**
	 * Отправка плана
	 * API
	 *
	 * @param {*} id
	 * @param {*} data
	 * @returns
	 */
	async postClinicPlan(id, data) {
		log.d(`Api | ${_name} | postClinicPlan`, id, data)
		return await postT(`clinic/${id}/plan`, data)
	},

	/**
	 * Согласование плана
	 * API
	 *
	 * @param {*} id
	 * @returns
	 */
	async postClinicPlanApprove(id) {
		log.d(`Api | ${_name} | postClinicPlanApprove`, id)
		return await putT(`/clinic/${id}/planApprove`, id)
	},

	/**
	 * Редактирование плана после отправки на согласование
	 * API
	 *
	 * @param {*} id
	 * @returns
	 */
	async postClinicPlanChange(id) {
		log.d(`Api | ${_name} | postClinicPlanChange`, id)
		return await putT(`/clinic/${id}/planChange`, id)
	},


}