import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import reportApi from '../../api/report.api'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	w_name: 'Report',
	w_loadingCount: 0,
	w_errors: [],
	w_netStatus: {},
	
	reports: {},
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadReport({state, commit, dispatch}, reqObj) {
		log.d(`Store | ${state.w_name} | Actions | loadReport`, reqObj)

		commit('w_loadingPlus')

		let url
		
		if (reqObj.type=='summary') {
			url = await reportApi.getSummaryUrl()
			url += `&regions=${reqObj.regions.join()}`
			if (reqObj.vaccinationLicense) {
				url += `&vaccination_license=${reqObj.vaccinationLicense}`
			}
			if (reqObj.coverage) {
				url += `&coverage=${reqObj.coverage}`
			}
			if (reqObj.lists) {
				url += `&lists=${reqObj.lists}`
			}
		} 
		else if (reqObj.type=='pva') {
			url = await reportApi.getPVAUrl()
			url += `&regions=${reqObj.regions.join()}`
			if (reqObj.vaccinationLicense) {
				url += `&vaccination_license=${reqObj.vaccinationLicense}`
			}
			if (reqObj.coverage) {
				url += `&coverage=${reqObj.coverage}`
			}
			if (reqObj.lists) {
				url += `&lists=${reqObj.lists}`
			}
		} else if (reqObj.type=='onco') {
			url = await reportApi.getOncoUrl()
			url += `&regions=${reqObj.regions.join()}`
			if (reqObj.vaccinationLicense) {
				url += `&vaccination_license=${reqObj.vaccinationLicense}`
			}
			if (reqObj.coverage) {
				url += `&coverage=${reqObj.coverage}`
			}
			if (reqObj.lists) {
				url += `&lists=${reqObj.lists}`
			}
		} else if (reqObj.type=='vx-plans') {
			url = await reportApi.getVXPlanUrl()
			url += `&regions=${reqObj.regions.join()}`
			if (reqObj.vaccinationLicense) {
				url += `&vaccination_license=${reqObj.vaccinationLicense}`
			}
			if (reqObj.coverage) {
				url += `&coverage=${reqObj.coverage}`
			}
			if (reqObj.lists) {
				url += `&lists=${reqObj.lists}`
			}
		}


		commit('w_loadingMinus')
		
		return url
	},
}


// mutations
const mutations = {
	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}