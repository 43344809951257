import {get, getT, putT, token} from './api.js'
import log from '../log'

const _name = 'Users'

export default {
	/**
	 * Получение списка сотрудников
	 * API
	 * 
	 * @returns 
	 */
	async getUsers(requestObj) {
		log.d(`Api | ${_name} | getUsers`, requestObj)
		let response = await getT(`/admin/user/list`, requestObj)
		return response
	},

}