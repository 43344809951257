import Vue from 'vue'
import { mapState } from 'vuex'

export default {
	install(Vue, options) {
		Vue.mixin({
			computed: {
				divisionVaccines() {
					if (this.divisions.includes(1)) {
						return true
					}
					return false
				},
				divisionOnko() {
					if (this.divisions.includes(2)) {
						return true
					}
					return false
				},

				...mapState('auth', [
					'divisions',
				]),
			}
		})
	}
}