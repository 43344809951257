<template>
	<div class="page-content py-8">
		<div class="container">
			<h1 class="mb-7">Сотрудники</h1>
			<div class="row">
				<div class="col-md-9 col-lg-10 my-2">
					<SearchString
						v-model="searchStringValue"
						@submit="submitSearchString"
						placeholder="ФИО, E-mail"
						:autocomplete="true">
						<template v-slot:autocomplete>
							<ul class="input-search__autocomplete">
								<li
									v-for="acItem in employeesAutocomplete"
									:key="acItem.id"
									:class="{ 'disabled' : acItem.is_active != true}"
									>
									<router-link :to="`admin/employee-profile/${acItem.id}`">
										<div :inner-html.prop="acItem.nm | highlightSearch(searchStringValue)"></div>
										<div class="fsz-sm text-base-op-4">
											<span
												:inner-html.prop="acItem.email | highlightSearch(searchStringValue)"
												></span><span v-if="acItem.divisions.length > 0">,</span>
											<span>
												<span
													v-for="(division, index) in acItem.divisions"
													:key="division.id"
													:inner-html.prop="division.nm + ((index+1==acItem.divisions.length)?'':', ') | highlightSearch(searchStringValue)"
													></span><span v-if="acItem.roles.length > 0">,</span>
											</span>
											<span>
												<span
													v-for="(role, index) in acItem.roles"
													:key="role.id"
													:inner-html.prop="role.nm + ((index+1==acItem.roles.length)?'':', ') | highlightSearch(searchStringValue)">
												</span>
											</span>
											<!-- <span v-if="acItem.roles.length > 0">,</span> -->
										</div>
									</router-link>
								</li>
							</ul>
						</template>
					</SearchString>
				</div>
				<div class="col-md-3 col-lg-2 my-2">
					<button class="btn btn-primary w-100" @click="submitSearchString" type="button">найти</button>
				</div>
			</div>
			<div class="mt-4">
				<div class="input-check input-check-gray input-check-align-center">
					<input
						type="checkbox"
						name="show-not-active-employees"
						id="show-not-active-employees"
						v-model="showNotActiveUsers"
						>
					<label class="text-primary" for="show-not-active-employees">Показывать неактивных сотрудников</label>
				</div>
			</div>
			
			<!-- <small>
				<pre>{{ employees }}</pre>
			</small> -->

			<div class="table-responsive">
				<table class="employees-table" :class="{box_loading: w_isLoading == true}">
					<tr>
						<th colspan="2">
							ФИО
							<TableSortControl v-model="nameSort"></TableSortControl>
						</th>
						<th>
							E-mail
							<TableSortControl v-model="emailSort"></TableSortControl>
						</th>
						<th>
							Бизнес-юнит
							<TableSortControl v-model="divisionSort"></TableSortControl>
						</th>
						<th>
							Роль
							<TableSortControl v-model="roleSort"></TableSortControl>
						</th>
					</tr>
					<tr
						v-for="(employee, index) in employees[currentPage]"
						:key="employee.id"
						:class="{ 'not-active' : employee.is_active != true}"
						>
						<td>{{ parseInt(index) + 1 }}.</td>
						<td>
							<router-link :to="`admin/employee-profile/${employee.id}`">
								{{ employee.nm }}
								<div v-if="employee.is_active == true" class="fsz-sm mt-1">Активен</div>
								<div v-else class="fsz-sm mt-1">Неактивен</div>
							</router-link>
						</td>
						<td>{{ employee.email }}</td>
						<td>
							<div v-for="division in employee.divisions" :key="division.id">{{ division.nm }}</div>
						</td>
						<td>
							<div v-for="role in employee.roles" :key="role.id">{{ role.nm }}</div>
						</td>
					</tr>
				</table>
			</div>
			<PaginationBlock :pagesCount="Object.keys(employees).length" @click="pageChange"></PaginationBlock>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import log from '../log.js'
import BtnBackArrow from './blocks/BtnBackArrow'
import Widget from './widgets/Widget'
import SearchString from './blocks/SearchString'
import PaginationBlock from './blocks/PaginationBlock'
import TableSortControl from './blocks/TableSortControl'

export default {
	name: "AdminRoom",
	components: {
		Widget,
		BtnBackArrow,
		SearchString,
		PaginationBlock,
		TableSortControl
	},
	async beforeMount() {
		log.d(`AdminRoom | beforeMount`, this.$route.params.id)
		
        await this.getEmployees()
	},
	data() {
		return {
			currentPage: 1,
		}
	},
	computed: {
		searchStringValue: {
            get() {
                return this.searchString
            },
            set(value) {
                return this.setSearchString({value: value, reqObj: {autocomplete: true}})
            }
        },
		showNotActiveUsers: {
            get() {
                return this.filterShowNotActiveUsers
            },
            set(value) {
                return this.setfilterShowNotActiveUsers(value)
            }
        },
		nameSort: {
			get() {
				return this.sortName
			},
			set(value) {
				return this.setSortName(value)
			}
		},
		emailSort: {
			get() {
				return this.sortEmail
			},
			set(value) {
				return this.setSortEmail(value)
			}
		},
		divisionSort: {
			get() {
				return this.sortDivision
			},
			set(value) {
				return this.setSortDivision(value)
			}
		},
		roleSort: {
			get() {
				return this.sortRole
			},
			set(value) {
				return this.setSortRole(value)
			}
		},
		...mapState('clinic', {
			clinicId: state => state.clinic.id,
			clinicName: state => state.clinic.name,
		}),
		...mapState('employees', [
			'employees',
			'employeesAutocomplete',
			'searchString',
			'filterShowNotActiveUsers',
			'sortName',
			'sortEmail',
			'sortDivision',
			'sortRole'
		]),
		...mapGetters('employees', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		submitSearchString() {
			this.clearSearchTimeout()
            this.handleSearchTimeout()
			this.wipeAutocompleteData()
        },
		pageChange(value) {
			this.currentPage = value
        },
		...mapActions('employees', [
			'getEmployees',
			'setSearchString',
			'clearSearchTimeout',
			'handleSearchTimeout',
			'wipeAutocompleteData',
			'setfilterShowNotActiveUsers',
			'setSortName',
			'setSortEmail',
			'setSortDivision',
			'setSortRole',
        ]),
	}
}
</script>

<style>

</style>

<style scoped>

</style>