<template>
	<div class="page-content">
		<div class="container">
			<div class="row align-items-center justify-content-between mb-4">
				<div class="col-md-4 col-lg-5">
					<h1>Регионы</h1>
				</div>
				<div v-if="userRegions.length > 3" class="col-md-5">
					<RegionsSelect v-model="selectedRegions" :options="userRegions"></RegionsSelect>
				</div>
				<div v-else class="col-md-8 col-lg-7">
					<div class="input-check-group-inline justify-content-end region-checkbox-wrapper">
						<div class="col-sm-4 col-md-auto my-2" v-for="region in userRegions" :key="region.id">
							<div class="input-check input-check-sm input-check-align-center">
								<input type="checkbox" name="region" v-model="selectedRegions" :id="'region_' + region.id" :value="region.id">
								<label :for="'region_' + region.id">{{ region.nm }}</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-9">
					<SalesWidget v-bind:regions="selectedRegions"></SalesWidget>
				</div>
				<div class="col-lg-3">
					<ClinicsWidget></ClinicsWidget>
				</div>
			</div>

			<div class="row" v-if="divisionVaccines">
				<div class="col-lg-3">
					<CoverWidget v-bind:regions="selectedRegions"></CoverWidget>
				</div>
				<div class="col-lg-9">
					<CohortsWidget v-bind:regions="selectedRegions"></CohortsWidget>
				</div>
			</div>

			<div class="row" v-if="divisionOnko">
				<div class="col-lg-6">
					<TenderStatsWidget></TenderStatsWidget>
				</div>
				<div class="col-lg-6">
					<PatientsWidget v-bind:regions="selectedRegions"></PatientsWidget>
				</div>
			</div>

			<EpidemiologyWidget v-if="divisionOnko"></EpidemiologyWidget>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Widget from './widgets/Widget'
import ClinicsWidget from './widgets/Regions/Clinics.widget'
import CohortsWidget from './widgets/Regions/Cohorts.widget'
import CoverWidget from './widgets/Regions/Cover.widget'
import SalesWidget from './widgets/Regions/Sales.widget'
import PatientsWidget from './widgets/Regions/Patients.widget'
import EpidemiologyWidget from './widgets/Regions/Epidemiology.widget'
import TenderStatsWidget from './widgets/Regions/TenderStats.widget'
import RegionsSelect from './blocks/RegionsSelect'

export default {
	name: "Regions",
	components: {
		Widget,
		ClinicsWidget,
		CohortsWidget,
		CoverWidget,
		SalesWidget,
		RegionsSelect,
		PatientsWidget,
		EpidemiologyWidget,
		TenderStatsWidget
	},
	async beforeMount() {
	},
  	data() {
	  	return {
	  	}
  	},
	methods: {
		...mapMutations('common', [
		  	'setSelectedUserRegionsId',
			'setselectedUserRegion',
		])
	},
  	computed: {
		selectedRegions: {
			get() {
				return this.selectedUserRegionsId
			},
			set(value) {
				return this.setSelectedUserRegionsId(value)
			}
		},
	  	...mapState('common', [
		  	'userRegions',
		  	'selectedUserRegionsId',
		])
  	},
}
</script>

<style>

</style>

<style scoped>
	.region-checkbox-wrapper {
		justify-content: end;
	}
</style>