<template>
    <div class="notification">
        <div class="row align-items-center justify-content-between">
            <div class="my-2" :class="{ 'col-lg-8' : buttonText, 'col-lg-12' : !buttonText,}">
                {{ text }}
            </div>
            <div v-if="buttonText" class="col-auto my-2">
                <button
                    type="button"
                    class="btn text-primary-light btn-link btn-link-lg pl-0 pr-4"
                    @click="btnClick"
                >
                    {{ buttonText }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	name: "Notification",
    props: {
        text: {
            type: String,
            required: true
        },
		buttonText: {
            type: String,
            required: false
        }
    },
    methods: {
        btnClick(e) {
            this.$emit('btnClick')
        }
    }
}
</script>