import Vue from 'vue'
import router from '../../router'
import authApi from '../../api/auth.api'
import regionsApi from '../../api/regions.api'
import log from './../../log'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],
    regionProductPatientCategory: {},
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async getRegionProductPatientCategory({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | Regions | Actions | regionProductPatientCategory`, reqObj)
		
		commit('w_loadingPlus')

		let req = await regionsApi.getPatientCategory(reqObj.regions, reqObj.products)

		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('setRegionProductPatientCategory', data)

		commit('w_loadingMinus')
	}
}

// mutations
const mutations = {
	setRegionProductPatientCategory(state, data) {
		log.d(`Store | regions | Mutations | setRegionProductPatientCategory`, data)
		state.regionProductPatientCategory = {}
		data.forEach(el => {
			state.regionProductPatientCategory[el.id] = el;
		})
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}