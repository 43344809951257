import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import clinicApi from '../../api/clinic.api'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

const _name = 'CalcHistory'

// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],
	w_name: 'CalcHistory',
	w_netStatus: {},
	
	calcHistory: {},	// Список рассчитаных продуктов на клинику
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadCalcHistory({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | loadPatientCategories`, reqObj)

		commit('w_loadingPlus')
		
		let req = await clinicApi.getCalcHistoryList(reqObj.id, 1)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			if (req.json.error.code == 17) {
				// Расчеты не найдены
				commit('w_loadingMinus')
				return
			}
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('loadCalcHistory', data)

		commit('w_loadingMinus')
	},

}

// mutations
const mutations = {
	loadCalcHistory(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | loadCalcHistory`, d)

		let visits = {}
		d.forEach(el => {
			visits[el.visitId] = {}
			visits[el.visitId].visitId = el.visitId
			visits[el.visitId].products = el.products
			visits[el.visitId].productsCalc = el.productsCalc
			visits[el.visitId].visitTime = el.visitTime
			Vue.set(state.calcHistory, el.clinicId, visits)
		});
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}