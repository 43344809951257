import {get, getT, getTUrl, post, token} from './api.js'
import log from '../log'

export default {

	async getPVAUrl(data = {}) {
		log.d(`Api | Sells | getPVA`, data)
		let response = await getTUrl(`report/PVA`, data)
		return response
	},

	async getSummaryUrl(data = {}) {
		log.d(`Api | Sells | getSummary`, data)
		let response = await getTUrl(`report/summary`, data)
		return response
	},

	async getOncoUrl(data = {}) {
		log.d(`Api | Sells | getOncoUrl`, data)
		let response = await getTUrl(`report/onco`, data)
		return response
	},

	async getVXPlanUrl(data = {}) {
		log.d(`Api | Sells | getVXPlanUrl`, data)
		let response = await getTUrl(`report/vx-plan`, data)
		return response
	},

	async getCalcUrl(data = {}) {
		log.d(`Api | Sells | getSummary`, data)
		let response = await getTUrl(`report/${data.clinicId}/calcHistory/${data.visitId}`, {productId: data.productId})
		return response
	},
}