<template>
    <div>
        <div class="modal user-list-modal" tabindex="-1" id="createListModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    
                    <button type="button" class="close back" data-target="#userListModal" data-toggle="modal" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.2925 0.707499C7.68324 0.316757 8.31676 0.316758 8.7075 0.7075C9.09824 1.09824 9.09824 1.73176 8.7075 2.1225L3.83 7L15 7C15.5523 7 16 7.44771 16 8C16 8.55228 15.5523 9 15 9L3.83 9L8.7075 13.8775C9.09824 14.2682 9.09824 14.9018 8.7075 15.2925C8.31676 15.6832 7.68324 15.6832 7.2925 15.2925L0 8L7.2925 0.707499Z" fill="#0C2340"/>
                            </svg>
                        </span>
                    </button>
                    
                    <div class="modal-body">
                        <div class="row justify-content-between">
                            <div v-if="listNm === 'Избранное'" class="col-md-5 my-2">
                                <label class="fsz-sm text-muted" for="list_name">Название</label>
                                <div class="h2 mb-0 mt-1">{{ listNm }}</div>
                            </div>
                            <div v-else class="col-md-5 my-2 form-item-ui" :class="{'has-value': listNm}">
                                <label class="fsz-sm" for="list_name">Название</label>
                                <input  class="form-control" name="list_name" v-model="listNm" type="text">
                            </div>
                            <div class="col-md-6 my-2">
                                <label class="fsz-sm text-muted" for="">Цвет</label>
                                <ul class="color-picker">
                                    <li v-for="color in colors" :key="color" class="color-picker__item" :class="{'default' : color === 'ffffff'}">
                                        <input type="radio" name="listColor" v-model="listColor" :id="'list_' + color" :value="parseInt(color, 16)">
                                        <label :for="'list_' + color" :style="{backgroundColor: '#' + color}"></label>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div v-if="listClinics" class="h1 my-4">Выбрано: {{ listClinics.length }}</div>
                        
                        <div class="row justify-content-between">
                            <div class="col-md-5">
                                <SearchString v-model="searchStringValue"></SearchString>
                            </div>
                            <div class="col-md-6">
                                <RegionsSelect v-model="selectedRegions" :options="userRegions"></RegionsSelect>
                            </div>
                        </div>

                        <div v-if="clinics.length != 0" class="modal-overflow mt-4">
                            <table class="table table-list-edit-clinics">
                                <transition-group name="flip-list" tag="tr">
                                    <tr key="-1">
                                        <th></th>
                                        <th>Наименование и тип ЛПУ</th>
                                        <th>Город</th>
                                        <th>Адрес</th>
                                    </tr>

                                    <tr v-for="clinic in joinedCurrentListClinics" :key="clinic.id">
                                        <td>
                                            <div class="input-check">
                                                <input v-model="listClinics" :id="'clinic_' + clinic.id" :value="clinic.id" type="checkbox">
                                                <label :for="'clinic_' + clinic.id"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="font-weight-bold fsz-base">{{ clinic.name }}</div>
                                            <div class="fsz-sm mt-2">{{ clinic.type }}</div>
                                        </td>
                                        <td>{{ clinic.city }}</td>
                                        <td>{{ clinic.address }}</td>
                                    </tr>
                                </transition-group>
                            </table>
                            <a class="page-btn" v-if="clinicsCounter > clinics.length" @click="getClinicsToAdd">Показать ещё</a>
                        </div>
                        <div v-else class="text-center mt-11"><ClinicsNotFoundMessageBlock></ClinicsNotFoundMessageBlock></div>
                    </div>

                    <div class="modal-footer justify-content-between">
                        <button :disabled="!saveBtnDisabled || !listNm || listClinics.length==0" @click="handleSave(newList)" class="btn btn-primary btn-width" type="button" >сохранить</button>
                        <button @click="handleDelete(newList)" v-if="listNm !== 'Избранное' && !!newList.id" class="btn btn-link text-danger" type="button">Удалить список</button>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <Notification v-if="timer" :text="notificationText" :buttonText="notificationButtonText" @btnClick="notificationButtonAction"></Notification>
        </transition>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import ClinicsNotFoundMessageBlock from '../../blocks/ClinicsNotFoundMessageBlock'
import SearchString from '../../blocks/SearchString'
import Notification from '../../blocks/Notification'
import RegionsSelect from '../../blocks/RegionsSelect'
import log from '../../../log'
import { isEqual } from 'lodash'

export default {
	name: "CreateUserListModal",
    components: {
        SearchString,
        Notification,
        RegionsSelect,
        ClinicsNotFoundMessageBlock,
    },
    async beforeMount() {
        await this.getClinics()
	},
	data() {
		return {
            timer: null,
            notificationText: '',
            notificationButtonText: '',
            notificationButtonAction: null,
		}
	},
	computed: {
        listNm: {
            get() {
                return this.newList.nm
            },
            set(value) {
                return this.setNewListNm(value)
            }
        },
        listColor: {
            get() {
                return this.newList.color
            },
            set(value) {
                return this.setNewListColor(value)
            }
        },
        listClinics: {
            get() {
                if(this.newList.clinics) {
                    return this.newList.clinics.map(el => el.id)
                } else {
                    return []
                }
            },
            set(value) {
                let arr = this.joinedCurrentListClinics.filter(clinic => {
                    return value.indexOf(clinic.id) === -1 ? false : true
                })
                return this.setNewListClinics(arr)
            }
        },
        selectedRegions: {
			get() {
				return this.selectedUserRegionsId
			},
			set(value) {
                this.setSearchTimeout()
				return this.setSelectedUserRegionsId(value)
			}
		},
        searchStringValue: {
            get() {
                return this.searchString
            },
            set(value) {
                return this.setSearchString(value)
            }
        },
        isNameExists() {
            let isExists = false

            Object.keys(this.lists).forEach(key => {
                if (this.lists[key].nm === this.newList.nm) {
                    isExists = true
                }
            })

            return isExists
        },
        isListNmValid() {
            if (this.newList.id && this.lists[this.newList.id]) {
                return this.newList.nm !== this.lists[this.newList.id].nm
            } else {
                return !!this.newList.nm
            }
        },
        isListColorValid() {
            return this.newList.color !== this.lists[this.newList.id].color
        },
        isListClinicsValid() {
            return !isEqual(this.newListClinicsId, this.listClinicsId(this.newList.id))
        },
        saveBtnDisabled() {
            if (this.newList.id) {
                return this.isListClinicsValid || this.isListNmValid || this.isListColorValid || !this.isNameExists
            } else {
                return this.isListNmValid && this.isListClinicsValid && !this.isNameExists
            }
        },
        joinedCurrentListClinics() {
            let current = [...this.currentListClinics(this.newList.id)]
            let newListClinics = [...this.newList.clinics]
            let searched = [...this.clinics]

            let filtered = current.concat(newListClinics, searched).filter((el, index, self) =>
                index === self.findIndex((t) => (
                    t.place === el.place && t.id === el.id
                ))
            )

            return filtered
        },

		...mapState('userLists', [
            'lists',
			'newList',
			'colors',
			'w_errors',
		]),
        ...mapState('common', [
		  	'userRegions',
		  	// 'selectedUserRegionsId',
		]),
        ...mapState('userListClinics', [
			'clinics',
            'clinicsCounter',
            'searchString',
            'selectedUserRegionsId',
		]),
		...mapGetters('userLists', [
			'w_isLoading',
			'w_isError',
            'newListClinicsId',
            'listClinicsId',
            'currentListClinics',
		]),
	},
	methods: {
        handleDelete(list) {
            $('#createListModal').modal('hide')
            this.notificationText = `Список «${this.listNm}» удалён`
            this.notificationButtonText = 'Отмена'
            this.notificationButtonAction = this.abortDeletion
            this.timer = setTimeout(() => {
                this.timer = null
                this.deleteList(list)
            }, 5000)
        },
        handleSave(list) {
            $('#createListModal').modal('hide')
            if (list.id) {
                this.notificationText = `Список «${this.listNm}» отредактирован`
                this.notificationButtonText = 'Показать'
                this.notificationButtonAction = this.showList

                this.updateList(list)
            } else {
                this.notificationText = `Список «${this.listNm}» создан`
                this.notificationButtonText = 'Показать'
                this.notificationButtonAction = this.showList

                this.createList(list)
            }
            this.timer = setTimeout(() => {
                this.timer = null
            }, 5000)
        },
        abortDeletion() {
            clearTimeout(this.timer)
            this.timer = null
        },
        showList() {
            this.timer = null
            $('#createListModal').modal('show')
        },
		...mapActions('userLists', [
			'updateList',
			'createList',
            'deleteList',
		]),
        ...mapActions('userListClinics', [
			'setSearchTimeout',
            'setSearchString',
            'getClinicsToAdd',
            'getClinics',
            'setSelectedUserRegionsId',
		]),
        ...mapMutations('userLists', [
		  	'setNewListNm',
		  	'setNewListColor',
		  	'setNewListClinics',
            'resetNewList'
		]),
        ...mapMutations('common', [
		  	// 'setSelectedUserRegionsId',
		])
	},
}
</script>

<style>
    .fade-enter-active {
        transition: opacity .3s ease;
    }
    .fade-leave-active {
        transition: opacity .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>

<style scoped>
    /* #createListModal .modal-body {
        overflow: hidden;
    } */
    .flip-list-move {
        transition: transform 1s;
    }
</style>