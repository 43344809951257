import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import clinicApi from '../../api/clinic.api'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

const _name = 'СlinicPros'

// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],
	
	clinicPros: [],
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadСlinicPros({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | loadСlinicPros`, reqObj)

		commit('w_loadingPlus')
		
		let req = await clinicApi.getСlinicPros(reqObj.id)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('loadСlinicPros', data)
		
		commit('w_loadingMinus')
	},

}

// mutations
const mutations = {
	loadСlinicPros(state, data) {
		log.d(`Store | ${_name} | Mutations | loadСlinicPros`, data)

        state.clinicPros = []

        data.forEach(el => {
            if (el.count > 0) {
                state.clinicPros.push(el)
            }
        });
	},

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}