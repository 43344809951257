import Vue from 'vue';

Vue.filter('capitalize', value => {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
});


Vue.filter('digitSpace1k', value => {
	if (value == undefined) return ''
	if (!Number.isNaN(parseFloat(value))) {
		return parseFloat(Math.round(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
	}
	return value
});

// .00
Vue.filter('hundredths', value => {
	if (value == undefined) return ''
	value = parseFloat(value)
	value = value * 100
	return Math.round(value)/100
});

// .0
Vue.filter('tenths', value => {
	if (value == undefined) return ''
	value = parseFloat(value)
	value = value * 10
	return Math.round(value)/10
});

Vue.filter('int', value => {
	if (value == undefined) return ''
	return Math.round(value)
});

Vue.filter('float', value => {
	if (value == undefined) return ''
	return Number.parseFloat(value)
});

Vue.filter('noNaN', value => {
	if(Number.isNaN(value) || !Number.isFinite(value)) {
		return '-'
	}
	return value
})

Vue.filter('highlight', (words, query) => {
    let iQuery = new RegExp(query, "ig");
    return words.toString().replace(iQuery, (matchedTxt,a,b) => {
        return ('<span class=\'highlight\'>' + matchedTxt + '</span>');
    });
});

Vue.filter('highlightSearch', (words, query) => {
    let iQuery = new RegExp(query, "ig");
    return words.toString().replace(iQuery, (matchedTxt,a,b) => {
        return ('<span class=\'highlight-search\'>' + matchedTxt + '</span>');
    });
});