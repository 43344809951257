import log from '../log'
import config from '../config.loader'

function name(state) {
	return state.w_name||'-'
}

export function w_isLoading(state) {
	if (state.w_loadingCount>0) {return true}
	return false
}

export function w_isError(state) {
	if (state.w_errors.length>0) {return true}
	return false
}

export function w_loadingPlus(state) {
	log.d(`Store | ${name(state)} | Mutations | loadingPlus`, state.w_loadingCount)
	state.w_loadingCount++;
}

export function w_loadingMinus(state) {
	log.d(`Store | ${name(state)} | Mutations | loadingMinus`, state.w_loadingCount)
	state.w_loadingCount--;
	if (state.w_loadingCount<0) {state.w_loadingCount = 0}
}

export function w_addError(state, error) {
	log.d(`Store | ${name(state)} | Mutations | addError`, error)
	if(typeof error == 'function') {
		state.w_errors.push(error())
	} else {
		state.w_errors.push(error)
	}
}

export function w_clearError(state, error) {
	log.d(`Store | ${name(state)} | Mutations | clearError`)
	state.w_errors = []
}

export function w_wipeTimer(state, d) {
	log.d(`Store | ${name(state)} | Mutations | wipeTimer`, d)
	if (d.id && state.w_netStatus[d.id] && state.w_netStatus[d.id].timer) {
		clearTimeout(state.w_netStatus[d.id].timer)
	}
}

export function w_setTimer(state, d) {
	log.d(`Store | ${name(state)} | Mutations | setTimer`, d)
	if (!d.id || !d.action) {return}
	
	if (d.id && state.w_netStatus[d.id] && state.w_netStatus[d.id].timer) {
		clearTimeout(state.w_netStatus[d.id].timer)
		if (d.clearAction) {
			d.clearAction()
		}
	}

	if (!state.w_netStatus[d.id]) {state.w_netStatus[d.id] = {}}

	window.onbeforeunload = () => {
		return true;
	}

	state.w_netStatus[d.id].timer = setTimeout(() => {
		d.action()

		window.onbeforeunload = ''
	}, config.inputTimeout)
}