import Vue from 'vue'
import router from '../../router'
import authApi from '../../api/auth.api'
import adminApi from '../../api/admin.api'
import log from './../../log'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	loadingCount: 0,
	user: {},

	roleList: {}
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async getUserById({state, commit, dispatch}, id) {
		log.d(`Store | User | Actions | getUserById` , id)

		commit('loadingPlus')
		let req = await adminApi.getUser(id)
		if (!req.ok) {
			// TODO: throw error?
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}
		commit('setUser', data)
		dispatch('getUserRegionsList', id)
		commit('loadingMinus')
	},
	
	async getUserRegionsList({state, commit, dispatch}, id) {
		log.d(`Store | User | Actions | getUserRegionsList` , id)

		commit('loadingPlus')
		let req = await adminApi.getUserRegions(id)
		if (!req.ok) {
			// TODO: throw error?
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}
		commit('setUserRegions', data)
		commit('loadingMinus')
	},

	async getRoleList({state, commit, dispatch}, id) {
		log.d(`Store | User | Actions | getRoleList` , id)

		commit('loadingPlus')
		let req = await adminApi.getRoles()
		if (!req.ok) {
			// TODO: throw error?
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}
		commit('setRoleList', data)
		commit('loadingMinus')
	},
}

// mutations
const mutations = {
	setUser(state, data) {
		log.d(`Store | User | Mutations | setUser` , data)
		let user = data.user

		Vue.set(state.user, 'id', user.id);
		Vue.set(state.user, 'nm', user.nm);
		Vue.set(state.user, 'email', user.email);
		Vue.set(state.user, 'is_active', user.is_active);
		Vue.set(state.user, 'is_external', user.is_external);
		Vue.set(state.user, 'roles', user.roles);
		Vue.set(state.user, 'divisions', user.divisions);
	},
	setUserRegions(state, data) {
		log.d(`Store | User | Mutations | setUser` , data)
		
		Vue.set(state.user, 'regions', data);
	},
	setRoleList(state, data) {
		log.d(`Store | User | Mutations | setRoleList` , data)

		state.roleList = data
	},
	addError(state, error) {
		state.errors.push(error)
	},
	loadingPlus(state) {
		log.d(`Store | User | Mutations | loadingPlus` , state.loadingCount)
		state.loadingCount++;
	},
	loadingMinus(state) {
		log.d(`Store | User | Mutations | loadingMinus` , state.loadingCount)
		state.loadingCount--;
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}