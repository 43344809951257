<template>
	<div class="page-auth">
		<div class="page-auth__section">
			<div class="page-form  pb-7 justify-content-between">
				<!--autocomplete="off" action="/register.php" method="POST"-->
				<div class="page-form-header">
					<img src="./../assets/images/logo-msd-commerce.svg" />
					<!-- <a href="#" class="btn-close"></a> -->
				</div>
				<div class="page-form-body mb-8">
					<div class="d-flex justify-content-between align-items-center mb-2">
						<h1 class="page-form-title">Где вы работаете?</h1>
						<div>2 / <span class="text-muted">3</span></div>
					</div>
					<div class="fsz-sm text-muted mb-6">Мы используем ваши данные, чтобы проверить статус врача и предоставить доступ к информации о рецептурных препаратах</div>
					<div class="row">
						<div class="col-sm-6">
						</div>
						<div class="col-sm-6">
							
						</div>
					</div>

					<div class="input-check input-check-sm input-check-align-center mb-1">
						<input name="adSubscription" type="checkbox" id="adSubscription" v-model="adSubscription">
						<label for="adSubscription">Даю <a href="#" target="_blank">согласие</a> на получение от MSD рекламной информации<br>по указанным каналам связи.</label>
					</div>
					<div class="input-check input-check-sm input-check-align-center">
						<input name="terms" type="checkbox" id="terms" @change="checkValidity" v-model="terms" required>
						<label for="terms">Принимаю <a href="#" target="_blank">Условия использования Сайта и обработки персональных данных *</a></label>
					</div>
					<div class="fsz-sm text-muted mt-3">
						Поставив галочку в окне выше, вы подтверждаете, что принимаете <a href="#" target="_blank">Условия использования Сайта и обработки персональных данных</a> и ознакомились с <a href="#" target="_blank">Политикой конфиденциальности</a>.
					</div>
				</div>
				<div class="page-form-footer mt-0">
					<router-link v-if="formValid" to="/register/2" class="btn btn-primary w-100">Далее</router-link>
					<span v-else class="btn btn-primary disabled w-100">Далее</span>
					<div class="text-center mt-5">
						<router-link to="/login" class="page-form-link">войти в существующий аккаунт</router-link>
					</div>
					<div class="text-center mt-12">
						<a href="mailto:support@msdmed.ru" class="page-form-link">Техподдержка: <span class="td-underline">support@msdmed.ru</span></a>
					</div>
				</div>
			</div>
		</div>
  	</div>
</template>

<script>
export default {
	name: "RegisterJob",
	data() {
		return {
			email: '',
			lastName: '',
			firstName: '',
			middleName: '',
			adSubscription: false,
			terms: false,

			inputsValid: {
				email: false,
				lastName: false,
				firstName: false,
				terms: false,
			}
		}
	},
	computed: {
		formValid: {
			get() {
				return /*this.inputsValid.email && this.inputsValid.lastName && this.inputsValid.firstName && */this.inputsValid.terms
			},
			set() {
				return /*this.inputsValid.email && this.inputsValid.lastName && this.inputsValid.firstName && */this.inputsValid.terms
			}
		}
	},
	methods: {
		checkValidity(event) {
			this.inputsValid[event.target.name] = event.target.validity.valid
		}
	}
}
</script>

<style>

</style>

<style scoped>

</style>