<template>
	<multiselect
        :class="customClass"
        v-model="model"
        :options="options"
        @input="handleInput"
        @close="handleClose"
        :showNoResults="false"
        label="nm"
        track-by="id"
        :multiple="false"
        :close-on-select="true"
        :preselect-first="false"
        placeholder="выберите сотрудника:"
    >
        <template slot="selection" slot-scope="{ isOpen }">
            <span v-if="!isOpen" class="multiselect__single multiselect__single--true">{{ value.nm }}</span>
        </template>
        <template v-slot:caret>
            <svg class="multiselect__select" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 10L12 16L18 10" stroke="#00857C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </template>
        <template slot="option" slot-scope="props">
            <div :class="{'selected' : model.id == props.option.id}">{{ props.option.nm }}</div>
        </template>
    </multiselect>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import config from '../../config.loader'

export default {
	name: "RegionsSelectSingle",
	props: {
		value: {
            type: Object,
            default: {},
        },
		options: {
            type: Array,
            default: [],
        },
        customClass: {
            type: String,
            default: null
        }
    },
  	data() {
	  	return {
            model: this.value
	  	}
  	},
	methods: {
        handleInput(e) {
			this.$emit('input', this.model)
		},
        handleClose(e) {
			this.$emit('close', this.model)
		},
        sortOptions(value) {
            $('.multiselect__element').each((index, item) => {
                if ($(item).find('.selected').length > 0) {
                    $(item).css('order', '1')
                } else {
                    $(item).css('order', '2')
                }
            })
            
        }
	},
    watch: {
		value(value) {
			if (this.model != value) {
				this.model = value
			}
		}
	}
}
</script>

<style>
    .multiselect__option--selected.multiselect__option--highlight {
        background-color: #f3f3f3;
    }
</style>

<style scoped>

</style>