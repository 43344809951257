import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import ssiApi from '../../api/ssi.api'

// import sanitizeHtml from 'sanitize-html';

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	w_name: 'SSI',
	w_loadingCount: 0,
	w_errors: [],
	w_netStatus: {},
	
	ssi: {},
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadSSI({state, commit, dispatch}, productId) {
		log.d(`Store | ${state.w_name} | Actions | loadSSI`, productId)

		if (
			state.ssi[productId] &&
			!state.ssi[productId].includes('ошибка')
		) {return}
		
		commit('setLoadSSI', productId)

		commit('w_loadingPlus')

		let req = await ssiApi.getSSI(productId)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}

		if (req.text.includes('error')) {
			commit('loadSSI', {productId: productId, text: 'Возникла ошибка при загрузке описания.'})
		} else {
			commit('loadSSI', {productId: productId, text: req.text})
		}
		
		commit('w_loadingMinus')
	},
}


// mutations
const mutations = {
	setLoadSSI(state, productId) {
		log.d(`Store | ${state.w_name} | Mutations | setLoadSSI`, productId)
		
		Vue.set(state.ssi, productId, '...')
	},
	loadSSI(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | loadSSI`)
		
		Vue.set(state.ssi, d.productId, d.text)
	},
	removeSSI(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | removeSSI`)

		Vue.delete(state.ssi, d.productId);
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}