<script>
import { Pie } from 'vue-chartjs'

export default {
	name: "CoverChart",
    extends: Pie,
    props: {
        chartData: {
            type: Object,
            default: null
        }
    },
    mounted () {
        this.drawChart()
    },
    watch: {
        chartData: function() {
            this.drawChart()
        }
    },
    methods: {
        drawChart() {
            let fillCovered = this.$refs.canvas.getContext('2d').createRadialGradient(100, 100, 54, 95, 101, 90);
            fillCovered.addColorStop(0, 'rgba(110, 206, 178, 0.12)');
            fillCovered.addColorStop(0.8, 'rgba(110, 206, 178, 0.12)');
            fillCovered.addColorStop(0.84, 'rgba(0, 133, 124, 0.8)');
            fillCovered.addColorStop(1, 'rgba(0, 133, 124, 0.8)');
        
            let fillNotCovered = this.$refs.canvas.getContext('2d').createRadialGradient(100, 100, 54, 95, 101, 90);
            fillNotCovered.addColorStop(0, '#fff');
            fillNotCovered.addColorStop(0.8, '#fff');
            fillNotCovered.addColorStop(0.84, 'rgba(215, 215, 215, 0.4)');
            fillNotCovered.addColorStop(1, 'rgba(215, 215, 215, 0.4)');

            this.renderChart(
                {
                    datasets: [{
                        label: '78',
                        backgroundColor: [fillCovered, fillNotCovered],
                        borderColor: ['#fff', '#fff'],
                        borderWidth: 0,
                        borderAlign: 'center',
                        data: [this.chartData[1].percent, this.chartData[null].percent]
                    }]
                },
                {
                    responsive:true,
                    maintainAspectRatio: true,
                    padding: 0,
                    tooltips: {
                        enabled: false
                    }
                }
            )
        }
    }
}
</script>

<style>

</style>

<style scoped>

</style>