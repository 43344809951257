import {getT, postT} from './api.js'
import log from '../log'

const _name = 'Regions'

export default {
    /**
	 * Возвращает список общего количества пациентов в когортах, привязанных к выбранному продукту, в выбранных регионах
	 * 
	 * @param {number|null} id регион
	 * @returns 
	 */
	async getPatientCategory(regions, products) {
		log.d(`Api | ${_name} | patientCategory`)
		let response = await getT(`regions/patientCategory`, regions, products)
		return response
	},

    /**
	 * Возвращает сумму клиник, покрытых партнерами
	 * 
	 * @param {number|null} regions регион
	 * @returns 
	 */
	async getRegionPartners(regions) {
		log.d(`Api | ${_name} | regionPartners`)
		let response = await getT(`regions/partners`, regions)
		return response
	},

	/**
	 * Возвращает данные по эпидемиологии
	 * 
	 * @param {number|null} regions регион
	 * @returns 
	 */
	async getEpidemiologyByRegions(regions) {
		log.d(`Api | ${_name} | epidemiologyByRegions`)
		let response = await getT(`regions/epidemiology`, regions)
		return response
	},

	async getUserCustomRegionSellsPlan(regions) {
		log.d(`Api | ${_name} | getUserCustomRegionSellsPlan`)
		return await getT(`regions/${regions}/regionUserCustomSalesPlan`)
	},

	async postUserCustomRegionSellsPlan(region, data) {
		log.d(`Api | ${_name} | postUserCustomRegionSellsPlan`)
		return await postT(`regions/${region}/regionUserCustomSalesPlan`, data)
	},

	async getTenders(regions, data) {
		log.d(`Api | ${_name} | getTenders`)
		return await getT(`regions/${regions}/tenders`, data)
	},

	async getTendersSum(regions, data) {
		log.d(`Api | ${_name} | getTendersSum`)
		return await getT(`regions/${regions}/tendersSum`, data)
	},

	async getAllTendersSum(data) {
		log.d(`Api | ${_name} | getTendersSum`)
		return await getT(`regions/tendersSum`, data)
	},
}