<template>
    <Widget
        title="Статус"
        titleClass="text-muted"
        loadingMinHeight="50px"
        :isLoading="w_isLoading"
        :isError="w_isError"
        :errors="w_errors"
        >
        <div class="row">
            <div class="col-md-5 my-2">
                <div class="input-radio input-radio-status-primary">
                    <input @input="resetProblems" :checked="Object.keys(clinicProblems).length == 0" type="radio" id="status-ok" name="clinic-status">
                    <label for="status-ok" class="">Всё хорошо</label>
                </div>
            </div>
            <div class="col-md-5 my-2">
                <div class="input-radio input-radio-status-danger">
                    <input :checked="Object.keys(clinicProblems).length > 0" type="radio" id="status-bad" name="clinic-status">
                    <label @click="openProblemsModal" for="status-bad" class="">Есть проблемы</label>
                </div>
            </div>

            <ClinicProblemsModal></ClinicProblemsModal>
        </div>
    </Widget>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Widget from '../Widget'
import ClinicProblemsModal from './ClinicProblemsModal.widget'
import log from '../../../log'

export default {
    name: "ClinicStatus",
	components: {
		Widget,
        ClinicProblemsModal
	},
    async beforeMount() {
		log.d(`ClinicStatus | beforeMount`, this.$route.params.id)

        await this.loadClinicProblems({id: this.$route.params.id})
	},
    data() {
        return {
        }
    },
	computed: {
		...mapState('clinicProblems', [
            'clinicProblems',
            'w_errors',
		]),
        ...mapGetters('clinicProblems', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
        openProblemsModal() {
            $('#problemsModal').modal('show')
        },
        ...mapActions('clinicProblems', [
            'loadClinicProblems',
            'resetProblems'
		]),
	}
}
</script>