import {get, post} from './api.js'
import log from './../log'

export default {
	async loadFromStorage() {
		log.d(`Api | Auth | loadFromStorage`)
		return { 
			'id': localStorage.getItem('auth-user-id') || 0,
			'KiloEchoYankee': localStorage.getItem('auth-KiloEchoYankee') || '',
			'name': localStorage.getItem('auth-name') || '',
			'email': localStorage.getItem('auth-email') || '',
			'divisions': localStorage.getItem('auth-divisions') || '',
			// 'role': localStorage.getItem('auth-role') || '',
			// 'msdRole': localStorage.getItem('auth-msdRole') || '',
		}
	},

	async saveToStorage(id, KiloEchoYankee, name, email, divisions) {
		log.d(`Api | Auth | saveToStorage`, id, KiloEchoYankee, email, name)
		localStorage.setItem('auth-user-id', id)
		localStorage.setItem('auth-KiloEchoYankee', KiloEchoYankee)
		localStorage.setItem('auth-name', name)
		localStorage.setItem('auth-email', email)
		localStorage.setItem('auth-divisions', divisions)
		// localStorage.setItem('auth-role', role)
		// localStorage.setItem('auth-msdRole', msdRole)
	},

	async loginByEmail(email, password) {
		log.d(`Api | Auth | loginByEmail`, email)
		return await post(`login`, {email: email, password: password})
	},

	async signupToMSD(email, first_name, patronymic, last_name, password) {
		log.d(`Api | Auth | signupToMSD`, email)
		return await post(`register`, {
			email: email,
			first_name: first_name,
			patronymic: patronymic,
			last_name: last_name,
			//TODO рекламная информация
			password: password
		})
	},

	async sendConfirmAgainToMSD(email) {
		log.d(`Api | Auth | sendConfirmAgainToMSD`, email)
		return await post(`sendRegistrationEmail`, {email: email})
	},
	
	async recoverPasswordToMSD(email) {
		log.d(`Api | Auth | sendConfirmAgainToMSD`, email)
		return await post(`recoverPassword`, {email: email})
	},
	

	async getUser(token) {
		log.d(`Api | Auth | getUser`, token)
		let response = await get(`user`, {token: token})
		return response
	},
}