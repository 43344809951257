<template>
	<Widget
		class="pb-6"
		title="Недавние расчёты"
		:showEmptyMessage="Object.keys(cCalcHistory).length == 0"
		:isLoading="w_isLoading"
		:isError="w_isError"
		:errors="w_errors"
		url="calc"
		>
		<template v-slot:empty-data>
			Расчётов калькулятора пока нет
		</template>
		<div class="calcHistoryEl" v-for="(chEl) of cCalcHistoryReverced.slice(0,7)" :key="chEl.visitId">
			<div class="row" :data-id="chEl.visitId">
				<div class="col-2 pr-4 my-1">
					<span class="text-muted fsz-sm">{{chEl.visitTime | moment("DD.MM.YY, HH:mm")}}</span>
				</div>
				<div class="col-10 my-1 fsz-sm">
					<!-- getUniquVal(chEl.products) -->
					<div class="row">

					<div
						v-for="(productId, key) of chEl.products"
						:key="chEl.productsCalc[key].id"
						class="row my-2 col-lg-4 col-sm-6"
						>
							<div
								v-if="userProducts[productId]"
								class="col-5
								offset-1"
								>
								<router-link
									:to="`/clinic/${$route.params.id}/calc/${productId}/${chEl.visitId}/${chEl.productsCalc[key].id}`"
									>{{userProducts[productId].nm}}</router-link>
							</div>
							<div
								v-if="chEl.productsCalc[key] && chEl.productsCalc[key].lostBenefit !== null"
								class="col-6 qtext-right"
								>{{chEl.productsCalc[key].lostBenefit | int | noNaN | digitSpace1k }} ₽</div>
							<div
								v-else
								class="col-6 qtext-right"
								>-</div>
							<!-- <span v-if="key!=getUniquVal(chEl.products).length-1">, </span> -->
					</div>

					</div>
				</div>
			</div>
			<hr>
		</div>
	</Widget>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Widget from '../Widget'
import log from '../../../log'

export default {
    name: "CalcHistoryList",
	props: [
		'cid'
	],
	components: {
		Widget,
	},
	data() {
		return {
			
		}
	},
	async beforeMount() {
		log.d(`CalcHistoryList | beforeMount`, this.$route.params.id)
		await this.loadCalcHistory({id: this.$route.params.id})
	},
	computed: {
		cCalcHistory() {
			if(this.calcHistory && this.calcHistory[this.$route.params.id]) {
				return this.calcHistory[this.$route.params.id]
			}
			return {}
		},
		cCalcHistoryArr() {
			let result = []
			if (this.cCalcHistory) {
				for(let id in this.cCalcHistory) {
					result.push(this.cCalcHistory[id])
				}
			}
			return result
		},
		cCalcHistoryReverced() {
			return this.cCalcHistoryArr.sort((a,b)=>{a.id==b.id}).reverse()
		},
		...mapState('calcHistory', [
			'calcHistory',

			'w_errors',
		]),
		...mapGetters('calcHistory', [
			'w_isLoading',
			'w_isError',
		]),

		...mapState('common', [
			'userProducts',
		]),
		...mapGetters('common', [
			'orderedUserProducts',
		])
	},
	methods: {
		...mapActions('calcHistory', [
			'loadCalcHistory',
		]),
		getUniquVal(val){
			return val.reduce((unique, item)=>unique.includes(item)? unique:[...unique, item], [])
		},
	}
}
</script>

<style>
.calcHistoryEl:last-child hr {
	display: none;
}
</style>

<style scoped>

</style>