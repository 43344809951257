import {get, getT, post, token} from './api.js'
import log from '../log'

const _name = 'User'

export default {
	/**
	 * Получение профиля пользователя
	 * 
	 * @param {number|null} id пользователь
	 * @returns 
	 */
	async getUser(id) {
		log.d(`Api | ${_name} | getUser`, id)
		let userString = ''
		if (id && id>0) {
			userString = `/${id}`
		}
		let response = await getT(`user${userString}`)
		return response
	},

	/**
	 * Получение последних визитов с сервера
	 * API
	 * 
	 * @param {number|null} id пользователь
	 * @returns 
	 */
	async getLastVisits(id, page=0, count=10) {
		log.d(`Api | ${_name} | getLastVisits`, id, page, count)
		let userString = ''
		if (id && id>0) {
			userString = `/${id}`
		}
		let response = await getT(`user${userString}/lastVisits`, {
			page: page||0,
			count: count||10,
		})
		return response
	},

	/**
	 * Получение новостей (обновлений по клиникам)
	 * API
	 * 
	 * @param {number|null} id - id пользователя
	 * @param {number|null} page - текущая страница
	 * @param {number|null} count - количество элементов на страницу
	 * @returns 
	 */
	async getNews(id, page=0, count=10) {
		log.d(`Api | ${_name} | getNews`, id, page, count)
		let userString = ''
		if (id && id>0) {
			userString = `/${id}`
		}
		let response = await getT(`user${userString}/news`, {
			page: page||0,
			count: count||10,
		})
		return response
	},

	/**
	 * Получение клиник к посещению
	 * API
	 * 
	 * @param {number|null} id пользователь
	 * @returns 
	 */
	async getToVisits(id, page, count) {
		log.d(`Api | ${_name} | getToVisits`, id, page, count)
		let userString = ''
		if (id && id>0) {
			userString = `/${id}`
		}
		let response = await getT(`user${userString}/toVisit`)
		return response
	},
}