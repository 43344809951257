<template>
    <div class="modal user-list-modal" tabindex="-1" id="userListModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-body">
                    <div class="h1 mb-4">Личные списки</div>
                    <button class="btn btn-link btn-link-lg pl-0" @click="resetNewList" data-target="#createListModal" data-toggle="modal" data-dismiss="modal" type="button">
                        <svg class="mr-4" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 5v14M5 12h14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Создать
                    </button>
                    <div class="modal-overflow mt-4">
                        <table class="table table-fav-lists">
                            <tr>
                                <th>Наименование</th>
                                <th>Цвет</th>
                                <th>Регионы</th>
                                <th></th>
                            </tr>
                            <tr v-for="list in lists" :key="list.id">
                                <td class="fsz-base">
                                    <div class="font-weight-bold">{{ list.nm }}</div>
                                    <div v-if="list.clinics" class="mt-3">
                                        <Plurals :count="list.clinics.length" :postfix="['клиника','клиники','клиник']"></Plurals>
                                    </div>
                                </td>
                                <td>
                                    <div
                                        class="color-sample"
                                        v-if="list.color != 16777215"
                                        :style="{backgroundColor: '#' + list.color.toString(16)}"
                                        ></div>
                                </td>
                                <td>
                                    <div v-for="(region, index) in getListRegions(list.id)" :key="region">
                                        {{ region }}<span v-if="index != getListRegions(list.id).length - 1">,</span>
                                    </div>
                                </td>
                                <td>
                                    <button @click="loadListHandler(list)" class="btn-edit"  data-toggle="modal" data-target="#createListModal" data-dismiss="modal" type="button">
                                        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 20h9M16.5 3.5a2.121 2.121 0 113 3L7 19l-4 1 1-4L16.5 3.5z" stroke="#00857C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import Plurals from '../../blocks/Plurals'
import log from '../../../log'

export default {
	name: "UserListsModalWidget",
    components: {
        Plurals,
    },
	async beforeMount() {
        log.d(`UserListsModalWidget | beforeMount`)
        // await this.loadLists()
	},
	data() {
		return {
		}
	},
	computed: {
		...mapState('userLists', [
			'lists',
			'w_errors',
		]),
		...mapGetters('userLists', [
			'w_isLoading',
			'w_isError',
            'getListRegions'
		]),
	},
	methods: {
        async loadListHandler(e) {
            this.setSearchString('')
            this.loadList(e);
        },
		...mapActions('userLists', [
			'loadList',
			'loadLists',
		]),
		...mapMutations('userLists', [
			'setNewList',
            'resetNewList'
		]),
        ...mapActions('userListClinics', [
			'setSearchTimeout',
            'setSearchString',
		]),
	},
}
</script>

<style>

</style>

<style scoped>

</style>