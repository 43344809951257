<template>
    <Widget
        title="Информация"
        :isLoading="w_isLoading"
        :isError="w_isError"
        :errors="w_errors"
        loadingMinHeight="200px"
    >
        <div class="mb-4 pr-4">
            <div class="fsz-sm text-muted mb-2">Адрес</div>
            <div v-if="(cClinic.city || cClinic.address)">{{ cClinic.city }}, {{ cClinic.address }}</div>
            <div v-else>-</div>
        </div>
        <div class="mb-4 pr-4">
            <div class="fsz-sm text-muted mb-2">Телефон</div>
            <div v-if="cClinic.tel">{{ cClinic.tel }}</div>
            <div v-else>-</div>
        </div>
        <div class="mb-4 pr-4">
            <div class="fsz-sm text-muted mb-2">Parent ЛПУ</div>
            <div v-if="cClinic.medicalOrganistaionName">{{ cClinic.medicalOrganistaionName }} </div>
            <div v-else>-</div>
        </div>
        <div class="mb-4 pr-4">
            <div class="fsz-sm text-muted mb-2">Parent ID</div>
            <div v-if="cClinic.externalId">{{ cClinic.externalId }}</div>
            <div v-else>-</div>
        </div>
    </Widget>	
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Widget from '../Widget'

export default {
    name: "ClinicMainInfo",
	components: {
		Widget,
	},
	computed: {
		cClinic() {
			if(this.clinics && this.clinics[this.$route.params.id]) {
				return this.clinics[this.$route.params.id]
			}
			return {}
		},
		...mapState('clinic', [
			'clinics',
			'w_errors',
		]),
		...mapGetters('clinic', [
			'w_isLoading',
			'w_isError',
		])
	},
	methods: {
		...mapActions('clinic', [
			'savePassportData',
		]),
	}
}
</script>

<style>

</style>

<style scoped>

</style>