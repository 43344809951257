<template>
<div>
	<div class="table-responsive">
		<transition name="fade">
			<div class="loader" v-if="w_isLoading">
				<div class="loader-spinner">
					<svg class="spinner" width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill="#6ECEB2" fill-rule="evenodd" clip-rule="evenodd" d="M10.185 13.32c-.335.5-.202 1.177.296 1.513a1.085 1.085 0 001.509-.297 9.764 9.764 0 018.13-4.354c5.405 0 9.786 4.396 9.786 9.818s-4.381 9.818-9.785 9.818c-.6 0-1.088.489-1.088 1.091 0 .603.487 1.091 1.088 1.091 6.605 0 11.959-5.373 11.959-12S26.726 8 20.12 8c-4.034 0-7.729 2.021-9.935 5.32z"/>
					</svg>
				</div>
			</div>
		</transition>
		
		<table class="table table-clinics" :class="{box_loading: w_isLoading == true}">
			<tr>
				<th>Наименование и тип ЛПУ</th>
				<th>Город</th>
				<th>Адрес</th>
				<th></th>
			</tr>

			<tr
				v-for="clinic in clinics" :key="clinic.id"
				:class="{deleted: clinic.isOld && clinic.visitCount == 0}"
				>
				<td>
					<router-link :to="`/clinic/${clinic.id}/passport`">
						<div class="font-weight-bold fsz-base" :inner-html.prop="clinic.name | highlight(searchString)"></div>
						<div class="mt-2" :inner-html.prop="clinic.type | highlight(searchString)"></div>
					</router-link>
					<div v-if="clinic.isOld" class="status has-icon text-danger mt-2">
						<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0v0zM12 9v4M12 17h.01" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
						<ClinicDeletionType :visitCount="clinic.visitCount"></ClinicDeletionType>
					</div>
				</td>
				<td>
					<router-link :to="`/clinic/${clinic.id}/passport`" :inner-html.prop="clinic.city | highlight(searchString)"></router-link>
				</td>
				<td>
					<router-link :to="`/clinic/${clinic.id}/passport`" :inner-html.prop="clinic.address | highlight(searchString)"></router-link>
				</td>
				<td class="pt-1">
					<button
						class="btn-fav active" 
						v-if="clinic.inFavourites==1"
						@click="handleFav(clinic)"
						>
						<svg width="18" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 1l2.472 5.101L17 6.925l-4 3.968.944 5.607L9 13.851 4.056 16.5 5 10.893 1 6.925 6.528 6.1 9 1z" stroke="#00857C" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</button>

					<button
						class="btn-fav"
						v-else
						@click="handleFav(clinic)"
						>
						<svg width="18" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 1l2.472 5.101L17 6.925l-4 3.968.944 5.607L9 13.851 4.056 16.5 5 10.893 1 6.925 6.528 6.1 9 1z" stroke="#00857C" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</button>
				</td>
			</tr>
		</table>
	</div>
	<transition name="fade">
		<Notification v-if="timer" :text="notificationText" :buttonText="notificationButtonText" @btnClick="notificationButtonAction"></Notification>
	</transition>
</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ClinicDeletionType from '../../blocks/ClinicDeletionType'
import Notification from '../../blocks/Notification'
import log from '../../../log'

export default {
	name: "ClinicsListWidget",
	components: {
		ClinicDeletionType,
		Notification
	},
	async beforeMount() {
	},
	data() {
		return {
			timer: null,
            notificationText: '',
            notificationButtonText: '',
            notificationButtonAction: null,
		}
	},
	props: {
		searchString: {
			type: String,
			default: ''
		}
	},
	computed: {
		...mapState('clinics', [
			'clinics',

			'w_errors',
		]),
		...mapGetters('clinics', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		handleFav(clinic) {
			if (clinic.inFavourites==1) {
				this.notificationText = `Клиника удалена из списка «Избранное»`
				this.removeFromFav(clinic.id)
				this.timer = null
				this.timer = setTimeout(() => {
					this.timer = null
				}, 5000)
			} else {
				this.notificationText = `Клиника добавлена в список «Избранное»`
				this.addToFav(clinic)
				this.timer = null
				this.timer = setTimeout(() => {
					this.timer = null
				}, 5000)
			}
		},
		...mapActions('clinics', [
		]),

		...mapActions('userLists', [
			'addToFav',
			'removeFromFav',
		]),
	},
}
</script>

<style>

</style>

<style scoped>
	.table-responsive {
		position: relative;
	}
	.loader {
		position: absolute;
		top:0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.8);
		backdrop-filter: blur(4px);
	}
	.loader-spinner {
		position: sticky;
		top: 100px;
		left: 50px;
		z-index: 50;
		text-align: center;
		overflow: hidden;
	}

	.fade-leave-active {
		transition: opacity .2s;
	}
	.fade-leave-to {
		opacity: 0;
	}
</style>