<template>
	<div class="page-auth">
		<div class="page-auth__section">
			<div id="register-form" class="page-form  pb-7 validate justify-content-between">
				<!--autocomplete="off" action="/register.php" method="POST"-->
				<div class="page-form-header">
					<img src="./../assets/images/logo-msd-commerce.svg" />
					<!-- <a href="#" class="btn-close"></a> -->
				</div>
				<div class="page-form-body mb-12">
					<h1 class="mt-2 mb-5">Почти готово!</h1>
					<p>Мы отправили 
						<!-- на адрес <span class="text-primary">medpredtestmail@merck.com</span> -->
						письмо для завершения регистрации.</p>
					<p>Перейдите по ссылке из письма, чтобы подтвердить указанные данные и получить доступ к сайту.</p>
				</div>
				<div class="page-form-footer mt-0">
					<router-link to="/register-finish" class="btn btn-primary w-100">Далее</router-link>
					<div class="text-center mt-5">
						<router-link to="/register-confirm-repeat" class="page-form-link">отправить ещё раз</router-link>
					</div>
					<div class="text-center mt-12 pt-6">
						<a href="mailto:support@msdmed.ru" class="page-form-link">Техподдержка: <span class="td-underline">support@msdmed.ru</span></a>
					</div>
				</div>
			</div>
		</div>
  	</div>
</template>

<script>
export default {
	name: "RegisterConfirm",
	data() {
		return {
		}
	},
}
</script>

<style>

</style>

<style scoped>

</style>