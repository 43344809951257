import Vue from 'vue'
import router from '../../router'
import authApi from '../../api/auth.api'
import adminApi from '../../api/admin.api'
import log from '../../log'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

const _store = 'Employees2'

// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],

	searchString: '',
	searchTimeout: null,

	filterShowNotActiveUsers: false,

	sortName: undefined,
	sortEmail: undefined,
	sortDivision: undefined,
	sortRole: undefined,
	
	users: {
	},

	regions: [], // Список всех регионов
	roles: [],	// Список всех ролей
	divisions: [
		{id: 1, nm: "Вакцины"},
		{id: 2, nm: "Онкология"},
	], // Список всех подразделений
	statuses: [
		{id: 0, nm: "Неактивен"},
		{id: 1, nm: "Активен"},
	], // Список всех статусов
}

// getters
const getters = {
	rolesWithoutAdmin(state) {
		return state.roles.filter(el => {
			return (el?.nm?.toLowerCase() != 'admin' && el?.nm?.toLowerCase() != 'админ' && el?.nm?.toLowerCase() != 'администратор')
		})
	},
	rolesWithAdmin(state) {
		return state.roles.filter(el => {
			return (el?.nm?.toLowerCase() == 'admin' || el?.nm?.toLowerCase() == 'админ'  || el?.nm?.toLowerCase() == 'администратор')
		})
	},

	// isAdmin(state) {
	// 	let admArray = state.roles.filter(el => {
	// 		return (el.nm.toLowerCase() == 'admin' || el.nm.toLowerCase() == 'админ')
	// 	})
	// 	console.debug(`admArray`, admArray, admArray.length)
	// 	return admArray.length > 0
	// },

	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async getRoleList({state, commit, dispatch}) {
		log.d(`Store | ${_store} | Actions | getRolesList`)

		if (state.roles.length > 0) {
			return
		}

		commit('w_loadingPlus')
		let req = await adminApi.getRoleList()
		if (!req.ok) {
			// TODO: throw error?
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}

		commit('setRoleList', data);
		commit('w_loadingMinus')
	},

	async getRegionList({state, commit, dispatch}) {
		log.d(`Store | ${_store} | Actions | getRegionsList`)

		if (state.regions.length > 0) {
			return
		}
		commit('w_loadingPlus')
		let req = await adminApi.getRegionList()
		if (!req.ok) {
			// TODO: throw error?
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}

		commit('setRegionList', data);
		commit('w_loadingMinus')
	},

	async getUser({state, commit, dispatch}, id) {
		log.d(`Store | ${_store} | Actions | getUser`, id)

		commit('w_loadingPlus')
		let req = await adminApi.getUser(id)
		if (!req.ok) {
			// TODO: throw error?
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}

		commit('setUser', data);
		commit('w_loadingMinus')
	},

	async getUserList({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_store} | Actions | getUser`, reqObj)

		commit('w_loadingPlus')
		let req = await adminApi.getUserList(reqObj)
		if (!req.ok) {
			// TODO: throw error?
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}

		commit('setUserList', data);
		commit('w_loadingMinus')
	},

	async updateUserActivity({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_store} | Actions | updateUserActivity`, reqObj)

		// commit('w_loadingPlus')
		commit('updateUserActivity', {data: data, id: reqObj.id, activity: reqObj.activity});
		let req = await adminApi.setUserActivity(reqObj.id, reqObj.activity)
		if (!req.ok) {
			// TODO: throw error?
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}

		// commit('w_loadingMinus')
	},

	async updateUserRole({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_store} | Actions | updateUserRole`, reqObj)

		let current = state?.users[reqObj.id]?.roles.map(el => el.id) ?? []
		let newRole = reqObj.roles.map(el => el.id) ?? []
		
		let toRemove = current.filter(x => !newRole.includes(x));
		let toAdd = newRole.filter(x => !current.includes(x));

		console.debug('add', toAdd, 'remove', toRemove)

		// commit('w_loadingPlus')
		
		commit('updateUserRoles', {id: reqObj.id, roles: reqObj.roles});

		for(let el of toAdd) {
			let req = await adminApi.addUserRole(reqObj.id, el)
	
			if (!req.ok) {
				// TODO: throw error?
			}
			let data = req.json.data
			if(req.json.status !== 'ok') {
				// TODO: throw error?
			}
		}

		for(let el of toRemove) {
			let req = await adminApi.removeUserRole(reqObj.id, el)
	
			if (!req.ok) {
				// TODO: throw error?
			}
			let data = req.json.data
			if(req.json.status !== 'ok') {
				// TODO: throw error?
			}
		}

		// commit('w_loadingMinus')
	},

	async updateUserDivisions({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_store} | Actions | updateUserDivisions`, reqObj)

		console.debug(`! all`, state.divisions)
		console.debug(`! current`, state?.users[reqObj.id]?.divisions)
		console.debug(`! new`, reqObj.divisions)

		let current = state?.users[reqObj.id]?.divisions.map(el => el.id) ?? []
		let newDiv = reqObj.divisions.map(el => el.id) ?? []
		
		let toRemove = current.filter(x => !newDiv.includes(x));
		let toAdd = newDiv.filter(x => !current.includes(x));
		
		console.debug('add', toAdd, 'remove', toRemove)

		// commit('w_loadingPlus')
		commit('updateUserDivisions', {id: reqObj.id, divisions: reqObj.divisions});

		for(let el of toAdd) {
			let req = await adminApi.addUserDivision(reqObj.id, el)
	
			if (!req.ok) {
				// TODO: throw error?
			}
			let data = req.json.data
			if(req.json.status !== 'ok') {
				// TODO: throw error?
			}
		}

		for(let el of toRemove) {
			let req = await adminApi.removeUserDivision(reqObj.id, el)
	
			if (!req.ok) {
				// TODO: throw error?
			}
			let data = req.json.data
			if(req.json.status !== 'ok') {
				// TODO: throw error?
			}
		}

		// commit('w_loadingMinus')
	},


}

// mutations
const mutations = {
	setRoleList(state, data) {
		log.d(`Store | ${_store} | Mutations | setRolesList` , data)
		state.roles = data
	},

	setRegionList(state, data) {
		log.d(`Store | ${_store} | Mutations | setRegionsList` , data)
		state.regions = data
	},

	setUser(state, data) {
		log.d(`Store | ${_store} | Mutations | setUser` , data)

		let userId = data?.user?.id;
		if (data?.user?.is_active) {
			data.user.is_active = 1
		} else {
			data.user.is_active = 0
		}
		for (let role of data.user.roles) {
			delete role['laravel_through_key']
		}

		if (userId) {
			Vue.set(state.users, userId, data.user)
		}
	},

	setUserList(state, data) {
		log.d(`Store | ${_store} | Mutations | setUser` , data)

		let userId = data?.user[0]?.id;
		if (userId) {
			Vue.set(state.users, userId, data.user[0])
		}
	},

	updateUserActivity(state, data) {
		log.d(`Store | ${_store} | Mutations | updateUserActivity` , data)
		console.debug(state.users[data.id].is_active)
		state.users[data.id].is_active = data.activity
		// Vue.set(state.user, data.id, )
	},
	
	updateUserRoles(state, data) {
		log.d(`Store | ${_store} | Mutations | updateUserRoles` , data)
		let newData = Object.assign({}, state.users[data.id])
		newData.roles = data.roles
		Vue.set(state.users, data.id, newData)
	},

	updateUserDivisions(state, data) {
		log.d(`Store | ${_store} | Mutations | updateUserDivisions` , data)
		state.users[data.id].divisions = data.divisions
		// Vue.set(state.users, data.id, )
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}