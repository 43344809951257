<template>
	<div class="container">
		<div class="box">
            <div class="box__body" v-if="divisionVaccines">
                <div class="row align-items-center mb-2">
                    <div class="col-sm-6">
                        <h1>Продажи</h1>
                    </div>
                    <div class="col-sm-6 text-sm-right">
                        <div class="switch">
                            <input type="checkbox" name="period" id="period" class="switch-input" v-model="period">
                            <label class="switch-label" for="period">за месяц</label>
                            <label class="switch-label" for="period">за год</label>
                            <label class="switch-appearance" for="period"></label>
                        </div>
                    </div>
                </div>
                <transition name="fade" mode="in-out">
                    <div v-if="w_isLoading">
                        <LoadingBlock
                            isEnabled="true"
                            minHeight="300px">
                        </LoadingBlock>
                    </div>
                    <div v-else>
                        <SalesBlock
                            :isLoading="w_isLoading"
                            :year="prevYear"
                            :periodMode="perionMode"
                            :sales="getSalesFromYear(prevYear).sell"
                            :edited="getSalesFromYear(prevYear).edited"
                            :current="getSalesFromYear(prevYear).current"
                            @save="onSaveHandler"
                            >
                        </SalesBlock>

                        <hr>

                        <SalesBlock
                            :isLoading="w_isLoading"
                            :year="currentYear"
                            :periodMode="perionMode"
                            :sales="getSalesFromYear(currentYear).sell"
                            :edited="getSalesFromYear(currentYear).edited"
                            :current="getSalesFromYear(currentYear).current"
                            @save="onSaveHandler"
                            >
                        </SalesBlock>
                    </div>
                </transition>
            </div>

            <div class="box__body pb-8" v-if="divisionOnko">
                <h1 class="mt-3 mb-4">Продажи</h1>
                <div>
                    <div class="fw-bold mb-4">2021 на текущий момент (упаковки)</div>
                    <div>Китруда (факт)</div>
                    <div class="h1 mt-1 mb-3">10 000</div>
                    <SalesTable></SalesTable>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import SalesBlock from './blocks/SalesBlock'
import SalesTable from './blocks/SalesTable'
import LoadingBlock from './blocks/LoadingBlock'
import log from '../log'

export default {
    name: "ClinicSales",
    components: {
        SalesBlock,
        SalesTable,
        LoadingBlock
	},
    data() {
        return {
            ccId: this.$route.params.id,
            years: {
                2020: {
                    editingMode: false
                },
                2021: {
                    editingMode: false
                },
            },
            period: false,
        }
    },
    async beforeMount() {
		log.d(`ClinicSales | beforeMount`, this.$route.params.id)
		
        await this.loadClinicProductSales(this.ccId)
	},
    computed: {
        cClinicSales() {
			if(this.clinicSales && this.clinicSales[this.ccId]) {
				return this.clinicSales[this.ccId]
			}
			return {}
		},
        currentYear() {
            return new Date().getFullYear()
        },
        prevYear() {
            return new Date().getFullYear()-1
        },
        perionMode() {
            if (this.period) {return 'm'}
            return 'y'
        },

        ...mapState('clinicProductSales', [
			'clinicSales',
			'w_errors',
		]),
		...mapGetters('clinicProductSales', [
			'w_isLoading',
			'w_isError',
		]),

        ...mapState('common', [
			'userProducts',
		]),
        ...mapGetters('common', [
            'orderedUserProducts',
        ])

		// ...mapState('common', [
		// 	'headPositions',
		// 	'distributors',
		// 	'inputSendTimeout',
		// 	'userProducts',
		// ]),
		// ...mapGetters('common', [
		// 	'orderedUserProducts',
		// ]),
    },
    methods: {
        getSalesFromYear(year) {
            let c = this.cClinicSales
            if (!c || !c.sells || !c.sells.years) {return {}}
            let index = c.sells.years.findIndex(el=>el.year==year)
            return c.sells.years[index]
        },
        toggleEditingMode(value) {
            this.years[value].editingMode = !this.years[value].editingMode
        },
        onSaveHandler(value){
            this.saveClinicProductSales({year: value.year, clinicId: this.ccId, value: value.editedData})
        },

        ...mapActions('clinicProductSales', [
			'loadClinicProductSales',
            'saveClinicProductSales',
        ]),
    }

}
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.25s ease-out;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>

<style scoped>

</style>