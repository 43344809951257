const fs = require('fs')

let protocol = typeof window !== "undefined"?window.location.protocol:'https'
let hostname = typeof window !== "undefined"?window.location.hostname:'localhost'
let port = typeof window !== "undefined"?window.location.port:''

let defaultConfig = {
	apiBaseUrl: `${protocol}//${hostname}:${port}/api/v1`,
	logEnabled: false,
	searchTimeout: 300,
	inputTimeout: 1000,

	showHideAllRegionsLevel: 6, // Количество регионов при превышении которого появляется селектор выбрать все/снять все

	envMode: process.env.NODE_ENV,
	build: 0,
	version: '',
}

let version = ""
let build = ""

let loadedConfig = {}

try {
	loadedConfig = require('./config.js');
	if (loadedConfig && loadedConfig.default) {
		loadedConfig = loadedConfig.default
	}
} catch (error) {
	loadedConfig = {}
}

try {
	version = fs.readFileSync(`${__dirname}/version`, "utf8");
	defaultConfig.version = version.trim()
} catch (error) {}

try {
	build = fs.readFileSync(`${__dirname}/build`, "utf8");
	defaultConfig.build = build.trim()
} catch (error) {}

export default {...defaultConfig, ...loadedConfig}