<template>
	<Widget
        :title="widgetTitle"
        :isLoading="w_isLoading"
        :isError="w_isError"
        :errors="w_errors"
        :url="divisionVaccines?'sells':''"
        >
        <div class="row align-items-center justify-content-between">
            <div v-if="divisionVaccines" class="col-lg-2">
                <div class="row">
                    <div class="col-6 col-md-4 col-lg-12"
                         v-for="product in MSDuserProducts"
                         :key="product.id"
                        >
                        <div v-if="Object.keys(userProducts).length" class="input-check input-check-sm input-check-align-center my-2">
                            <input
                                type="checkbox"
                                :id="userProducts[product.id].alias"
                                v-model="products"
                                :value="product.id"
                                >
                            <label class="text-nowrap" :for="userProducts[product.id].alias">{{userProducts[product.id].nm}}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-2">
                <div class="row">
                <div class="col-6 col-md-4 col-lg-12 mb-4">
                    <div class="mb-2">Факт</div>
                    <div class="h1 mb-0 text-nowrap">{{ actualSum | digitSpace1k }}</div>
                </div>
                <div class="col-6 col-md-4 col-lg-12 text-primary-light">
                    <div class="mb-2">План</div>
                    <div class="h1 mb-0 font-weight-light text-nowrap">{{ planSum | digitSpace1k }}</div>
                </div>
                </div>
            </div>
            
            <div class="col-lg-7">
                <div class="btn-switch justify-content-end mb-4" :class="{'pr-lg-7': offsetRight}">
                    <button :class="{'active': graphActiveTab==0}" @click="setGraphActiveTab(0)" type="button">янв – июн</button>
                    <button :class="{'active': graphActiveTab==1}" @click="setGraphActiveTab(1)" type="button">июл – дек</button>
                </div>
                <div class="chart-container" :class="{'pr-lg-7': offsetRight}">
                    <SalesChart v-if="graphActiveTab==0" :chartData="monthsSales" half=1></SalesChart>
                    <SalesChart v-if="graphActiveTab==1" :chartData="monthsSales" half=2></SalesChart>

                </div>
            </div>

        </div>
    </Widget>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Widget from '../Widget'
import SalesChart from '../../blocks/SalesChart'
import log from '../../../log'

export default {
    name: "ClinicSales",
	components: {
		Widget,
        SalesChart
	},
    props: {
        widgetTitle: {
            type: String,
            default: 'Продажи'
        },
        offsetRight: {
            type: Boolean,
            default: false
        }
    },
    async beforeMount() {
		log.d(`ClinicSales | beforeMount`, this.$route.params.id)
		await this.loadClinicSales({id: this.$route.params.id})

        if (this.products.length == 0) {
            this.products = Object.keys(this.MSDuserProducts)
        }

        this.graphActiveTab = (new Date().getMonth() + 1) <= 6 ? 0 : 1
	},
    data() {
        return {
            products: [],
            graphActiveTab: 0,
        }
    },
	computed: {
        MSDuserProducts() {
			let resultProducts = {}
			for (let prId in this.userProducts) {
				let el = this.userProducts[prId]
				if (el.is_msd_product) {
					resultProducts[el.id] = el
				}
			}
			return resultProducts
		},
        planSum() {
            return this.products.reduce((sum, pId) => {
                if (this.clinicSales[pId]) {
                    return sum + parseInt(this.clinicSales[pId].plan)
                }
                return sum
            }, 0)
        },
        actualSum() {
            return this.products.reduce((sum, pId) => {
                if (this.clinicSales[pId]) {
                    return sum + parseInt(this.clinicSales[pId].actual)
                }
                return sum
            }, 0)
        },
        monthsSales() {
            let obj = {
                actual: 0,
                plan: 0,
            }

            this.products.forEach(pId => {
                if (this.clinicSales[pId] == undefined) {return}
                let months = Object.values(this.clinicSales[pId].months);
                months.forEach(month => {
                    if (!obj.hasOwnProperty(month.month)) {
                        obj[month.month] = {
                            plan: 0,
                            actual: 0
                        }
                    }
                    if (month.plan) obj[month.month].plan += parseInt(month.plan)
                    if (month.actual) obj[month.month].actual += parseInt(month.actual)
                })
                obj.actual += parseInt(this.clinicSales[pId].actual)
                obj.plan += parseInt(this.clinicSales[pId].plan)
            })

            return obj
        },
		...mapState('common', [
			'userProducts'
		]),
		...mapState('clinicSales', [
			'clinicSales',
            'w_errors',
		]),
        ...mapGetters('clinicSales', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
        setGraphActiveTab(value) {
            this.graphActiveTab = value
        },
		...mapActions('clinicSales', [
			'loadClinicSales',
		]),
	},
    watch: {
        userProducts() {
            if(Object.keys(this.MSDuserProducts).length>0 && this.products.length==0) {
                this.products = Object.keys(this.MSDuserProducts)
            }
        },
    },
}
</script>

<style>
    .chart-container #line-chart {
		height: 100% !important;
    }
</style>

<style scoped>
    .chart-container {
        display: flex;
        justify-content: flex-end;
        position: relative;
        right: -12px;
        width: 100%;
        height: 190px;
        overflow: auto;
    }
    .chart-container > div {
        flex-shrink: 0;
        /* width: 542px; */
        width: 100%;
    }
</style>