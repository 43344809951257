import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import regionsApi from '../../api/regions.api'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],
	w_name: 'regionUserCustomSalesPlan',
	w_netStatus: {},

	users: {},
	plans: {},

}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadRegionUserCustomSalesPlan({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | loadRegionUserCustomSalesPlan`, reqObj)
		
		commit('w_loadingPlus')

		// if(Object.keys(state.plans[reqObj.regions]).length === 0) {	// TODO: подумать над кэшированием // TODO: тут теоретически может прилететь массив из выбранных регионов
			let req = await regionsApi.getUserCustomRegionSellsPlan(reqObj.regions)

			if (!req.ok) {
				// TODO: throw error?
				commit('w_addError', 'Ошибка при загрузке данных')
				return
			}
			let data = req.json.data
			if(req.json.status !== 'ok') {
				// TODO: throw error?
			}

			commit('setUsers', data.users)
			commit('setPlans', data.regions)
		// }

		commit('w_loadingMinus')
	},

	async saveUserCustomRegionSellsPlan({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | saveUserCustomRegionSellsPlan`, reqObj)
		commit('saveUserCustomRegionSellsPlan', reqObj)
		commit('w_setTimer', {
			id: reqObj.regionId + reqObj.userId + reqObj.year + reqObj.month,
			action: ()=>{
				regionsApi.postUserCustomRegionSellsPlan(reqObj.regionId, {
					product: 7,
					year: reqObj.year,
					month: reqObj.month,
					count: reqObj.count,
				})
			},
		})
	},

	
}

// mutations
const mutations = {
	setUsers(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | setUsers`, data)
		if (data == undefined) {return}
		state.users = {}
		Object.keys(data).forEach((el) => {
			Vue.set(state.users, el, data[el])
		})
	},

	setPlans(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | setPlans`, data)
		if (data == undefined) {return}
		Object.keys(data).forEach((el) => {
			Vue.set(state.plans, el, data[el])
		})
	},

	saveUserCustomRegionSellsPlan(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | setPlans`, data)

		if (!state.plans[data.regionId]) {
			state.plans[data.regionId] = {product: {7:{}}}
			let obj = {7:{users:{[data.userId]:{'yr':{[data.year]:{}}}}}}

			Vue.set(state.plans[data.regionId], 'product', obj)
		}

		if (!state.plans[data.regionId].product[7].users[data.userId]) {
			state.plans[data.regionId].product[7].users[data.userId] = {
				'yr': {
					[data.year]: {}
				}
			}
		}

		Vue.set(state.plans[data.regionId].product[7].users[data.userId]['yr'][data.year], data.month, data.count)
	},
	
	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}