import {get, getT, putT, postT, delT, token} from './api.js'
import log from '../log'

const _name = 'Admin'

export default {
	/**
	 * Получение списка всх ролей
	 * API
	 * 
	 * @returns 
	 */
	async getRoleList() {
		log.d(`Api | ${_name} | getRoleList`)
		let response = await getT(`admin/role/list`)
		return response
	},

	/**
	 * Получение списка ролей пользователя
	 * @param {*} requestObj 
	 * @returns 
	 */
	async getUserRoleList(id) {
		log.d(`Api | ${_name} | getUserRoleList`, id)
		let response = await getT(`admin/user/${id}/role/list`)
		return response
	},

	/**
	 * Добавление роли пользователю
	 * @param {*} id 
	 * @param {*} roleId 
	 * @returns 
	 */
	async addUserRole(id, roleId) {
		log.d(`Api | ${_name} | addUserRole`, id, roleId)
		let response = await postT(`admin/user/${id}/role/${roleId}`)
		return response
	},

	/**
	 * Удаление роли у пользователя
	 * @param {*} id 
	 * @param {*} roleId 
	 * @returns 
	 */
	async removeUserRole(id, roleId) {
		log.d(`Api | ${_name} | removeUserRole`, id, roleId)
		let response = await delT(`admin/user/${id}/role/${roleId}`)
		return response
	},

	/**
	 * Добавление подразделения пользователю
	 * @param {*} id 
	 * @param {*} divisionId 
	 * @returns 
	 */
	async addUserDivision(id, divisionId) {
		log.d(`Api | ${_name} | addUserDivision`, id, divisionId)
		let response = await postT(`admin/user/${id}/division/${divisionId}`)
		return response
	},
	
	/**
	 * Удаление подразделения у пользователя
	 * @param {*} id 
	 * @param {*} divisionId 
	 * @returns 
	 */
	async removeUserDivision(id, divisionId) {
		log.d(`Api | ${_name} | removeUserDivision`, id, divisionId)
		let response = await delT(`admin/user/${id}/division/${divisionId}`)
		return response
	},

	/**
	 * Получение списка сотрудников
	 * API
	 * 
	 * @returns 
	 */
	async getUserList(requestObj) {
		log.d(`Api | ${_name} | getUserList`, requestObj)
		let response = await getT(`admin/user/list`, requestObj)
		return response
	},

	/**
	 * Получение списка регионов сотрудника
	 * API
	 * 
	 * @returns 
	 */
	async getUser(id) {
		log.d(`Api | ${_name} | getUser`, id)
		let response = await getT(`admin/user/${id}`)
		return response
	},

	///////////////////////////////
	async setUserActivity(id, activity) {
		log.d(`Api | ${_name} | getUser`, id, activity)
		let response = await putT(`admin/user/${id}`, {is_active: activity})
		return response
	},


	/**
	 * Получение всех регионов
	 * @param {*} id 
	 * @returns 
	 */
	async getRegionList() {
		log.d(`Api | ${_name} | getRegionList`)
		let response = await getT(`admin/region/list`)
		return response
	},

	/**
	 * Получение регионов сотрудника
	 * @param {*} id 
	 * @returns 
	 */
	async getUserRegionList(id) {
		log.d(`Api | ${_name} | getUserRegionList`, id)
		let response = await getT(`admin/user/${id}/region/list`)
		return response
	},

	/**
	 * Добавление региона сотруднику
	 * @param {*} id 
	 * @param {*} regionId 
	 * @returns 
	 */
	async addUserRegion(id, regionId) {
		log.d(`Api | ${_name} | addUserRegion`, id, regionId)
		let response = await postT(`admin/user/${id}/region/${regionId}`)
		return response
	},

	/**
	 * Удаление региона у сотрудника
	 * @param {*} id 
	 * @param {*} regionId 
	 * @returns 
	 */
	async removeUserRegion(id, regionId) {
		log.d(`Api | ${_name} | removeUserRegion`, id, regionId)
		let response = await delT(`admin/user/${id}/region/${regionId}`)
		return response
	},

}