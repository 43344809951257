import {getT, postT, putT, delT, token} from './api.js'
import log from '../log'

const _name = 'UserList'

export default {
	
	/**
	 * Получение списка пользовательских списков
	 * API
	 * 
	 * @param {*} userId 
	 * @returns 
	 */
	async getLists(userId) {
		log.d(`Api | ${_name} | getLists`, userId)
		let userString = ''
		if (userId && userId>0) {
			userString = `/${userId}`
		}
		let response = await getT(`user${userString}/list`)
		return response
	},

	/**
	 * Получение параметров указанного списка
	 * API
	 * 
	 * @param {*} listId 
	 * @param {*} userId 
	 * @returns 
	 */
	async getList(listId, userId) {
		log.d(`Api | ${_name} | getList`, listId, userId)
		let userString = ''
		if (userId && userId>0) {
			userString = `/${userId}`
		}
		let response = await getT(`user${userString}/list/${listId}`)
		return response
	},
	
	/**
	 * Создание нового списка
	 * API
	 * 
	 * @param {*} data 
	 * @param {*} userId 
	 * @returns 
	 */
	async postList(data, userId) {
		log.d(`Api | ${_name} | postList`, data, userId)
		let userString = ''
		if (userId && userId>0) {
			userString = `/${userId}`
		}
		let response = await postT(`user${userString}/list`, data)
		return response
	},
	
	/**
	 * Изменение параметров указанного списка
	 * API
	 * 
	 * @param {*} data 
	 * @param {*} listId 
	 * @param {*} userid 
	 * @returns 
	 */
	async putList(data, listId, userId) {
		log.d(`Api | ${_name} | putList`, data, listId, userId)
		let userString = ''
		if (userId && userId>0) {
			userString = `/${userId}`
		}
		let response = await putT(`user${userString}/list/${listId}`, data)
		return response
	},

	/**
	 * Удаление указанного списка
	 * API
	 * 
	 * @param {*} listId 
	 * @param {*} userId 
	 * @returns 
	 */
	async delList(listId, userId) {
		log.d(`Api | ${_name} | delList`, listId, userId)
		let userString = ''
		if (userId && userId>0) {
			userString = `/${userId}`
		}
		let response = await delT(`user${userString}/list/${listId}`)
		return response
	},

	/**
	 * Получение клиник из списка
	 * API
	 * 
	 * @param {*} listId 
	 * @param {*} userId 
	 * @returns 
	 */
	async getClinics(listId, userId) {
		log.d(`Api | ${_name} | getClinics`, listId, userId)
		let userString = ''
		if (userId && userId>0) {
			userString = `/${userId}`
		}
		let response = await getT(`user${userString}/list/${listId}/clinics`)
		return response
	},

	/**
	 * Добвание клиники в список
	 * API
	 * 
	 * @param {*} clinicId 
	 * @param {*} listId 
	 * @param {*} userId 
	 * @returns 
	 */
	async addClinic(clinicId, listId, userId) {
		log.d(`Api | ${_name} | addClinic`, clinicId, listId, userId)
		let userString = ''
		if (userId && userId>0) {
			userString = `/${userId}`
		}
		let response = await postT(`user${userString}/list/${listId}/clinic/${clinicId}`)
		return response
	},

	/**
	 * Удаление клиники из списка
	 * API
	 * 
	 * @param {*} clinicId 
	 * @param {*} listId 
	 * @param {*} userId 
	 * @returns 
	 */
	async delClinic(clinicId, listId, userId) {
		log.d(`Api | ${_name} | delClinic`, clinicId, listId, userId)
		let userString = ''
		if (userId && userId>0) {
			userString = `/${userId}`
		}
		let response = await delT(`user${userString}/list/${listId}/clinic/${clinicId}`)
		return response
	},

}