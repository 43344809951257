import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import clinicApi from '../../api/clinic.api'
import config from '../../config.loader'
import tealium from '../../tealium'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],
	w_name: 'ClinicQuestPatientCategory',
	w_netStatus: {},
	
	patientCategories: {},
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadPatientCategories({state, commit, dispatch, rootState}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | loadPatientCategories`, reqObj)
		commit('w_loadingPlus')
		
		let req = await clinicApi.getPatientCategories(reqObj.id)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('loadPatientCategories', {clinicId: reqObj.id, data: data})

		commit('w_loadingMinus')
	},

	async savePatientCategory({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | savePatientCategory`, reqObj)
		commit('savePatientCategory', reqObj)

		commit('w_setTimer', {
			id: reqObj.categoryId,
			action: ()=>{
				clinicApi.postPatientCategory(reqObj.clinicId, {datum: {[reqObj.categoryId]: reqObj.value}})
				tealium.sendForData({ "event_category" : "form",  "event_action" : "sumbit",  "event_label":"cogorts"})
			},
		})
	},
}

// mutations
const mutations = {
	loadPatientCategories(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | loadPatientCategories`, d)
		let result = {}
		for(let el in d.data) {
			if (d.data[el] && d.data[el].orderby<99999) {
				result[d.data[el].id] = d.data[el]
			}
		}
		Vue.set(state.patientCategories, d.clinicId, result)
	},

	savePatientCategory(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | savePatientCategory`, d)
		let categories = {...state.patientCategories[d.clinicId]}
		categories[d.categoryId].cnt = d.value
		Vue.set(state.patientCategories, d.clinicId, categories)
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}