import config from './config.loader'

export function l(...args) {
	if (!config.logEnabled) {return}
	console.log(...args);
}
export function d(...args) {
	if (!config.logEnabled) {return}
	console.debug(...args);
}
export function w(...args) {
	if (!config.logEnabled) {return}
	console.warn(...args);
}
export function e (...args) {
	if (!config.logEnabled) {return}
	console.error(...args);
}

export default {
	l: (...args) => {
		return l(...args)
	},
	d: (...args) => {
		return d(...args)
	},
	w: (...args) => {
		return w(...args)
	},
	e: (...args) => {
		return e(...args)
	},
}