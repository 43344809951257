<template>
	<div class="container">
		<div class="page-row">
            <div class="page-aside">
                <div v-scroll="pinAsideMenu" class="page-aside-fixed">
                    <div v-if="divisionVaccines">
                        <div v-if="divisionVaccines" class="tt-upcase fsz-sm mt-2 mb-4">Общие вопросы</div>
                        <ol class="page-aside-menu">
                            <li v-if="divisionVaccines"><a @click="scrollTo" class="page-aside-menu-link" href="#quizPrice">Цена услуги и&nbsp;ответственное лицо</a></li>
                            <li v-if="divisionVaccines"><a @click="scrollTo" class="page-aside-menu-link" href="#quizDistributors">Дистрибьюторы</a></li>
                            <li v-if="divisionVaccines"><a @click="scrollTo" class="page-aside-menu-link" href="#quizProductPrice">Закупочная цена</a></li>
                            <li v-if="divisionVaccines"><a @click="scrollTo" class="page-aside-menu-link" href="#quizPatients">Пациенты</a></li>
                        </ol>
                        <a @click="scrollTo" href="#quizCohorts" class="page-aside-menu-link d-block tt-upcase fsz-sm my-4 text-body">Когорты</a>
                    </div>

                    <div v-if="divisionOnko">
                        <ol class="page-aside-menu">
                            <li><a @click="scrollTo" class="page-aside-menu-link" href="#o-patients">Пациенты</a></li>
                            <li><a @click="scrollTo" class="page-aside-menu-link" href="#o-diagnostics">Диагностика</a></li>
                            <li><a @click="scrollTo" class="page-aside-menu-link" href="#o-cam-strategy">КАМ-Стратегия</a></li>
                        </ol>
                    </div>

                    <BtnScrollTop></BtnScrollTop>
                </div>
            </div>
            <div class="page-main pr-2">
                <QuestCommon v-if="divisionVaccines"></QuestCommon>
                <QuestCohorts v-if="divisionVaccines"></QuestCohorts>

                <QuestOncoPatientCategories v-if="divisionOnko"></QuestOncoPatientCategories>
                <QuestOnco v-if="divisionOnko"></QuestOnco>
            </div>
        </div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import BoxCollapse from "./blocks/BoxCollapse"
import BtnScrollTop from "./blocks/BtnScrollTop"
import QuestCommon from "./widgets/Clinic/QuestCommon.widget"
import QuestCohorts from './widgets/Clinic/QuestCohorts.widget'
import QuestOncoPatientCategories from './widgets/Clinic/QuestOncoPatientCategories.widget'
import QuestOnco from './widgets/Clinic/QuestOnco.widget.vue'

export default {
    name: "ClinicQuest",
    components: {
        BoxCollapse,
        QuestCommon,
        QuestCohorts,
        BtnScrollTop,
        QuestOncoPatientCategories,
        QuestOnco,
    },
    data() {
        return {
            selectOptions: [
                {label: 'Имя', value: '1'},
                {label: 'Имя', value: '1'},
                {label: 'Имя', value: '1'},
            ]
        }
    },
    mounted() {
        $(window).on('scroll', () => {
            $('.page-aside-menu-link').each((index, item) => {
                if ($(item.hash) && $(item.hash).offset() && $(window).scrollTop() > $(item.hash).offset().top - 100 && ($(window).scrollTop() + 100) < $(item.hash).offset().top + $(item.hash).height()) {
                    $(item).addClass('active')
                } else {
                    $(item).removeClass('active')
                }
            })
        })
    },
    methods: {
        pinAsideMenu(event, el) {
            if (window.matchMedia("(min-width: 991px)").matches) {
                if ($(el).parent().offset().top + $(el).parent().height() - $(window).height() < $(window).scrollTop()) {
                    let footerScrollTop = Math.round($('.page-footer').offset().top - ($(window).scrollTop() + $(window).height()))
    
                    $(el).addClass('absolute');
                    $(el).removeClass('fixed');
                    $(el).css({height: `calc(100vh + ${footerScrollTop - 220}px)`})
                } else if ($(window).scrollTop() > $(el).parent().offset().top) {
                    $(el).addClass('fixed');
                    $(el).removeClass('absolute');
                    $(el).css({
                        left: $(el).parent().offset().left + 12,
                    })
                    el.style.removeProperty('height')
                } else {
                    $(el).removeClass('fixed');
                    $(el).removeClass('absolute');
                    el.style.removeProperty('height')
                }
            }
        },
        scrollTo(event, el) {
            event.preventDefault()
            if (event.target && $(event.target.hash) && $(event.target.hash).offset()) {
                $('html, body').animate({
                    scrollTop: $(event.target.hash).offset().top
                }, 400)
            }
        },
    }
}
</script>

<style>

</style>

<style scoped>

</style>