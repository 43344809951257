<script>
import { Pie } from 'vue-chartjs'

export default {
	name: "TenderStatsChart",
    extends: Pie,
    props: {
        allSum: {
            type: Number,
            default() {
                return 0
            }
        },
        chartData: {
            type: Array,
            default() {
                return []
            }
        }
    },
    mounted () {
        this.drawChart()
    },
    watch: {
        chartData: function() {
            this.drawChart()
        }
    },
    computed: {
        normalizedChartData() {
            let arr = []

            this.chartData.forEach((el, index) => {
                arr.push(this.getPercentValue(el.cnt, this.allSum))
            });

            return arr
        },
        normalizedBGColor() {
            let bgColor = []

            this.chartData.forEach((el, index) => {
                let opacity = 1
                switch (index) {
                    case 1: opacity = 0.72; break;
                    case 2: opacity = 0.64; break;
                    case 3: opacity = 0.4; break;
                    case 4: opacity = 0.24; break;
                    case 5: opacity = 0.1; break;
                }

                if (el.mnm.toLowerCase() == 'pembrolizumab') {
                    bgColor.push('#00857C')
                } else {
                    bgColor.push(`rgba(255, 120, 0, ${opacity})`)
                }
            });
            return bgColor
        }
    },
    methods: {
        getPercentValue(value, from) {
            return parseFloat((value/from)*100)
        },
        drawChart() {
            this.renderChart(
                {
                    datasets: [{
                        label: '78',
                        backgroundColor: 
                        this.normalizedBGColor,
                        borderColor: ['#fff', '#fff'],
                        borderWidth: 0,
                        borderAlign: 'center',
                        data: this.normalizedChartData
                    }]
                },
                {
                    responsive:true,
                    maintainAspectRatio: true,
                    padding: 0,
                    tooltips: {
                        enabled: false
                    }
                }
            )
        }
    }
}
</script>

<style>

</style>

<style scoped>

</style>