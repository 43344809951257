<template>
	<div class="input-search">
		<input
			class="form-control"
			type="text"
			:placeholder="placeholder"
			v-model="search"
			@input="handleInput"
			@keyup.enter="submit"
			/>
		<button type="button" @click="reset(), submit()" v-if="search" class="input-search-reset"></button>
		<button type="submit" @click="submit" class="input-search-submit"></button>
		<slot name="autocomplete" v-if="autocomplete && search.length > 0"></slot>
	</div>
</template>

<script>
export default {
	name: 'SearchString',
	props: {
        value: {
            type: String,
            required: true
        },
		placeholder: {
            type: String,
			default: 'Поиск',
            required: false
        },
		autocomplete: {
			type: Boolean,
			default: false
		},
    },
	data() {
		return {
			search: this.value,
		}
	},
	computed: {
	},
	methods: {
		handleInput (e) {
			this.$emit('input', this.search)
		},
		reset() {
			this.search = ''
			this.$emit('input', this.search)
		},
		submit() {
			this.$emit('submit', this.search)
		},
	},
	watch: {
		value(value) {
			if (this.search != value) {
				this.search = value
			}
		}
	}
}
</script>

<style>

</style>

<style scoped>

</style>