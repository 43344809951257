import { readyException } from 'jquery'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import log from './log'

export default {
	install(Vue, options) {
		Vue.mixin({
			computed: {
				...mapState('auth', [
					'role',
					'roles',
					'msdRole',
				]),
				role_manager_editor() {
					return (this.hasOneExRole('*') || this.hasOneRole(['Администратор','Менеджер']))
				},
			},
			methods: {
				hasOneMsdRole(roles) {
					if (Array.isArray(roles)) {
						for(let el of roles) {
							if (el.toLowerCase() == this.msdRole?.toLowerCase()) {return true}
						}
					} else if (typeof roles == 'string') {
						roles = roles.trim()
						if (roles == '*' && this.msdRole?.trim() != '') {return true}
						if (roles.toLowerCase() == this.msdRole?.toLowerCase()) {return true}
					}
					return false
				},
				hasOneExRole(roles) {
					if (Array.isArray(roles)) {
						for(let el of roles) {
							if (el.toLowerCase() == this.role?.toLowerCase()) {return true}
						}
					} else if (typeof roles == 'string') {
						roles = roles.trim()
						if (roles == '*' && this.role != undefined && this.role?.trim() != '') {return true}
						if (roles.toLowerCase() == this.role?.toLowerCase()) {return true}
					}
					return false
				},
				hasOneRole(roles) {
					if (Array.isArray(roles)) {
						for(let el of roles) {
							for(let role of this.roles) {
								if (typeof el == 'string') {
									if (el.toLowerCase() == role.nm.toLowerCase()) {return true}
								}
								// if (el.nm.toLowerCase() == role.nm.toLowerCase()) {return true}
							}
						}
					} else if (typeof roles == 'string') {
						if (roles == '*' && this.roles.length() > 0) {return true}
						for(let role of this.roles) {
							if (roles.toLowerCase() == role.nm.toLowerCase()) {return true}
						}
					}
					return false
				},

				hasAllRoles(roles) {	// TOOD:
					throw new Error('Not implemented yet')
				},
			}
		})
	}
}