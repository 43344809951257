import Vue from 'vue'
import router from '../../router'
import sellsApi from '../../api/sells.api'
import log from './../../log'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

const _store = 'userPVA'
// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],
	sales: {}
}

// getters
const getters = {
	allPlan: (state) => (products) => {
		return products.reduce((sum, pId) => { return sum + state.sales[pId].plan }, 0)
	},
	allActual: (state) => (products) => {
		return products.reduce((sum, pId) => { return sum + state.sales[pId].actual }, 0)
	},
	regions: (state) => (products) => {
		let obj = {}

		products.forEach(pId => {
			let product = state.sales[pId];
			for(let rId in product.regions) {
				let region = product.regions[rId]
				if (!obj[rId]) {
					obj[rId] = {
						id: rId,
						allPlan: 0,
						allActual: 0 
					}
				}
				obj[rId].id = rId;
				obj[rId].allPlan += parseInt(region.plan);
				obj[rId].allActual += parseInt(region.actual);
			}
		});

		return obj
	},
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadUserPVA({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_store} | Actions | loadUserPVA`, reqObj)

		// if (Object.keys(state.sales).length>0) {
		// 	log.d(`Store | ${_store} | Mutations | setUserPVA: cached`)
		// 	return;	//TODO: Улучшить кеширование (обновляется приблизительно раз в сутки)
		// }
		log.d(`Store | ${_store} | Mutations | setUserPVA: loaded`)
		
		commit('w_loadingPlus')

		let req = await sellsApi.getUsersPVA()
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}
		commit('setUserPVA', data)

		commit('w_loadingMinus')
	}
}

// mutations
const mutations = {
	setUserPVA(state, data) {
		log.d(`Store | ${_store} | Mutations | setUserPVA`, data)
		state.sales = data
	},

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}