<template>
    <div class="box-collapse">
        <h2 class="box-collapse-num pt-2">{{ order }}.</h2>
        <div>
            <button class="box-collapse-toggle pt-2 pb-3" :class="{'show': isShow}" @click.stop="toggleCollapse" type="button">
                <h2 class="mb-0" v-html="title"></h2>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 10L12 16L18 10" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <div v-show="show" class="box-collapse-content">
                <transition name="fade" mode="in-out">
                    <div v-if="isLoading">
                        <slot name="loader">
                            <LoadingBlock
                                isEnabled="true"
                                minHeight="100px"
                                ></LoadingBlock>
                        </slot>
                    </div>
                    <div v-else>
                        <slot></slot>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingBlock from './LoadingBlock'

export default {
	name: 'BoxCollapse',
    components: {
        LoadingBlock
    },
	data() {
		return {
            isShow: this.show || true
		}
	},
    props: {
        title: {
            type: String,
            required: false
        },
        show:{
            type: Boolean,
			default: true,
		},
        order:{
            type: Number,
			required: true,
		},
        isLoading:{
            type: Boolean,
			default: true,
		},
    },
	methods: {
        toggleCollapse(event) {
            this.isShow = !this.isShow
            $(event.target).next().slideToggle(200)
        }
	}
}
</script>

<style>

</style>

<style scoped>
</style>