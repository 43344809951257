<template>
<Widget
	title="Тендерная статистика"
	:isLoading="w_isLoading"
	:isError="w_isError"
	:errors="w_errors"
	:showEmptyMessage="allClinicsCountByRegions == 0"
    url="/regionSales"
	>
    <div class="row">
        <div class="col-lg-5">
            <div class="tender-chart">
                <TenderStatsChart :chartData="currentTenderSumSorted" :allSum="allSum"></TenderStatsChart>
                <div class="tender-chart__featured mt-4" v-if="currentTenderSumSorted[0]">
                    {{getPercentValue(currentTenderSumSorted[0].cnt) | int}} <span>%</span>
                </div>
            </div>
        </div>
        <div class="col-lg-7">
            <div class="pie-chart-legend">
                
                <div
                    class="pie-chart-legend__item mt-4"
                    v-for="(sum, index) of currentTenderSumSorted"
                    :key="sum.mnm"
                    >
                    <div class="row">
                        <div class="col-7">
                            <div 
                                class="pie-chart-legend__label fsz-base mb-2"
                                :class="[`number-${index}`, {
                                    'primary': sum.product_nm == 'Китруда',
                                    'accent': sum.product_nm != 'Китруда',
                                }]"
                                >{{sum.product_nm}}</div>
                            <span class="text-muted">{{sum.cnt}} уп.</span>
                        </div>
                        <div class="col-5 fw-bold">{{getPercentValue(sum.cnt) | int}}%</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</Widget>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../../../log'
import Widget from '../Widget'
import TenderStatsChart from '../../blocks/TenderStatsChart'

export default {
	name: "TenderStatsWidget",
	components: {Widget, TenderStatsChart},
	async beforeMount() {
		log.d(`TenderStatsWidget | beforeMount`)
        await this.loadAllTendersSum()
	},
	data() {
		return {
		}
	},
	computed: {
		...mapState('regionsClinics', [
			'clinicCountByRegions',
			// 'w_errors',
		]),
		...mapGetters('regionsClinics', [
			'selectedRegionsClinics',
			'allClinicsCountByRegions',
			// 'w_isLoading',
			// 'w_isError',
		]),
        ...mapState('regionsTenders', [
            'w_errors',
            'tenders',
            'tendersSum',
        ]),
        ...mapGetters('regionsTenders', [
            'w_isLoading',
			'w_isError',
        ]),
        currentTenderSum() {
            let sum = {}

            for(let region of this.selectedRegionsClinics) {
                if (this.tendersSum[region.id]) {
                    for (let productId in this.tendersSum[region.id]) {
                        let product = this.tendersSum[region.id][productId]
                        if (sum[productId]) {
                            sum[productId].cnt += parseFloat(product.cnt)
                        } else {
                            sum[productId] = Object.assign({}, product);
                            sum[productId].cnt = parseFloat(product.cnt)
                        }
                    }
                }
            }

            return sum
        },
        currentTenderSumSorted() {
            let sumArray = Object.values(this.currentTenderSum)
            sumArray.map(el=>{
                el.count = el.cnt
            })
            return sumArray.sort((a,b)=>{return b.cnt - a.cnt})
        },
        allSum() {
            let sum = 0
            this.currentTenderSumSorted.map(e=>{
                sum += parseFloat(e.cnt)
            })
            return sum
        },
	},
	methods: {
        ...mapActions('regionsTenders', [
            'loadTendersByRegions',
            'loadAllTendersSum',
        ]),
        ...mapMutations('regionsTenders', [

        ]),
        getPercentValue(value) {
            return parseFloat((value/this.allSum)*100)
        },
	},
}
</script>

<style>

</style>

<style scoped>

</style>