import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import authApi from '../../api/auth.api'
import userApi from '../../api/user.api'
import clinicsApi from '../../api/clinics.api'
import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

const _store = 'userListClinics'

// initial state
const state = {
	w_name: 'UserListClinics',
	w_loadingCount: 0,
	w_errors: [],
	w_netStatus: {},

	searchString: '',
	searchTimeout: null,

	filterIsVaccinationLicense: false,
	filterIsMSDPartner: false,
	filterSelectedUserList: [],

	sortPVA: null,
	sortPotential: null,

	clinics: [],
	clinicsCounter: 0,
	currentPage: 0,

	selectedUserRegionsId: [],
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async getClinicsData({state, commit, dispatch, rootState}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | getClinicsData`, reqObj)
		let requestObj = {...{}, ...reqObj}
		
		if (state.selectedUserRegionsId && state.selectedUserRegionsId.join()) {
			requestObj.regions = state.selectedUserRegionsId.join()
		}

		if (state.filterSelectedUserList && state.filterSelectedUserList.join()) {
			requestObj.lists = state.filterSelectedUserList.join()
		}

		if (state.searchString && state.searchString != '') {
			requestObj.search = state.searchString
		}

		if (state.filterIsVaccinationLicense && state.filterIsVaccinationLicense != null) {
			requestObj.vaccinationLicense = state.filterIsVaccinationLicense
		}

		if (state.filterIsMSDPartner && state.filterIsMSDPartner != null) {
			requestObj.partners = '1' // TODO: Поменять на нормальный расчет
		}

		if (state.sortPVA != undefined) {
			if (state.sortPVA == 1) {
				requestObj.sellsSort = 'asc'
			} else if (state.sortPVA == 0) {
				requestObj.sellsSort = 'desc'
			}
		}

		if (state.sortPotential != undefined) {
			if (state.sortPotential == 1) {
				requestObj.potentialSort = 'asc'
			} else if (state.sortPotential == 0) {
				requestObj.potentialSort = 'desc'
			}
		}

		let req = await clinicsApi.getClinics(requestObj)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}

		commit('setClinicsCount', req.json.count || state.clinicsCounter)

		return data
	},

	async getClinics({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | getClinics`, reqObj)
		commit('clearSearchTimeout')
		commit('w_loadingPlus')
		
		commit('wipeCurrentPage', data)
		commit('setClinicsCount', 0)
		let data = await dispatch('getClinicsData')
		commit('setClinics', data)

		commit('w_loadingMinus')
	},

	async getClinicsToAdd({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | getClinicsToAdd`, reqObj)
		commit('clearSearchTimeout')
		commit('w_loadingPlus')
		let requestObject = {}
		commit('increaseCurrentPage')
		requestObject.page = state.currentPage
		let data = await dispatch('getClinicsData', requestObject)
		commit('addClinics', data)

		commit('w_loadingMinus')
	},


	async setVaccinationLicenseFilter({commit, dispatch}, data) {
		log.d(`Store | ${state.w_name} | Actions | setVaccinationLicenseFilter`, data)
		dispatch('setSearchTimeout')
		commit('setVaccinationLicenseFilter', data)
	},

	async setMSDPartnerFilter({commit, dispatch}, data) {
		log.d(`Store | ${state.w_name} | Actions | setMSDPartnerFilter`, data)
		dispatch('setSearchTimeout')
		commit('setMSDPartnerFilter', data)
	},

	async setSelectedUserListFilter({commit, dispatch}, data) {
		log.d(`Store | ${state.w_name} | Actions | setSelectedUserListFilter`, data)
		dispatch('setSearchTimeout')
		commit('setSelectedUserListFilter', data)
	},

	async setPVASort({commit, dispatch}, data) {
		log.d(`Store | ${state.w_name} | Actions | setPVASort`, data)
		dispatch('setSearchTimeout')
		commit('setPVASort', data)
	},

	async setPotencialSort({commit, dispatch}, data) {
		log.d(`Store | ${state.w_name} | Actions | setPotencialSort`, data)
		dispatch('setSearchTimeout')
		commit('setPotencialSort', data)
	},

	async setSearchString({commit, dispatch}, data) {
		log.d(`Store | ${state.w_name} | Actions | setSearchString`, data)
		commit('clearSearchTimeout')
		dispatch('setSearchTimeout')
		commit('setSearchString', data)
	},

	async setSearchTimeout({commit, dispatch}, data) {
		log.d(`Store | ${state.w_name} | Actions | setSearchTimeout`, data)
		commit('clearSearchTimeout')
		commit('setSearchTimeout', setTimeout(()=>{dispatch('handleSearchTimeout')}, config.searchTimeout))
	},

	async clearSearchTimeout({commit}, data) {
		log.d(`Store | ${state.w_name} | Actions | clearSearchTimeout`, data)
		commit('clearSearchTimeout')
	},

	async resetFilters({commit, dispatch}) { // пока ресет только списков, лицензии на вакцинацию, покрытие
		log.d(`Store | ${state.w_name} | Actions | resetFilters`)

		commit('clearSearchTimeout')

		commit('setSelectedUserListFilter', [])
		commit('setMSDPartnerFilter', false)
		commit('setVaccinationLicenseFilter', false)

		dispatch('getClinics')
	},

	/**
	 * Обработчик таймера строки поиска
	 * @param {*} param0 
	 * @param {*} data 
	 */
	async handleSearchTimeout({commit, dispatch}, data) {
		log.d(`Store | ${state.w_name} | Actions | handleSearchTimeout`, data)
		dispatch('getClinics')
	},

	async changeInFavStatus({commit}, data) {
		log.d(`Store | ${state.w_name} | Actions | changeInFavStatus`, data)
		commit('changeInFavStatus', data)
	},

	async setSelectedUserRegionsId({state, commit}, data) {
		log.d(`Store | ${state.w_name} | Actions | setSelectedUserRegionsId`, data)
		commit('setSelectedUserRegionsId', data)
	},
	
}

// mutations
const mutations = {
	setVaccinationLicenseFilter(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | setVaccinationLicenseFilter`, data)
		state.filterIsVaccinationLicense = (data === true)
	},
	setMSDPartnerFilter(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | setMSDPartnerFilter`, data)
		state.filterIsMSDPartner = (data === true)
	},
	setSelectedUserListFilter(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | setSelectedUserListFilter`, data)
		state.filterSelectedUserList = data
	},
	setPVASort(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | setPVASort`, data)
		state.sortPVA = data
	},
	setPotencialSort(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | setPotencialSort`, data)
		state.sortPotential = data
	},
	setSearchString(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | setSearchString`, data)
		state.searchString = data
	},
	setSearchTimeout(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | setSearchTimeout`, data)
		clearTimeout(state.searchTimeout)
		state.searchTimeout = data
	},
	clearSearchTimeout(state) {
		log.d(`Store | ${state.w_name} | Mutations | clearSearchTimeout`, state.searchTimeout)
		clearTimeout(state.searchTimeout)
	},
	increaseCurrentPage(state) {
		log.d(`Store | ${state.w_name} | Mutations | increaseCurrentPage`, state.currentPage)
		state.currentPage++
	},
	wipeCurrentPage(state) {
		log.d(`Store | ${state.w_name} | Mutations | wipeCurrentPage`, state.currentPage)
		state.currentPage=0
	},
	setClinicsCount(state, count) {
		state.clinicsCounter = count
	},
	addClinics(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | addClinics`, data)
		
		data.forEach(el=> {
			state.clinics.push(el)
		})
	},
	setClinics(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | setClinics`, data)

		state.clinics = []
		data.forEach(el=> {
			state.clinics.push(el)
		})
	},
	changeInFavStatus(state, data) {
		log.d(`Store | ${state.w_name} | Actions | changeInFavStatus`, data)

		let elId = state.clinics.findIndex(el => el.id == data.clinicId)
		if (!state.clinics[elId]) { return }
		let result = state.clinics[elId]
		result.inFavourites = data.value
		// Vue.set(state.clinics, elId, result)
	},
	setSelectedUserRegionsId(state, data) {
		log.d(`Store | ${state.w_name} | Actions | setSelectedUserRegionsId` , data)
		
		state.selectedUserRegionsId = []
		data.forEach((el) => {
			let id = typeof el === 'number' ? el : el.id
			let i = state.selectedUserRegionsId.indexOf(id)

			if (i === -1) {
				state.selectedUserRegionsId.push(id)
			} else {
				state.selectedUserRegionsId.splice(i, 1)
			}
		})
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}