<template>
<Widget
	title="Эпидемиология"
	:isLoading="w_isLoading"
	:isError="w_isError"
	:errors="w_errors"
	:showEmptyMessage="selectedUserRegionsId.length == 0"
    url="/epidemiology"
	>
    <div class="table-responsive" v-if="egi3[0]">
        <table class="table mb-2">
            <tr>
                <th></th>
                <th class="text-body"
                    v-for="region of selectedRegionsClinics.slice(0,3)"
                    :key="region.id"
                    >{{region.nm}}</th>
            </tr>
			<tr>
				<td class="fsz-sm">{{egi3[0]['Рак легкого'].nm}}</td>
				<td class="fsz-base" v-if="egi3[0]">{{egi3[0]['Рак легкого'].patientsSum}}</td>
				<td class="fsz-base" v-if="egi3[1]">{{egi3[1]['Рак легкого'].patientsSum}}</td>
				<td class="fsz-base" v-if="egi3[2]">{{egi3[2]['Рак легкого'].patientsSum}}</td>
            </tr>
			<tr>
				<td class="fsz-sm">{{egi3[0]['Рак головы и шеи'].nm}}</td>
				<td class="fsz-base" v-if="egi3[0]">{{egi3[0]['Рак головы и шеи'].patientsSum}}</td>
				<td class="fsz-base" v-if="egi3[1]">{{egi3[1]['Рак головы и шеи'].patientsSum}}</td>
				<td class="fsz-base" v-if="egi3[2]">{{egi3[2]['Рак головы и шеи'].patientsSum}}</td>
            </tr>
			<tr>
				<td class="fsz-sm">{{egi3[0]['Почечноклеточный рак'].nm}}</td>
				<td class="fsz-base" v-if="egi3[0]">{{egi3[0]['Почечноклеточный рак'].patientsSum}}</td>
				<td class="fsz-base" v-if="egi3[1]">{{egi3[1]['Почечноклеточный рак'].patientsSum}}</td>
				<td class="fsz-base" v-if="egi3[2]">{{egi3[2]['Почечноклеточный рак'].patientsSum}}</td>
            </tr>
			<tr>
				<td class="fsz-sm">{{egi3[0]['Рак тела матки'].nm}}</td>
				<td class="fsz-base" v-if="egi3[0]">{{egi3[0]['Рак тела матки'].patientsSum}}</td>
				<td class="fsz-base" v-if="egi3[1]">{{egi3[1]['Рак тела матки'].patientsSum}}</td>
				<td class="fsz-base" v-if="egi3[2]">{{egi3[2]['Рак тела матки'].patientsSum}}</td>
            </tr>
        </table>
    </div>
</Widget>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../../../log'
import Widget from '../Widget'

export default {
	name: "EpidemiologyWidget",
	components: {Widget},
	async beforeMount() {
		log.d(`EpidemiologyWidget | beforeMount`)
        await this.loadEpidemiologyByRegions()
	},
	data() {
		return {
		}
	},
	computed: {
		...mapState('common', [
            'userRegions',
			'selectedUserRegionsId',
		]),
		...mapState('regionsEpidemiology', [
			'epidemiology',
			'year',
		]),
        ...mapState('regionsEpidemiology', [
			'w_errors'
		]),
		...mapGetters('regionsEpidemiology', [
			'w_isLoading',
			'w_isError'
		]),
        ...mapGetters('regionsClinics', [
			'selectedRegionsClinics',
		]),

		egi3() {
			return this.epidemiologyGroupedRegions(this.selectedRegionsClinics.slice(0,3))
		},

	},
	methods: {
		epidemiologyGroupedRegions(regions) {
			let result = []
			for(let region of regions) {
				result.push(this.epidemiologyGrouped(region.id))
			}
			return result
		},

		epidemiologyGrouped(regionId) {
			let result = {
				'Рак легкого': {
					nm: 'Рак легкого', 
					subCat: ['Трахея, бронхи, легкое (С33,34)'], 
					regionId: 0,
					count: 0,
					registeredSum: 0,
					morphology_diagnosisSum: 0,
					patientsSum: 0,
					stage_1Sum: 0,
					stage_2Sum: 0,
					stage_3Sum: 0,
					stage_4Sum: 0,
				},
				'Рак головы и шеи': {
					nm: 'Рак головы и шеи',
					subCat: ['Глотка (C10-13)', 'Гортань (С32)', 'Губа (C00)', 'Полость рта (C01-09)'],
					regionId: 0,
					count: 0,
					registeredSum: 0,
					morphology_diagnosisSum: 0,
					patientsSum: 0,
					stage_1Sum: 0,
					stage_2Sum: 0,
					stage_3Sum: 0,
					stage_4Sum: 0,
				},
				'Почечноклеточный рак': {
					nm: 'Почечноклеточный рак',
					subCat: ['Почка (С64)'],
					regionId: 0,
					count: 0,
					registeredSum: 0,
					morphology_diagnosisSum: 0,
					patientsSum: 0,
					stage_1Sum: 0,
					stage_2Sum: 0,
					stage_3Sum: 0,
					stage_4Sum: 0,
				},
				'Рак тела матки': {
					nm: 'Рак тела матки',
					subCat: ['Тело матки (С54)'],
					regionId: 0,
					count: 0,
					registeredSum: 0,
					morphology_diagnosisSum: 0,
					patientsSum: 0,
					stage_1Sum: 0,
					stage_2Sum: 0,
					stage_3Sum: 0,
					stage_4Sum: 0,
				},
			}

			for(let epEl in this.epidemiology[regionId]) {
				let epidemiology = this.epidemiology[regionId][epEl]

				for(let rEl in result) {
					for(let scEl of result[rEl].subCat) {
						if (epidemiology.case.toLowerCase() == scEl.toLowerCase()) {
							result[rEl].count += 1
							result[rEl].regionId = regionId
							result[rEl].registeredSum += parseFloat(epidemiology.registeredSum)
							result[rEl].morphology_diagnosisSum += parseFloat(epidemiology.morphology_diagnosisSum)
							result[rEl].patientsSum += parseFloat(epidemiology.patientsSum)
							result[rEl].stage_1Sum += parseFloat(epidemiology.stage_1Sum)
							result[rEl].stage_2Sum += parseFloat(epidemiology.stage_2Sum)
							result[rEl].stage_3Sum += parseFloat(epidemiology.stage_3Sum)
							result[rEl].stage_4Sum += parseFloat(epidemiology.stage_4Sum)
						}
					}
				}
			}
			
			return result
		},

		...mapActions('regionsEpidemiology', [
			'loadEpidemiologyByRegions'
		]),
		...mapMutations('common', [
			'setselectedUserRegion',
		]),
	},
}
</script>

<style>

</style>

<style scoped>

</style>