import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import clinicApi from '../../api/clinic.api'
import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	w_name: 'ClinicProblems',
	w_loadingCount: 0,
	w_savingCount: 0,
	w_errors: [],
	w_netStatus: {},
	
	clinicProblems: {}
}

// getters
const getters = {
	w_isSaving(state) {
		if (state.w_savingCount>0) {return true}
		return false
	},
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadClinicProblems({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | loadClinicProblems`, reqObj)

        commit('w_loadingPlus')
		
		let req = await clinicApi.getClinicProblems(reqObj.id)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('loadClinicProblems', data.form)
		
		commit('w_loadingMinus')
	},

    async saveClinicProblem({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | saveClinicProblem`, reqObj)

		if (reqObj.isDeleted) {
			commit('deleteClinicProblem', reqObj.problem)
		} else {
			commit('saveClinicProblem', reqObj.problem)
		}

        let res = await clinicApi.postClinicProblem(reqObj.clinicId, {
            problem_id: reqObj.problem.id,
            datum: { deleted: reqObj.isDeleted }
        })

	},
    async saveClinicProblemComment({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | saveClinicProblemComment`, reqObj)

		commit('w_savingPlus')
		commit('w_setTimer', {
			id: reqObj.clinicId + state.clinicProblems[reqObj.problemId].nm,
			action: ()=>{
				clinicApi.postClinicProblem(reqObj.clinicId, {
					problem_id: reqObj.problemId,
					datum: { problem_comment: reqObj.problemComment}
				})
				commit('w_savingMinus')
			},
		})

		commit('saveClinicProblemComment', reqObj)
	},
	async resetProblems({state, commit, dispatch}, reqObj={}) {
		Object.keys(state.clinicProblems).forEach((problemId) => {
			dispatch('saveClinicProblem', {
				clinicId: router.currentRoute.params.id,
				problem: {id: problemId},
				isDeleted: 1
			})
		})
	},
}

// mutations
const mutations = {
	loadClinicProblems(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | loadClinicProblems`, d)

		d.forEach((problem) => {
			Vue.set(state.clinicProblems, problem.id, problem)
		})
	},
	saveClinicProblem(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | saveClinicProblem`, d)

		Vue.set(state.clinicProblems, d.id, d)
	},
	deleteClinicProblem(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | deleteClinicProblem`, d)

		Vue.delete(state.clinicProblems, d.id)
	},
	saveClinicProblemComment(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | saveClinicProblemComment`, d)

		state.clinicProblems[d.problemId].problem_comment = d.problemComment
	},

	w_savingPlus(state) {
		log.d(`Store | ${state.w_name} | Mutations | savingPlus`, state.w_savingCount)
		state.w_savingCount = 1;
	},
	
	w_savingMinus(state) {
		log.d(`Store | ${state.w_name} | Mutations | savingMinus`, state.w_savingCount)
		state.w_savingCount = 0;
		if (state.w_savingCount<0) {state.w_savingCount = 0}
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}