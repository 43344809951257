<template>
    <Widget
        title="Лояльность"
        titleClass="text-muted"
        :isLoading="w_isLoading"
        :isError="w_isError"
        :errors="w_errors"
        >
        <div class="row">
            <div class="col-md-4 my-2">
                <div class="input-radio">
                    <input v-model="loyalty" type="radio" value="1" id="loyalty-high" name="clinic-loyalty">
                    <label for="loyalty-high" class="">Высокая</label>
                </div>
            </div>
            <div class="col-md-4 my-2">
                <div class="input-radio">
                    <input v-model="loyalty" type="radio" value="2" id="loyalty-medium" name="clinic-loyalty">
                    <label for="loyalty-medium" class="">Средняя</label>
                </div>
            </div>
            <div class="col-md-4 my-2">
                <div class="input-radio">
                    <input v-model="loyalty" type="radio" value="3" id="loyalty-low" name="clinic-loyalty">
                    <label for="loyalty-low" class="">Низкая</label>
                </div>
            </div>
        </div>
    </Widget>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Widget from '../Widget'
import log from '../../../log'

export default {
    name: "ClinicLoyalty",
	components: {
		Widget,
	},
    async beforeMount() {
		log.d(`ClinicLoyalty | beforeMount`, this.$route.params.id)

        await this.loadClinicLoyalty({id: this.$route.params.id})
	},
    data() {
        return {
        }
    },
	computed: {
        loyalty: {
			get() {
				return this.clinicLoyalty.id
			},
			set(value) {
				return this.saveClinicLoyalty({ clinicId: this.$route.params.id, loyalty: value })
			}
		},
		...mapState('clinicLoyalty', [
            'clinicLoyalty',
            'w_errors',
		]),
        ...mapGetters('clinicLoyalty', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		...mapActions('clinicLoyalty', [
			'loadClinicLoyalty',
            'saveClinicLoyalty'
		]),
	}
}
</script>