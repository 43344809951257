<template>
	<div :class="{ 'box_loading' : w_isLoading == true, 'box_error' : w_isError == true }">
		
		<div class="box mb-4 pb-9" v-if="divisionOnko" id="o-diagnostics">
			<div class="box__body">
				<h2 class="h1 mt-2 mb-5">Диагностика</h2>
				<div class="row align-items-center form-item-ui">
					<div class="col-md-6 my-2">
						Погружен ли PD-L1 в ОМС?
					</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="PDL1inOMS"
								id="PDL1inOMS-1"
								required=""
								value="1"
								v-model="PDL1inOMS"
								>
							<!-- <input type="radio" name="PD-L1-onko" id="PDL1inOMS-1" required="required" value="1"> -->
							<label for="PDL1inOMS-1">Да</label>
						</div>
					</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="PDL1inOMS"
								id="PDL1inOMS-0"
								required=""
								value="0"
								v-model="PDL1inOMS"
								>
							<!-- <input type="radio" name="PD-L1-onko" id="PDL1inOMS-0" required="required" value="0"> -->
							<label for="PDL1inOMS-0">Нет</label>
						</div>
					</div>
				</div>
				<hr>
				<div class="row align-items-center form-item-ui">
					<div class="col-md-6 my-2">
						Погружен ли MSI в ОМС?
					</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="MSIinOMS"
								id="MSIinOMS-1"
								required=""
								value="1"
								v-model="MSIinOMS"
								>
							<!-- <input type="radio" name="MSI" id="MSI-yes" required="required" value="1"> -->
							<label for="MSIinOMS-1">Да</label>
						</div>
					</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="MSIinOMS"
								id="MSIinOMS-0"
								required=""
								value="0"
								v-model="MSIinOMS"
								>
							<!-- <input type="radio" name="MSI" id="MSI-no" required="required" value="0"> -->
							<label for="MSIinOMS-0">Нет</label>
						</div>
					</div>
				</div>
				<hr>
				<div class="my-4">Укажите объем тестирования</div>
				<div class="row form-item-ui">
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="testing_value"
								id="testing_value-OMS"
								required=""
								value="oms"
								v-model="testing_value"
								>
							<!-- <input type="radio" name="OMS" id="OMS" required="required" value="0"> -->
							<label for="testing_value-OMS">ОМС</label>
						</div>
					</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="testing_value"
								id="testing_value-RUSSCO"
								required=""
								value="russco"
								v-model="testing_value"
								>
							<!-- <input type="radio" name="RUSSCO" id="RUSSCO" required="required" value="0"> -->
							<label for="testing_value-RUSSCO">RUSSCO</label>
						</div>
					</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="testing_value"
								id="testing_value-MSI"
								required=""
								value="msi"
								v-model="testing_value"
								>
							<!-- <input type="radio" name="MSI" id="MSI" required="required" value="0"> -->
							<label for="testing_value-MSI">MSI программа</label>
						</div>
					</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="testing_value"
								id="testing_value-none"
								required=""
								value="none"
								v-model="testing_value"
								>
							<!-- <input type="radio" name="none" id="none" required="required" value="0"> -->
							<label for="testing_value-none">Не проводится</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="box pb-9" v-if="divisionOnko" id="o-cam-strategy">
			<div class="box__body">
				<h2 class="h1 mt-2 mb-6">КАМ-стратегия</h2>
				<div class="fw-bold mb-5">Немелкоклеточный рак лёгкого</div>
				<div class="row align-items-center form-item-ui">
					<div class="col-md-6 my-2">Погружение PD-L1 в ОМС</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="NRL_PDinOMS"
								id="NRL_PDinOMS-1"
								required=""
								value="1"
								v-model="NRL_PDinOMS"
								>
							<!-- <input type="radio" name="PD-L1-kam" id="PD-L1-yes-kam" required="required" value="1"> -->
							<label for="NRL_PDinOMS-1">Да</label>
						</div>
					</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="NRL_PDinOMS"
								id="NRL_PDinOMS-0"
								required=""
								value="0"
								v-model="NRL_PDinOMS"
								>
							<!-- <input type="radio" name="PD-L1-kam" id="PD-L1-no-kam" required="required" value="0"> -->
							<label for="NRL_PDinOMS-0">Нет</label>
						</div>
					</div>
				</div>
				<hr>
				<div class="row align-items-center form-item-ui">
					<div class="col-md-6 my-2">Возможно ли увеличение КСГ?</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="NRL_KSGincrease"
								id="NRL_KSGincrease-1"
								required=""
								value="1"
								v-model="NRL_KSGincrease"
								>
							<!-- <input type="radio" name="KSG" id="KSG-yes" required="required" value="1"> -->
							<label for="NRL_KSGincrease-1">Да</label>
						</div>
					</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="NRL_KSGincrease"
								id="NRL_KSGincrease-0"
								required=""
								value="0"
								v-model="NRL_KSGincrease"
								>
							<!-- <input type="radio" name="KSG" id="KSG-no" required="required" value="0"> -->
							<label for="NRL_KSGincrease-0">Нет</label>
						</div>
					</div>
				</div>
				<hr>
				<div class="row align-items-center form-item-ui">
					<div class="col-md-6 my-2">Выделяем ли мы подгруппу КСГ?</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="NRL_KSGsubgroup"
								id="NRL_KSGsubgroup-1"
								required=""
								value="1"
								v-model="NRL_KSGsubgroup"
								>
							<!-- <input type="radio" name="sub-KSG" id="sub-KSG-yes" required="required" value="1"> -->
							<label for="NRL_KSGsubgroup-1">Да</label>
						</div>
					</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="NRL_KSGsubgroup"
								id="NRL_KSGsubgroup-0"
								required=""
								value="0"
								v-model="NRL_KSGsubgroup"
								>
							<!-- <input type="radio" name="sub-KSG" id="sub-KSG-no" required="required" value="0"> -->
							<label for="NRL_KSGsubgroup-0">Нет</label>
						</div>
					</div>
				</div>
				<hr>
				<div class="fw-bold mt-6 mb-5">Почечноклеточный рак</div>
				<div class="row align-items-center form-item-ui">
					<div class="col-md-6 my-2">Возможно ли увеличение КСГ?</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="POCH_KGSincrease"
								id="POCH_KGSincrease-1"
								required=""
								value="1"
								v-model="POCH_KGSincrease"
								>
							<!-- <input type="radio" name="PD-L1-kam" id="PD-L1-yes-kam" required="required" value="1"> -->
							<label for="POCH_KGSincrease-1">Да</label>
						</div>
					</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="POCH_KGSincrease"
								id="POCH_KGSincrease-0"
								required=""
								value="0"
								v-model="POCH_KGSincrease"
								>
							<!-- <input type="radio" name="PD-L1-kam" id="PD-L1-no-kam" required="required" value="0"> -->
							<label for="POCH_KGSincrease-0">Нет</label>
						</div>
					</div>
				</div>
				<hr>
				<div class="row align-items-center form-item-ui">
					<div class="col-md-6 my-2">Выделяем ли мы подгруппу КСГ?</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="POCH_KGSsubgroup"
								id="POCH_KGSsubgroup-1"
								required=""
								value="1"
								v-model="POCH_KGSsubgroup"
								>
							<!-- <input type="radio" name="KSG" id="KSG-yes" required="required" value="1"> -->
							<label for="POCH_KGSsubgroup-1">Да</label>
						</div>
					</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="POCH_KGSsubgroup"
								id="POCH_KGSsubgroup-0"
								required=""
								value="0"
								v-model="POCH_KGSsubgroup"
								>
							<!-- <input type="radio" name="KSG" id="KSG-no" required="required" value="0"> -->
							<label for="POCH_KGSsubgroup-0">Нет</label>
						</div>
					</div>
				</div>
				<hr>
				<div class="row align-items-center form-item-ui">
					<div class="col-md-6 my-2">Увеличение КСЛП?</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="POCH_KSLPincrease"
								id="POCH_KSLPincrease-1"
								required=""
								value="1"
								v-model="POCH_KSLPincrease"
								>
							<!-- <input type="radio" name="sub-KSG" id="sub-KSG-yes" required="required" value="1"> -->
							<label for="POCH_KSLPincrease-1">Да</label>
						</div>
					</div>
					<div class="col-6 col-md-3 my-2">
						<div class="input-radio">
							<input
								type="radio"
								name="POCH_KSLPincrease"
								id="POCH_KSLPincrease-0"
								required=""
								value="0"
								v-model="POCH_KSLPincrease"
								>
							<!-- <input type="radio" name="sub-KSG" id="sub-KSG-no" required="required" value="0"> -->
							<label for="POCH_KSLPincrease-0">Нет</label>
						</div>
					</div>
				</div>
				<hr>
				<div class="fw-bold mt-6 mb-4">Комментарий</div>
				<textarea v-model="comment" class="form-control" name="" id="" rows="4" placeholder="Расскажите, что в работе ЛПУ можно улучшить?"></textarea>
			</div>
		</div>


	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../../../log'
import BoxCollapse from "../../blocks/BoxCollapse"
import InputBlock from '../../blocks/InputBlock'

export default {
	name: "QuestOnco",
	components: {
		BoxCollapse,
		InputBlock,
	},
	data() {
		return {
			ccId: this.$route.params.id,
		}
	},
	async beforeMount() {
		log.d(`QuestOnco | beforeMount`, this.$route.params.id)
		
		await this.loadClinicQuestOncoDiagnostics({id: this.$route.params.id})
		await this.loadClinicQuestOncoKAMStrategies({id: this.$route.params.id})
	},
	computed: {
		...mapState('clinicQuestPatientCategory', [
			'patientCategories',

			'w_errors',
		]),
		...mapGetters('clinicQuestPatientCategory', [
			'w_isLoading',
			'w_isError',
		]),

		...mapState('common', [
			'inputSendTimeout',
		]),
		...mapGetters('common', [

		]),

		...mapState('clinicQuestOnco', [
			'questOncoDiagnostics',
			'questOncoKAMStrategies',
			// 'w_errors',
		]),


		cQuestOncoDiagnostics() {
			if(this.questOncoDiagnostics && this.questOncoDiagnostics[this.$route.params.id]) {
				return this.questOncoDiagnostics[this.$route.params.id]
			}
			return {}
		},

		cQuestOncoKAMStrategies() {
			if(this.questOncoKAMStrategies && this.questOncoKAMStrategies[this.$route.params.id]) {
				return this.questOncoKAMStrategies[this.$route.params.id]
			}
			return {}
		},

		/**
		 * Погружен ли PD-L1 в ОМС?
		 */
		PDL1inOMS: {
			get() {
				return this.cQuestOncoDiagnostics.PDL1inOMS
			},
			set(value) {
				return this.saveDiagnosticsData({clinicId: this.ccId, parameter: 'PDL1inOMS', value: value})
			}
		},

		/**
		 * Погружен ли MSI в ОМС? 
		 */
		MSIinOMS: {
			get() {
				return this.cQuestOncoDiagnostics.MSIinOMS
			},
			set(value) {
				return this.saveDiagnosticsData({clinicId: this.ccId, parameter: 'MSIinOMS', value: value})
			}
		},

		/**
		 * Укажите объем тестирования
		 */
		testing_value: {
			get() {
				return this.cQuestOncoDiagnostics.testing_value
			},
			set(value) {
				return this.saveDiagnosticsData({clinicId: this.ccId, parameter: 'testing_value', value: value})
			}
		},

		// KAM

		/**
		 * KAM: 
		 */
		NRL_PDinOMS: {
			get() {
				return this.cQuestOncoKAMStrategies.NRL_PDinOMS
			},
			set(value) {
				return this.saveKAMStrategiesData({clinicId: this.ccId, parameter: 'NRL_PDinOMS', value: value})
			}
		},

		/**
		 * KAM: 
		 */
		NRL_KSGincrease: {
			get() {
				return this.cQuestOncoKAMStrategies.NRL_KSGincrease
			},
			set(value) {
				return this.saveKAMStrategiesData({clinicId: this.ccId, parameter: 'NRL_KSGincrease', value: value})
			}
		},

		/**
		 * KAM: 
		 */
		NRL_KSGsubgroup: {
			get() {
				return this.cQuestOncoKAMStrategies.NRL_KSGsubgroup
			},
			set(value) {
				return this.saveKAMStrategiesData({clinicId: this.ccId, parameter: 'NRL_KSGsubgroup', value: value})
			}
		},

		/**
		 * KAM: 
		 */
		POCH_KGSincrease: {
			get() {
				return this.cQuestOncoKAMStrategies.POCH_KGSincrease
			},
			set(value) {
				return this.saveKAMStrategiesData({clinicId: this.ccId, parameter: 'POCH_KGSincrease', value: value})
			}
		},

		/**
		 * KAM: 
		 */
		POCH_KGSsubgroup: {
			get() {
				return this.cQuestOncoKAMStrategies.POCH_KGSsubgroup
			},
			set(value) {
				return this.saveKAMStrategiesData({clinicId: this.ccId, parameter: 'POCH_KGSsubgroup', value: value})
			}
		},

		/**
		 * KAM: 
		 */
		POCH_KSLPincrease: {
			get() {
				return this.cQuestOncoKAMStrategies.POCH_KSLPincrease
			},
			set(value) {
				return this.saveKAMStrategiesData({clinicId: this.ccId, parameter: 'POCH_KSLPincrease', value: value})
			}
		},

		/**
		 * KAM: 
		 */
		comment: {
			get() {
				return this.cQuestOncoKAMStrategies.comment
			},
			set(value) {
				return this.saveKAMStrategiesDataTimer({clinicId: this.ccId, parameter: 'comment', value: value})
			}
		},


		
	},
	methods: {
		...mapActions('clinicQuestOnco', [
			'loadClinicQuestOncoDiagnostics',
			'loadClinicQuestOncoKAMStrategies',
			'saveDiagnosticsData',
			'saveDiagnosticsDataTimer',
			'saveDiagnosticsData',
			'saveDiagnosticsDataTimer',
			'saveKAMStrategiesData',
			'saveKAMStrategiesDataTimer',
		]),

		getPatientCategoryCnt(categoryId) {
			let cnt =  this.orderedCPatientCategories.find(category => category.id === categoryId).cnt

			return cnt
			// return this.$options.filters.digitSpace1k(cnt)
		},

		/**
		 * Сохранение данных о категории пациентов (когорты) в клинике
		 */
		setPatientCategory(categoryId, value) {
			this.savePatientCategory({clinicId: this.ccId, categoryId: categoryId, value: value.replace(/\s+/g,'')})
		},

	}
}
</script>

<style>

</style>

<style scoped>

</style>