<template>
	<span v-if="visitCount">
		данные не обновляются
	</span>
	<span v-else>
		удалена или сменила адрес
	</span>
</template>

<script>
export default {
	name: 'ClinicDeletionType',
	props: [
		'visitCount',
	],
	data() {
		return {
			
		}
	},
	methods: {
		
	},
}
</script>

<style>

</style>

<style scoped>

</style>