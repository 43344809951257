import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import userListApi from '../../api/userList.api'
import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'
import { difference } from 'lodash'

const _name = 'UserList'

// initial state
const state = {
	w_name: 'UserList',
	w_loadingCount: 0,
	w_errors: [],
	w_netStatus: {},

	colors: [
		'ffffff',
		'ff8a00',
		'fff063',
		'bfed33',
		'6eceb2',
		'69b8f7',
		'688ce8',
		'5450e4'
	],
	
	lists: {},

	newList: {
		id: null,
		nm: 'Новый лист',
		color: parseInt('ffffff', 16),
		clinics: []
	},

	favId: null
}

// getters
const getters = {
	w_isLoading,
	w_isError,
	getListRegions: (state) => (listId) => {
		let regions = []

		if (state.lists[listId].clinics) {
			state.lists[listId].clinics.forEach(clinic => {
				regions.indexOf(clinic.region) === -1 ? regions.push(clinic.region) : false
			})
		}
		return regions
	},
	listClinicsId: (state) => (listId) => {
		if (state.lists[listId] && state.lists[listId].clinics) {
			return state.lists[listId].clinics.map(el => el.id)
		} else {
			return []
		}
	},
	currentListClinics: (state) => (listId) => {
		if (state.lists[listId] && state.lists[listId].clinics) {
			return state.lists[listId].clinics
		} else {
			return []
		}
	},
	newListClinicsId: state => {
		if (state.newList.clinics) {
			return state.newList.clinics.map(el => el.id)
		} else {
			return []
		}
	}
}

// actions
const actions = {

	async loadLists({state, commit, dispatch, rootState}, reqObj={}) {
		commit('w_loadingPlus')
		
		if(Object.keys(state.lists).length === 0) {
			log.d(`Store | ${state.w_name} | Actions | loadListOfLists`, reqObj)
			let req = await userListApi.getLists(reqObj.id)

			if (!req.ok) {
				// TODO: throw error?
				commit('w_addError', 'Ошибка при загрузке данных')
				return
			}

			let data = req.json.data

			if(req.json.status !== 'ok') {
				// TODO: throw error?
				commit('w_addError', 'Ошибка при загрузке данных')
				commit('w_loadingMinus')
				return
			}

			data.forEach(list => {
				commit('setList', list)
				dispatch('loadListClinics', {id: list.id})
			})
		}

		commit('w_loadingMinus')
	},
	async loadList({state, commit, dispatch, rootState}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | loadList`, reqObj)
		commit('w_loadingPlus')
		
		let req = await userListApi.getList(reqObj.id)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data.list
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('setNewList', data)
		commit('setNewListClinics', reqObj.clinics)

		commit('w_loadingMinus')
	},
	async loadListClinics({state, commit, dispatch, rootState}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | loadListClinics`, reqObj)
		commit('w_loadingPlus')
		
		let req = await userListApi.getClinics(reqObj.id)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('setListClinics', {id: reqObj.id, clinics: data})

		commit('w_loadingMinus')
	},
	async createList({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | createList`, reqObj)
		let req = await userListApi.postList({nm: reqObj.nm, color: reqObj.color, order: 2})
		let data = req.json.data.list

		commit('setList', data)
		if (reqObj.clinics.length) {
			commit('setListClinics', {id: data.id, clinics: reqObj.clinics})
			reqObj.clinics.forEach(clinic => {
				userListApi.addClinic(clinic.id, data.id)
			})
		}
	},
	async updateList({state, getters, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | updateList`, reqObj)
		userListApi.putList({newValues: {nm: reqObj.nm, color: reqObj.color}}, reqObj.id)
		
		let removedClinics = difference(getters.listClinicsId(reqObj.id), getters.newListClinicsId)
		let addedClinics = difference(getters.newListClinicsId, getters.listClinicsId(reqObj.id))

		if (removedClinics.length) {
			userListApi.delClinic(removedClinics.join(','), reqObj.id)
			removedClinics.forEach(clinicId => {
				if (reqObj.nm.toLowerCase() == 'Избранное'.toLowerCase()) {
					dispatch('clinics/changeInFavStatus', {clinicId: clinicId, value: 0}, {root:true})
					dispatch('clinic/changeInFavStatus', {clinicId: clinicId, value: 0}, {root:true})
				}
			})
		}

		if (addedClinics.length) {
			userListApi.addClinic(addedClinics.join(','), reqObj.id)
			addedClinics.forEach(clinicId => {
				if (reqObj.nm.toLowerCase() == 'Избранное'.toLowerCase()) {
					dispatch('clinics/changeInFavStatus', {clinicId: clinicId, value: 1}, {root:true})
					dispatch('clinic/changeInFavStatus', {clinicId: clinicId, value: 1}, {root:true})
				}
			})
		}

		commit('setList', reqObj)
	},
	async deleteList({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | deleteList`, reqObj)
		userListApi.delList(reqObj.id)
		commit('deleteList', reqObj)
	},

	async addToFav({state, commit, dispatch, rootState}, clinic) {
		log.d(`Store | ${state.w_name} | Actions | addToFav`, clinic)
		
		dispatch('clinics/changeInFavStatus', {clinicId: clinic.id, value: 1}, {root:true})
		dispatch('clinic/changeInFavStatus', {clinicId: clinic.id, value: 1}, {root:true})

		let req = await userListApi.addClinic(clinic.id, 'fav')
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('setListClinic', {id: data.clinic[0].list_id, clinic: clinic})

	},
	async removeFromFav({state, commit, dispatch, rootState}, clinicId) {
		log.d(`Store | ${state.w_name} | Actions | removeFromFav`, clinicId)

		dispatch('clinics/changeInFavStatus', {clinicId: clinicId, value: 0}, {root:true})
		dispatch('clinic/changeInFavStatus', {clinicId: clinicId, value: 0}, {root:true})

		let req = await userListApi.delClinic(clinicId, 'fav')
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('deleteListClinic', {id: clinicId})
	},

}


// mutations
const mutations = {
	setList(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | setList`, d)
		Vue.set(state.lists, d.id, d)
		if (d.nm === 'Избранное') {
			state.favId = d.id
		}
	},
	setListClinics(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | setListClinics`, d)
		Vue.set(state.lists[d.id], 'clinics', d.clinics)
	},
	setListClinic(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | setListClinic`, d)
		state.lists[d.id].clinics.push(d.clinic)
	},
	deleteListClinic(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | deleteListClinic`, d)
		state.lists[state.favId].clinics = state.lists[state.favId].clinics.filter(el=> {
			return el.id !== d.id
		})
	},
	deleteList(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | deleteListsClinic`, d)
		Vue.delete(state.lists, d.id)
	},
	setNewList(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | setNewList`, d)
		state.newList = d
	},
	setNewListNm(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | setNewListNm`, d)
		Vue.set(state.newList, 'nm', d)
	},
	setNewListColor(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | setNewListColor`, d)
		Vue.set(state.newList, 'color', d)
	},
	setNewListClinics(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | setNewListClinics`, d)
		Vue.set(state.newList, 'clinics', d)
	},
	resetNewList(state) {
		log.d(`Store | ${state.w_name} | Mutations | resetNewList`)
		state.newList = {
			id: null,
			nm: `Новый список ${Object.keys(state.lists).length + 1}`,
			color: parseInt('ffffff', 16),
			clinics: []
		}
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}