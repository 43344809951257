<template>
	<div class="page-auth">
		<div id="loginForm" class="page-auth__section">
			<div id="register-form" class="page-form  pb-7 validate justify-content-between">
				<!--autocomplete="off" action="/register.php" method="POST"-->
				<div class="page-form-header">
					<img src="./../assets/images/logo-msd-commerce.svg" />
					<!-- <a href="#" class="btn-close"></a> -->
				</div>
				<div class="page-form-body mb-8">
					<div class="d-flex justify-content-between align-items-center mb-2">
						<h1 class="page-form-title">Регистрация</h1>
						<div>1 / <span class="text-muted">3</span></div>
					</div>
					<div class="fsz-sm text-muted mb-6">Поля, отмеченные * обязательны к заполнению</div>
					<div class="page-form-group">
						<input
							name="email"
							type="email"
							id="register-email"
							class="page-form-control"
							:class="{'has-value' : email}"
							required
							autocomplete="off"
							@keyup="checkValidity"
							v-model="email"
						>
						<label class="page-form-label" for="register-email">E-mail</label>
					</div>
					<div class="page-form-group">
						<input
							name="lastName"
							type="text"
							id="lastName"
							class="page-form-control"
							:class="{'has-value' : lastName}"
							required
							autocomplete="off"
							@keyup="checkValidity"
							v-model="lastName"
						>
						<label class="page-form-label" for="lastName">Фамилия</label>
					</div>
					<div class="page-form-group">
						<input
							name="firstName"
							type="text"
							id="firstName"
							class="page-form-control"
							:class="{'has-value' : firstName}"
							required
							autocomplete="off"
							@keyup="checkValidity"
							v-model="firstName"
						>
						<label class="page-form-label" for="firstName">Имя</label>
					</div>
					<div class="page-form-group mb-4">
						<input
							name="middleName"
							type="text"
							id="middleName"
							class="page-form-control"
							:class="{'has-value' : middleName}"
							autocomplete="off"
							v-model="middleName"
						>
						<label class="page-form-label" for="middleName">Отчество</label>
					</div>

					<div class="input-check input-check-sm input-check-align-center mb-1">
						<input name="adSubscription" type="checkbox" id="adSubscription" v-model="adSubscription">
						<label for="adSubscription">Даю <a href="#" target="_blank">согласие</a> на получение от MSD рекламной информации<br>по указанным каналам связи.</label>
					</div>
					<div class="input-check input-check-sm input-check-align-center">
						<input name="terms" type="checkbox" id="terms" @change="checkValidity" v-model="terms" required>
						<label for="terms">Принимаю <a href="#" target="_blank">Условия использования Сайта и обработки персональных данных *</a></label>
					</div>
					<div class="fsz-sm text-muted mt-3">
						Поставив галочку в окне выше, вы подтверждаете, что принимаете <a href="#" target="_blank">Условия использования Сайта и обработки персональных данных</a> и ознакомились с <a href="#" target="_blank">Политикой конфиденциальности</a>.
					</div>
				</div>
				<div class="page-form-footer mt-0">
					<router-link v-if="formValid" to="/register/2" class="btn btn-primary w-100">Далее</router-link>
					<span v-else class="btn btn-primary disabled w-100">Далее</span>
					<div class="text-center mt-5">
						<router-link to="/login" class="page-form-link">войти в существующий аккаунт</router-link>
					</div>
					<div class="text-center mt-12">
						<a href="mailto:support@msdmed.ru" class="page-form-link">Техподдержка: <span class="td-underline">support@msdmed.ru</span></a>
					</div>
				</div>
			</div>
		</div>
  	</div>
</template>

<script>
export default {
	name: "RegisterConfirmRepeat",
	data() {
		return {
			email: '',
			lastName: '',
			firstName: '',
			middleName: '',
			adSubscription: false,
			terms: false,

			inputsValid: {
				email: false,
				lastName: false,
				firstName: false,
				terms: false,
			}
		}
	},
	computed: {
		formValid: {
			get() {
				return this.inputsValid.email && this.inputsValid.lastName && this.inputsValid.firstName && this.inputsValid.terms
			},
			set() {
				return this.inputsValid.email && this.inputsValid.lastName && this.inputsValid.firstName && this.inputsValid.terms
			}
		}
	},
	methods: {
		checkValidity(event) {
			this.inputsValid[event.target.name] = event.target.validity.valid
		}
	}
}
</script>

<style>

</style>

<style scoped>

</style>