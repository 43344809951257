<template>
    <div>
        <div class="modal problems-modal" :class="{'disable-pointer-events' : isModalClosed && w_isSaving}" tabindex="-1" id="problemsModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="h2 m-0 text-danger">Есть проблемы</div>
                        <button type="button" class="close" :class="{'disable-pointer-events' : isModalClosed && w_isSaving}" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    
                    <div class="modal-body">
                        <div v-for="problem in problems" :key="problem.id">
                            <div v-if="problem.id == 1" class="problem-input-group row align-items-center my-2">
                                <div  class="col-md-5 col-lg-3">
                                    <div class="input-check input-check-align-center my-1">
                                        <input
                                            type="checkbox"
                                            :name="'problem-' + problem.id"
                                            :id="'problem-' + problem.id"
                                            v-model="status"
                                            @input="resetProblems()"
                                        >
                                        <label :for="'problem-' + problem.id">{{ problem.nm }}</label>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="problem-input-group row align-items-center my-2">
                                <div class="col-md-5 col-lg-3">
                                    <div class="input-check input-check-align-center my-1">
                                        <input
                                            type="checkbox"
                                            :name="'problem-' + problem.id"
                                            :id="'problem-' + problem.id"
                                            :checked="getClinicProblem(problem.id)"
                                            @input="setClinicProblem(problem.id)"
                                        >
                                        <label :for="'problem-' + problem.id">{{ problem.nm }}</label>
                                    </div>
                                </div>
                                <div v-if="getClinicProblem(problem.id)" class="col-md-7">
                                    <input
                                        class="form-control"
                                        type="text"
                                        placeholder="Уточните, в чём именно  заключаются сложности?"
                                        :value="getClinicProblemComment(problem.id)"
                                        @keyup="setClinicProblemComment($event.target.value, problem.id)"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <LoadingBlock :isEnabled="isModalClosed && w_isSaving"></LoadingBlock>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../../../log'
import LoadingBlock from '../../blocks/LoadingBlock'

export default {
	name: "ClinicProblemsModal",
    components: { LoadingBlock },
	data() {
		return {
            status: false,
            isModalClosed: false
		}
	},
    mounted() {
        $('#problemsModal').on('hide.bs.modal', (e) => {
            if (this.w_isSaving) {
                e.preventDefault();
                e.stopPropagation();
                this.isModalClosed = true

                let timerId = setInterval(() => {
                    if (!this.w_isSaving) {
                        clearInterval(timerId)
                        this.isModalClosed = false
                        $('#problemsModal').modal('hide')
                    }
                }, 100)
                return false
            }
        })
    },
	computed: {
        ...mapState('common', [
            'problems',
		]),
        ...mapState('clinicProblems', [
            'clinicProblems',
		]),
        ...mapGetters('clinicProblems', [
            'w_isSaving',
		]),
	},
	methods: {
        setClinicProblem(problemId) {
            this.status = false
            this.saveClinicProblem({
                clinicId: this.$route.params.id,
                problem: this.problems[problemId],
                isDeleted: this.clinicProblems[problemId] ? 1 : 0
            })
        },
        getClinicProblem(problemId) {
            if (this.clinicProblems[problemId]) {
                return true
            }
            return false
        },
        setClinicProblemComment(comment, problemId) {
            this.saveClinicProblemComment({
                clinicId: this.$route.params.id,
                problemId: problemId,
                problemComment: comment
            })
        },
        getClinicProblemComment(problemId) {
            if (this.clinicProblems[problemId]) {
                return this.clinicProblems[problemId].problem_comment
            }
            return ''
        },
		...mapActions('clinicProblems', [
            'loadClinicProblems',
            'saveClinicProblem',
            'saveClinicProblemComment',
            'resetProblems'
		]),
        ...mapMutations('clinicProblems', [
		])
	},
}
</script>

<style>
</style>

<style scoped>
</style>