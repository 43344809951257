<template>
    <div class="sort-control" :class="{ 'show' : show,  'active' : sortType !== undefined && sortType !== null }">
        <div class="sort-control-label">
			<!-- style="transform: scaleY(-1);"  -->
			<span v-if="sortType !== undefined && sortType !== null">
				<span v-if="sortType === 1">
					<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path  d="M4 18h10M4 12h5M4 6h1M20 11l-3 3V6M14 11l3 3V6" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>
				<span v-if="sortType === 0">
					<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path  d="M4 6h10M4 12h5M4 18h1M20 15l-3 3v-8M14 15l3 3v-8" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>
			</span>
            {{ label }}
            <button @click="toggle()" v-if="sortType === undefined || sortType === null" class="sort-control-toggle" type="button"></button>
            <button @click="reset()" v-else class="sort-control-reset" type="button"></button>
        </div>
        <ul>
            <li>
				<button :class="{ 'selected' : sortType === 1 }" @click="sort(1)" type="button">По возрастанию</button>
			</li>
            <li>
				<button :class="{ 'selected' : sortType === 0 }" @click="sort(0)" type="button">По убыванию</button>
			</li>
        </ul>
    </div>
</template>

<script>
export default {
	name: 'SortControl',
	props: {
		value: {
			type: Number,
		},
		label: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			show: false,
			sortType: this.value,
		}
	},
	methods: {
		toggle() {
			this.show = !this.show
		},
		sort(value) {
			this.sortType = value
			this.handleInput()
			this.toggle()
		},
		reset() {
			this.sortType = undefined
			this.handleInput()
		},

		handleInput (e) {
			this.$emit('input', this.sortType)
		},
	},
}
</script>

<style>

</style>

<style scoped>

</style>