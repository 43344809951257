<template>
	<div 
		class="page-content pt-0"
		:class="{ 'box_loading' : w_isLoading == true, 'box_error' : w_isError == true }"
		>
		<div class="page-intro mb-4">
			<div class="container">
				<router-link to="/clinics" class="btn-back">
					<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M7.293.707a1 1 0 111.414 1.416L3.83 7H15a1 1 0 110 2H3.83l4.877 4.877a1 1 0 01-1.415 1.415L0 8 7.293.707z" fill="#0C2340"/>
					</svg>
				</router-link>
				<div class="row mt-3 justify-content-between">
					<div class="col-lg-8">
						<h1
							class="h2 mb-3"
							v-if="cClinic.id"
							>{{ cClinic.name }}
						</h1>
						<h1 class="h2 mb-3" v-else>...</h1>
					</div>
					<div class="col-lg-2 mb-3">
						<button
							class="btn-fav active" 
							v-if="cClinic.inFavourites==1"
							@click="removeFromFav(cClinic.id)"
							>
							<svg width="18" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9 1l2.472 5.101L17 6.925l-4 3.968.944 5.607L9 13.851 4.056 16.5 5 10.893 1 6.925 6.528 6.1 9 1z" stroke="#00857C" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
							<span class="fsz-sm tt-upcase text-primary ml-3">В избранном</span>
						</button>

						<button
							class="btn-fav"
							v-else
							@click="addToFav(cClinic)"
							>
							<svg width="18" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9 1l2.472 5.101L17 6.925l-4 3.968.944 5.607L9 13.851 4.056 16.5 5 10.893 1 6.925 6.528 6.1 9 1z" stroke="#00857C" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</button>
					</div>
				</div>
				<div class="fsz-sm opacity-8">
					{{ cClinic.address }}
				</div>

			</div>
			<nav class="page-tabs">
				<div class="container">
				<router-link :to="{ path: `/clinic/${$route.params.id}/passport` }" active-class="active" class="page-tabs__item">Паспорт</router-link>
				<router-link :to="{ path: `/clinic/${$route.params.id}/quest` }" active-class="active" class="page-tabs__item">Анкеты</router-link>
				<router-link v-if="divisionVaccines" :to="{ path: `/clinic/${$route.params.id}/sells` }" active-class="active" class="page-tabs__item">Продажи</router-link>
				<router-link v-if="divisionVaccines" :to="{ path: `/clinic/${$route.params.id}/calc` }" active-class="active" class="page-tabs__item">Калькулятор</router-link>
				<router-link :to="{ path: `/clinic/${$route.params.id}/plan` }" active-class="active" class="page-tabs__item">План `22</router-link>
				</div>
			</nav>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import log from '../log.js'
import BtnBackArrow from './blocks/BtnBackArrow'
import Widget from './widgets/Widget'

export default {
	name: "Clinic",
	components: {
		Widget,
		BtnBackArrow,
	},
	data() {
		return {
			
		}
	},
	async beforeMount() {
		log.d(`Clinic | beforeMount`, this.$route.params.id)
		await this.loadClinic({id: this.$route.params.id})
	},
	async mounted() {
		log.d(`Clinic | mounted`, this.$route.params.id)
		// this.setupPage();
	},
	beforeRouteUpdate(to, from, next) {
		log.d(`Clinic | beforeRouteUpdate`, to, from, next)
		// this.setupPage();
		next();
	},
	computed: {
		cClinic() {
			if(this.clinics && this.clinics[this.$route.params.id]) {
				return this.clinics[this.$route.params.id]
			}
			return {}
		},
		...mapState('clinic', {
			clinicId: state => state.clinic.id,
			clinicName: state => state.clinic.name,
		}),
		...mapState('clinic', [
			'clinic',
			'clinics',

			'w_errors',
		]),
		...mapGetters('clinic', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		// async setupPage() {
		// 	log.d(`Clinic | setupPage`, $route.params.id)
		// 	await this.loadClinic({id: $route.params.id})
		// },
		...mapActions('clinic', [
			'loadClinic'
		]),

		...mapActions('userLists', [
			'addToFav',
			'removeFromFav',
		]),
	}
}
</script>

<style>

</style>

<style scoped>

</style>