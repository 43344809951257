<template>
  <footer class="page-footer">
      <div class="container">
          <div class="page-footer__logo">
              <a href="/">
                  <svg width="82" height="31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.217 15.44a7.608 7.608 0 017.608 7.609 7.609 7.609 0 100-15.217 7.607 7.607 0 01-7.608 7.608zM7.608 23.05a7.608 7.608 0 007.609 7.608 7.608 7.608 0 007.608-7.608H7.608zm0 0a7.609 7.609 0 017.609-7.609 7.608 7.608 0 01-7.609-7.608 7.608 7.608 0 000 15.217zm0-15.217A7.608 7.608 0 0115.217.224a7.608 7.608 0 017.608 7.608H7.608zM73.112 20.25l-1.342.004v-9.645h1.342c3.696 0 5.028 2.41 5.028 4.82s-1.332 4.821-5.028 4.821zm.22-12.435h-4.848v15.201h4.847c4.03 0 8.12-2.328 8.12-7.586 0-5.26-4.09-7.615-8.12-7.615zm-6.576 4.51c0-3.004-2.31-4.752-5.071-4.752-2.739 0-5.725 1.603-5.725 4.718 0 2.702 2.973 4.091 4.671 4.529.932.246 3.284.51 3.284 2.19 0 1.598-1.643 2.034-2.874 2.034-1.586 0-2.516-1.111-2.379-2.79h-3.117c-.11 3.785 2.464 5.196 5.468 5.265 3.474.08 6.126-1.405 6.126-4.75 0-3.363-3.008-3.893-5.36-4.656-.911-.296-2.598-.56-2.598-2.112 0-1.3 1.122-1.922 2.27-1.922 1.477 0 2.242.73 2.352 2.247h2.953zM49.61 7.833h4.715v15.215h-3.494V12.815l-4.47 10.232-4.703-10.245v10.245h-3.26V7.832h4.66l3.359 7.875 3.193-7.875z" fill="#fff"></path>
                  </svg>
              </a>
          </div>
          <div class="row justify-content-between">
              <div class="col-md-4">
                  <p class="fsz-15 mb-5">
                    <span :title="getVersionBuild">© 2021</span> ООО «МСД Фармасьютикалс».<br>Все права защищены.<br><br>
                    119021, Россия, г. Москва, ул. Тимура Фрунзе,<br>
                    д. 11, стр. 1, БЦ «Демидов».<br><br>
                    msd.ru<br><br>
                    <nobr>Тел.: +7 (495) 916-71-00</nobr>, <nobr>факс: +7 (495) 916-70-94</nobr>
                  </p>
                  <p></p>
              </div>
              <div class="col-md-8 col-lg-7 pr-md-6 pr-lg-12">
                  <p class="page-footer__text-muted">Контакт-центр DPOC </p>
                  <p class="page-footer__text-muted">Для сообщений о&nbsp;нежелательных явлениях на&nbsp;фоне применения лекарственных препаратов MSD, претензий к&nbsp;качеству продукции и/или для&nbsp;получения медицинской и научной информации о&nbsp;препаратах компании:<br>+7 (495) 916-71-00 (доб. 120)<br>
                      <a class="text-white" href="mailto:DPOC.Russia@merck.com">DPOC.Russia@merck.com</a>
                  </p>
                  <p class="page-footer__text-muted">Пожалуйста, примите во&nbsp;внимание, что отправляя информацию на&nbsp;эл.почту <a class="text-white" href="mailto:dpoc.russia@merck.com">dpoc.russia@merck.com</a>, Вы даете свое согласие на&nbsp;обработку Ваших персональных данных. Мы гарантируем, что любые полученные персональные данные будут обрабатываться исключительно в&nbsp;соответствии с&nbsp;политикой ООО&nbsp;«МСД Фармасьютикалс» в&nbsp;отношении обработки персональных данных. С&nbsp;текстом политики ООО «МСД Фармасьютикалс» в&nbsp;отношении обработки персональных данных Вы можете ознакомиться на&nbsp;сайте msd.ru. Вы можете отозвать свое согласие на&nbsp;обработку персональных данных в&nbsp;любой момент, направив заявление об этом в ООО «МСД Фармасьютикалс» или по эл.почте <a class="text-white" href="mailto:russiadataprivacy@merck.com">russiadataprivacy@merck.com</a></p>
              </div>
          </div>
          <div class="page-footer__links">
              <div class="row">
                  <div class="col-md-3 my-3">
                      <a href="https://www.msdmed.ru/rules">Правила пользования</a>
                  </div>
                  <div class="col-md-2 my-2">
                      <a href="https://www.msd.ru/static/pdf/PDP_policy.pdf">Положение об обработке персональных данных</a>
                  </div>
                  <div class="col-md-2 my-2">
                      <a href="https://www.msdprivacy.com/ru/ru/">Политика конфиденциальности</a>
                  </div>
                  <div class="col-md-2 my-3">
                    <a class="ot-sdk-show-settings" @click="toggleOTDisplay">Настройки cookie-файлов</a>
                  </div>
                  <div class="col-md-3 my-3">
                      <a href="mailto:support@msdmed.ru">Техподдержка: <span>support@msdmed.ru</span></a>
                  </div>
              </div>
          </div>
      </div>
  </footer>
</template>

<script>
  import config from '../../config.loader'

  export default {
    name: "Footer",
    async mounted() {
        let otBtn = document.querySelector('.ot-sdk-show-settings')

        const config = { attributes: true, childList: true, subtree: true };
        const callback = function(mutationsList, observer) {
            for(const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    observer.disconnect()
                    otBtn.text = 'Настройки cookie-файлов'
                }
            }
        };
        const observer = new MutationObserver(callback);
        observer.observe(otBtn, config);
	},
    methods: {
		toggleOTDisplay() {
            try {
                OneTrust.ToggleInfoDisplay()
            } catch (err) {}
        },
    },
    computed: {
        getVersionBuild() {
            let ret = ''
            if (config.version && config.version!='') {
                ret += `${config.version} `
            }

            if (config.build && config.build!='') {
                ret += `(${config.build})`
            }

            return ret
        }
    },
  }
</script>

<style>

</style>

<style scoped>
.ot-sdk-show-settings{
    cursor:pointer;
}
</style>