<template>
  <div class="page-content">
  </div>
</template>

<script>
export default {
  name: "Index",

  async beforeMount() {
    this.$router.push({path:'./activity'}).catch(()=>{})
  },
}
</script>

<style>

</style>

<style scoped>

</style>