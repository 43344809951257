<template>
	<div class="calc-input" :class="{'has-value': value && parseFloat(value) > 0}">
		<slot name="label">
			<label :class="{'text-primary': readonly === false}" for="">{{label}}</label>
		</slot>

		<div class="input-group mb-4">
			<input
				type="number"
				class="form-control"
				:readonly="readonly"
				:id="`input-${_uid}`"
				:value="value"
				@input="inputHandler"
				@focusout="focusoutHandler"
				@keypress="keypressHandler"
				>
			
			<slot name="unit">
				<span class="input-group-text">{{unit}}</span>
			</slot>

			<div class="input-group-append" :class="{'no-left-border':!hasInfoButton}">
				<div v-if="hasInfoButton">
					<slot name="infoButton">
						<PopoverInfoButton
							:class="infoClass"
							:html="infoHtml"
							:placement="infoPlacement"
							:content="info"
							></PopoverInfoButton>
					</slot>
				</div>
			</div>
		</div>
	</div>

	<!-- 
		Наработки для доверстки

		<div class="calc-input" :class="{'has-value': value}">
			<slot name="label">
				<label for="">{{label}}</label>
			</slot>
			<div class="input-group input-group--unit mb-4">
				<input
					type="text"
					class="form-control numeric"
					:id="`input-${_uid}`"
					:size="size"
					:value="value"
					@input="inputHandler"
					>

				<slot name="unit">
					<label :for="`input-${_uid}`" class="input-group-text">
						<span v-if="size">{{unit}}</span>
					</label>
				</slot>
				
				<slot name="infoButton">
					<div class="input-group-append">
						<PopoverInfoButton
							:class="infoClass"
							:html="infoHtml"
							:placement="infoPlacement"
							:content="info"
							></PopoverInfoButton>
					</div>
				</slot>
			</div>
		</div>
	 -->


	 <!-- 
		 Наработки для доверстки

		 <div class="calc-input" :class="{'has-value': getActual(getInputValue(selectedProduct.id, 'purchasedDosesNow', selectedCalculation.purchasedDosesNow))}">
			<label for="">Закупленных доз за <PeriodBlock :mode="periodMode" m="месяц" y="год"></PeriodBlock></label>
			<div class="input-group input-group--unit mb-4">
				<input
					type="text"
					class="form-control numeric"
					id="purchasedDosesNow"
					:size="getActual(getInputValue(selectedProduct.id, 'purchasedDosesNow', selectedCalculation.purchasedDosesNow)).length"
					:value="getActual(getInputValue(selectedProduct.id, 'purchasedDosesNow', selectedCalculation.purchasedDosesNow))"
					@input="(e)=>{setInputValue(selectedProduct.id, 'purchasedDosesNow', setActual(e.target.value))}"
					>
				<label for="purchasedDosesNow" class="input-group-text">
					<span v-if="getActual(getInputValue(selectedProduct.id, 'purchasedDosesNow', selectedCalculation.purchasedDosesNow))">доз</span>
				</label>
				<div class="input-group-append">
					<PopoverInfoButton
						class="btn-info"
						html="true"
						placement="right"
						:content="getHintText('purchasedDosesNow')"
						></PopoverInfoButton>
				</div>
			</div>
		</div>
	  -->
</template>

<script>
import PopoverInfoButton from './../blocks/PopoverInfoButton'

export default {
	name: 'CalcInputBlock',
	components: {
		PopoverInfoButton,
	},
	props: {
		label: {
			type: String,
		},
		labelClass: {
			type: String,
		},
		value: {
			require: true,
			default: null,
		},
		unit: {
			type: String,
			default: null,
		},
		readonly: {
			type: Boolean,
			default: false,
		},

		info: {
			type: String,
			default: null,
		},
		infoClass: {
			type: String,
			default: 'btn-info',
		},
		infoPlacement: {
			type: String,
			default: 'auto',
		},
		infoHtml: {
			type: Boolean,
			default: true,
		},

		chartData: {
			type: Object,
			default: null
		},
	},
	data() {
		return {
		}
	},
	computed: {
		isUnit() {
			if (this.unit) {
				return true
			}
			return false
		},
		size() {
			return this.value ? this.value.length : 0
		},
		hasInfoButton() {
			return this.$slots.infoButton || this.info
		},
	},
	methods: {
		inputHandler(e) {
			this.$emit('input', e)
		},
		focusoutHandler(e) {
			this.$emit('focusout', e)
		},
		keypressHandler(e) {
			return this.isNumber(e)
		},
		isNumber: function(e) {
			e = (e) ? e : window.event;
			var charCode = (e.which) ? e.which : e.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				e.preventDefault();
			} else {
				return true;
			}
		},
	},
}
</script>

<style>

</style>

<style scoped>
	.input-group-text {
		border-left-width: 0px;
		line-height: 20px;
	}
	.calc-input .input-group-text {
		border-right-width: 1px;
	}
	.form-control {
		border-right-width: 0px;
	}
	.no-left-border {
		border-left-width: 0px;
		border-top-width: 0px;
		border-bottom-width: 0px;
	}
	.calc-input.has-value .no-left-border {
		border-right-color: rgb(215, 215, 215);
		border-color: rgb(215, 215, 215) ;
	}
	/* .calc-input.form-item-ui.has-value .input-group-append {
		border-right-color: rgb(215, 215, 215);
		border-color: rgb(215, 215, 215) ;
	}
	.calc-input.form-item-ui.has-value label {
		color: rgba(12, 35, 64, 0.56);
	} */
	.text-primary .text-grey {
		color:rgb(12, 35, 64);
	}
</style>