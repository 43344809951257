<template>
	<div class="box" :class="{ 'box_loading' : w_isLoading == true, 'box_error' : w_isError == true }">
		<h1 class="mb-5">Общие вопросы</h1>

		<hr>

		<BoxCollapse id="quizPrice" title="Цена услуги и ответственное лицо" :order="1" :show="true" :isLoading="w_isLoading">
			<div class="row pb-3" v-if="Object.keys(headPositions).length > 0">
				<div class="col-md-6">
					<!-- injectionPrice -->
					<InputBlock
						:disabled="!role_manager_editor"
						class="form-item-ui my-2"
						inputClass="numeric"
						label="Цена услуги, включая осмотр"
						labelClass="fsz-sm"
						v-model="cInjectionPrice"
						unit="₽"
						></InputBlock>

<!-- 
					<div class="form-item-ui my-2" :class="{'has-value' : cInjectionPrice}">
						<label class="fsz-sm" for="">Цена услуги, включая осмотр</label>
						<div class="input-group input-group--unit">
							<input
								type="text"
								class="form-control numeric"
								id="cinjection-price"
								v-model="cInjectionPrice"
								:size="cInjectionPrice? cInjectionPrice.length: 1">
							<label for="cinjection-price" class="input-group-text"><span>₽</span></label>
						</div>
					</div>
 -->
				</div>
				<div class="col-md-6">
					<div class="form-item-ui my-2" :class="{'has-value' : cHeadPosition}">
						<label class="fsz-sm">Лицо, ответственное за продажи</label>
						<v-select 
							:disabled="!role_manager_editor"
							class="vs"
							name="select2"
							:placeholder="'Введите первые буквы'"
							label="nm"
							:options="headPositions"
							v-model="cHeadPosition"
							:reduce="el => el.id"
							:searchable="true"
							>
							<template v-slot:open-indicator>
								<svg class="vs__open-indicator" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0 0L6 6L12 0"/>
								</svg>
							</template>
							<template v-slot:header>
								<span class="vs__pre-open-title">Выберите специалиста</span>
							</template>
						</v-select>
					</div>
				</div>
			</div>
		</BoxCollapse>
		
		<hr>
		
		<BoxCollapse id="quizDistributors" title="Дистрибьюторы" :order="2" :show="true" :isLoading="w_isLoading">
			<div v-if="distributors && orderedUserProducts">
				<div v-for="product of orderedUserProducts" :key="product.id">
					<div v-if="product.is_msd_product">
						<div class="font-weight-bold mt-2" v-html="product.nm"></div>
						<div class="row pb-3">
							<div class="col-md-6">
								<div class="my-2 form-item-ui" :class="{'has-value': getDistributorId(product.id), 'disabled': getCProductData(product.id).distributorName}">
									<label class="fsz-sm">Дистрибьютор</label>
									<v-select
										class="vs"
										name="select2"
										label="nm"
										:options="distributors"
										:value="getCProductData(product.id).distributorId"
										@input="value => setDistributorId(product.id ,value)"
										:reduce="el => el.id"
										:searchable="true"
										:disabled="!role_manager_editor || !!getCProductData(product.id).distributorName"
										>
										<template v-slot:open-indicator>
											<svg class="vs__open-indicator" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M0 0L6 6L12 0"/>
											</svg>
										</template>
										<template v-slot:header>
											<span class="vs__pre-open-title">Наименование</span>
										</template>
										<template #search="{ attributes, events }">
											<input
												class="vs__search"
												placeholder="Введите первые буквы"
												v-bind="attributes"
												v-on="events"
											>
										</template>
									</v-select>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-item-ui my-2" :class="{'has-value': getCProductData(product.id).distributorName, 'disabled': getDistributorId(product.id) || !getCProductData(product.id).distributorName}">
									<label class="fsz-sm" for="">Другой дистрибьютор / не из списка</label>
									<input
										:disabled="!role_manager_editor"
										type="text"
										class="form-control"
										placeholder="Укажите наименование"
										:value="getCProductData(product.id).distributorName"
										@input="e => setDistributorName(product.id, e.target.value)"
										@focus="removeDisabled"
										@blur="addDisabled"
										>
										<!-- :disabled="getDistributorId(product.id)" -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</BoxCollapse>
		<hr>
		<BoxCollapse id="quizProductPrice" title="Закупочная цена <span class='opacity-3'>(доза)</span>" :order="3" :show="true" :isLoading="w_isLoading">
			<div class="row row-cols-2 row-cols-md-4 row-cols-lg-5 pb-3">
				<!-- конкурентов можно получить через product.is_msd_product -->
				<div
					class="col"
					v-for="product of orderedUserProducts"
					:key="product.id"
					>
					<InputBlock
						:disabled="!role_manager_editor"
						class="form-item-ui my-2"
						inputClass="numeric"
						:value="getCProductPrice(product.id)"
						@input="value => setDistributorPrice(product.id, value)"
						unit="₽"
						>
						<label
							slot="label"
							class="fsz-sm"
							:class="{'text-accent': !product.is_msd_product}"
							for=""
							>{{product.nm}}
							</label>
						</InputBlock>

<!-- 
					<div class="my-2 form-item-ui" :class="{'has-value' : getCProductData(product.id).distributorPrice}">
						<label
							class="fsz-sm"
							for=""
							>{{product.nm}}
							<span v-if="product.id==4">(10 доз)</span>
						</label>
						<div class="input-group input-group--unit">
							<input
								type="text"
								class="form-control numeric"
								:id="'distributor-price_'+product.id"
								:value="getCProductPrice(product.id)"
								:size="getCProductPrice(product.id).length"
								@input="e => setDistributorPrice(product.id, e.target.value)"
							>
							<label :for="'distributor-price_'+product.id" class="input-group-text"><span v-if="getCProductPrice(product.id).length">₽</span></label>
						</div>
					</div>
 -->

				</div>
			</div>
		</BoxCollapse>
		
		<hr>

		<BoxCollapse id="quizPatients" title="Пациенты" :order="4" :show="true" :isLoading="w_isLoading">
			<div class="row pb-4">
				<div class="col-md-6 col-lg-5">
					
					<InputBlock
						:disabled="!role_manager_editor"
						class="form-item-ui my-2"
						inputClass="numeric"
						label="Общее количество пациентов в месяц"
						labelClass="fsz-sm"
						v-model="monthPatientFlow"
						unit="чел."
						></InputBlock>
					
					<!-- 
					<div class="my-2 form-item-ui" :class="{ 'has-value' : monthPatientFlow }">
						<label class="fsz-sm" for="">Общее количество пациентов в месяц</label>
						<div class="input-group input-group--unit">
							<input
								type="text"
								class="form-control numeric"
								id="month-patient-flow"
								:size="monthPatientFlow ? monthPatientFlow.length : 0"
								v-model="monthPatientFlow"
								>
							<label for="month-patient-flow" class="input-group-text"><span v-if="monthPatientFlow">чел.</span></label>
						</div>
					</div>
					 -->

				</div>
				<div class="col-md-6 col-lg-5">
					<div class="my-2 form-item-ui" :class="{ 'has-value' : isCallPatients }">
						<label class="fsz-sm" for="">Обзваниваете ли вы пациентов?</label>
						<div class="row my-1">
							<div class="col-4 col-lg-5 my-2">
								<div class="input-radio input-radio-sm">
									<input
										:disabled="!role_manager_editor"
										type="radio"
										name="PD-L1"
										id="PD-L1-yes"
										required=""
										value="1"
										v-model="isCallPatients"
										>
									<label class="tt-upcase" for="PD-L1-yes">Да</label>
								</div>
							</div>
							<div class="col-4 col-lg-5 my-2">
								<div class="input-radio input-radio-sm">
									<input
										:disabled="!role_manager_editor"
										type="radio"
										name="PD-L1"
										id="PD-L1-no"
										required=""
										value="0"
										v-model="isCallPatients"
										>
									<label class="tt-upcase" for="PD-L1-no">Нет</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="row pb-4">
				<div class="col-md-6 col-lg-5">
					<div class="my-2 form-item-ui" :class="{ 'has-value' : isSchoolExamination }">
						<label class="fsz-sm" for="">Вы проводите медосмотры в школах?</label>
						<div class="row my-1">
							<div class="col-4 col-lg-5 my-2">
								<div class="input-radio input-radio-sm">
									<input
										:disabled="!role_manager_editor"
										type="radio"
										name="PD-L2"
										id="PD-L2-yes"
										required=""
										value="1"
										v-model="isSchoolExaminationValue"
										>
									<label class="tt-upcase" for="PD-L2-yes">Да</label>
								</div>
							</div>
							<div class="col-4 col-lg-5 my-2">
								<div class="input-radio input-radio-sm">
									<input
										:disabled="!role_manager_editor"
										type="radio"
										name="PD-L2"
										id="PD-L2-no"
										required=""
										value="0"
										v-model="isSchoolExaminationValue"
										>
									<label class="tt-upcase" for="PD-L2-no">Нет</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-lg-5" v-if="isSchoolExamination == true">
					<InputBlock
						:disabled="!role_manager_editor"
						class="form-item-ui my-2"
						inputClass="numeric"
						inputName="schoolExaminationCnt"
						label="Осмотренных детей в месяц"
						labelClass="fsz-sm"
						v-model="schoolExaminationCnt"
						unit="чел."
						></InputBlock>
				</div>
			</div>

			<div class="row pb-4" v-if="isSchoolExamination == true">
				<div class="col-md-12 col-lg-11">
					<div class="my-2 form-item-ui" :class="{'has-value': schoolVaccinationLevel}">
						<label class="fsz-sm" for="">Уровень вакцинации в школе</label>
						<div class="row my-1">

							<div class="col-3 col-lg-3 my-2">
								<div class="input-radio input-radio-sm">
									<input
										:disabled="!role_manager_editor"
										type="radio"
										name="schoolVaccinationLevel"
										id="schoolVaccinationLevel-1"
										required=""
										value=1
										v-model="schoolVaccinationLevel"
										>
									<label class="tt-upcase" for="schoolVaccinationLevel-1">Низкий</label>
									<div class="input-radio-append tt-upcase">до 25%</div>
								</div>
							</div>

							<div class="col-3 col-lg-3 my-2">
								<div class="input-radio input-radio-sm">
									<input
										:disabled="!role_manager_editor"
										type="radio"
										name="schoolVaccinationLevel"
										id="schoolVaccinationLevel-2"
										required=""
										value=2
										v-model="schoolVaccinationLevel"
										>
									<label class="tt-upcase" for="schoolVaccinationLevel-2">Средний</label>
									<div class="input-radio-append tt-upcase">25-50%</div>
								</div>
							</div>

							<div class="col-3 col-lg-3 my-2">
								<div class="input-radio input-radio-sm">
									<input
										:disabled="!role_manager_editor"
										type="radio"
										name="schoolVaccinationLevel"
										id="schoolVaccinationLevel-3"
										required=""
										value=3
										v-model="schoolVaccinationLevel"
										>
									<label class="tt-upcase" for="schoolVaccinationLevel-3">Выше сред.</label>
									<div class="input-radio-append tt-upcase">50-75%</div>
								</div>
							</div>

							<div class="col-3 col-lg-3 my-2">
								<div class="input-radio input-radio-sm">
									<input
										:disabled="!role_manager_editor"
										type="radio"
										name="schoolVaccinationLevel"
										id="schoolVaccinationLevel-4"
										required=""
										value=4
										v-model="schoolVaccinationLevel"
										>
									<label class="tt-upcase" for="schoolVaccinationLevel-4">Высокий</label>
									<div class="input-radio-append tt-upcase">от 75%</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

			<div class="row pb-3">
				<div class="col-md-6 col-lg-5">
					<div class="my-2 form-item-ui" :class="{ 'has-value' : isKindergartenExamination }">
						<label class="fsz-sm" for="">Вы проводите медосмотры в детских садах? </label>
						<div class="row my-1">
							<div class="col-4 col-lg-5 my-2">
								<div class="input-radio input-radio-sm">
									<input
										:disabled="!role_manager_editor"
										type="radio"
										name="PD-L3"
										id="PD-L3-yes"
										required=""
										value=1
										v-model="isKindergartenExaminationValue"
										>
									<label class="tt-upcase" for="PD-L3-yes">Да</label>
								</div>
							</div>
							<div class="col-4 col-lg-5 my-2">
								<div class="input-radio input-radio-sm">
									<input
										:disabled="!role_manager_editor"
										type="radio"
										name="PD-L3"
										id="PD-L3-no"
										required=""
										value=0
										v-model="isKindergartenExaminationValue"
										>
									<label class="tt-upcase" for="PD-L3-no">Нет</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-lg-5" v-if="isKindergartenExamination == true">
					<InputBlock
						:disabled="!role_manager_editor"
						class="form-item-ui my-2"
						inputClass="numeric"
						label="Осмотренных детей в месяц"
						labelClass="fsz-sm"
						v-model="kindergartenExaminationCnt"
						unit="чел."
						></InputBlock>
				</div>
			</div>

			<div class="row pb-4" v-if="isKindergartenExamination == true">
				<div class="col-md-12 col-lg-11">
					<div class="my-2 form-item-ui" :class="{'has-value': kindergartenVaccinationLevel}">
						<label class="fsz-sm" for="">Уровень вакцинации в детском саду</label>
						<div class="row my-1">

							<div class="col-3 col-lg-3 my-2">
								<div class="input-radio input-radio-sm">
									<input
										:disabled="!role_manager_editor"
										type="radio"
										name="kindergartenVaccinationLevel"
										id="kindergartenVaccinationLevel-1"
										required=""
										value=1
										v-model="kindergartenVaccinationLevel"
										>
									<label class="tt-upcase" for="kindergartenVaccinationLevel-1">Низкий</label>
									<div class="input-radio-append tt-upcase">до 25%</div>
								</div>
							</div>

							<div class="col-3 col-lg-3 my-2">
								<div class="input-radio input-radio-sm">
									<input
										:disabled="!role_manager_editor"
										type="radio"
										name="kindergartenVaccinationLevel"
										id="kindergartenVaccinationLevel-2"
										required=""
										value=2
										v-model="kindergartenVaccinationLevel"
										>
									<label class="tt-upcase" for="kindergartenVaccinationLevel-2">Средний</label>
									<div class="input-radio-append tt-upcase">25-50%</div>
								</div>
							</div>

							<div class="col-3 col-lg-3 my-2">
								<div class="input-radio input-radio-sm">
									<input
										:disabled="!role_manager_editor"
										type="radio"
										name="kindergartenVaccinationLevel"
										id="kindergartenVaccinationLevel-3"
										required=""
										value=3
										v-model="kindergartenVaccinationLevel"
										>
									<label class="tt-upcase" for="kindergartenVaccinationLevel-3">Выше сред.</label>
									<div class="input-radio-append tt-upcase">50-75%</div>
								</div>
							</div>

							<div class="col-3 col-lg-3 my-2">
								<div class="input-radio input-radio-sm">
									<input
										:disabled="!role_manager_editor"
										type="radio"
										name="kindergartenVaccinationLevel"
										id="kindergartenVaccinationLevel-4"
										required=""
										value=4
										v-model="kindergartenVaccinationLevel"
										>
									<label class="tt-upcase" for="kindergartenVaccinationLevel-4">Высокий</label>
									<div class="input-radio-append tt-upcase">от 75%</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

		</BoxCollapse>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../../../log'
import BoxCollapse from "../../blocks/BoxCollapse"
import InputBlock from '../../blocks/InputBlock'

export default {
	name: "QuestCommon",
	components: {
		BoxCollapse,
		InputBlock,
	},
	data() {
		return {
			ccId: this.$route.params.id,

			clinics: {},

			cInjectionPriceTimer: null,
			monthPatientFlowTimer: null,
			monthPatientFlowTimer: null,
			kindergartenExaminationCntTimer: null,
			schoolExaminationCntTimer: null,

			cDistributorNameTimers: {},
			cDistributorPriceTimers: {},
        }
    },
	async beforeMount() {
		log.d(`QuestCommon | beforeMount`, this.$route.params.id)

		await this.loadClinicQuestCommon({
			id: this.$route.params.id,
			loaded: () => { this.$emit('loaded') }
		})
	},
	computed: {
		/**
		 * Получение анкеты для текущей клиники
		 */
		cQuestCommon() {
			if(this.questCommons && this.questCommons[this.$route.params.id]) {
				return this.questCommons[this.$route.params.id]
			}
			return {}
		},
		
		/**
		 * Получение объекта patients анкеты для текущей клиники
		 */
		cQuestCommonPatients() {
			if(this.cQuestCommon && this.cQuestCommon.patients) {
				return this.cQuestCommon.patients
			}
			return {}
		},

		/**
		 * Лицо, ответственное за продажи
		 */
		cHeadPosition: {
			get() {
				if (this.cQuestCommon && this.cQuestCommon.sales && this.cQuestCommon.sales.headPositionId) {
					return this.cQuestCommon.sales.headPositionId
				}
				// return null
			},
			set(value) {
				return this.saveHeadPosition({id: this.$route.params.id, headPositionId: value})
			},
		},

		/**
		 * Цена услуги
		 */
		cInjectionPrice: {
			get() {
				if (this.cQuestCommon && this.cQuestCommon.sales && this.cQuestCommon.sales.injectionPrice) {
					return /*this.$options.filters.float(*/this.cQuestCommon.sales.injectionPrice/*)*/
					// return this.$options.filters.digitSpace1k(this.cQuestCommon.sales.injectionPrice)
				}
				return null
			},
			set(value) {
				this.saveInjectionPrice({
					id: this.$route.params.id,
					injectionPrice: value.replace(/\s+/g,''),
					name: 'injectionPrice'
				})
				return
			},
		},

		/**
		 * Общее количество пациентов в месяц
		 */
		monthPatientFlow: {
			get() {
				return this.$options.filters.int(this.cQuestCommonPatients.monthPatientFlow)
				// return this.$options.filters.digitSpace1k(this.cQuestCommonPatients.monthPatientFlow)
			},
			set(value) {
				console.debug(`set monthPatientFlow`, value)
				this.saveMonthPatientFlow({
					clinicId: this.ccId,
					value: value.replace(/\s+/g,''),
					name: 'monthPatientFlow'
				})
				return
			}
		},

		/**
		 * Обзваниваете ли вы пациентов?
		 */
		isCallPatients: {
			get() {
				return this.cQuestCommonPatients.callPatients
			},
			set(value) {
				this.saveCallPatients({clinicId: this.ccId, value: value})
			}
		},

		/**
		 * Вы проводите медосмотры в школах?
		 */
		isSchoolExaminationValue: {
			get() {
				return this.isSchoolExamination
			},
			set(value) {
				//TODO: устанавилвать null для schoolExaminationCnt schoolVaccinationLevel если false
				if (value == false) {
					this.schoolExaminationCnt = 0
					this.schoolVaccinationLevel = 0
				}

				return this.setIsSchoolExamination({clinicId: this.$route.params.id, value: value})
			}
		},

		/**
		 * Вы проводите медосмотры в детских садах?
		 */
		isKindergartenExaminationValue: {
			get() {
				return this.isKindergartenExamination
			},
			set(value) {
				//TODO: устанавилвать null для kindergartenExaminationCnt kindergartenVaccinationLevel если false
				if (value == false) {
					this.kindergartenExaminationCnt = 0
					this.kindergartenVaccinationLevel = 0
				}

				return this.setIsKindergartenExamination({clinicId: this.$route.params.id, value: value})
			}
		},

		/**
		 * Осмотренных детей в месяц
		 */
		schoolExaminationCnt: {
			get() {
				return this.$options.filters.int(this.cQuestCommonPatients.schoolExaminationCnt)
				return this.$options.filters.digitSpace1k(this.cQuestCommonPatients.schoolExaminationCnt)
			},
			set(value) {
				if (value == '') {value = 0}
				this.saveSchoolExaminationCnt({
					clinicId: this.ccId,
					value: value.toString().replace(/\s+/g,''),
					name: 'SchoolExaminationCnt' 
				})
			}
		},

		/**
		 * Уровень вакцинации в школе
		 */
		schoolVaccinationLevel: {
			get() {
				return this.cQuestCommonPatients.schoolVaccinationLevel
			},
			set(value) {
				return this.saveSchoolVaccinationLevel({clinicId: this.ccId, value: value})
			}
		},

		/**
		 * Осмотренных детей в месяц
		 */
		kindergartenExaminationCnt: {
			get() {
				return this.$options.filters.int(this.cQuestCommonPatients.kindergartenExaminationCnt)
				return this.$options.filters.digitSpace1k(this.cQuestCommonPatients.kindergartenExaminationCnt)
			},
			set(value) {
				if (value == '') {value = 0}
				this.saveKindergartenExaminationCnt({
					clinicId: this.ccId,
					value: value.toString().replace(/\s+/g,''),
					name: 'kindergartenExaminationCnt'
				})
			}
		},

		/**
		 * Уровень вакцинации в детском саду
		 */
		kindergartenVaccinationLevel: {
			get() {
				return this.cQuestCommonPatients.kindergartenVaccinationLevel
			},
			set(value) {
				return this.saveKindergartenVaccinationLevel({clinicId: this.ccId, value: value})
			}
		},

		...mapState('clinicQuestCommon', [
			'questCommons',
			'isSchoolExamination',
			'isKindergartenExamination',

			'w_errors',
		]),
		...mapGetters('clinicQuestCommon', [
			'w_isLoading',
			'w_isError',
		]),

		...mapState('common', [
			'headPositions',
			'distributors',
			'inputSendTimeout',
			'userProducts',
		]),
		...mapGetters('common', [
			'orderedUserProducts',
		])
	},
	methods: {
		inputSize(value) {
			return value.length
		},
		removeDisabled(e) {
			$(e.target).closest('.form-item-ui').removeClass('disabled')
		},
		addDisabled(e) {
			if (e.target.value.length === 0) {
				$(e.target).closest('.form-item-ui').addClass('disabled')
			}
		},
		/**
		 * Получение продукта текущей клниники по ид
		 */
		getCProductData(productId) {
			if (this.cQuestCommon.productsData) {
				let index = this.cQuestCommon.productsData.findIndex(el => el.productID == productId);
				return this.cQuestCommon.productsData[index]
			}
			return []
		},

		/**
		 * Получение закупочной цены препарата
		 */
		getCProductPrice(productId) {
			return /*this.$options.filters.float(*/this.getCProductData(productId).distributorPrice/*)*/
			// return this.$options.filters.digitSpace1k(this.getCProductData(productId).distributorPrice)
		},

		/**
		 * Установка id дистрибьютора для указанного продукта
		 */
		setDistributorId(id, value) {
			this.saveDistributorId({clinicId: this.$route.params.id, productId: id, value: value})

		},

		/**
		 * Получение id дистрибьютора для указанного продукта
		 */
		getDistributorId(productId) {
			if(this.questCommons && this.questCommons[this.$route.params.id]) {
				let index = this.questCommons[this.$route.params.id].productsData.findIndex(el => el.productID == productId)

				return this.questCommons[this.$route.params.id].productsData[index].distributorId
			}
			return null
		},

		/**
		 * Установка имени дистрибьютора для указанного продукта
		 */
		setDistributorName(id, value) {
			this.saveDistributorName({clinicId: this.$route.params.id, productId: id, value: value})
		},

		/**
		 * Установка цены для указанного продукта
		 */
		setDistributorPrice(id, value) {
			this.saveDistributorPrice({clinicId: this.$route.params.id, productId: id, value: value.replace(/\s+/g,'')})
		},
		
		...mapActions('clinicQuestCommon', [
			'loadClinicQuestCommon',
			'saveHeadPosition',
			'saveInjectionPrice',
			'saveDistributorId',
			'saveDistributorName',
			'saveDistributorPrice',
			'saveMonthPatientFlow',
			'saveCallPatients',
			'saveSchoolExaminationCnt',
			'saveSchoolVaccinationLevel',
			'saveKindergartenExaminationCnt',
			'saveKindergartenVaccinationLevel',
		]),
		...mapMutations('clinicQuestCommon', [
			'setIsSchoolExamination',
			'setIsKindergartenExamination'
		])
	}
}
</script>

<style>

</style>

<style scoped>

</style>