<template>
  <div class="page-content">
    <div class="container">
      <h1 class="mb-4">Активность</h1>
      <div class="row row-cols-1 row-cols-lg-2 align-items-start">
        <div class="col">
          <UserSellsWidget></UserSellsWidget>
          <News></News>
        </div>
        <div class="col">
          <ToVisitWidget></ToVisitWidget>
          <ListVisitsWidget></ListVisitsWidget>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Widget from './widgets/Widget'
import ListVisitsWidget from './widgets/Activity/LastVisits.widget'
import ToVisitWidget from './widgets/Activity/ToVisit.widget'
import News from './widgets/Activity/News.widget'
import UserSellsWidget from './widgets/Activity/UserSells.widget'

export default {
  name: "Activity",
  components: {Widget, ListVisitsWidget, ToVisitWidget, News, UserSellsWidget},
}
</script>

<style>

</style>

<style scoped>

</style>