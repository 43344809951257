import {get, getT, putT, token} from './api.js'
import log from '../log'

const _name = 'Clinics'

export default {
	/**
	 * Получение списка клиник
	 * API
	 * 
	 * @returns 
	 */
	async getClinics(requestObj) {
		log.d(`Api | ${_name} | getClinics`, requestObj)
		let response = await getT(`clinic/list`, requestObj)
		return response
	},

}