import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import clinicApi from '../../api/clinic.api'
import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	w_name: 'ClinicLoyalty',
	w_errors: [],
	w_netStatus: {},

    clinicLoyalty: {}
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadClinicLoyalty({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | loadClinicLoyalty`, reqObj)

        commit('w_loadingPlus')
		
		let req = await clinicApi.getClinicLoyalty(reqObj.id)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('loadClinicLoyalty', data)
		
		commit('w_loadingMinus')
	},

    async saveClinicLoyalty({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | saveClinicLoyalty`, reqObj)

        await clinicApi.postClinicLoyalty(reqObj.clinicId, {
            loyalty_id: reqObj.loyalty
        })

        commit('loadClinicLoyalty', {id: reqObj.loyalty})
	},
}

// mutations
const mutations = {
	loadClinicLoyalty(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | loadClinicLoyalty`, d)

        state.clinicLoyalty = d
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}