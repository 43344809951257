<template>
<Widget
	title="Когорты"
	:isLoading="w_isLoading"
	:isError="w_isError"
	:showEmptyMessage="Object.keys(allRegionProductPatientCategory).length == 0"
	>
	<ul class="box__tab-list mb-5 nav nav-tabs" role="tablist">
		<li v-for="product in MSDuserProducts" :key="product.id">
			<a
				class="box__tab-list-item"
				:class="{'active' : product.id === 1}"
				data-toggle="tab"
				:href="'#CohortsTab' + product.id"
				qv-if="Object.keys(product.categories).length"
				v-if="product.is_msd_product==true"
			>
				{{ product.nm }}
			</a>
		</li>
	</ul>
	<div class="tab-content">
		<div
			v-for="product in allRegionProductPatientCategory"
			:key="product.id"
			:id="'CohortsTab' + product.id"
			class="tab-pane" role="tabpanel"
			:class="{'active' : product.id === 1}"
			>
			<div class="row" qv-if="Object.keys(product.categories).length">
				<div class="col-10 col-sm-6 col-md-4">
					<img class="img-fluid bg-gray-100 mb-3 mb-md-0" v-bind:src="images[product.id]" alt="">
				</div>
				<div class="col-md-8">
					<h2 class="mb-1">{{product.name}}</h2>
					<div class="text-muted fsz-sm mb-5" v-if="Object.keys(userProducts).length && userProducts[product.id]">
						{{ userProducts[product.id].description }}
					</div>
					<div class="mb-2">
						<span class="h2 mb-0">{{ product.categoryCountAll | digitSpace1k }}</span>  чел. в месяц
					</div>
					<div class="row">
						<div
							v-for="category in product.categories"
							class="col-6 col-sm-4 my-2"
							:key="category.id"
							>
							<div class="fsz-sm opacity-8 mb-1">{{ category.nm }} 
								<span v-if="category.sex==1">(Муж)</span>
								<span v-if="category.sex==0">(Жен)</span>
							</div>
							<div v-if="category.count>=0 && category.count != null">{{ category.count | digitSpace1k }}</div>
							<div v-else>-</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</Widget>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../../../log'
import Widget from '../Widget'
import images from '../../../assets/images/products/*.png';
export default { 
	name: "CohortsWidget",
	components: {Widget},
	async beforeMount() {
		log.d(`CohortsWidget | beforeMount`)
		await this.getRegionProductPatientCategory()
		this.setCohorts()
	},
	data() {
		return {
			images,
			cohorts: []
		}
	},
	props: ['regions'],
	watch:{
		regions: function() {
			if (!this.w_isLoading) {
				this.setCohorts()
			}
		}
	},
	computed: {
		MSDuserProducts() {
			let resultProducts = {}
			for (let prId in this.userProducts) {
				let el = this.userProducts[prId]
				if (el.is_msd_product) {
					resultProducts[el.id] = el
				}
			}
			return resultProducts
		},
		allRegionProductPatientCategory() {
			let obj = {}
			this.cohorts.forEach(el => {
				el.products.forEach(product => {
					if (!obj.hasOwnProperty(product.id)) {
						obj[product.id] = {
							id: product.id,
							name: product.name,
							nameTM: product.nameTM,
							categories: {},
							categoryCountAll: 0
						}
					}

					product.categories.forEach((category) => {
						if (!obj[product.id].categories[category.id]) {
							obj[product.id].categories[category.id] = {
								count: null,
								id: category.id,
								nm: category.nm,
								sex: category.sex,
								orderby: category.orderby,
							}
						}
					})

					product.categories.forEach((category) => {
						let initObj = obj[product.id].categories[category.id]

						if (category.count != null) {
							obj[product.id].categories[category.id] = Object.assign(initObj, {
								count: initObj.count + parseInt(category.count),
							})
							obj[product.id].categoryCountAll += parseInt(category.count)
						}
					})

				}, 0)
			})

			return obj
		},
		...mapState('regions', [
		  	'regionProductPatientCategory',
		]),
		...mapState('common', [
		  	'selectedUserRegionsId',
			'userProducts'
		]),
		...mapGetters('regions', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		setCohorts() {
			this.cohorts = []
			this.regions.forEach(regionId => {
				this.cohorts.push(this.regionProductPatientCategory[regionId])
			})
		},
		...mapActions('regions', [
			'getRegionProductPatientCategory'
		]),
		...mapMutations('regions', [
			'w_loadingPlus',
			'w_loadingMinus'
		])
	},
}
</script>

<style>

</style>

<style scoped>

</style>