import Vue from 'vue'
import router from '../../router'
import authApi from '../../api/auth.api'
import userApi from '../../api/user.api'
import log from './../../log'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],
	news: [],
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadNews({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | News | Actions | loadNews`, reqObj)
		if (state.news.length>0) {
			// TODO: поставить кеширование на сутки. Обновления происходят раз в сутки.
			return;
		}
		commit('w_loadingPlus')

		let req = await userApi.getNews(reqObj.id, reqObj.page, reqObj.count)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}
		commit('setNews', data)

		commit('w_loadingMinus')
	}
}

// mutations
const mutations = {
	setNews(state, data) {
		log.d(`Store | News | Mutations | setVisits`, data)
		state.news = []
		data.forEach(el => {
			state.news.push(el)
		})
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}