import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import authApi from '../../api/auth.api'
import userApi from '../../api/user.api'
import usersApi from '../../api/users.api'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

const _store = 'Employees'

// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],

	searchString: '',
	searchTimeout: null,

	filterShowNotActiveUsers: false,

	sortName: undefined,
	sortEmail: undefined,
	sortDivision: undefined,
	sortRole: undefined,

	employees: {},
	employeesAutocomplete: {}
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async getEmployees({state, commit, dispatch, rootState}, reqObj={}) {
		log.d(`Store | ${_store} | Actions | getEmployees`, reqObj)

		let requestObj = {}
		requestObj.count = 5 // TODO: fix pagination

		if (state.searchString && state.searchString != '') {
			requestObj.search = state.searchString
		}
		
		// Фильтр неактивных
		requestObj.is_active = state.filterShowNotActiveUsers ? 0 : 1

		if (reqObj.autocomplete != true) {
			requestObj.count = 2000	// TODO: fix pagination
			
			// Сортировка по имени
			if (state.sortName != undefined) {
				if (state.sortName == 1) {
					requestObj.sort_nm = 'asc'
				} else if (state.sortName == 0) {
					requestObj.sort_nm = 'desc'
				}
			}

			// Сортировка по емайлу
			if (state.sortEmail != undefined) {
				if (state.sortEmail == 1) {
					requestObj.sort_email = 'asc'
				} else if (state.sortEmail == 0) {
					requestObj.sort_email = 'desc'
				}
			}

			// Сортировка по дивизии
			if (state.sortDivision != undefined) {
				if (state.sortDivision == 1) {
					requestObj.sort_divisions = 'asc'
				} else if (state.sortDivision == 0) {
					requestObj.sort_divisions = 'desc'
				}
			}

			// Сортировка по роли
			if (state.sortRole != undefined) {
				if (state.sortRole == 1) {
					requestObj.sort_roles = 'asc'
				} else if (state.sortRole == 0) {
					requestObj.sort_roles = 'desc'
				}
			}
		}
		
		let req = await usersApi.getUsers(requestObj)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}

		if (reqObj.autocomplete == true) {
			commit('setEmployeesAutocomplete', data)
		} else {
			commit('setEmployees', data)
		}
	},

	async setSearchString({commit, dispatch}, data) {
		log.d(`Store | ${_store} | Actions | setSearchString`, data)
		commit('clearSearchTimeout')
		dispatch('setSearchTimeout', data.reqObj)
		commit('setSearchString', data.value)
	},

	async setSearchTimeout({commit, dispatch}, data) {
		log.d(`Store | ${_store} | Actions | setSearchTimeout`, data)
		commit('clearSearchTimeout')
		commit('setSearchTimeout', setTimeout(()=>{dispatch('handleSearchTimeout', data)}, config.searchTimeout))
	},

	async clearSearchTimeout({commit}, data) {
		log.d(`Store | ${_store} | Actions | clearSearchTimeout`, data)
		commit('clearSearchTimeout')
	},

	/**
	 * Обработчик таймера строки поиска
	 * @param {*} param0 
	 * @param {*} data 
	 */
	async handleSearchTimeout({commit, dispatch}, data) {
		log.d(`Store | ${_store} | Actions | handleSearchTimeout`, data)
		dispatch('getEmployees', data)
	},
	async wipeAutocompleteData({commit}, data) {
		log.d(`Store | ${_store} | Actions | wipeAutocompleteData`, data)
		commit('wipeAutocompleteData')
	},

	async setfilterShowNotActiveUsers({commit, dispatch}, data) {
		log.d(`Store | ${_store} | Actions | setfilterShowNotActiveUsers`, data)
		dispatch('setSearchTimeout')
		commit('setfilterShowNotActiveUsers', data)
	},
	async setSortName({commit, dispatch}, data) {
		log.d(`Store | ${_store} | Actions | setSortName`, data)
		dispatch('setSearchTimeout')
		dispatch('resetSortAll')
		commit('setSortName', data)
	},
	async setSortEmail({commit, dispatch}, data) {
		log.d(`Store | ${_store} | Actions | setSortEmail`, data)
		dispatch('setSearchTimeout')
		dispatch('resetSortAll')
		commit('setSortEmail', data)
	},
	async setSortDivision({commit, dispatch}, data) {
		log.d(`Store | ${_store} | Actions | setSortDivision`, data)
		dispatch('setSearchTimeout')
		dispatch('resetSortAll')
		commit('setSortDivision', data)
	},
	async setSortRole({commit, dispatch}, data) {
		log.d(`Store | ${_store} | Actions | setSortRole`, data)
		dispatch('setSearchTimeout')
		dispatch('resetSortAll')
		commit('setSortRole', data)
	},

	async resetSortAll({commit, dispatch}, data) {
		log.d(`Store | ${_store} | Actions | resetSortAll`, data)
		commit('setSortName', undefined)
		commit('setSortEmail', undefined)
		commit('setSortDivision', undefined)
		commit('setSortRole', undefined)
	},
}

// mutations
const mutations = {

    setEmployees(state, data) {
		log.d(`Store | ${_store} | Mutations | setEmployees`, data)

		let perPage = 15

		state.employees = {}
		for(let i = 0; i < Math.ceil(data.length/perPage); i++) {
			Vue.set(state.employees, i + 1, data.slice(i * perPage, (i * perPage) + perPage))
		}
	},

    setEmployeesAutocomplete(state, data) {
		log.d(`Store | ${_store} | Mutations | setEmployeesAutocomplete`, data)
		state.employeesAutocomplete = []
		state.employeesAutocomplete = data.filter((item, i) => {
			if (i < 5) {
				return item
			}
		})
	},
	wipeAutocompleteData(state, data) {
		log.d(`Store | ${_store} | Mutations | wipeAutocompleteData`, data)
		state.employeesAutocomplete = {}
	},
	setSearchString(state, data) {
		log.d(`Store | ${_store} | Mutations | setSearchString`, data)
		state.searchString = data
	},
	setSearchTimeout(state, data) {
		log.d(`Store | ${_store} | Mutations | setSearchTimeout`, data)
		clearTimeout(state.searchTimeout)
		state.searchTimeout = data
	},
	clearSearchTimeout(state) {
		log.d(`Store | ${_store} | Mutations | clearSearchTimeout`, state.searchTimeout)
		clearTimeout(state.searchTimeout)
	},

	setfilterShowNotActiveUsers(state, data) {
		log.d(`Store | ${_store} | Mutations | setfilterShowNotActiveUsers`, data)
		state.filterShowNotActiveUsers = (data === true)
	},
	setSortName(state, data) {
		log.d(`Store | ${_store} | Mutations | setSortName`, data)
		state.sortName = data
	},
	setSortEmail(state, data) {
		log.d(`Store | ${_store} | Mutations | setSortEmail`, data)
		state.sortEmail = data
	},
	setSortDivision(state, data) {
		log.d(`Store | ${_store} | Mutations | setSortDivision`, data)
		state.sortDivision = data
	},
	setSortRole(state, data) {
		log.d(`Store | ${_store} | Mutations | setSortRole`, data)
		state.sortRole = data
	},

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}