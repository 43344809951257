<template>
    <div>
        <label
            v-if="label"
            class="fsz-sm"
            :class="{ 'text-muted' : !isOpen, 'text-primary' : isOpen}">
            {{ label }}
        </label>
        <multiselect
            :class="customClass"
            v-model="model"
            :options="options"
            @input="handleInput(value)"
            :showNoResults="false"
            label="nm"
            track-by="id"
            :multiple="false"
            :close-on-select="true"
            :preselect-first="false"
            placeholder=""
            @open="handleOpen"
            @close="handleOpen"
        >
            <template slot="selection" slot-scope="{ isOpen }">
                <span v-if="!isOpen" class="multiselect__single">{{ value.nm }}</span>
            </template>
            <template v-slot:caret>
                <div class="multiselect__select">
                    <svg width="12" height="6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h12L6 6 0 0Z"/>
                    </svg>
                </div>
            </template>
            <template slot="option" slot-scope="props">
                <div :class="{'selected' : props.option.id == value.id}">{{ props.option.nm }}</div>
            </template>
        </multiselect>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import config from '../../config.loader'

export default {
	name: "SelectSingle",
	props: {
		value: {
            type: Object,
            default: null,
        },
		options: {
            type: Array,
            default: [],
        },
        customClass: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        }
    },
  	data() {
	  	return {
            model: this.value,
            isOpen: false,
	  	}
  	},
	methods: {
        handleInput(value) {
			this.$emit('input', this.model)
		},
        handleOpen() {
            this.isOpen = !this.isOpen
        },
	},
  	computed: {
  	}
}
</script>

<style>

</style>

<style scoped>

</style>