import Vue from 'vue'
import router from '../../router'
import sellsApi from '../../api/sells.api'
import log from './../../log'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

const _store = 'regionsPVA'
// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],
    regionsPVA: {},
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {

	async loadRegionsPVA({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_store} | Actions | loadRegionsPVA`, reqObj)

		if (state.regions && state.allPlan>0 && state.allActual>0) {
			log.d(`Store | ${_store} | Mutations | setRegionsPVA: cached`)
			return;	//TODO: Улучшить кеширование (обновляется приблизительно раз в сутки)
		}
		log.d(`Store | ${_store} | Mutations | setRegionsPVA: loaded`)
		
		commit('w_loadingPlus')

		let req = await sellsApi.getRegionsPVA()
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}
		commit('setRegionsPVA', data)

		commit('w_loadingMinus')
	}
}

// mutations
const mutations = {
	setRegionsPVA(state, data) {
		log.d(`Store | regions | Mutations | setRegionsPVA`, data)
		state.regionsPVA={}
		state.regionsPVA = data
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}