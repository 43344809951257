<template>
<Widget
	title="Клиники"
	url="/clinics"
	:isLoading="w_isLoading"
	:isError="w_isError"
	:errors="w_errors"
	:showEmptyMessage="allClinicsCountByRegions == 0"
	>
	<div class="h1">{{ allClinicsCountByRegions | digitSpace1k }}</div>
	<div class="mb-3">ЛПУ</div>
	<div class="box__clinics-count-overflow">
		<div class="mt-3" v-for="region in selectedRegionsClinics.slice(0,3)" :key="region.id">
			<div class="mb-2 fsz-sm">{{ region.nm }}</div>
			<div class="h2">{{ region.count | digitSpace1k }}</div>
		</div>
	</div>
</Widget>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../../../log'
import Widget from '../Widget'

export default {
	name: "ClinicsWidget",
	components: {Widget},
	async beforeMount() {
		log.d(`ClinicsWidget | beforeMount`)
		await this.loadClinicCountByRegions()
	},
	data() {
		return {
			showAll: false
		}
	},
	computed: {
		...mapState('regionsClinics', [
			'clinicCountByRegions',
			'w_errors',
		]),
		...mapGetters('regionsClinics', [
			'selectedRegionsClinics',
			'allClinicsCountByRegions',
			'w_isLoading',
			'w_isError',
		])
	},
	methods: {
		toggleShowAll() {
			this.showAll = !this.showAll
		},
		...mapActions('regionsClinics', [
			'loadClinicCountByRegions'
		]),
	},
}
</script>

<style>

</style>

<style scoped>

</style>