<template>
	<multiselect
        :class="customClass"
        v-model="model"
        :options="options"
        @input="handleInput"
        @open="sortOptions(value)"
        :showNoResults="false"
        label="nm"
        track-by="id"
        :multiple="true"
        :close-on-select="false"
        :preselect-first="false"
        placeholder=""
    >
        <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="!isOpen" class="multiselect__single">Регионы: {{ values.length }}</span>
        </template>
        <template v-slot:caret>
            <svg class="multiselect__select" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 10L12 16L18 10" stroke="#00857C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </template>
        <template v-slot:beforeList v-if="Object.keys(options).length && Object.keys(options).length>showHideAllRegionsLevel">
            <button v-if="model.length === 0" @click="toggleAll" class="multiselect__before" type="button">Выбрать все</button>
            <button v-else @click="toggleAll" class="multiselect__before" type="button">Снять все</button>
        </template>
        <template slot="option" slot-scope="props">
            <div :class="{'selected' : model.includes(props.option.id)}">{{ props.option.nm }}</div>
        </template>
    </multiselect>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import config from '../../config.loader'

export default {
	name: "RegionsSelect",
	props: {
		value: {
            type: Array,
            default: [],
        },
		options: {
            type: Array,
            default: [],
        },
        customClass: {
            type: String,
            default: null
        }
    },
  	data() {
	  	return {
            model: this.value
	  	}
  	},
	methods: {
        handleInput(e) {
			this.$emit('input', this.model)
		},
        toggleAll(e) {
            if (this.model.length === 0) {
                this.model = this.options.map(el => el.id)
            } else {
                this.model = []
            }
            this.$emit('input', this.model)
        },
        sortOptions(value) {
            $('.multiselect__element').each((index, item) => {
                if ($(item).find('.selected').length > 0) {
                    $(item).css('order', '1')
                } else {
                    $(item).css('order', '2')
                }
            })
            
        }
	},
  	computed: {
        showHideAllRegionsLevel() {
            return config.showHideAllRegionsLevel
        }, 
  	},
    watch: {
		value(value) {
			if (this.model != value) {
				this.model = value
			}
		}
	}
}
</script>

<style>

</style>

<style scoped>

</style>