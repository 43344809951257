<template>
	<div class="calc-input" :class="{'has-value': value && parseFloat(value) > 0}">
		<slot name="label">
			<label :class="labelClass" for="">{{label}}</label>
		</slot>

		<div class="input-group mb-4">
			<input
				:disabled="disabled"
				type="number"
				class="form-control"
				:class="inputClass"
				:name="inputName"
				:readonly="readonly"
				:id="`input-${_uid}`"
				:value="value"
				@input="inputHandler"
				@keypress="keypressHandler"
				@focus="focusHandler"
				@blur="blurHandler"
				>
			
			<slot name="unit">
				<span class="input-group-text">{{unit}}</span>
			</slot>

			<div v-if="hasInfoButton" class="input-group-append" :class="{'no-left-border':!hasInfoButton}">
				<slot name="infoButton">
					<PopoverInfoButton
						:class="infoClass"
						:html="infoHtml"
						:placement="infoPlacement"
						:content="info"
						></PopoverInfoButton>
				</slot>
			</div>
		</div>
	</div>
</template>

<script>
import PopoverInfoButton from './../blocks/PopoverInfoButton'

export default {
	name: 'InputBlock',
	components: {
		PopoverInfoButton,
	},
	props: {
		label: {
			type: String,
		},
		labelClass: {
			type: String,
		},
		inputType: {
			type: String,
			default: 'number',
		},
		inputClass: {
			default: null,
		},
		inputName: {
			default: null,
		},
		value: {
			require: true,
			default: null,
		},
		
		unit: {
			type: String,
			default: null,
		},
		readonly: {
			type: Boolean | String,
			default: false,
		},

		info: {
			type: String,
			default: null,
		},
		infoClass: {
			type: String,
			default: 'btn-info',
		},
		infoPlacement: {
			type: String,
			default: 'auto',
		},
		infoHtml: {
			type: Boolean,
			default: true,
		},

		chartData: {
			type: Object,
			default: null
		},

		disabled: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
		}
	},
	computed: {
		isUnit() {
			if (this.unit) {
				return true
			}
			return false
		},
		size() {
			return this.value ? this.value.length : 0
		},
		hasInfoButton() {
			return this.$slots.infoButton || this.info
		}
	},
	methods: {
		inputHandler(e) {
			// this.$emit('update:prop', e.target.value)
			this.$emit('input', e.target.value)
		},
		keypressHandler(e) {
			if(this.inputType === 'number') {
				return this.isNumber(e)
			}
			return
		},
		focusHandler(e) {
			if (!this.value) {
				e.target.value = ''
			}
		},
		blurHandler(e) {
			if (!e.target.value) {
				e.target.value = 0
			}
		},
		isNumber: function(e) {
			e = (e) ? e : window.event;
			var charCode = (e.which) ? e.which : e.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				e.preventDefault();
			} else {
				return true;
			}
		},
	},
}
</script>

<style>

</style>

<style scoped>
	.input-group-text {
		border-left-width: 0px;
		line-height: 20px;
	}
	.calc-input .input-group-text {
		border-right-width: 1px;
	}
	.form-control {
		border-right-width: 0px;
	}
	.no-left-border {
		border-left-width: 0px;
		border-top-width: 0px;
		border-bottom-width: 0px;
	}
	.calc-input.has-value .no-left-border {
		border-right-color: rgb(215, 215, 215);
		border-color: rgb(215, 215, 215) ;
	}

	/* .form-item-ui .vs__search, .form-item-ui .vs__pre-open-title, .form-item-ui .input-group-text {
		border-color: #d7d7d7;
		border-color: #f0f0f0;
	} */
</style>