<template>
	<div class="pagination">
        <button
            type="button"
            class="pagination__btn-beginning"
            :disabled="currentPage - (maxVisibleButtons + 1) <= 0"
            :class="{ 'disabled' : currentPage - (maxVisibleButtons + 1) <= 0}"
            @click="changePage(1)"
            >
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="m6 18 6-6-6-6M12 18l6-6-6-6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
        <button
            type="button"
            class="pagination__btn-prev"
            :class="{ 'disabled' : currentPage == 1}"
            :disabled="currentPage == 1"
            @click="changePage(currentPage - 1)"
            >
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="m9 18 6-6-6-6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
        <ul>
            <li v-for="pageNumber in pagesToShow" :key="pageNumber">
                <button
                    type="button"
                    class="pagination__item"
                    :class="{ 'active' : currentPage == pageNumber }"
                    @click="changePage(pageNumber)"
                    >{{ pageNumber }}
                </button>
            </li>
        </ul>

        <button
            type="button"
            class="pagination__btn-next"
            :disabled="currentPage == pagesCount"
            :class="{ 'disabled' : currentPage == pagesCount}"
            @click="changePage(currentPage + 1)"
            >
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="m9 18 6-6-6-6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
        <button
            type="button"
            class="pagination__btn-end"
            :disabled="currentPage >= pagesCount - (maxVisibleButtons + 1)"
            :class="{ 'disabled' : currentPage >= pagesCount - (maxVisibleButtons + 1)}"
            @click="changePage(pagesCount)"
            >
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="m6 18 6-6-6-6M12 18l6-6-6-6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
    </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex'

export default {
	name: "PaginationBlock",
	props: {
        pagesCount: {
			type: Number,
		},
        maxVisibleButtons: {
			type: Number,
            default: 4
		},
	},
	data() {
		return {
			currentPage: 1,
            prevPage: 1,
            filteredPages: this.pages
		}
	},
    computed: {
        pages() {
            return Array.from({ length: this.pagesCount }, (x, i) => { return i + 1 })
        },
        startPage() {
            if (this.pagesCount < 5) {
                return this.currentPage
            } else {

                if (this.currentPage === 1) {
                    return 1;
                }
    
                if (this.currentPage >= this.pagesCount - this.maxVisibleButtons) {
                    return this.pagesCount - this.maxVisibleButtons;
                }
    
                return this.currentPage - 1
            }
        },
        pagesToShow() {
            if (this.pagesCount < 5) {
                
                return this.pages
            } else {
                const range = []
    
                for (
                    let i = this.startPage;
                    i <= Math.min(this.startPage + this.maxVisibleButtons, this.pagesCount);
                    i++
                ) {
                    range.push(i);
                }
    
                return range;
            }
        },
    },
    methods: {
        changePage(value) {
            this.prevPage = this.currentPage
            this.currentPage = value
            this.handlePageChange()
        },

        handlePageChange() {
			this.$emit('click', this.currentPage)
		},
    }
}
</script>

<style>
	[v-cloak] { display:none; }
</style>

<style scoped>

</style>