import {get, getT, post, token} from './api.js'
import log from './../log'

export default {
	/**
	 * Получение Plan vs Actuas продаж для клиник
	 * 
	 * @returns 
	 */
	async getClinicsPVA() {
		log.d(`Api | Sells | getClinicsPVA`)
		let response = await getT(`sells/clinicsPVA`, {'month':'true'})
		return response
	},

	/**
	 * Получение Plan vs Actuas продаж для региона
	 * 
	 * @returns 
	 */
	async getRegionsPVA() {
		log.d(`Api | Sells | getRegionsPVA`)
		let response = await getT(`sells/regionsPVA`, {'month':'true'})
		return response
	},

	/**
	 * Получение Plan vs Actuas продаж для пользователя
	 * 
	 * @returns 
	 */
	async getUsersPVA() {
		log.d(`Api | Sells | getUsersPVA`)
		let response = await getT(`sells/usersPVA`)
		return response
	},
	
}