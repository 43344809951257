<template>
	<div class="container">
		<div class="box pb-7">
            <div class="box__body">
                <h1>План</h1>
                
                <div class="h2 my-6">
                    2022 год
                    <span class="text-muted">(дозы)</span>
                </div>
                <div class="plan-item row">
                    <div
                        v-for="(halfPlan, index) in clinicPlan" :key="index"
                        class="col-lg-8 mb-4">

                        <div v-if="index == '1' " class="fw-bold mb-4">январь - июнь</div>
                        <div v-if="index == '2' " class="fw-bold mb-4">июль - декабрь</div>
                        
                        <div v-if="isDraft" class="row row-cols-2 row-cols-md-4 row-cols-lg-5">

                            <div
                                v-for="product in MSDuserProducts" :key="product.id"
                                class="col my-2 form-item-ui focus-has-value"
                                :class="{ 'has-value' : getPlanValue(index, product.id) > 0 }"
                                >
                                <div 
                                    :class="{'disabled': !role_manager_editor}"
                                    class="input-group input-group--unit">
                                    <input
                                        type="number"
                                        class="form-control numeric"
                                        :disabled="!role_manager_editor"
                                        :value="getPlanValue(index, product.id)"
                                        @input="(e)=>setPlanValue(e, index, product.id)"
                                        @keypress="keypressHandler"
                                        @paste.prevent
                                        @focus="focusInputGroup"
                                        @blur="focusInputGroup"
                                        >
                                    <label class="input-group-text">
                                        <span>доз.</span>
                                    </label>
                                </div>
                                <label for="" class="fsz-sm wsp-nowrap">{{ product.nm }}</label>
                            </div>
                        </div>

                        <div v-else class="row row-cols-2 row-cols-md-4 row-cols-lg-5">
                            <div
                                class="col my-2"
                                v-for="product in MSDuserProducts" :key="product.id"
                            >
                                <label class="fsz-sm wsp-nowrap">{{ product.nm }}</label>
                                <div class="h2 mt-2">{{ getPlanValue(index, product.id) }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center mb-4">
                    <div class="col-auto">
                        <button
                            @click="onApproveHandler"
                            type="button"
                            class="btn btn-primary"
                            :class="{ 'loading' :  w_isSaving || !isCanSendToApprove}"
                            :disabled="isApproveButtonDisabled"
                            >согласовать</button>
                    </div>

                    <div class="col-auto fsz-sm text-muted" v-if="isDraft && !dateSaved && lastSaveDate">
                        Сохранено {{ lastSaveDate | moment('add', '3 hour', "DD.MM.YY") }}, в {{ lastSaveDate | moment('add', '3 hour', "HH:mm") }}
                    </div>
                    <div class="col-auto fsz-sm text-muted" v-else-if="!isDraft && !dateSaved && lastSaveDate">
                        Отправлено на согласование {{ lastSaveDate | moment('add', '3 hour', "DD.MM.YY") }}, в {{ lastSaveDate | moment('add', '3 hour', "HH:mm") }}
                    </div>
                    <div class="col-auto fsz-sm text-muted" v-else-if="dateSaved">
                        Сохранено {{ dateSaved | moment("DD.MM.YY") }}, в {{ dateSaved | moment("HH:mm") }}
                    </div>
                    <div class="col-auto" v-if="!isDraft">
                        <button @click="toggleDraftStatus" type="button" class="btn btn-link">Редактировать</button>
                    </div>
                </div>

                <hr class="mb-4 mt-0">

                <div class="row">
                    <div class="col-lg-8">
                        <div class="fw-bold mb-3">Суммарно за 2022</div>
                        <div class="row row-cols-2 row-cols-md-4 row-cols-lg-5">
                            <div
                                v-for="product in MSDuserProducts" :key="product.id"
                                class="col my-2"
                                >
                                <label for="" class="fsz-sm wsp-nowrap">{{ product.nm }}</label>
                                <div class="h2 mt-2">{{ getSum(product.id) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import LoadingBlock from './blocks/LoadingBlock'
import log from '../log'
// Добавлять класс editing  к полю при наведении
export default {
    name: "ClinicPlan",
    components: {
        LoadingBlock
	},
    data() {
        return {
            editedData: {
                1: {},
                2: {}
            },
            dateSaved: '',
            editingMode: true
        }
    },
    async beforeMount() {
		log.d(`ClinicPlan | beforeMount`, this.$route.params.id)
		
        await this.loadClinicPlan({clinicId: this.$route.params.id})
        this.dateSaved = ''
	},
    computed: {
        isApproveButtonDisabled() {
            if (this.isEmptyValues || !this.isDraft || !this.isCanSendToApprove || this.w_isSaving ||this.w_isLoading) {
                return 'disabled'
            }
            return false
        },
        isEmptyValues() {
            for (let half in this.clinicPlan) {
                for(let product in this.MSDuserProducts) {
                    if (this.clinicPlan[half][product] == null) {
                        return true
                    }
                }
            }

            return false
        },
        isDraft() {
            for (let half in this.clinicPlan) {
                if (Object.keys(this.clinicPlan[half]).length == 0) {
                    return true
                } else {
                    for(let product in this.clinicPlan[half]) {
                        if (this.clinicPlan[half][product].status != 'draft') {
                            return false
                        }
                    }
                }
            }

            return true
        },
        isCanSendToApprove() {
            if (!this.MSDuserProducts) {return false}

            for (let productId in this.MSDuserProducts) {
                if (this.clinicPlanSaved[1][productId] == false) {return false}
                if (this.clinicPlanSaved[2][productId] == false) {return false}
            }
            return true
        },
        ...mapState('clinicPlan', [
			'clinicPlan',
            'lastSaveDate',
            'clinicPlanSaved',
			'w_errors',
		]),
		...mapGetters('clinicPlan', [
			'w_isLoading',
			'w_isError',
            'w_isSaving'
		]),

        ...mapState('common', [
			'userProducts',
		]),
        ...mapGetters('common', [
            'orderedUserProducts',
            'MSDuserProducts'
        ])
    },
    methods: {
        getPlanValue(half, productId) {
            if (this.clinicPlan[half][productId]) {
                return this.clinicPlan[half][productId].cnt
            }
            return ''
        },
        setPlanValue(e, half, productId) {
            let value = {
                clinicId: this.$route.params.id,
                data: {
                    product_id: productId,
                    half: half,
                    yr: 2022,
                    cnt: e.target.value,
                    status: 'draft'
                }
            }

            this.savePlanValue(value)
            this.dateSaved = new Date
            this.editedData[half][productId] = value
        },
        getSum(productId) {
            let sum = 0;

            for (let half in this.clinicPlan) {
                if (this.clinicPlan[half][productId] != undefined) {
                    sum += isNaN(parseInt(this.clinicPlan[half][productId].cnt)) ? 0 : parseInt(this.clinicPlan[half][productId].cnt)
                }
            }

            return sum
        },

        focusInputGroup(e) {
			e.target.parentNode.classList.toggle('focus')
		},
        onApproveHandler(){
            this.approveClinicPlan({clinicId: this.$route.params.id})
        },
        toggleDraftStatus() {
            this.changeClinicPlan({clinicId: this.$route.params.id})
            // for (let half in this.clinicPlan) {
            //     for(let product in this.clinicPlan[half]) {
            //         this.clinicPlan[half][product].status = 'draft'
            //     }
            // }
        },
        wipeEditedData() {
            this.editedData = {
                1: {},
                2: {}
            }
        },
        keypressHandler(e) {
            if (e.target.value == '0') {
                e.preventDefault();
                
                return
            }
            return this.isNumber(e)
		},
        isNumber: function(e) {
			e = (e) ? e : window.event;
			var charCode = (e.which) ? e.which : e.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				e.preventDefault();
			} else {
				return true;
			}
		},

        ...mapActions('clinicPlan', [
			'loadClinicPlan',
            'approveClinicPlan',
            'changeClinicPlan',
            'savePlanValue',
        ]),
    }

}
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.25s ease-out;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>

<style scoped>

</style>