import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import clinicApi from '../../api/clinic.api'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

const _name = 'Clinic'

// initial state
const state = {
	w_name: 'Clinic',
	w_loadingCount: 0,
	w_errors: [],
	w_netStatus: {},

	clinics: {},

	PVA: {},	//TODO: вынести отдельно
	calcHistory: {},	//TODO: вынести отдельно
	professionals: {},	//TODO: вынести отдельно

	clinic: {
		id: 0,
		name: '',
		externalid: '',
		type: '',
		sectorOfCate: '',
		subjectType: '',
		partners: [],
		regionId: 0,
		region: '',
		cityId: 0,
		city: '',
		address: '',
		tel: '',
		medicalOrganistaionId: 0,
		medicalOrganistaionName: '',
		initial: 0,
		site: '',
		updatedTime: 0,
		addedTime: 0
	},
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadClinic({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | loadClinic`, reqObj)
		commit('w_loadingPlus')
		let req = await clinicApi.getClinic(reqObj.id)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data.clinic
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('loadClinic', data)

		commit('setId', data.id)
		commit('setName', data.name)
		commit('setExternalId', data.externalid)
		commit('setType', data.type)
		commit('setSectorOfCate', data.sectorOfCate)
		commit('setSubjectType', data.subjectType)
		commit('setPartners', data.partners)
		commit('setRegionId', data.regionId)
		commit('setRegion', data.region)
		commit('setCityId', data.cityId)
		commit('setCity', data.city)
		commit('setAddress', data.address)
		commit('setTel', data.tel)
		commit('setMedicalOrganistaionId', data.medicalOrganistaionId)
		commit('setMedicalOrganistaionName', data.medicalOrganistaionName)
		commit('setInitial', data.initial)
		commit('setSite', data.site)
		commit('setUpdatedTime', data.updatedTime)
		commit('setAddedTime', data.addedTime)

		commit('w_loadingMinus')
	},

	async savePassportData({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | savePassportData`, reqObj)
		
		if (state.clinics[reqObj.id] == undefined) {
			return //TODO: кинуть еррор
		}

		let result = {...state.clinics[reqObj.id], ...reqObj}

		commit('w_loadingPlus')
		//commit('loadClinic', result)

		// TODO: Placeholder
		clinicApi.postQuestCommon(reqObj.id, {main: reqObj})
		// subjectType
		// partners
		// vaccination_license

		commit('w_loadingMinus')
	},

	async changeInFavStatus({commit}, data) {
		log.d(`Store | ${_name} | Actions | changeInFavStatus`, data)
		commit('changeInFavStatus', data)
	},
}


// mutations
const mutations = {
	loadClinic(state, d) {
		log.d(`Store | ${_name} | Mutations | loadClinic`, d)
		
		let obj = {
			id: d.id,
			name: d.name,
			externalId: d.externalid,
			type: d.type,
			sectorOfCare: d.sectorOfCate || d.sectorOfCare, // TODO: поменть как нибудь на sectorOfCare XD
			subjectType: d.subjectType,
			partners: d.partners, //((d.partners=='MSD')?[1]:[]),
			visitCount: d.visitCount,
			regionId: d.regionId,
			region: d.region,
			cityId: d.cityId,
			city: d.city,
			address: d.address,
			tel: d.tel,
			medicalOrganistaionId: d.medicalOrganistaionId,
			medicalOrganistaionName: d.medicalOrganistaionName,
			initial: d.initial,
			site: d.site,
			vaccinationLicense: d.vaccination_license || d.vaccinationLicense,
			updatedTime: d.updatedTime,
			addedTime: d.addedTime,
			inFavourites: d.inFavourites,
			//TODO: ...
		}
		// state.clinics[d.id] = obj
		Vue.set(state.clinics, d.id, obj)
	},
	setClinic(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | setClinic`, data)
		state.clinic = data;
	},

	setId(state, id) {
		state.clinic.id = id
	},
	setName(state, name) {
		state.clinic.name = name
	},
	setExternalId(state, externalid) {
		state.clinic.externalid = externalid
	},
	setType(state, type) {
		state.clinic.type = type
	},
	setSectorOfCate(state, sectorOfCate) {
		state.clinic.sectorOfCate = sectorOfCate
	},
	setSubjectType(state, subjectType) {
		state.clinic.subjectType = subjectType
	},
	setPartners(state, partners) {
		if (Array.isArray(partners)) {
			partners.forEach(el => {
				state.clinic.partners.push(el)
			})
		}
	},
	setRegionId(state, regionId) {
		state.clinic.regionId = regionId
	},
	setRegion(state, region) {
		state.clinic.region = region
	},
	setCityId(state, cityId) {
		state.clinic.cityId = cityId
	},
	setCity(state, city) {
		state.clinic.city = city
	},
	setAddress(state, address) {
		state.clinic.address = address
	},
	setTel(state, tel) {
		state.clinic.tel = tel
	},
	setMedicalOrganistaionId(state, medicalOrganistaionId) {
		state.clinic.medicalOrganistaionId = medicalOrganistaionId
	},
	setMedicalOrganistaionName(state, medicalOrganistaionName) {
		state.clinic.medicalOrganistaionName = medicalOrganistaionName
	},
	setInitial(state, initial) {
		state.clinic.initial = initial
	},
	setSite(state, site) {
		state.clinic.site = site
	},
	setUpdatedTime(state, updatedTime) {
		state.clinic.updatedTime = updatedTime
	},
	setAddedTime(state, addedTime) {
		state.clinic.addedTime = addedTime
	},
	changeInFavStatus(state, data) {
		log.d(`Store | ${_name} | Actions | changeInFavStatus`, data)

		if (!state.clinics[data.clinicId]) {return}
		
		let clinic = state.clinics[data.clinicId]
		clinic.inFavourites = data.value
		// Vue.set(state.clinics, data.clinicId, clinic)
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}