<template>
	<div
		v-if="year && edited && sales && orderedUserProducts"
		:class="{'box_loading' : isLoading == true}"
	>	
		<div class="sales-item mb-0">
			<div class="h2 mb-4">{{year}}
				<span
					v-if="periodMode=='m'"
					>в месяц</span>
				<span
					v-else>за год</span>
				<span class="text-muted">(дозы)</span>
			</div>

			<div class="row">
				<div
					class="col-2 col-sm-2"
					v-for="product of orderedUserProducts"
					:key="product.id"
					>
					<label
						for=""
						class="fsz-sm wsp-nowrap"
						:class="{'text-accent': !product.is_msd_product}"
						>{{product.nm}}</label>
					<div
						class="h2 mt-2"
						>{{getActual(sales[product.id]) | digitSpace1k }}</div>
				</div>
			</div>

			<div v-if="!editingMode">
				<div class="mt-4 mb-4 pt-4">
					<div class="font-weight-bold mb-4">Отредактировано</div>

					<div class="row mb-4">
						<div
							class="col-2 col-sm-2 form-item-ui"
							v-for="product of orderedUserProducts"
							:key="product.id"
							>
							
							<div class="input-group input-group--unit">
								<input
									type="number"
									class="form-control numeric"
									disabled="true"
									:id="'product_'+product.id"
									:value="getActual(currentEdited[product.id])"
									@keyup="(e)=>onChangeHandler(e, product.id)"
									@focus="focusInputGroup"
									@blur="focusInputGroup"
									>
								<label :for="'product_'+product.id" class="input-group-text">
									<span>доз.</span>
								</label>
							</div>
							<label
								for=""
								class="fsz-sm wsp-nowrap"
								:class="{'text-accent': !product.is_msd_product}"
								>{{product.nm}}</label>

						</div>
					</div>
				
					<!-- Кнопки для начала редактирования -->
					<div v-if="!editingMode" class="mt-5">
						<button
							:disabled="w_isSaving || !role_manager_editor"
							@click="toggleEditingMode"
							type="button"
							class="btn btn-link btn-edit">
							<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12 20h9M16.5 3.5a2.121 2.121 0 113 3L7 19l-4 1 1-4L16.5 3.5z" stroke="#00857C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
							<span v-if="w_isSaving">Работа с данными</span>
							<span v-else>редактировать</span>
						</button>
						<span v-if="w_isSaving">
							<svg class="spinner" width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill="#6ECEB2" fill-rule="evenodd" clip-rule="evenodd" d="M10.185 13.32c-.335.5-.202 1.177.296 1.513a1.085 1.085 0 001.509-.297 9.764 9.764 0 018.13-4.354c5.405 0 9.786 4.396 9.786 9.818s-4.381 9.818-9.785 9.818c-.6 0-1.088.489-1.088 1.091 0 .603.487 1.091 1.088 1.091 6.605 0 11.959-5.373 11.959-12S26.726 8 20.12 8c-4.034 0-7.729 2.021-9.935 5.32z"/>
							</svg>
						</span>
					</div>
				</div>
			</div>
		</div>
		
		<!-- Блок редактора -->
		<div v-if="editingMode" class="sales-item editing mb-4">
			<div class="font-weight-bold mb-4">Редактирование</div>

			<div class="row mb-4">
				<div
					class="col-2 col-sm-2 form-item-ui"
					v-for="product of orderedUserProducts"
					:key="product.id"
					>
					<div class="input-group input-group--unit">
						<input
							type="number"
							class="form-control numeric"
							:disabled="w_isLoading"
							:id="'product_'+product.id"
							:value="getActual(currentEdited[product.id])"
							@keyup="(e)=>onChangeHandler(e, product.id)"
							@keypress="keypressHandler"
							@focus="focusInputGroup"
							@blur="focusInputGroup"
							>
						<label :for="'product_'+product.id" class="input-group-text">
							<span>доз.</span>
						</label>
					</div>
					<label
						for=""
						class="fsz-sm wsp-nowrap"
						:class="{'text-accent': !product.is_msd_product}"
						>{{product.nm}}</label>
				</div>
			</div>

			<div class="row">
				<div class="col-auto">
					<button
						type="button"
						class="btn btn-primary"
						@click="onSaveHandler"
						>Сохранить</button>
				</div>
				<div class="col-auto">
					<button
						type="button"
						class="btn btn-link"
						@click="toggleEditingMode(true)"
						>Отменить</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import log from '../../log'

export default {
    name: "SalesBlock",
    components: {
		
	},
	props: [
		'year',
		'sales',
		'edited',
		'current',
		'periodMode',
		'isLoading',
	],
    data() {
		return {
			editingMode: false,
			defaultPeriodMode: 'y',
			currentEdited: this.edited || {},
		}
	},
	async beforeMount() {
		this.wipeEditedData()
	},
	computed: {
		currentYear() {
            return new Date().getFullYear()
        },
        prevYear() {
            return new Date().getFullYear()-1
        },

		...mapState('clinicProductSales', [
			'w_errors',
		]),
		...mapGetters('clinicProductSales', [
			'w_isLoading',
			'w_isSaving',
			'w_isError',
		]),

        ...mapState('common', [
			'userProducts',
		]),
        ...mapGetters('common', [
            'orderedUserProducts',
        ]),
	},
	methods: {
		toggleEditingMode(wipe) {
			if (this.editingMode && wipe) {
				this.wipeEditedData()
			}
			if (!this.editingMode) {
				this.currentEdited=this.edited
			}
			this.editingMode = !this.editingMode
		},
		y2m(value) {
			return Math.round(value/12)
		},
		m2y(value) {
			return value*12
		},
		getActual(value) {
			if(this.periodMode && this.periodMode.toLowerCase()=="m") {
				return this.y2m(value)
				return this.$options.filters.digitSpace1k(this.y2m(value))
			}
			return value
			return this.$options.filters.digitSpace1k(value)
		},
		setActual(value) {
			if(this.periodMode && this.periodMode.toLowerCase()=="m") {
				return this.m2y(value)
			}
			return value
		},
		wipeEditedData() {
			this.currentEdited = this.edited || {}
		},
		onChangeHandler(e, productId) {
			let pr = {...this.currentEdited}
			pr[productId] = this.setActual(e.target.value.replace(/\s+/g,''))
			this.currentEdited=pr
		},
		onSaveHandler(e) {
			this.$emit('save', {editedData: this.currentEdited, year: this.year})
			this.toggleEditingMode()
		},
		focusInputGroup(e) {
			e.target.parentNode.classList.toggle('focus')
		},
		keypressHandler(e) {
			return this.isNumber(e)
		},
		isNumber: function(e) {
			e = (e) ? e : window.event;
			var charCode = (e.which) ? e.which : e.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				e.preventDefault();
			} else {
				return true;
			}
		},
	},
	watch: {
		edited() {
			if (Object.keys(this.currentEdited).length == 0 && Object.keys(this.edited).length>0) {
				this.currentEdited = this.edited
			}
		},
	}
}
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.25s ease-out;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>

<style scoped>
	.btn-edit:disabled {
		color: inherit;
		background-color: inherit;
		border-color: inherit;
	}
	.input-group.input-group--unit input {
		color: #0C2340;
	}
</style>