import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from './components/Index.vue';

import Login from './components/Login.vue';

import AdminRoom from './components/AdminRoom.vue';
import EmployeeProfile from './components/EmployeeProfile.vue';

import Register from './components/Register.vue';
import RegisterJob from './components/RegisterJob.vue';
import RegisterPassword from './components/RegisterPassword.vue';
import RegisterConfirm from './components/RegisterConfirm.vue';
import RegisterConfirmRepeat from './components/RegisterConfirmRepeat.vue';
import RegisterFinish from './components/RegisterFinish.vue';
import RestorePassword from './components/RestorePassword.vue';

import Profile from './components/Profile.vue';
import Activity from './components/Activity.vue';

import Clinic from './components/Clinic.vue';
import ClinicPassport from './components/ClinicPassport.vue';
import ClinicQuest from './components/ClinicQuest.vue';
import ClinicCalc from './components/ClinicCalc.vue';
import ClinicSales from './components/ClinicSales.vue';
import ClinicPlan from './components/ClinicPlan.vue';

import Clinics from './components/Clinics.vue';
import Regions from './components/Regions.vue';
import Reports from './components/Reports.vue';
import Visits from './components/Visits.vue';
import News from './components/News.vue';
import Epidemiology from './components/Epidemiology.vue';
import RegionOncoSales from './components/RegionOncoSales.vue';
import UserOncoSales from './components/UserOncoSales.vue';

import ClinicsToVisit from './components/ClinicsToVisit.vue'

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	// base: __dirname,

	routes: [
		{ path: '/', component: Index },
		
		{ path: '/login', component: Login,
			meta: {
				guest: true
			}
		},

		{ path: '/admin', component: AdminRoom },
		{ path: '/admin/employee-profile/:id', component: EmployeeProfile, props: true },

		{ path: '/register', component: Register, meta: { guest: true } },
		{ path: '/register-job', name: 'job', component: RegisterJob, meta: { guest: true } },
		{ path: '/restore', name: 'restore', component: RestorePassword, meta: { guest: true } },
		{ path: '/register-password', name: 'password', component: RegisterPassword, meta: { guest: true } },
		{ path: '/register-confirm', name: 'confirm', component: RegisterConfirm, meta: { guest: true } },
		{ path: '/register-confirm-repeat', name: 'confirm-repeat', component: RegisterConfirmRepeat, meta: { guest: true } },
		{ path: '/register-finish', name: 'finish', component: RegisterFinish, meta: { guest: true } },

		{ path: '/profile', component: Profile },
		{ path: '/profile/:id', component: Profile, props: true },
		{ path: '/activity', component: Activity },
		{ path: '/clinics', component: Clinics },
		{
			path: '/clinic/:id',
			component: Clinic,
			children: [
				{ path: '', component: ClinicPassport },
				{ path: 'passport', component: ClinicPassport },
				{ path: 'quest', component: ClinicQuest },
				{ path: 'sells', component: ClinicSales },
				{ path: 'calc', component: ClinicCalc },
				{ path: 'calc/:productId/:visitId?/:calcId?', component: ClinicCalc },
				{ path: 'plan', component: ClinicPlan },
			],
		},
		{ path: '/regions', component: Regions },
		{ path: '/reports', component: Reports },

		{ path: '/visits', component: Visits },
		{ path: '/news', component: News },
		{ path: '/epidemiology', component: Epidemiology },
		{ path: '/regionSales', component: RegionOncoSales },
		{ path: '/userSales', component: UserOncoSales },
		{ path: '/toVisit', component: ClinicsToVisit },
	],
	
	scrollBehavior: function(to, from, savedPosition) {
		if (to.hash) {
			return {selector: to.hash, behavior: 'smooth'}
		} else {
			return { x: 0, y: 0 }
		}
	},
})

router.beforeEach((to, from, next) => {
	if(to.matched.some(record => record.meta.guest)) {
		if ((localStorage.getItem('auth-KiloEchoYankee') || '') != '') {	// TODO: Улучшить проверку на авторизованного
			next({
				path: '/'
			})
		}
		next()
	} else {
		if ((localStorage.getItem('auth-KiloEchoYankee') || '') == '') {	// TODO: Улучшить проверку на авторизованного
			next({
				path: '/login',
				// params: { nextUrl: to.fullPath }
			})
		}
	}
	next()
})

export default router