import Vue from 'vue'
import Vuex from 'vuex'

import common from './modules/common.store'
import auth from './modules/auth.store'

// users
import user from './modules/user.store'
import userLastVisits from './modules/user.lastVisits.store'
import userToVisit from './modules/user.ToVisit.store'
import userNews from './modules/user.news.store'
import userUserPVA from './modules/user.userPVA.store'
import userLists from './modules/userList.store'
import userListClinics from './modules/userListClinics.store'

import employees2 from './modules/employees2.store'
import employees from './modules/employees.store'
import employee from './modules/employee.store'

// regions
import regions from './modules/regions.store'
import regionsClinics from './modules/regions.clinics.store'
import regionsCover from './modules/regions.cover.store'
import regionsRegionsPVA from './modules/regions.regionsPVA.store'
import regionsEpidemiology from './modules/regions.epidemiology.store'
import regionsTenders from './modules/regions.tenders.store'

import regionUserCustomSalesPlan from './modules/regionUserCustomSalesPlan.store'

// clinics
import clinic from './modules/clinic.store'
import clinics from './modules/clinics.store'
import clinicPatientCategories from './modules/clinicPatientCategories.store'
import clinicQuestCommon from './modules/clinicQuestCommon.store'
import clinicQuestPatientCategory from './modules/clinicQuestPatientCategory.store'
import calc from './modules/calc.store'
import calcHistory from './modules/calcHistory.store'
import clinicSales from './modules/clinicSales.store'
import clinicPlan from './modules/clinicPlan.store'
import clinicPros from './modules/clinicPros.store'
import clinicProductSales from './modules/clinicProductSales.store'
import clinicQuestOnco from './modules/clinicQuestOnco.store'
import clinicProblems from './modules/clinicProblems.store'
import clinicLoyalty from './modules/clinicLoyalty.store'

//
import report from './modules/report.store'
import ssi from './modules/ssi.store'


Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    common,
    auth,

    // users
    user,
    userLastVisits,
    userToVisit,
    userNews,
    userUserPVA,
    userLists,
    userListClinics,

    employees2,
    employees,
    employee,

    //regions
    regions,
    regionsClinics,
    regionsCover,
    regionsRegionsPVA,
    regionsEpidemiology,
    regionsTenders,
    regionUserCustomSalesPlan,

    // clinics
    clinic,
    clinics,
    clinicPatientCategories,
    calc,
    calcHistory,
    clinicQuestCommon,
    clinicQuestPatientCategory,
    clinicSales,
    clinicPros,
    clinicPlan,
    clinicProductSales,
    clinicQuestOnco,
    clinicProblems,
    clinicLoyalty,

    //
    report,
    ssi,
  },
  strict: debug,
//   plugins: debug ? [createLogger()] : []
})