<template>
	<div
		:isLoading="isLoading"
		:isError="isError"
		:errors="errors"
		class="box"
		:class="{ 'box_loading' : isLoading == true, 'box_error' : isError == true }"
		>
		<div class="box__header" v-if="title">
			<div class="box__title" :class="titleClass" v-html="title"></div>
			<router-link v-if="url && !showEmptyMessage" :to="url">
				<i class="box__link-icon">
					<svg width="8" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 13l6-6-6-6" stroke="#00857C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</i>
			</router-link>
		</div>
		<div class="box__body text-danger" v-if="isError">
			<div v-for="error in errors">
				{{error}}
			</div>
		</div>
		<transition name="fade" mode="in-out">
			<div class="box__body" v-if="isLoading && isHiddenLoading===false">
				<slot name="loader">
					<LoadingBlock
						isEnabled="true"
						:minHeight="loadingMinHeight"></LoadingBlock>
				</slot>
			</div>
			<div class="box__body" v-else>
				<div v-if="showEmptyMessage" class="box__empty-data">
					<slot name="empty-data"><div>¯\_(ツ)_/¯<br><br>Данных пока нет</div></slot>
				</div>
				<slot v-else></slot>
			</div>
		</transition>
		<slot name="box-footer"></slot>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import LoadingBlock from '../blocks/LoadingBlock'

export default {
	name: "Widget",
	props: {
		title:{},
		titleClass: {
			type: String,
			default: null
		},
		url:{},
		isLoading:{
			default: false,
		},
		isError:{
			default: false,
		},
		errors:{},
		isHiddenLoading:{
			default: false,
		},
		loadingMinHeight:{},
		showEmptyMessage:{
			default: false,
		},
	},
	components: {
		LoadingBlock,
	},
	data() {
		return {
			
		}
	},

	methods: {
		
	},
	computed: {
		element () {
			return this.url ? 'router-link' : 'div';
		}
	},
}
</script>

<style>

</style>

<style scoped>

</style>