<template>
    <button class="btn-sort ml-3"
        @click="toggle()"
        :class="classes"
        type="button">
        </button>
</template>

<script>
export default {
	name: 'TableSortControl',
	props: {
		value: {
			type: Number,
            default: undefined
		},
		label: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			sortType: this.value,
		}
	},
	computed: {
		// "{ 'desc' : value == undefined, 'asc' : sortType == 1, 'desc' : sortType == 0 }"
		classes() {
			if (this.value == 1) {
					return 'asc'
				} else {
					return 'desc'
			}
		}
	},
	methods: {
		toggle() {
			if (this.value == 1) {
                this.sortType = 0
            } else {
				this.sortType = 1
            }
			this.handleInput()
		},

		handleInput (e) {
			this.$emit('input', this.sortType)
		},
	}
}
</script>

<style>

</style>

<style scoped>

</style>