import router from './../router'
import config from '../config.loader'
import store from '../store/index'
import log from '../log'
import EM from '../errorMessage'

const normalizeUrl = require('normalize-url');

const ctFormUrlecoded = 'application/x-www-form-urlencoded;charset=UTF-8'
const ctJSON = 'application/json;charset=utf-8'

/**
 * Запрос с обработкой ответа
 * @param {string} url относительный адрес запроса
 * @param {object|null} options 
 * @returns 
 */
export async function request(url, options) {
	options = Object.assign({}, options)
	let reqOts = {}
	if (options.readBody) {
		reqOts.readBody = options.readBody
		delete options.readBody
	}
	let response
	try {
		response = await fetch(normalizeUrl(`${config.apiBaseUrl}/${url}`), options)
		if (reqOts.readBody) {
			response.text = await response.text()
		} else {
			response.json = await response.json()
		}
	} catch (err) {
		if (err.message.includes('NetworkError')) {
			log.e(`Ошибка сети`, err)
			store.commit('common/w_addError', EM.connectionProblem)
			return
		}
		log.w(`Ошибка`, err)
	}
	if (response === undefined) {
		// TODO: Throw error
		log.e('response === undefined')
		store.commit('common/w_addError', EM.noServerAnswer)
		return
	}
	if (response.status === 401) {
		// TODO: Deauth user
		router.push({path:'/login'})
		return
	}
	if (response.body === undefined) {
		// TODO: Throw error
		log.e(`Ошибка ответа`, err)
		store.commit('common/w_addError', EM.noBodyContent)
		return
	}
	
	if (response.json) {
		if (response.json.status === 'error') {
			if (response.json.error.message.toLowerCase() === 'wrong token') {	// Разлогинивать при неверном токене
				await store.dispatch('auth/logout')
				store.commit('auth/setMessage', EM.endSession)
			}
			if (response.json.error.message.toLowerCase() === 'wrong user') {	// Разлогинивать при неверном пользователе
				await store.dispatch('auth/logout')
				store.commit('auth/setMessage', EM.endSession)
			}
			if (response.json.error.message.toLowerCase() === 'inactive user') {	// Разлогинивать при неверном пользователе
				await store.dispatch('auth/logout')
				store.commit('auth/setMessage', EM.inactiveUser)
			}
			if (response.json.error.code === 2002) {
				store.commit('common/w_addError', EM.dbErrorConnection)
				await store.dispatch('auth/logout')
			}
			if (response.json.error.code === '42S02') {
				store.commit('common/w_addError', EM.dbErrorConnection)
				await store.dispatch('auth/logout')
			}
		}
		if (response.json.status === 'ok') {
			if (response.json.systemMessages) {
				await store.dispatch('common/getSystemMessages', response.json.systemMessages)
			}
		}
	}
	return response
}

export async function getTUrl(url, body={}) {
	url = normalizeUrl(`${config.apiBaseUrl}/${url}`)
	body = Object.assign({'token':token()}, body)

	let params = new URLSearchParams(body)
	let resultUrl = url
	if (params.toString() !== '') {
		resultUrl += `?${params.toString()}`
	}

	return resultUrl
}

/**
 * GET запрос
 * 
 * @param {*} url относительный адрес запроса
 * @param {*} body 
 * @param {*} options 
 * @returns 
 */
export async function get(url, body={}, options={}) {
	return getSP(url, body, options)
}

/**
 * POST запрос
 * 
 * @param {*} url относительный адрес запроса
 * @param {*} body 
 * @param {*} options 
 * @returns 
 */
export async function post(url, body, options) {
	return postJSON(url, body, options)
}

/**
 * PUT запрос
 * @param {string} url относительный адрес запроса
 * @param {*} body 
 * @param {*} options 
 * @returns 
 */
export async function put(url, body, options) {
	return putJSON(url, body, options)
}

/**
 * PATCH запрос
 * @param {*} url относительный адрес запроса
 * @param {*} body 
 * @param {*} options 
 * @returns 
 */
export async function patch(url, body, options) {
	return patchJSON(url, body, options)
}

/**
 * DELETE запрос
 * @param {string} url относительный адрес запроса
 * @param {*} body 
 * @param {*} options 
 * @returns 
 */
export async function del(url, body, options) {
	return delJSON(url, body, options)
}

/**
 * GET запрос с подстановкой токена
 * 
 * @param {string} url относительный адрес запроса
 * @param {object} body 
 * @param {object|null} options 
 * @returns 
 */
export async function getT(url, body, options) {
	return get(url, Object.assign({'token':token()}, body), options);
}

/**
 * POST запрос с подстановкой токена
 * 
 * @param {string} url относительный адрес запроса
 * @param {object} body 
 * @param {object|null} options 
 * @returns 
 */
export async function postT(url, body, options) {
	return post(url, Object.assign({'token':token()}, body), options);
}

/**
 * PUT запрос с подстановкой токена
 * 
 * @param {string} url относительный адрес запроса
 * @param {object} body 
 * @param {object|null} options 
 * @returns 
 */
export async function putT(url, body, options) {
	return put(url, Object.assign({'token':token()}, body), options);
}

/**
 * PATCH запрос с подстановкой токена
 * 
 * @param {*} url относительный адрес запроса
 * @param {*} body 
 * @param {*} options 
 * @returns 
 */
export async function patchT(url, body, options) {
	return patch(url, Object.assign({'token':token()}, body), options);
}

/**
 * DELETE запрос с подстановкой токена
 * 
 * @param {*} url относительный адрес запроса
 * @param {*} body 
 * @param {*} options 
 * @returns 
 */
export async function delT(url, body, options) {
	return del(url, Object.assign({'token':token()}, body), options);
}

/*
 * JSON 
 */

export async function getJSON(url, options) {
	options = Object.assign({
		method: 'GET',
		headers: {
			'Content-Type': ctJSON
		},
	}, options)

	return request(url, options)
}

export async function postJSON(url, body, options) {
	options = Object.assign({
		method: 'POST',
		headers: {
			'Content-Type': ctJSON
		},
		body: JSON.stringify(body)
	}, options)

	return request(url, options)
}

export async function putJSON(url, body, options) {
	options = Object.assign({
		method: 'PUT',
		headers: {
			'Content-Type': ctJSON
		},
		body: JSON.stringify(body)
	}, options)

	return request(url, options)
}

export async function patchJSON(url, body, options) {
	options = Object.assign({
		method: 'PATCH',
		headers: {
			'Content-Type': ctJSON
		},
		body: JSON.stringify(body)
	}, options)

	return request(url, options)
}

export async function delJSON(url, body, options) {
	options = Object.assign({
		method: 'DELETE',
		headers: {
			'Content-Type': ctJSON
		},
		body: JSON.stringify(body)
	}, options)

	return request(url, options)
}

/*
 *	URLSearchParams
 */

/**
 * GET запрос
 * 
 * @param {*} url относительный адрес запроса
 * @param {*} body 
 * @param {*} options 
 * @returns 
 */
export async function getSP(url, body={}, options={}) {
	options = Object.assign({
		method: 'GET',
		headers: {
			'Content-Type': ctFormUrlecoded
		},
	}, options)
	let params = new URLSearchParams(body)
	let resultUrl = url
	if (params.toString() !== '') {
		resultUrl += `?${params.toString()}`
	}
	return request(resultUrl, options)
}

/**
 * POST запрос
 * 
 * @param {*} url относительный адрес запроса
 * @param {*} body 
 * @param {*} options 
 * @returns 
 */
export async function postSP(url, body, options) {
	options = Object.assign({
		method: 'POST',
		headers: {
			'Content-Type': ctFormUrlecoded
		},
		body: new URLSearchParams(body)
	}, options)

	return request(url, options)
}

/**
 * PUT запрос
 * @param {string} url относительный адрес запроса
 * @param {*} body 
 * @param {*} options 
 * @returns 
 */
export async function putSP(url, body, options) {
	options = Object.assign({
		method: 'PUT',
		headers: {
			'Content-Type': ctFormUrlecoded
		},
		body: new URLSearchParams(body)
	}, options)

	return request(url, options)
}

/**
 * PATCH запрос
 * @param {*} url относительный адрес запроса
 * @param {*} body 
 * @param {*} options 
 * @returns 
 */
export async function patchSP(url, body, options) {
	options = Object.assign({
		method: 'PATCH',
		headers: {
			'Content-Type': ctFormUrlecoded
		},
		body: new URLSearchParams(body)
	}, options)

	return request(url, options)
}

/**
 * DELETE запрос
 * @param {string} url относительный адрес запроса
 * @param {*} body 
 * @param {*} options 
 * @returns 
 */
export async function delSP(url, body, options) {
	options = Object.assign({
		method: 'DELETE',
		headers: {
			'Content-Type': ctFormUrlecoded
		},
		body: new URLSearchParams(body)
	}, options)

	return request(url, options)
}

/**
 * Возвращает текущий токен текущего пользователя
 * 
 * @returns token
 */
export function token() {
	return localStorage.getItem('auth-KiloEchoYankee') || ''
	//TODO: store.state.auth.key
}

/**
 * Возвращает id текущего пользователя
 * 
 * @returns id
 */
export function userid() {
	return localStorage.getItem('auth-user-id') || 0
	//TODO: store.state.auth.key
}

// export async function getAuth(url, options) {
// 	options = [...new Set([...options, ...options])]
// 	return await get(url, options);
// }