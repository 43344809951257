import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import clinicApi from '../../api/clinic.api'
import errorApi from '../../api/error.api'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	w_name: 'ClinicPlan',
	w_loadingCount: 0,
	w_savingCount: 0,
	w_errors: [],
	w_netStatus: {},
	
	clinicPlan: {
		1: {},
		2: {}
	},
	clinicPlanSaved: {
		1: {},
		2: {}
	},
	lastSaveDate: 0,
}

// getters
const getters = {
	w_isSaving(state) {
		if (state.w_savingCount>0) {return true}
		return false
	},
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadClinicPlan({state, commit, dispatch}, reqObj) {
		log.d(`Store | ${state.w_name} | Actions | loadClinicPlan`, reqObj)

		commit('w_loadingPlus')
		
		let req = await clinicApi.getClinicPlan(reqObj.clinicId)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data.vx_plans
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('loadClinicPlan', {clinicId: reqObj.clinicId, value: data})
		
		commit('w_loadingMinus')
	},

	async approveClinicPlan({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | approveClinicPlan`, reqObj)

		commit('w_savingPlus')

		let req = await clinicApi.postClinicPlanApprove(reqObj.clinicId)

		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка сервера')
			commit('common/w_addError', 'Ошибка сервера', {root: true})
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при обновлении статуса плана')
			commit('common/w_addError', `Ошибка при обновлении статуса плана. Попробуйте обновить страницу и повторить попытку позже.`, {root: true})
			errorApi.sendError('error', `Ошибка при обновлении статуса плана. Status: ${req.json?.status}. Message: ${req.json?.error?.message}.`)
			return
		}

		dispatch('loadClinicPlan', reqObj)

		commit('w_savingMinus')
	},

	async changeClinicPlan({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | changeClinicPlan`, reqObj)

		commit('w_savingPlus')

		let req = await clinicApi.postClinicPlanChange(reqObj.clinicId)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка сервера')
			commit('common/w_addError', 'Ошибка сервера', {root: true})
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при обновлении статуса плана')
			commit('common/w_addError', `Ошибка при обновлении статуса плана. Попробуйте обновить страницу и повторить попытку позже. ${req?.json?.error?.message}`, {root: true})
			errorApi.sendError('error', `Ошибка при обновлении статуса плана. Status: ${req.json?.status}. Message: ${req.json?.error?.message}.`)
			return
		}

		dispatch('loadClinicPlan', reqObj)

		commit('w_savingMinus')
	},

	async savePlanValue({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | savePlanValue`, reqObj)
		
		commit('savePlanValue', reqObj.data)

		commit('setClinicPlanSaved', {
			half: reqObj.data.half,
			product_id: reqObj.data.product_id,
			status: false,
		})

		commit('w_setTimer', {
			id: `postPlan-${reqObj.data.product_id}-${reqObj.data.half}`,
			action: async ()=>{
				let req = await clinicApi.postClinicPlan(reqObj.clinicId, reqObj.data)

				if (!req.ok) {
					// TODO: throw error?
					commit('w_addError', 'Ошибка сервера при сохранении значений')
					commit('common/w_addError', 'Ошибка сервера при сохранении значений', {root: true})
					return
				}
				let data = req.json.data
				if(req.json.status !== 'ok') {
					// TODO: throw error?
					commit('w_addError', 'Ошибка при сохранении значений')
					commit('common/w_addError', `Ошибка при сохранении значений. Попробуйте обновить страницу и повторить попытку позже. ${req?.json?.error?.message}`, {root: true})
					errorApi.sendError('error', `Ошибка при сохранении значений. Status: ${req.json?.status}. Message: ${req.json?.error?.message}.`)
					return
				}

				if (req.json.status == 'ok') {
					commit('setClinicPlanSaved', {
						half: reqObj.data.half,
						product_id: reqObj.data.product_id,
						status: true,
					})
				}

			},
		})
	}

}


// mutations
const mutations = {
	loadClinicPlan(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | loadClinicPlan`, d)
		let products = d.value.products

		state.clinicPlan = {
			1: {},
			2: {}
		}

		state.clinicPlanSaved = {
			1: {},
			2: {}
		}

		state.lastSaveDate = 0

		for (let i in products) {
			let half = products[i].half
			Vue.set(state.clinicPlan[half], products[i].product_id, products[i])
			Vue.set(state.clinicPlanSaved[half], products[i].product_id, true)
			state.lastSaveDate = products[i].dt_updated
		}
	},
	savePlanValue(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | savePlanValue`, d)

		if (d.cnt) {
			Vue.set(state.clinicPlan[d.half], d.product_id, d)
		} else {
			Vue.delete(state.clinicPlan[d.half], d.product_id)
		}
	},

	saveClinicPlan(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | saveClinicPlan`, d)

	},

	setClinicPlanSaved(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | setClinicPlanSaved`, d)
		Vue.set(state.clinicPlanSaved[d.half], d.product_id, d.status)
	},

	w_savingPlus(state) {
		log.d(`Store | ${state.w_name} | Mutations | savingPlus`, state.w_savingCount)
		state.w_savingCount++;
	},
	
	w_savingMinus(state) {
		log.d(`Store | ${state.w_name} | Mutations | savingMinus`, state.w_savingCount)
		state.w_savingCount--;
		if (state.w_savingCount<0) {state.w_savingCount = 0}
	},


	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}