<template>
<Widget title="Продажи" :isLoading="w_isLoading" :isError="w_isError" class="pb-3">
	<div class="row mb-3">
		<div class="col-md-3">
			<div class="row">
			<div class="col-6 col-md-12 mb-5">
				<div class="mb-2">Факт</div>
				<div class="h1 mb-0 text-nowrap">{{ actualAll | digitSpace1k}}</div>
			</div>
			<div class="col-6 col-md-12 mb-4 text-primary-light">
				<div class="mb-2">План</div>
				<div class="h1 mb-0 font-weight-light text-nowrap">{{ planAll | digitSpace1k}}</div>
			</div>
			</div>
		</div>
		<div class="col-md-9">
			<div class="btn-switch justify-content-end mb-4">
				<button :class="{'active': graphActiveTab==0}" @click="setGraphActiveTab(0)" type="button">янв – июн</button>
				<button :class="{'active': graphActiveTab==1}" @click="setGraphActiveTab(1)" type="button">июл – дек</button>
			</div>
            <div class="chart-container">
			    <SalesChart v-if="graphActiveTab==0" :chartData="sales" half=1></SalesChart>
				<SalesChart v-if="graphActiveTab==1" :chartData="sales" half=2></SalesChart>
            </div>
		</div>
	</div>
	<div v-if="Object.keys(MSDuserProducts).length>1">
		<hr>
		<div class="row">
			<div
				v-for="product in MSDuserProducts"
				:key="product.id"
				class="col-6 col-md-3 my-2"
			>
				<div class="input-check input-check-sm input-check-align-center mr-5 mb-1">
					<input v-model="products" :value="product.id" :id="product.alias" type="checkbox">
					<label :for="product.alias" class="text-nowrap">{{product.nm}}</label>
				</div>
			</div>
		</div>
	</div>
</Widget>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../../../log'
import Widget from '../Widget'
import SalesChart from '../../blocks/SalesChart'

export default {
	name: "SalesWidget",
	components: {Widget, SalesChart},
	async beforeMount() {
		log.d(`SalesWidget | beforeMount`)
		await this.loadRegionsPVA()

		if (this.products.length == 0) {
			this.products = Object.keys(this.MSDuserProducts)
		}

		this.graphActiveTab = (new Date().getMonth() + 1) <= 6 ? 0 : 1
	},
	data() {
		return {
			products: [],
			graphActiveTab: 0,
			planAll: 0,
			actualAll: 0,
		}
	},
	props: ['regions'],
	computed: {
		sales() {
			let obj = {}
			this.planAll = 0
			this.actualAll = 0

			this.products.forEach(productId => {

				this.regions.forEach(regionId => {
					if (this.regionsPVA[productId] && this.regionsPVA[productId].regions[regionId]) {
						this.planAll += parseFloat(this.regionsPVA[productId].regions[regionId].plan)
						this.actualAll += parseFloat(this.regionsPVA[productId].regions[regionId].actual)

						if (this.regionsPVA[productId].regions[regionId].months) { // TODO: проверить на наличие ключей
							let months = Object.values(this.regionsPVA[productId].regions[regionId].months);
							months.forEach(month => {
								if (!obj.hasOwnProperty(month.month)) {

									obj[month.month] = {
										plan: 0,
										actual: 0
									}
								}

								if (month.plan) obj[month.month].plan += parseFloat(month.plan)
								if (month.actual) obj[month.month].actual += parseFloat(month.actual)
								obj[month.month].plan = this.planAll 
							})
						}

					}
				})
			})
			obj.actual = this.actualAll
			obj.plan = this.planAll 

			return obj
		},
        ...mapState('regionsRegionsPVA', [
			'regionsPVA',
			'w_errors',
		]),
		...mapGetters('common', [
			'MSDuserProducts'
		]),
		...mapGetters('regionsRegionsPVA', [
			'w_isLoading',
			'w_isError',
		])
	},
	methods: {
		setGraphActiveTab(value) {
            this.graphActiveTab = value
        },
		...mapActions('regionsRegionsPVA', [
			'loadRegionsPVA'
		])
	},
	watch: {
        MSDuserProducts() {
            if(Object.keys(this.MSDuserProducts).length>0 && this.products.length==0) {
                this.products = Object.keys(this.MSDuserProducts)
            }
        },
    }
}
</script>

<style>
	.chart-container #line-chart {
		height: 100% !important;
    }
</style>

<style scoped>
    .chart-container {
        display: flex;
        position: relative;
		right: -13px;
        width: 100%;
        height: 210px;
        overflow: auto;
    }
    .chart-container > div {
        flex-shrink: 0;
        width: 534px;
    }
</style>