<template>
	<div class="sales-table">
        <div class="sales-table__head">
            <div>1-е полугодие</div>
            <div>Январь</div>
            <div>Февраль</div>
            <div>Март</div>
            <div>Апрель</div>
            <div>Май</div>
            <div>Июнь</div>
            <button class="sales-table__btn" type="button">
                <svg width="8" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 13l6-6-6-6" stroke="#0C2340" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
        <div class="sales-table__row align-items-center plan">
            <div>План</div>
            <div>
                <div class="input-group">
                    <input type="number" class="form-control">
                    <label for="" class="input-group-text">уп.</label>
                </div>
            </div>
            <div>
                <div class="input-group">
                    <input type="number" class="form-control">
                    <label for="" class="input-group-text">уп.</label>
                </div>
            </div>
            <div>
                <div class="input-group">
                    <input type="number" class="form-control">
                    <label for="" class="input-group-text">уп.</label>
                </div>
            </div>
            <div>
                <div class="input-group">
                    <input type="number" class="form-control">
                    <label for="" class="input-group-text">уп.</label>
                </div>
            </div>
            <div>
                <div class="input-group">
                    <input type="number" class="form-control">
                    <label for="" class="input-group-text">уп.</label>
                </div>
            </div>
            <div>
                <div class="input-group">
                    <input type="number" class="form-control">
                    <label for="" class="input-group-text">уп.</label>
                </div>
            </div>
        </div>
        <div class="sales-table__row align-items-end">
            <div>
                <div>Факт:</div>
                <div class="fw-bold mt-1">7 404</div>
            </div>
            <div>1234</div>
            <div>1234</div>
            <div>1234</div>
            <div>1234</div>
            <div>1234</div>
            <div>1234</div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import log from '../../log'

export default {
    name: "SalesTable",
    components: {
		
	},
	props: [
	],
    data() {
		return {
		}
	},
}
</script>

<style>
</style>

<style scoped>
</style>