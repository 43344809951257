<template>
  <div class="page-content pt-3 bg-white">
		<div class="container">
			<BtnBackArrow></BtnBackArrow>
			<h1 class="mt-3 mb-4">Последние визиты</h1>
			<div class="table-responsive">
				<table class="table table-visits">
					<tr>
						<th>Дата и время</th>
						<th>Наименование ЛПУ, показанные продукты</th>
						<th>Город</th>
						<th>Адрес</th>
					</tr>
					<tr v-for="lastVisit in lastVisits" :key="lastVisit.id">
						<td>{{ lastVisit.timestamp | moment("DD.MM.YY, HH:mm") }}</td>
						<td>
							<div class="font-weight-bold fsz-base">{{ lastVisit.clinic.name }}</div>
							<div class="mt-3">
								<span v-for="productId in lastVisit.products" key="productId" v-if="userProducts[productId]">
									{{userProducts[productId].nm}}
								</span>
							</div>
						</td>
						<td>{{ lastVisit.clinic.city }}</td>
						<td>{{ lastVisit.clinic.address }}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import log from './../log'
import BtnBackArrow from './blocks/BtnBackArrow'

export default {
	name: "Visits",

	components: {
		BtnBackArrow
	},

	async created() {
	},

	async beforeMount() {
		log.d(`Visits | beforeMount`)
		await this.loadVisits({count:10})
	},

	data() {
		return {
			
		}
	},

	computed: {
		...mapState('common', [
			'userProducts',
		]),
		...mapState('userLastVisits', [
			'lastVisits',
			'w_errors',
		]),
		...mapGetters('userLastVisits', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		...mapActions('userLastVisits', [
			'loadVisits'
		]),
	},
}
</script>

<style>

</style>

<style scoped>

</style>