<template>
	<span>
		<slot>
			{{prefixStr}} {{count}} {{postfixStr}}
		</slot>
	</span>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import log from '../../log'

export default {
	name: "Plurals",
	props: {
		count: {
			type: Number|String,
		},
		prefix: {
			type: Array,
			default: () => ['','','']
		},
		postfix: {
			type: Array,
			default: () => ['','','']
		},
	},
	data() {
		return {
		}
	},
	computed: {
		prefixStr() {
			return this.getNoun(this.count, this.prefix[0], this.prefix[1], this.prefix[2])
		},
		postfixStr() {
			return this.getNoun(this.count, this.postfix[0], this.postfix[1], this.postfix[2])
		},
	},
	methods: {
		getNoun(number, one, two, five) {
			let n = Math.abs(parseInt(number));
			n %= 100;
			if (n >= 5 && n <= 20) {
				return five;
			}
			n %= 10;
			if (n === 1) {
				return one;
			}
			if (n >= 2 && n <= 4) {
				return two;
			}
			return five;
		},
	},
}
</script>

<style>

</style>

<style scoped>

</style>