import Vue from 'vue'
import router from '../../router'
import authApi from '../../api/auth.api'
import log from '../../log'
import regionsApi from '../../api/regions.api'

import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	w_name: 'Regions Cover',
	w_loadingCount: 0,
	w_errors: [],
	w_netStatus: {},

    regionPartners: {},
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {

	async loadRegionPartners({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | loadRegionPartners`, reqObj)
		
		commit('w_loadingPlus')

		if(Object.keys(state.regionPartners).length === 0) {	// TODO: подумать над кэшированием
			let req = await regionsApi.getRegionPartners(reqObj.regions)

			if (!req.ok) {
				// TODO: throw error?
				commit('w_addError', 'Ошибка при загрузке данных')
				return
			}
			let data = req.json.data
			if(req.json.status !== 'ok') {
				// TODO: throw error?
			}

			commit('setRegionPartners', data)
		}

		commit('w_loadingMinus')
	}
}

// mutations
const mutations = {
	setRegionPartners(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | setRegionPartners`, data)
		state.regionPartners = {}
		data.forEach(el => {
			state.regionPartners[el.regionId] = el
		})
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}