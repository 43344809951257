<template>
    <div>
        <div class="row align-items-center mb-4">
            <div class="col-md-3 col-lg-2 my-1">
                Сотрудник
            </div>
            <div class="col-md-6 col-lg-4 my-1">
                <EmployeesSelectSingle v-model="userModel" :options="normalizedUsers" customClass="multiselect-single"></EmployeesSelectSingle>
            </div>
        </div>
        <div class="bg-white">
            <div class="sales-table" v-if="selectedHalfYear==0">
                <div class="sales-table__head">
                    <div class="opacity-4">1-е полугодие</div>
                    <div class="opacity-1">Январь</div>
                    <div class="opacity-1">Февраль</div>
                    <div class="opacity-1">Март</div>
                    <div class="opacity-1">Апрель</div>
                    <div class="opacity-1">Май</div>
                    <div class="opacity-1">Июнь</div>
                    <button class="sales-table__btn" type="button" @click="halfYearSwitch">
                        <svg width="8" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13l6-6-6-6" stroke="#0C2340" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
                <div class="sales-table__row align-items-center plan">
                    <div>План</div>
                    <div v-for="month of [1,2,3,4,5,6]" :key="month">
                        <span v-if="getUsersIdFromSelectedPlan.includes(userModel.id)">
                            {{ plans[selectedUserRegion.id].product[7].users[userModel.id]['yr'][new Date().getFullYear()][month] || 0 }}
                        </span>
                        <span v-else>0</span>
                    </div>
                </div>
                <div class="sales-table__row align-items-end">
                    <div>
                        <div>Факт:</div>
                        <div class="fw-bold mt-1">{{sales.actual}}</div>
                    </div>
                    <div v-for="month of [1,2,3,4,5,6]" :key="month">
                        <span v-if="sales[month]">{{sales[month].actual_month}}</span>
                        <span v-else>0</span>
                    </div>
                </div>
            </div>
            
            <div class="sales-table" v-if="selectedHalfYear==1">
                <div class="sales-table__head">
                    <div class="opacity-4">2-е полугодие</div>
                    <div class="opacity-1">Июль</div>
                    <div class="opacity-1">Август</div>
                    <div class="opacity-1">Сентябрь</div>
                    <div class="opacity-1">Октябрь</div>
                    <div class="opacity-1">Ноябрь</div>
                    <div class="opacity-1">Декабрь</div>
                    <button class="sales-table__btn" type="button" @click="halfYearSwitch">
                        <svg width="8" height="14" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)">
                            <path d="M1 13l6-6-6-6" stroke="#0C2340" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
                <div class="sales-table__row align-items-center plan">
                    <div>План</div>
                    <div v-for="month of [7,8,9,10,11,12]" :key="month">
                        <span v-if="getUsersIdFromSelectedPlan.includes(userModel.id)">
                            {{ plans[selectedUserRegion.id].product[7].users[userModel.id]['yr'][new Date().getFullYear()][month] || 0 }}
                        </span>
                        <span v-else>0</span>
                    </div>
                </div>
                <div class="sales-table__row align-items-end">
                    <div>
                        <div>Факт:</div>
                        <div class="fw-bold mt-1">{{sales.actual}}</div>
                    </div>
                    <div v-for="month of [7,8,9,10,11,12]" :key="month">
                        <span v-if="sales[month]">{{sales[month].actual_month}}</span>
                        <span v-else>0</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../../log'
import EmployeesSelectSingle from './EmployeesSelectSingle.vue'

export default {
    name: "UserSalesBlock",
    components: {
        EmployeesSelectSingle,
    },
    props: {
        users: {
            type: Object
        },
        selectedUser: {
            type: Object
        },
        plans: {
            type: Object
        },
        sales: {
            type: Object
        }
    },
    data() {
        return {
            userModel: {id: this.selectedUser.id.toString(), nm: this.selectedUser.nm},
            selectedHalfYear: (new Date().getMonth()>5)?1:0
        }
    },
    computed: {
        normalizedUsers() {
            let arr = [];

            if (Object.keys(this.users).length > 0) {
                for (const key in this.users) {
    
                    if (this.users.hasOwnProperty(key)) {
    
                        arr.push({id: key, nm: this.users[key]})
                    }
                }
            } else {
                arr.push({id: this.selectedUser.id.toString(), nm: this.selectedUser.nm})
            }

            return arr
        },
        getUsersIdFromSelectedPlan() {
            try {
                let users = this.plans[this.selectedUserRegion.id].product[7].users
                if (users) {
                    let usersKeys = Object.keys(users)
                    return usersKeys
                }
            } catch(e) {}
            return []
        },
        ...mapState('common', [
            'selectedUserRegion',
        ]),
    },
    methods: {
        halfYearSwitch() {
            this.selectedHalfYear = !this.selectedHalfYear
        }
    }
}
</script>

<style>

</style>

<style scoped>
</style>