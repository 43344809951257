<template>
	<div class="page-content pt-8 pb-6">
		<div class="container" v-if="roles && currentUserRoles">
            <BtnBackArrow text="К списку сотрудников"></BtnBackArrow>
			<h1 class="mb-7 mt-5">Карточка сотрудника</h1>
            <div class="box px-lg-5 pt-3">
                <div class="row">
                    <div class="col-lg-4 my-3">
                        <div class="fsz-sm text-muted mb-2">ФИО</div>
                        <div class="pt-1">{{ currentUser.nm }}</div>
                    </div>
                    <div class="col-lg-8 my-3">
                        <div class="fsz-sm text-muted mb-2">E-mail</div>
                        <div class="pt-1">{{ currentUser.email }}</div>
                    </div>
                </div>

                <div v-if="parseInt(currentUser.is_external)==0">
                    <div class="row" v-if="!w_isLoading">
                        <div class="col-lg-4 my-3">
                            <SelectMultiple v-model="currentUserDivisions" :options="divisions" label="Бизнес-юнит" customClass="ui-select"></SelectMultiple>
                        </div>
                        <div class="col-lg-4 my-3"> 
                            <SelectMultiple v-model="currentUserRoles" :options="roles" label="Роль" customClass="ui-select"></SelectMultiple>
                        </div>
                        <div class="col-lg-4 my-3">
                            <SelectSingle v-model="currentUserStatuses" :options="statuses" label="Статус" customClass="ui-select"></SelectSingle>
                        </div>
                    </div>
                    <LoadingBlock :isEnabled='w_isLoading'></LoadingBlock>
                </div>
                <!-- <div class="mt-1 mb-3" v-if="parseInt(currentUser.is_external)==0">
                    <div class="input-check input-check-gray input-check-align-center">
                        <input 
                            type="checkbox"
                            name="assign-admin"
                            id="assign-admin" 
                            v-model="currentUserIsAdmin"
                            >
                        <label class="text-primary" for="assign-admin">Сделать администратором</label>
                    </div>
                </div> -->

                <div class="row" v-if="parseInt(currentUser.is_external)==1">
                    <div class="col-lg-4 my-3">
                        <div class="fsz-sm text-muted mb-2">Бизнес-юнит</div>
                        <div class="pt-1" v-for="division in currentUser.divisions" :key="division.id">{{ division.nm }}</div>
                    </div>

                    <div class="col-lg-4 my-3">
                        <div class="fsz-sm text-muted mb-2">Роль</div>
                        <div class="pt-1">{{ currentUser.role_code }}</div>
                    </div>

                    <div class="col-lg-4 my-3">
                        <div class="fsz-sm text-muted mb-2">Статус</div>
                        <div v-if="currentUser.is_active == true" class="pt-1">Активен</div>
                        <div v-if="currentUser.is_active == false" class="pt-1">Неактивен</div>
                    </div>
                </div>

                <div class="my-3" v-if="currentUser && currentUser.regions && parseInt(currentUser.is_external)==1">
                    <div class="fsz-sm text-muted mb-2">Регионы: {{currentUser.regions.length}}</div>
                    <ul class="list-horizontal">
                        <li 
                            class="my-2"
                            v-for="region in currentUser.regions"
                            :key="region.id"
                            >{{region.nm}}</li>
                    </ul>
                </div>

                <div class="my-3" v-if="regions && parseInt(currentUser.is_external)==0">
                    <div class="fsz-sm text-muted mb-2">Регионы: 
                        <span v-if="currentUserRoles.length>0">{{regions.length}}</span>
                        <span v-else>0</span>
                    </div>
                    <ul class="list-horizontal" v-if="currentUserRoles.length>0">
                        <li 
                            class="my-2"
                            v-for="region in regions"
                            :key="region.id"
                            >{{region.nm}}</li>
                    </ul>
                </div>

            </div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import log from '../log.js'
import Widget from './widgets/Widget'
import BtnBackArrow from './blocks/BtnBackArrow'
import SelectMultiple from './blocks/SelectMultiple'
import SelectSingle from './blocks/SelectSingle'
import LoadingBlock from './blocks/LoadingBlock'

export default {
	name: "EmployeeProfile",
	components: {
		Widget,
		BtnBackArrow,
        SelectMultiple,
        SelectSingle,
        LoadingBlock,
	},
    async beforeMount() {
        await this.getRoleList()
        await this.getRegionList()
        await this.getUser(this.curId)
	},
	data() {
		return {
            curId: this.$route.params.id,
		}
	},
	computed: {
        ...mapState('common', [
            'userRegions',
            'selectedUserRegionsId',
		]),
        ...mapState('employees2', [
            'regions',
            'roles',
            'divisions',
            'statuses',
            'users',
        ]),
        ...mapGetters('employees2', [
            'w_isLoading',
			'w_isError',
            'rolesWithoutAdmin',
        ]),
        currentUser() {
            if (this.users && this.users[this.curId]) {
                return this.users[this.curId]
            } 
            return {}
        },
        // currentUserIsAdmin: {
        //     get() {
        //         if (this.currentUserRoles?.length > 0) {
        //             for(let el of this.currentUserRoles) {
        //                 if (el.nm.toLowerCase() == 'admin' || el.nm.toLowerCase() == 'админ') return true
        //             }
        //             return false
        //         } 
        //         return null
        //     },
        //     set(value) {
        //         console.debug(`currentUserIsAdmin`, value)
        //         // if (value) {
        //         //     this.updateUserRole({id: this.curId, isAdmin: true})
        //         // } else {
        //         //     this.updateUserRole({id: this.curId, isAdmin: false})
        //         // }

        //     }
        // },

        currentUserRolesId: {
            get() {
                return this.currentUserRoles?.map(el=>el.id)
			},
			set(value) {
                console.debug(`currentUserRoles`, value)
                this.updateUserRole({id: this.curId, roles: value})
			}
        },

        currentUserRoles: {
            get() {
                if (this.currentUser) {
                    return this.currentUser?.roles
                }
                return []
			},
			set(value) {
                console.debug(`currentUserRoles`, value)
                this.updateUserRole({id: this.curId, roles: value})
			}
        },

        currentUserRolesWithoutAdmin: {
            get() {
                console.debug(`get currentUserRolesWithoutAdmin`, this.currentUserRoles)
                if (this.currentUserRoles.length > 0) {
                    return this.currentUserRoles.filter(el => {
                        return (el.nm.toLowerCase() != 'admin' && el.nm.toLowerCase() != 'админ' && el.nm.toLowerCase() != 'администратор')
                    })
                }
            },
            set(value) {
                console.debug(`set currentUserRolesWithoutAdmin`, value)
                this.updateUserRole({id: this.curId, roles: value})
                // Object.assign(value, {[value.length]:this.roles[0]})
                
            }
        },

        currentUserDivisions: {
            get() {
                if (this.currentUser) {
                    return this.currentUser.divisions
                }
                return []
			},
			set(value) {
                console.debug(`currentUserDivisions`, value)
                this.updateUserDivisions({id: this.curId, divisions: value})
				// return this.setSelectedUserRegionsId(value)
			}
        },
        currentUserStatuses: {
            get() {
                if (this.currentUser) {
                    // return this.currentUser.is_active
                    return this.statuses[this.currentUser.is_active]
                }
                return null
			},
			set(value) {
                console.debug(`currentUserStatuses`, value)
                this.updateUserActivity({id: this.curId, activity: value.id})
				// return this.setSelectedUserRegionsId(value)
			}
        }

	},
	methods: {
        ...mapActions('employees2', [
			'getRoleList',
            'getRegionList',
            'getUser',
            'getUserList',
            'updateUserActivity',
            'updateUserRole',
            'updateUserDivisions',
		]),
	}
}
</script>

<style>

</style>

<style scoped>

</style>