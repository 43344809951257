import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import authApi from '../../api/auth.api'
import regionApi from '../../api/regions.api'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
} from '../widgetHelper'

const _name = 'regionsEpidemiology'

// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],

	epidemiology: {},
	year: null,
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadEpidemiologyByRegions({state, commit, dispatch, rootState}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | loadEpidemiologyByRegions`, reqObj)
		
		if (Object.keys(state.epidemiology).length === 0) {
			commit('w_loadingPlus')
			let req = await regionApi.getEpidemiologyByRegions(reqObj.regions)
			if (!req.ok) {
				// TODO: throw error?
				commit('w_addError', 'Ошибка при загрузке данных')
				return
			}
			let data = req.json.data
			if(req.json.status !== 'ok') {
				// TODO: throw error?
			}
			commit('setEpidemiologyByRegions', data)
			commit('w_loadingMinus')
		}
		
	}
}

// mutations
const mutations = {
	setEpidemiologyByRegions(state, data) {
		log.d(`Store | ${_name} | Mutations | setEpidemiologyByRegions`, data)
		state.year = data.year
		let epidemiology = {}
		Object.keys(data.regions).forEach(el => {
			epidemiology[el] = data.regions[el]
		})
		state.epidemiology = epidemiology
	},

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}