<template>
	<div class="page-content pt-3 bg-white">
		<div class="container">
			<BtnBackArrow></BtnBackArrow>
			<div class="row mt-3 mb-6">
				<div class="col-md-7">
					<h1 class="">Эпидемиология <span class="opacity-24">{{year}}</span></h1>
				</div>
				<div class="col-md-5 mt-2">
					<RegionsSelectSingle v-model="selectedRegion" :options="userRegions" customClass="multiselect-single"></RegionsSelectSingle>
				</div>
			</div>
			
			<h2 class="mb-4">{{selectedRegion.nm}}</h2>
			<div class="table-responsive">
				<table class="table table-epidemiology">
					<tr>
						<th>Локализация</th>
						<th>I</th>
						<th>II</th>
						<th>III</th>
						<th>IV</th>
						<th>Зарегистрировано ЗНО</th>
						<th>Диагноз подтвержден<br>морфологически, %</th>
						<th class="text-body">Пациентов с распространённым<br>процессом</th>
					</tr>
					<tr v-for="el of epidemiologyGrouped(selectedRegion.id)" :key="el.nm">
						<td class="fsz-base">{{el.nm}}</td>
						<td class="fsz-base">{{el.stage_1Sum}}%</td>
						<td class="fsz-base">{{el.stage_2Sum}}%</td>
						<td class="fsz-base">{{el.stage_3Sum}}%</td>
						<td class="fsz-base">{{el.stage_4Sum}}%</td>
						<td class="fsz-base">{{el.registeredSum}}</td>
						<td class="fsz-base">{{el.morphology_diagnosisSum}}%</td>
						<td class="fsz-base">{{el.patientsSum}}</td>
					</tr>
				</table>
			</div>

		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import log from './../log'
import BtnBackArrow from './blocks/BtnBackArrow'
import RegionsSelectSingle from './blocks/RegionsSelectSingle.vue'

export default {
	name: "Epidemiology",

	components: {
		BtnBackArrow,
		RegionsSelectSingle,
	},

	async beforeMount() {
		log.d(`Epidemiology | beforeMount`)
		await this.loadEpidemiologyByRegions()
	},
	data() {
		return {
			
		}
	},
	computed: {
		selectedRegion: {
			get() {
				return this.selectedUserRegion
			},
			set(value) {
				return this.setselectedUserRegion(value)
			}
		},
		...mapState('common', [
			'userRegions',
			'selectedUserRegion',
		]),
		...mapState('regionsEpidemiology', [
			'epidemiology',
			'year',
		]),
		...mapGetters('regionsEpidemiology', [
			'w_isLoading',
			'w_isError'
		]),
		
	},
	methods: {
		...mapActions('regionsEpidemiology', [
			'loadEpidemiologyByRegions'
		]),
		...mapMutations('common', [
			'setselectedUserRegion',
		]),

		epidemiologyGrouped(regionId) {
			let result = {
				'Рак легкого': {
					nm: 'Рак легкого', 
					subCat: ['Трахея, бронхи, легкое (С33,34)'], 
					count: 0,
					registeredSum: 0,
					morphology_diagnosisSum: 0,
					patientsSum: 0,
					stage_1Sum: 0,
					stage_2Sum: 0,
					stage_3Sum: 0,
					stage_4Sum: 0,
				},
				'Рак головы и шеи': {
					nm: 'Рак головы и шеи',
					subCat: ['Глотка (C10-13)', 'Гортань (С32)', 'Губа (C00)', 'Полость рта (C01-09)'],
					count: 0,
					registeredSum: 0,
					morphology_diagnosisSum: 0,
					patientsSum: 0,
					stage_1Sum: 0,
					stage_2Sum: 0,
					stage_3Sum: 0,
					stage_4Sum: 0,
				},
				'Почечноклеточный рак': {
					nm: 'Почечноклеточный рак',
					subCat: ['Почка (С64)'],
					count: 0,
					registeredSum: 0,
					morphology_diagnosisSum: 0,
					patientsSum: 0,
					stage_1Sum: 0,
					stage_2Sum: 0,
					stage_3Sum: 0,
					stage_4Sum: 0,
				},
				'Рак тела матки': {
					nm: 'Рак тела матки',
					subCat: ['Тело матки (С54)'],
					count: 0,
					registeredSum: 0,
					morphology_diagnosisSum: 0,
					patientsSum: 0,
					stage_1Sum: 0,
					stage_2Sum: 0,
					stage_3Sum: 0,
					stage_4Sum: 0,
				},
			}

			for(let epEl in this.epidemiology[regionId]) {
				let epidemiology = this.epidemiology[regionId][epEl]

				for(let rEl in result) {
					for(let scEl of result[rEl].subCat) {
						if (epidemiology.case.toLowerCase() == scEl.toLowerCase()) {
							result[rEl].count += 1
							result[rEl].registeredSum += parseFloat(epidemiology.registeredSum)
							result[rEl].morphology_diagnosisSum += parseFloat(epidemiology.morphology_diagnosisSum)
							result[rEl].patientsSum += parseFloat(epidemiology.patientsSum)
							result[rEl].stage_1Sum += parseFloat(epidemiology.stage_1Sum)
							result[rEl].stage_2Sum += parseFloat(epidemiology.stage_2Sum)
							result[rEl].stage_3Sum += parseFloat(epidemiology.stage_3Sum)
							result[rEl].stage_4Sum += parseFloat(epidemiology.stage_4Sum)
						}
					}
				}
			}
			
			return result
		}
	},

}
</script>

<style>

</style>

<style scoped>

</style>