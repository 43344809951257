<template>
	<span v-if="m && mode=='m'">{{m}}</span>
	<span v-else-if="y && mode=='y'">{{y}}</span>
	<span v-else><slot v-if="mode=='m'" name="m"></slot><slot v-if="mode=='y'" name="y"></slot></span>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import log from '../../log'

export default {
    name: "PeriodBlock",
    components: {
	},
	props: [
		'mode',
		'y',
		'm',
	],
    data() {
		return {
		
		}
	},
	async beforeMount() {
		
	},
	computed: {
		
	},
	methods: {
		
	},
}
</script>

<style>
    
</style>

<style scoped>

</style>