<template>
<Widget
	title="К посещению"
	url="/toVisit"
	:showEmptyMessage="toVisits.length == 0"
	:isLoading="w_isLoading"
	:isError="w_isError"
	:errors="w_errors"
	:isHiddenLoading="toVisits && toVisits.length>0"
	loadingMinHeight="200px"
	>
	<div class="box__body">
		<div class="mb-5" v-for="toVisit in toVisits.slice(0, 3)" :key="toVisit.id">
			<div class="row flex-nowrap align-items-center">
				<div class="col-sm-8 mr-3 fsz-sm">
					<router-link :to="`clinic/${toVisit.clinic.id}/passport`">{{toVisit.clinic.name}}</router-link>
				</div>
				<div v-if="toVisit.sell && toVisit.sell.mark && divisionVaccines" class="col-auto pr-1">
					<span v-if="toVisit.sell.mark == 'high'">
						<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill="#FF0000" d="M7.138 12.472a.667.667 0 11.944-.944l3.251 3.252V7.333a.667.667 0 011.334 0v7.447l3.251-3.252a.667.667 0 11.944.944L12 17.332l-4.862-4.861z"/>
							<rect stroke="#FF0000" x=".5" y=".5" width="23" height="23" rx="11.5"/>
						</svg>
					</span>
					<span v-if="toVisit.sell.mark == 'middle'">
						<svg style="transform: rotate(180deg);" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill="#FFCC33" d="M7.138 12.472a.667.667 0 11.944-.944l3.251 3.252V7.333a.667.667 0 011.334 0v7.447l3.251-3.252a.667.667 0 11.944.944L12 17.332l-4.862-4.861z"/>
							<rect stroke="#FFCC33" x=".5" y=".5" width="23" height="23" rx="11.5"/>
						</svg>
					</span>
					<span v-if="toVisit.sell.mark == 'low'">
						<svg style="transform: rotate(180deg);" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill="#6ECEB2" d="M7.138 12.472a.667.667 0 11.944-.944l3.251 3.252V7.333a.667.667 0 011.334 0v7.447l3.251-3.252a.667.667 0 11.944.944L12 17.332l-4.862-4.861z"/>
							<rect stroke="#6ECEB2" x=".5" y=".5" width="23" height="23" rx="11.5"/>
						</svg>
					</span>
				</div>
				<div v-if="divisionVaccines" class="col-auto pl-1 mb-0">
					<div v-if="toVisit.sell && toVisit.sell.doses >= 0" >
						{{toVisit.sell.doses | digitSpace1k }} <span v-if="divisionOnko">уп.</span><span v-else>доз</span>
					</div>
					<div v-else>??? доз</div>
				</div>
			</div>
			<div v-if="divisionOnko" class="fsz-sm mt-2">
				{{ toVisit.sell.actual | digitSpace1k }} уп.
			</div>
		</div>
	</div>
</Widget>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import log from '../../../log'
import Widget from '../Widget'

export default {
	name: "ToVisitWidget",
	components: {Widget},
	async beforeMount() {
		log.d(`ToVisitWidget | beforeMount`)
		await this.loadToVisits({count:10})
	},
	data() {
		return {
			
		}
	},
	computed: {
		...mapState('userToVisit', [
			'toVisits',
			'w_errors',
		]),
		...mapGetters('userToVisit', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		...mapActions('userToVisit', [
			'loadToVisits'
		]),
	},
}
</script>

<style>

</style>

<style scoped>

</style>