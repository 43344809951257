import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import clinicApi from '../../api/clinic.api'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	w_name: 'ClinicProductSales',
	w_loadingCount: 0,
	w_savingCount: 0,
	w_errors: [],
	w_netStatus: {},
	
	clinicSales: {},
}

// getters
const getters = {
	w_isSaving(state) {
		if (state.w_savingCount>0) {return true}
		return false
	},
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadClinicProductSales({state, commit, dispatch}, reqObj) {
		log.d(`Store | ${state.w_name} | Actions | loadClinicProductSales`, reqObj)

		commit('w_loadingPlus')
		
		let req = await clinicApi.getClinicProductSales(reqObj)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('loadClinicProductSales', {clinicId: reqObj, value: data})
		
		commit('w_loadingMinus')
	},

	async saveClinicProductSales({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | saveClinicProductSales`, reqObj)
		commit('saveClinicProductSales', reqObj)

		commit('w_savingPlus')
		
			for (let elId in reqObj.value) {
				let el = reqObj.value[elId]
				let req
				
				if (el > 0) {	// TODO: поправить, отправлять только при ручном вводе
					if (new Date().getFullYear() == reqObj.year) {
						req = await clinicApi.postClinicProductSales(reqObj.clinicId, {
							year: reqObj.year,
							productId: elId,
							cnt: el,
						})
					} else {
						req = await clinicApi.postClinicProductSalesForce(reqObj.clinicId, {
							year: reqObj.year,
							productId: elId,
							cnt: el,
						})
					}
				}
			}

		commit('w_savingMinus')
	}

}


// mutations
const mutations = {
	loadClinicProductSales(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | loadClinicProductSales`, d)

		let value = d.value[0]

		let result = {
			id: d.clinicId,
			plan: value.plan,
			sells: value.sells,
		}

		Vue.set(state.clinicSales, d.clinicId, result)
	},

	saveClinicProductSales(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | saveClinicProductSales`, d)

		try{
			let yearIndex = state.clinicSales[d.clinicId].sells.years.findIndex(el=>el.year == d.year)
			state.clinicSales[d.clinicId].sells.years[yearIndex].edited = {...state.clinicSales[d.clinicId].sells.years[yearIndex].edited, ...d.value}
			// Vue.set(state.clinicSales[d.clinicId].sells.years[yearIndex], 'edited', d.value)
		} catch(err) {
			log.e(err)
		}
	},

	w_savingPlus(state) {
		log.d(`Store | ${state.w_name} | Mutations | savingPlus`, state.w_savingCount)
		state.w_savingCount++;
	},
	
	w_savingMinus(state) {
		log.d(`Store | ${state.w_name} | Mutations | savingMinus`, state.w_savingCount)
		state.w_savingCount--;
		if (state.w_savingCount<0) {state.w_savingCount = 0}
	},


	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}