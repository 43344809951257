<template>
	<div class="page-auth">
		<div class="page-auth__section">
			<div id="register-form" class="page-form  pb-7 validate justify-content-between">
				<!--autocomplete="off" action="/register.php" method="POST"-->
				<div class="page-form-header">
					<img src="./../assets/images/logo-msd-commerce.svg" />
					<!-- <a href="#" class="btn-close"></a> -->
				</div>
				
				<div>
					<div class="page-form-body mb-8">
						<div class="d-flex justify-content-between align-items-center mb-2">
							<h1 class="page-form-title">Восстановление пароля</h1>
						</div>

						<div class="fsz-sm text-danger mb-6">{{message}}</div>
						<div class="fsz-sm text-muted mb-6">Поля, отмеченные * обязательны к заполнению</div>
						<div class="page-form-group">
							<input
								name="email"
								type="email"
								id="register-email"
								class="page-form-control"
								:class="{'has-value' : email, 'valid': inputsValid.email && isMerckEmail}"
								required
								autocomplete="off"
								@keyup="e=>{checkValidity(e); checkEmail(e)}"
								v-model="email"
							>
							<label class="page-form-label" for="register-email">E-mail</label>
						</div>
						
						<div class="fsz-sm text-muted mt-3">
							На указанный почтовый адрес мы отправим ссылку для восстановления пароля
						</div>
					</div>
					<button
						v-if="!isSended"
						class="btn btn-primary w-100 btn-form-ready"
						:disabled="(!form1Valid || isSended)"
						type="button"
						@click="recoverPassword"
						>Отправить</button>
					<button
						v-else
						class="btn btn-primary w-100 btn-form-ready"
						:disabled="true"
						type="button"
						>Отправлено</button>
				</div>

				<!-- footer -->
				<div class="page-form-footer mt-0">
					<div class="text-center mt-5">
						<router-link to="/login" class="page-form-link">войти в существующий аккаунт</router-link>
					</div>
					<div class="text-center mt-12">
						<a href="mailto:support@msdmed.ru" class="page-form-link">Техподдержка: <span class="td-underline">support@msdmed.ru</span></a>
					</div>
				</div>

			</div>
		</div>
  	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
	name: "Register",
	data() {
		return {
			page: 'confirm',
			
			email: '',
			isMerckEmail: false,
			isSended: false,

			inputsValid: {
				email: false,
			},
		}
	},
	computed: {
		form1Valid() {
			return this.inputsValid.email && this.isMerckEmail
		},
		isPasswordMatch() {
			return this.registerPassword === this.registerPasswordRepeat
		},
		...mapState('auth', [
			'message'
		]),
		...mapGetters('auth', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		async recoverPassword() {
			this.isSended = true
			let result = await this.recoverPasswordToMSD(this.email)
			// setInterval(()=>{this.sendConfirmSended=0}, 5000)
		},
		checkEmail(e) {
			this.isMerckEmail = e.target.value.toLowerCase().indexOf('merck.com') != -1
		},
		checkValidity(event) {
			this.inputsValid[event.target.name] = event.target.validity.valid
			this.isSended = false
		},
		setPage(value) {
			this.page = value
		},
		...mapActions('auth', [
			'recoverPasswordToMSD'
		])
	},
}
</script>

<style>

</style>

<style scoped>

</style>