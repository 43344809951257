<template>
  	<div class="page-auth">
		<div id="loginForm" class="page-auth__section">
			<div id="login-form" class="page-form  pb-7 validate justify-content-between">
				<!--autocomplete="off" action="/login.php" method="POST"-->
				<div class="page-form-header">
					<img src="./../assets/images/logo-msd-commerce.svg" />
					<!-- <a href="#" class="btn-close"></a> -->
				</div>
				<div class="page-form-body mb-10">
					<h1 class="page-form-title mb-2">Вход</h1>
					<div class="fsz-sm text-danger mb-6">{{authMessage}}</div>
					<div class="fsz-sm text-muted mb-6">Поля, отмеченные * обязательны к заполнению</div>
					<div class="page-form-group">
						<input
							name="username"
							type="email"
							id="login-email"
							class="page-form-control"
							:class="{'has-value' : username}"
							required
							autocomplete="off"
							@keyup="checkValidity"
							@keypress.enter="login"
							v-model="username"
						>
						<label class="page-form-label" for="login-email">E-mail</label>
					</div>
					<div class="page-form-group mb-3">
						<input
							name="password"
							type="password"
							id="login-password"
							class="page-form-control"
							:class="{'has-value' : password}"
							required
							autocomplete="off"
							@keyup="checkValidity"
							@keypress.enter="login"
							v-model="password"
						>
						<label class="page-form-label" for="login-password">Пароль</label>
					</div>
					<router-link to="/restore" class="page-form-link px-0">Восстановить пароль</router-link>
				</div>
				<div class="page-form-footer mt-0">
					<button
						class="btn btn-primary w-100 btn-form-ready"
						:disabled="!formValid"
						type="button"
						@click="login"
						data-tracking-datalayer='{ "event_category" : "login",  "event_action" : "click"}'
						>Войти</button>
					<div class="text-center mt-5">
						<router-link to="/register" class="page-form-link">создать новый аккаунт</router-link>
					</div>
					<div class="text-center mt-12">
						<a href="mailto:support@msdmed.ru" class="page-form-link">Техподдержка: <span class="td-underline">support@msdmed.ru</span></a>
					</div>
				</div>
			</div>
		</div>
  	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
name: "Login",

data() {
	return {
		username: '',
		password: '',
		inputsValid: {
			username: false,
			password: false
		}
	}
},
computed: {
	formValid() {
		return this.inputsValid.username && this.inputsValid.password
	},

	...mapState('auth', {
		authMessage: state => state.message,
	}),
	...mapGetters('auth', {
		
	}),
	...mapGetters('auth', [
		'isAuth',
		'isLoading',
	]),
},
methods: {
	async login() {
		await this.$store.dispatch('auth/loginByEmail', {email: this.username, password: this.password})
	},
	checkValidity(event) {
		this.inputsValid[event.target.name] = event.target.validity.valid
	},
}
}
</script>

<style>

</style>

<style scoped>

</style>