import {get, post} from './api.js'
import log from '../log'
import authStore from '../store/modules/auth.store.js'

export default {
	async sendError(errorType=null, message=null, email=null, userId=null) {
		let cEmail = email || authStore.state.email || null
		let cUserId = userId || authStore.state.id || null
		log.d(`Api | Error | sendError`, errorType, message, cEmail, cUserId)
		return await post(`error`, {error_type: errorType, message: message, email: cEmail, user_id: cUserId})
	},
}