<template>
	<div
		class="loading__wrapper"
		v-if="isEnabled"
		:style="{minHeight: minHeight}"
		>
		<div class="loading__image">
			<svg class="loading__spinner spinner" width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill="#6ECEB2" fill-rule="evenodd" clip-rule="evenodd" d="M10.185 13.32c-.335.5-.202 1.177.296 1.513a1.085 1.085 0 001.509-.297 9.764 9.764 0 018.13-4.354c5.405 0 9.786 4.396 9.786 9.818s-4.381 9.818-9.785 9.818c-.6 0-1.088.489-1.088 1.091 0 .603.487 1.091 1.088 1.091 6.605 0 11.959-5.373 11.959-12S26.726 8 20.12 8c-4.034 0-7.729 2.021-9.935 5.32z"/>
			</svg>
		</div>
	</div>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex'

export default {
	name: "LoadingBlock",
	props: {
		isEnabled: {
			default: false,
		},
		minHeight: {
			default: '100px',
		}
	},
	components: {},
	
	data() {
		return {
			
		}
	},
}
</script>

<style>
	[v-cloak] { display:none; }
</style>

<style scoped>
	.loading__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		/* min-height: 300px; */
		/* height: 100%; */
		/* width: 100%; */
	}
	.loading__image {
		display: block;
	}
</style>