<template>
	<button @click="scrollTop" class="btn-scroll-top" type="button">
        <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect width="40" height="40" rx="20" fill="#D7D7D7"/>
                <path d="M27.293 19.293a1 1 0 01-1.415 1.415L21 15.83V27a1 1 0 11-2 0V15.83l-4.877 4.877a1 1 0 01-1.415-1.415L20 12l7.293 7.293z" fill="#fff"/>
            </g>
        </svg>
    </button>
</template>

<script>
export default {
	name: "BtnScrollTop",
    methods: {
		scrollTop: function () {
			this.intervalId = setInterval(() => {
				if (window.pageYOffset === 0) {
					clearInterval(this.intervalId)
				}
					window.scroll(150, window.pageYOffset - 50)
			}, 20)
		}
  	},
}
</script>