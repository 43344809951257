<template>
	<div class="container">

		<div class="box pt-0 pb-8">
			<div class="box__body">
				<ul class="box__tab-list border-top-0 mb-5">
					<li v-for="product of orderedMSDuserProducts" :key="product.id">
						<button
							class="box__tab-list-item"
							:class="{'active': product.id == selectedTab}"
							type="button"
							v-html="product.nmtm"
							@click="setActiveTab(product.id)"
							></button>
					</li>
				</ul>

				<div v-if="Object.keys(selectedProduct).length>0 && Object.keys(selectedCalculation).length>0">
					<div class="row">
						<div class="col-6 col-md-4 col-lg-3 col-xl-2">
							<img class="img-fluid bg-gray-100 mb-3 mb-md-0" v-bind:src="productImages[selectedProduct.id]" alt="">
						</div>

						<div class="col-md-8 col-lg-9">
							<h2
								class="mb-4"
								v-html="selectedProduct.nmtm"
								></h2>
							
							<!-- Общая инфа о продукте -->
							<div v-if="selectedProduct.id == 1" class="row my-3">
								<CalcInputBlock
									class="col-6 col-lg-3 my-2 form-item-ui"
									label="Закупочная цена"
									labelClass="fsz-sm"
									:value="getInputValue(selectedProduct.id, 'buyPrice', round(get(selectedCalculation, 'essentialPrice')))"
									@input="(e)=>{setInputValue(selectedProduct.id, 'buyPrice', e.target.value)}"
									unit="₽"
									></CalcInputBlock>

								<CalcInputBlock
									class="col-6 col-lg-3 my-2 form-item-ui"
									label="Розн. цена с НДС, ₽"
									labelClass="fsz-sm"
									:value="getInputValue(selectedProduct.id, 'retailPriceWithVat', round(get(selectedCalculation, 'retailPriceWithVat')))"
									@input="(e)=>{setInputValue(selectedProduct.id, 'retailPriceWithVat', e.target.value)}"
									unit="₽"
									></CalcInputBlock>

								<CalcInputBlock
									class="col-6 col-lg-3 my-2 form-item-ui"
									label="Розн. надбавка, ₽"
									labelClass="fsz-sm"
									:value="getInputValue(selectedProduct.id, 'retailIncrease', round(get(selectedCalculation, 'retailIncrease')))"
									@input="(e)=>{setInputValue(selectedProduct.id, 'retailIncrease', e.target.value)}"
									@focusout="(e)=>{wipeEmptyInputValue(selectedProduct.id, 'retailIncrease', e.target.value)}"
									unit="₽"
									:content="getHintText('retailIncreasePrice')"
									>
										<label slot="label">
											Розн. надбавка, ₽
											<PopoverInfoButton
												
												class="btn-info absolute ml-auto pt-3"
												html="true"
												placement="top"
												:content="getHintText('retailIncreasePrice')"
												></PopoverInfoButton>
										</label>
									</CalcInputBlock>

									
							</div>

							<div v-else class="row my-3">
								<div class="col-6 col-lg-3 my-2">
									<div class="fsz-sm mb-2">Зарег. цена ЖНВЛП*</div>
									<span v-if="selectedCalculation.essentialPrice">
										<b>{{selectedCalculation.essentialPrice | hundredths}}</b> <span>₽</span>
									</span>
									<span v-else>-</span>
								</div>

								<div class="col-6 col-lg-3 my-2">
									<div class="fsz-sm mb-1">Опт. надбавка, %*</div>
									<b>{{selectedCalculation.wholesaleAllowance | hundredths}}</b> <span>%</span>
								</div>

								<div class="col-6 col-lg-3 my-2">
									<div class="fsz-sm mb-1 position-relative">Розн. надбавка, ₽
										<PopoverInfoButton
											class="btn-info absolute ml-auto pt-3"
											html="true"
											placement="top"
											:content="getHintText('retailIncrease')"
											></PopoverInfoButton>
										</div>
									<b>{{selectedCalculation.retailIncrease | hundredths}}</b> <span>₽</span>
								</div>
								

								<div class="col-6 col-lg-3 my-2">
									<div class="fsz-sm mb-1 position-relative">Розн. цена с НДС*
										<PopoverInfoButton
											class="btn-info absolute ml-auto pt-3"
											html="true"
											placement="top"
											:content="getHintText('retailPriceWithVat')"
											></PopoverInfoButton>
										</div>
									<b>{{selectedCalculation.retailPriceWithVat | hundredths}}</b> <span>₽</span>
								</div>

								<div class="col-6 col-lg-3 my-2">
									<div class="fsz-sm mb-1 position-relative">Розн. надбавка, %*</div>
									<b>{{selectedCalculation.retailAllowance | hundredths}}</b> <span>%</span>
								</div>
							</div>

							<!-- Когорты -->
							<div
								class="row my-3"
								v-if="selectedProduct.id && cPatientCategoriesObj[selectedProduct.id]"
								>
								<div
									class="col-12"
									v-if="Object.keys(cPatientCategoriesObj[selectedProduct.id].categories).length==0">
									Данные по когортам отсутствуют
								</div>
								<div
									v-else
									class="col-6 col-lg-3 my-2"
									v-for="category of cPatientCategoriesObj[selectedProduct.id].categories"
									:key="category.id"
									>
										<div
											class="fsz-sm mb-2"
											>{{category.nm}}
												<span v-if="category.sex!=null && category.sex==0">Ж</span>
												<span v-if="category.sex!=null && category.sex==1">М</span>
												<span v-if="category.id==1 || category.id==2 || category.id==4 || category.id==11 || category.id==13">*</span>
											</div>
										<b v-if="category.count>=0">{{category.count | int}}</b>
										<b v-else>-</b>
										чел. в месяц
									</div>
							</div>

						</div>
					</div>

					<hr>
					
					<div class="row justify-content-between mt-6">
						<div class="col-lg-9">

							<!-- Расчеты -->
							<div class="row justify-content-between">

								<!-- Сейчас -->
								<div class="col-md-6 pr-md-6">
									<div class="fw-bold mb-4">Сейчас</div>

									<CalcInputBlock
										:value="getActual(getInputValue(selectedProduct.id, 'purchasedDosesNow', selectedCalculation.purchasedDosesNow))"
										@input="(e)=>{setInputValue(selectedProduct.id, 'purchasedDosesNow', setActual(e.target.value))}"
										unit="доз"
										:info="getHintText('purchasedDosesNow')"
										infoPlacement="right"
										>
											<label
												class="text-primary"
												slot="label"
												for=""
												>Закупленных доз за <PeriodBlock class="text-grey opacity-3" :mode="periodMode" m="месяц" y="год"></PeriodBlock></label>
										</CalcInputBlock>

									<CalcInputBlock
										readonly="readonly"
										label="Охват вакцинацией"
										:value="getSelectedCalculation('vaccinationCoverageNow') | int | noNaN"
										unit="%"
										:info="getHintText('vaccinationCoverageNow')"
										infoPlacement="right"
										></CalcInputBlock>

									<CalcInputBlock
										label="Количество вакцинируемых"
										readonly="readonly"
										:value="getActual(getSelectedCalculation('vaccinedNumberNowYear')) | int | noNaN"
										unit="чел."
										:info="getHintText('vaccinedNumberNowYear')"
										infoPlacement="right"
										></CalcInputBlock>

									<CalcInputBlock
										label="Стоимость услуги"
										:value="getInputValue(selectedProduct.id, 'injectionPriceNow', selectedCalculation.vaccinationPriceNow)"
										@input="(e)=>{setInputValue(selectedProduct.id, 'injectionPriceNow', e.target.value)}"
										unit="₽"
										:info="getHintText('injectionPriceNow')"
										infoPlacement="right"
										></CalcInputBlock>

									<div class="calc-result mt-5">
										<div class="h2 mb-2">Выгода сейчас</div>
										<div class="fsz-sm text-muted mb-4">при вакцинации всех пациентов за <PeriodBlock :mode="periodMode" m="месяц" y="год"></PeriodBlock></div>
										<div class="h1 d-flex align-items-center">
											{{ getActual(selectedCalculation.benefitNow) | int | noNaN | digitSpace1k }}<span class="ml-4">₽</span>
											<PopoverInfoButton
												class="btn-info ml-auto"
												html="true"
												placement="right"
												:content="getHintText('benefitNow')"
												></PopoverInfoButton>
										</div>
									</div>
								</div>

								<!-- После -->
								<div class="col-md-6 pl-md-6">
									<div class="fw-bold mb-4">После</div>

									<CalcInputBlock
										readonly="readonly"
										:value="getActual(getSelectedCalculation('purchasedDosesAfter')) | int | noNaN"
										unit="доз"
										:info="getHintText('purchasedDosesAfter')"
										infoPlacement="right"
										>
											<label
												slot="label"
												for=""
												>Закупленных доз за <PeriodBlock class="text-grey opacity-3" :mode="periodMode" m="месяц" y="год"></PeriodBlock></label>
										</CalcInputBlock>

									<CalcInputBlock
										label="Охват вакцинацией"
										:value="getInputValue(selectedProduct.id, 'vaccinationCoverageAfter', selectedCalculation.vaccinationCoverageAfter)"
										@input="(e)=>{setInputValue(selectedProduct.id, 'vaccinationCoverageAfter', e.target.value)}"
										unit="%"
										inputClass="numeric"
										:info="getHintText('vaccinationCoverageAfter')"
										infoPlacement="right"
										></CalcInputBlock>

									<CalcInputBlock
										label="Количество вакцинируемых"
										readonly="readonly"
										:value="getActual(getSelectedCalculation('vaccinedNumberAfter')) | int | noNaN"
										unit="чел."
										inputClass="numeric"
										:info="getHintText('vaccinedNumberAfter')"
										infoPlacement="right"
										></CalcInputBlock>

									<CalcInputBlock
										label="Стоимость услуги"
										:value="getInputValue(selectedProduct.id, 'injectionPriceAfter', selectedCalculation.vaccinationPriceAfter)"
										@input="(e)=>{setInputValue(selectedProduct.id, 'injectionPriceAfter', e.target.value)}"
										unit="₽"
										inputClass="numeric"
										:info="getHintText('injectionPriceAfter')"
										infoPlacement="right"
										></CalcInputBlock>

									<div class="calc-result calc-result--bg mt-5">
										<div class="h2 mb-2">Выгода после</div>
										<div class="fsz-sm text-muted mb-4">при вакцинации всех пациентов за <PeriodBlock :mode="periodMode" m="месяц" y="год"></PeriodBlock></div>
										<div
											class="h1 mb-0 d-flex align-items-center"
											>{{ getActual(selectedCalculation.benefitAfter) | int | noNaN | digitSpace1k }}<span class="ml-4">₽</span>
											<PopoverInfoButton
												class="btn-info ml-auto"
												html="true"
												placement="right"
												:content="getHintText('benefitAfter')"
												></PopoverInfoButton>
											</div>
									</div>
								</div>
							</div>

							<!-- Итог -->
							<div class="calc-total mt-3">
								<div class="h2 mb-2">Упущенная прибыль</div>
								<div class="row align-items-center">
									<div class="col-md-6">
										<div
											class="font-weight-bold mb-2"
											v-html="selectedProduct.nmtm"
											></div>
										<div
											class="fsz-sm text-muted"
											>при вакцинации всех пациентов за 
											<PeriodBlock :mode="periodMode" m="месяц" y="год"></PeriodBlock>
											</div>
									</div>
									<div class="col-md-6 pl-md-6">
										<div class="h1 d-flex align-items-center">
											{{ getActual(selectedCalculation.lostBenefit) | int | noNaN | digitSpace1k }}<span class="ml-4">₽</span>
											<PopoverInfoButton
												class="btn-info ml-auto"
												html="true"
												placement="right"
												:content="getHintText('lostBenefit')"
												></PopoverInfoButton>
										</div>
									</div>
								</div>
							</div>
							<div class="row justify-content-between mt-3">
								<div class="col-md-6 pl-md-7 my-3">
									<a
										v-if="role_manager_editor"
										class="btn btn-primary btn-icon-width"
										qtype="button"
										@click="saveResult"
										data-tracking-datalayer='{ "event_category" : "form",  "event_action" : "sumbit",  "event_label":"calculation"}'
										>Сохранить расчёт</a>
								</div>
								<div class="col-md-6 my-3">
									<a
										class="btn btn-link btn-link-lg btn-download-green btn-icon-width"
										@click="downloadResult"
										data-tracking-datalayer='{ "event_category" : "document",  "event_action" : "download", "event_label":"calculation"}'
										>скачать результат</a>
								</div>
							</div>
						</div>
						
						<div class="col-lg-3 text-center">
							<div class="switch">
								<input type="checkbox" data-period="" name="period" class="switch-input js-period-toggler" id="period" v-model="periodYM">
								<label class="switch-label" for="period">за месяц</label>
								<label class="switch-label" for="period">за год</label>
								<label class="switch-appearance" for="period"></label>
							</div>
						</div>

					</div>
				</div>

			</div>
		</div>

		<div
			class="page-ssi-block pt-5 pb-1"
			v-if="Object.keys(selectedProduct).length"
			>
			<div
				v-if="!ssi_loading"
				v-html="ssi[selectedProduct.id]"
				class="fsz-xs text-muted"
				></div>
			<div
				v-else
				class="fsz-xs text-muted"
				>Загрузка данных</div>
		</div>

		<div
			class="pt-1 pb-7"
			>
			<div class="fsz-xs text-muted">RU-HPV-00416, 04.2021</div>
		</div>
		
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import PeriodBlock from './blocks/PeriodBlock'
import PopoverInfoButton from './blocks/PopoverInfoButton'
import CalcInputBlock from './blocks/CalcInputBlock'
import productImages from '../assets/images/products/*.png';
import log from '../log'

export default {
	name: "ClinicCalc",
	components: {
		PeriodBlock,
		PopoverInfoButton,
		CalcInputBlock,
	},
	data() {
		return {
			ccId: this.$route.params.id,
			productImages,
			inputValues: {},
			selectedTab: 2,	//TODO: подумать над первым табом по умолчанию
			periodYM: false,
		}
	},
	async beforeMount() {
		if (this.$route.params.productId) {
			this.selectedTab = parseInt(this.$route.params.productId)
		}
		this.loadPatientCategories({id: this.ccId})
		this.loadClinicProductSales(this.ccId)
		this.loadClinicQuestCommon({id: this.ccId})
		this.loadSSI(this.selectedTab)
		if (this.$route.params.visitId) {
			if (this.$route.params.calcId) {
				this.loadСalculations({clinicId: this.ccId, visitId: this.$route.params.visitId, calcId: this.$route.params.calcId})
			} else {
				this.loadСalculations({clinicId: this.ccId, visitId: this.$route.params.visitId})
			}
		}
	},
	mounted() {},
	updated() {
		// TODO: подумать куда это перенести
		$('[data-toggle="popover"]').popover({
			container: 'body'
		});
	},
	computed: {
		periodMode() {
            if (this.periodYM) {return 'm'}
            return 'y'
        },
		calculations() {
			let calculations = {}
			try {
				for (let prId in this.userProducts) {
					let product = this.userProducts[prId]
					calculations[prId] = this.makeCalculation(
						product,
						this.allRegionsObj[this.cClinic.regionId],
						this.cPatientCategoriesObj[product.id].categories,	// TODO: пофиксить can't access property "categories", this.cPatientCategoriesObj[product.id] is undefined
						this.currentActualSells[product.id],
						this.cQuestCommon,
						this.inputValues[prId],
					)
				}
			} catch(err) {
				if (err instanceof TypeError) {
					log.d(err)
  				} else {
					log.e(err)
				}
			}
			return calculations

		},
		
//========
		/**
		 * Цена услуги, включая осмотр для текущей клники
		 */
		currentInjectionPrice() {
			if (this.cQuestCommon && this.cQuestCommon.sales && this.cQuestCommon.sales.injectionPrice) {
				return this.cQuestCommon.sales.injectionPrice
			}
			return null
		},

		/**
		 * Количество пациентов в месяц для текущей клиники
		 */
		currentMonthPatientFlow() {
			if (this.cQuestCommon && this.cQuestCommon.patients) {
				return this.cQuestCommon.patients.monthPatientFlow
			}
			return null
		},

		/**
		 * Актуальные продажи для текущей клиники за прошлый год
		 */
		currentActualSells() {
			let result = {}
			if (Object.keys(this.getSalesFromYear(this.currentYear-1)).length>0) {
				if (Object.keys(this.getSalesFromYear(this.currentYear-1).sell)) {
					let sell = this.getSalesFromYear(this.currentYear-1).sell
					for(let elId in sell) {
						result[elId] = sell[elId]
					}
				}
				if (Object.keys(this.getSalesFromYear(this.currentYear-1).edited)) {
					let edited = this.getSalesFromYear(this.currentYear-1).edited
					for(let elId in edited) {
						if (edited[elId]>0) {
							result[elId] = edited[elId]
						}
					}
				}
			}
			
			return result
		},

		/**
		 * Количество вакцинируемых по продуктам для текущей клиники
		 */
		currentVaccinationCount() {
			let result = {}
			for (let elId in this.cPatientCategoriesObj) {
				result[this.cPatientCategoriesObj[elId].id] = 0
				for(let category of this.cPatientCategoriesObj[elId].categories){
					if (category.orderby<99999) {
						result[this.cPatientCategoriesObj[elId].id] += category.count
					}
				}
			}
			return result
		},
//========
		MSDuserProducts() {
			let resultProducts = {}
			for (let prId in this.userProducts) {
				let el = this.userProducts[prId]
				if (el.is_msd_product) {
					resultProducts[el.id] = el
				}
			}
			return resultProducts
		},
		orderedMSDuserProducts() {
			let products = []
			for (let el in this.MSDuserProducts) {
				products.push(this.MSDuserProducts[el])
			}
			return products.sort((a, b) => (a.orderby > b.orderby) ? 1 : -1)
		},

		/**
		 * Выбранный продукт
		 */
		selectedProduct() {
			if (this.MSDuserProducts[this.selectedTab]) {
				return this.MSDuserProducts[this.selectedTab]
			}
			return {}
		},

		selectedCalculation() {
			let isSelectedTabHasInput = Object.keys(this.inputValues).includes(this.selectedTab.toString())
			if (
				this.cCalculationsObj &&
				this.cCalculationsObj.productsObj &&
				this.cCalculationsObj.productsObj[this.selectedTab] &&
				this.cCalculationsObj.productsObj[this.selectedTab][this.$route.params.calcId] &&
				!isSelectedTabHasInput
			) {
				// console.debug(`selectedCalculation obj`)
				return this.cCalculationsObj.productsObj[this.selectedTab][this.$route.params.calcId]
			}
			if (
				this.cLastCalculations &&
				this.cLastCalculations.lastProducts &&
				this.cLastCalculations.lastProducts[this.selectedTab] &&
				!isSelectedTabHasInput
			) {
				return this.cLastCalculations.lastProducts[this.selectedTab]
			}
			if (this.calculations[this.selectedTab]) {
				return this.calculations[this.selectedTab]
			}
			return {}
		},

		/**
		 * Категории для текущей клиники
		 */
		cPatientCategories() {
			if(this.patientCategories && this.patientCategories[this.$route.params.id]) {
				return this.patientCategories[this.$route.params.id]
			}
			return {}
		},

		/**
		 * Категории для текущей клиники в виде объекта
		 */
		cPatientCategoriesObj() {
			let result = {}
			for(let el in this.cPatientCategories){
				result[this.cPatientCategories[el].id] = this.cPatientCategories[el]
			}
			return result
		},

		/**
		 * Текущая клиника
		 */
		cClinic() {
			if(this.clinics && this.clinics[this.$route.params.id]) {
				return this.clinics[this.$route.params.id]
			}
			return {}
		},

		/**
		 * Получение анкеты для текущей клиники
		 */
		cQuestCommon() {
			if(this.questCommons && this.questCommons[this.$route.params.id]) {
				return this.questCommons[this.$route.params.id]
			}
			return {}
		},

		/**
		 * Получение продаж для текущей клиники
		 */
		cClinicSales() {
			if(this.clinicSales && this.clinicSales[this.ccId]) {
				return this.clinicSales[this.ccId]
			}
			return {}
		},

		/**
		 * Расчетов по текущим продуктам
		 */
		cCalculationsObj() {
			if(
				this.calculationsObj &&
				this.calculationsObj[this.ccId] &&
				this.$route.params.visitId &&
				this.calculationsObj[this.ccId][this.$route.params.visitId]
			) {
				return this.calculationsObj[this.ccId][this.$route.params.visitId]
			}
			return {}
		},

		/**
		 * Получение последних расчетов по текущим продуктам
		 */
		cLastCalculations() {
			if(
				this.lastCalculations &&
				this.lastCalculations[this.ccId] &&
				this.$route.params.visitId &&
				this.lastCalculations[this.ccId][this.$route.params.visitId]
			) {
				return this.lastCalculations[this.ccId][this.$route.params.visitId]
			}
			return {}
		},

		selectedCLastCalculations() {
			if (
				this.cLastCalculations && 
				this.cLastCalculations.lastProducts &&
				this.cLastCalculations.lastProducts[this.selectedTab]
			) {
				return this.cLastCalculations.lastProducts[this.selectedTab]
			}
			return {}
		},

		currentYear() {
			return new Date().getFullYear()
		},

		...mapState('calc', [
			'calc',
		]),
		...mapState('calc', {
			loadedCalculations: 'calculations',
		}),
		...mapGetters('calc', [
			'lastCalculations',
			'calculationsObj',
		]),
		...mapGetters('calc', {
			calc_loading: 'w_isLoading',
			calc_error: 'w_isError',
		}),


		...mapState('clinicPatientCategories', [
			'patientCategories',

			'w_errors',
		]),
		...mapGetters('clinicPatientCategories', [
			'w_isLoading',
			'w_isError',
		]),

		...mapState('clinicProductSales', [
			'clinicSales',

			// 'w_errors',
		]),
		// ...mapGetters('clinicProductSales', [
		// 	'w_isLoading',
		// 	'w_isError',
		// ]),

		...mapState('clinic', [
			'clinic',
			'clinics',
		]),

		...mapState('common', [
			'userProducts',
		]),
		...mapGetters('common', [
			'orderedUserProducts',
			'userRegionsObj',
			'allRegionsObj',
		]),


		...mapState('clinicQuestCommon', [
			'questCommons',

			// 'w_errors',
		]),

		...mapState('ssi', [
			'ssi',
		]),
		...mapGetters('ssi', {
			ssi_loading: 'w_isLoading',
			ssi_error: 'w_isError',
		}),
	},
	methods: {
		round(value) {
			if (value) {
				return Math.round(parseFloat(value)*100)/100
			}
			return value
		},
		setActiveTab(value) {
			this.selectedTab = value
			this.loadSSI(this.selectedTab)
		},

		getHintText(name) {
			let cpt = 'год'
			if (this.periodMode=='m') {cpt = 'месяц'}
			
			let text = name
			switch(name.toLowerCase()) {
				case 'retailIncreasePrice'.toLowerCase(): // Розн. надбавка, ₽
					text=`Розничная цена с НДС - Закупочная цена`
					break
				case 'retailIncrease'.toLowerCase(): //Розн. надбавка, ₽*
					text=`1)&nbsp;Опт. цена без НДС = цена ЖНВЛП + опт. надбавка 2)&nbsp;Розн.&nbsp;надбавка в рублях = оптовая цена без НДС * 15 / 100`
					break
				case 'retailPriceWithVat'.toLowerCase():	//Розн. цена с НДС*
					text=`Оптовая цена с НДС + розничная надбавка + НДС`
					break
				case 'purchasedDosesNow'.toLowerCase():	// Закупленных доз за
					text=`Вводится вручную`
					break
				case 'vaccinationCoverageNow'.toLowerCase():	//Охват вакцинацией
					if (this.selectedProduct.id==1) {
						text=`Закупленных доз в ${cpt}<br>/ (число пациентов до 14 лет * 2<br> + число пациентов с 14 до 45 лет  * 3) * 100%`
					} else if (this.selectedProduct.id==4) {
						text=`Закупленных доз в ${cpt}<br>/ (дети 0-3 мес. * 3<br>+ дети 3-8 меc. * 2) * 100%`
					} else {
						text=`Закупленных доз в ${cpt}<br>/ (число пациентов * 1) * 100%`
					}
					break
				case 'vaccinedNumberNowYear'.toLowerCase():
					text=`Сумма всех пациентов в ${cpt} * охват вакцинацией`
					break
				case 'injectionPriceNow'.toLowerCase():
					text=`Стоимость услуги, включая осмотр (без стоимости вакцины)`
					break
				case 'benefitNow'.toLowerCase():
					text=`(Цена услуги + розничная надбавка) * число закупленных доз (из раздела “Сейчас”)`
					break
				case 'purchasedDosesAfter'.toLowerCase():
					if (this.selectedProduct.id==1) {
						text=`(Число пациентов до 14 лет * 2<br>+ число пациентов с 14 до 45 лет * 3) * охват вакцинацией`
					} else if (this.selectedProduct.id==4) {
						text=`(Дети 0-3 мес. * 3 + дети 3-8 меc.<br>* 2) * охват вакцинацией`
					} else {
						text=`Сумма всех пациентов в ${cpt} * 1<br>* охват вакцинацией`
					}
					break
				case 'vaccinationCoverageAfter'.toLowerCase():
					text=`Вводится вручную`
					break
				case 'vaccinedNumberAfter'.toLowerCase():
					text=`Сумма всех пациентов в ${cpt} * охват вакцинацией`
					break
				case 'injectionPriceAfter'.toLowerCase():
					text=`Стоимость услуги, включая осмотр (без стоимости вакцины)`
					break
				case 'benefitAfter'.toLowerCase():
					text=`(Цена услуги + розничная надбавка) * число закупленных доз (из раздела “После”)`
					break
				case 'lostBenefit'.toLowerCase():
					text=`Выгода после - Выгода сейчас`
					break
			}

			return text
		},

		get(obj, name, defaultValue=null) {
			if (obj && Object.keys(obj).length>0 && obj[name]) {
				return obj[name]
			}
			return defaultValue
		},

		getInputValue(id, name, defaultValue=null) {
			if (this.inputValues && this.inputValues[id] && this.inputValues[id][name]!=undefined) {
				return this.inputValues[id][name]
				// return this.$options.filters.digitSpace1k(this.inputValues[id][name])
			}
			return defaultValue
			// return this.$options.filters.digitSpace1k(defaultValue)
		},

		setInputValue(id, name, value) {
			let result = {}
			result[name] = value.toString().replace(/[^0-9\.]+/g,'')

			// this.inputValues[id] = {...this.inputValues[id], ...result}
			Vue.set(this.inputValues, id, {...this.inputValues[id], ...result})
		},

		wipeEmptyInputValue(id, name, value) {
			let result = {}
			if (value.trim() == '') {
				result[name] = undefined;
			}

			// this.inputValues[id] = {...this.inputValues[id], ...result}
			Vue.set(this.inputValues, id, {...this.inputValues[id], ...result})
		},

		async saveResult() {
			await this.saveCalcAndGetUrl({cid: this.ccId, product: this.selectedProduct, calculations: this.calculations[this.selectedProduct.id]})
		},

		async downloadResult() {
			let url = await this.saveCalcAndGetUrl({cid: this.ccId, product: this.selectedProduct, calculations: this.calculations[this.selectedProduct.id]})
			if (url) {
				this.downloadURI(url, '')
			}
		},

		downloadURI(uri, name) {
			var link = document.createElement("a");
			link.setAttribute('download', name);
			link.href = uri;
			document.body.appendChild(link);
			link.click();
			link.remove();
		},

		getPercent(value, percent) {
			return value * (percent / 100)
		},

		getValueWithPercent(value, percent) {
			return value + this.getPercent(value, percent)
		},

		getSelectedCalculation(prop) {
			return this.selectedCalculation[prop];
			// return this.$options.filters.digitSpace1k(this.selectedCalculation[prop])
		},

		//TODO: patientNumber с коэфицентами в зависимости от продукта

/*
essential-price - Зарег. цена ЖНВЛП
wholesale-allowance - Опт. надбавка, %
retail-allowance - Розн. надбавка, %
retail-increase - Розн. надбавка, ₽
retail-price-with-vat - Розн. цена с НДС, ₽

purchased-doses-now-year - Закупленных доз сейчас
vaccination-coverage-now-year - Охват вакцинацией % сейчас
vaccined-number-now-year - Число вакцинируемых сейчас
vaccination-price-now-year - Цена услуги сейчас

purchased-doses-after-year - Закупленных доз после
vaccination-coverage-after-year - Охват вакцинацией после
vaccined-number-after-year - Число вакцинируемых после
vaccination-price-after-year - Цена услуги после

benefit-now-year - Выгода сейчас сейчас
benefit-after-year - Выгода после после
lost-benefit-year - Упущенная прибыль
*/
		/**
		 * Расчеты для продукта
		 */
		makeCalculation(product, region, categories, sells, quest, input={}) {
			let vat = 11
			let calc = {}

			let defaultInput = {
				buyPrice: null,
				purchasedDosesNow: null,
				vaccinationCoverageAfter: null,
				injectionPriceNow: null,
				injectionPriceAfter: null,
				retailIncrease: null,
				retailPriceWithVat: null,
			}

			input = {...defaultInput, ...input}

			// console.debug(`makeCalculation`,
			// 	'product', product,
			// 	'region', region,
			// 	'categories', categories,
			// 	'sells', sells,
			// 	'quest', quest,
			// 	'input', input,
			// )

			// Общее
			calc.injectionPrice = quest.sales.injectionPrice

			calc.patientFlowMonth = quest.patients.monthPatientFlow
			calc.patientFlowYear = calc.patientFlowMonth * 12

			calc.essentialPrice = parseFloat(product.essential_price) || parseFloat(input.buyPrice)
			calc.wholesaleAllowance = region.wholesale_allowance
			calc.retailAllowance = region.retail_allowance
			calc.retailIncrease = 0
			// calc.retailIncreasePrice = 0
			calc.retailPriceWithVat = 0

			// Оптовая цена
			calc.wholesalePrcie = this.wholesalePrcie(calc.essentialPrice, calc.wholesaleAllowance)
			// Оптовая цена с НДС
			calc.wholesalePrcieWithVat = this.wholesalePrcieWithVat(calc.wholesalePrcie, vat)
			
			// Розничная надбавка в рублях? (на самом деле розначная цена без НДС?)
			calc.retailIncrease = parseFloat(input.retailIncrease) || this.retailIncrease(calc.wholesalePrcie, calc.retailAllowance)
			
			// calc.retailIncreasePrice = this.retailIncreasePrice(calc.wholesalePrcie, calc.retailAllowance)

			// Розничная цена с НДС
			calc.retailPriceWithVat = parseFloat(input.retailPriceWithVat) || this.retailPriceWithVat(calc.wholesalePrcieWithVat, calc.retailIncrease, vat)

			if (product.id == 1) {
				calc.retailPriceWithVat = parseFloat(input.retailPriceWithVat)
				calc.retailIncrease = parseFloat(input.retailIncrease) || parseFloat(input.retailPriceWithVat - calc.essentialPrice)
			}

			let catSumMonth = 0
			for(let category of categories){
				if (category.orderby<99999) {
					catSumMonth += category.count
				}
			}
			calc.categoriesSumMonth = catSumMonth
			calc.categoriesSumYear = catSumMonth*12

			let coefficientCatSumMonth = 0
			let coefficientCatSumYear = 0
			for(let category of categories){
				if (category.orderby<99999) {
					if (product.id == 1) {// Гардасил
						switch (category.id) {
							// Гардасил
							case 15:
							case 16: 
								coefficientCatSumMonth += category.count*2 // 9-13 лет
								coefficientCatSumYear += (category.count*2)*12
								break
							case 17:
							case 18:
								coefficientCatSumMonth += category.count*3 // 14-45 лет
								coefficientCatSumYear += (category.count*3)*12
								break
							default: 
								coefficientCatSumMonth += category.count
								coefficientCatSumYear += category.count*12
						}
					} else if (product.id == 4) {// Ротатек
						switch (category.id) {
							// Ротатек
							case 1:
								coefficientCatSumMonth += category.count*3 // Дети 0-3 месяцев
								coefficientCatSumYear += (category.count*3)*12 // Дети 0-3 месяцев
								break
							case 2:
								coefficientCatSumMonth += category.count*2 // Дети 3-8 месяцев
								coefficientCatSumYear += (category.count*2)*12 // Дети 3-8 месяцев
								break
							default: 
								coefficientCatSumMonth += category.count
								coefficientCatSumYear += category.count*12
						}
					} else {
						coefficientCatSumMonth += category.count
						coefficientCatSumYear += category.count*12
					}
				}
			}

			calc.coefficientCategoriesSumMonth = coefficientCatSumMonth
			calc.coefficientCategoriesSumYear = coefficientCatSumYear
			
			calc.coefficientCategoriesSumActual = calc.coefficientCategoriesSumYear

			calc.purchasedDosesNow = parseFloat(input.purchasedDosesNow) || sells
			calc.vaccinationCoverageNow = this.vaccinationCoverageNow(calc.purchasedDosesNow, calc.coefficientCategoriesSumActual)
			calc.vaccinedNumberNowYear = this.vaccinedNumberNow(calc.categoriesSumYear, calc.vaccinationCoverageNow)
			calc.vaccinationPriceNow = parseFloat(input.injectionPriceNow) || calc.injectionPrice

			calc.vaccinationCoverageAfter = parseFloat(input.vaccinationCoverageAfter) || product.coverage
			calc.vaccinationPriceAfter = parseFloat(input.injectionPriceAfter) || calc.injectionPrice
			calc.purchasedDosesAfter = this.purchasedDosesAfter(calc.coefficientCategoriesSumActual, calc.vaccinationCoverageAfter)
			calc.vaccinedNumberAfter = this.vaccinedNumberAfter(calc.categoriesSumYear, calc.vaccinationCoverageAfter)

			calc.benefitNow = this.benefitNow(calc.vaccinationPriceNow, calc.retailIncrease, calc.purchasedDosesNow)
			calc.benefitAfter = this.benefitAfter(calc.vaccinationPriceAfter, calc.retailIncrease, calc.purchasedDosesAfter)
			calc.lostBenefit = this.lostBenefit(calc.benefitNow, calc.benefitAfter)
			
			return calc
		},

		/**
		 * Оптовая цена без НДС
		 */
		wholesalePrcie(price, wholesaleAllowance) {
			return this.getValueWithPercent(price, wholesaleAllowance)
		},

		/**
		 * Оптовая цена с НДС
		 */
		wholesalePrcieWithVat(wholesalePrcie, vat) {
			return this.getValueWithPercent(wholesalePrcie, vat)
		},

		/**
		 * Розничная надбавка в рублях
		 */
		retailIncrease(wholesalePrcie, retailAllowance) {
			return this.getPercent(wholesalePrcie, retailAllowance)
		},

		/**
		 * Розничная цена с надбавкой в рублях
		 */
		retailIncreasePrice(wholesalePrcie, retailAllowance) {
			return this.getValueWithPercent(wholesalePrcie, retailAllowance)
		},

		/**
		 * Розничная цена с НДС
		 */
		retailPriceWithVat(wholesalePrcieWithVat, retailIncreasePrice, vat) {
			return this.getValueWithPercent(wholesalePrcieWithVat + retailIncreasePrice, vat)
		},

		/**
		 * Закупленные дозы сейчас
		 * Вводится вручную
		 */
		purchasedDosesNow(value) {
			return value
		},

		/**
		 * Охват вакцинацией % сейчас
		 * Закупленных доз в год _сейчас_ / (Число пациентов * _Коэфицент_) * 100%
		 */
		vaccinationCoverageNow(purchasedDoses, patientNumber) {
			return purchasedDoses / patientNumber * 100
		},

		/**
		 * Число вакцинируемых сейчас
		 * Сумма всех пациентов в год * Охват авкцинацией _сейчас_
		 */
		vaccinedNumberNow(allPatientNumber, vaccinationCoverage) {
			return this.getPercent(allPatientNumber, vaccinationCoverage)
		},

		/**
		 * Цена услуги сейчас
		 * Стоимость услуги, включая осмотр (без стоимости вакцины)
		 */
		vaccinationPriceNow(price) {
			return price
		},

		/**
		 * Закупленных доз после
		 * Сумма всех пациентов в год * _Коэфицент_ * охват вакцинацией _после_
		 */
		purchasedDosesAfter(patientNumber, vaccinationCoverage) {
			return this.getPercent(patientNumber, vaccinationCoverage)
		},

		/**
		 * Охват вакцинацией после
		 * Вводится вручную 
		 */
		vaccinationCoverageAfter(value) {
			return value
		},

		/**
		 * Число вакцинируемых после
		 * Сумма всех пациентов в год * охват вакцинацией _после_
		 */
		vaccinedNumberAfter(allPatientNumber, vaccinationCoverage) {
			return this.getPercent(allPatientNumber, vaccinationCoverage)
		},

		/**
		 * Цена услуги после
		 * Стоимость услуги, включая осмотр (без стоимости вакцины)
		 */
		vaccinationPriceAfter(price) {
			return price
		},

		/**
		 * Расчет выгоды
		 */
		benefit(vaccinationPrice, retailIncreasePrice, purchasedDoses) {
			return (parseFloat(vaccinationPrice) + parseFloat(retailIncreasePrice)) * parseFloat(purchasedDoses)
		},

		/**
		 * Выгода сейчас
		 * (Цена услуги * розничная надбавка) * число закупленных доз (из раздела сейчас)
		 */
		benefitNow(vaccinationPrice, retailIncreasePrice, purchasedDoses) {
			return this.benefit(vaccinationPrice, retailIncreasePrice, purchasedDoses)
		},

		/**
		 * Выгода после
		 * (Цена услуги * розничная надбавка) * число закупленных доз (из раздела после)
		 */
		benefitAfter(vaccinationPrice, retailIncreasePrice, purchasedDoses) {
			return this.benefit(vaccinationPrice, retailIncreasePrice, purchasedDoses)
		},

		/**
		 * Упущенная прибыль
		 * Выгода после - Выгода сейчас
		 */
		lostBenefit(benefitNow, benefitAfter) {
			return benefitAfter - benefitNow
		},

		/**
		 * Получение продаж за год для текущей клиники
		 */
		getSalesFromYear(year) {
			let c = this.cClinicSales
			if (!c || !c.sells || !c.sells.years) {return {}}
			let index = c.sells.years.findIndex(el=>el.year==year)
			return c.sells.years[index]
		},



		/**
		 * Переключатель год/месяц
		 */

		y2m(value) {
			return Math.round(value/12)
		},
		m2y(value) {
			return value*12
		},
		getActual(value) {
			if(this.periodMode && this.periodMode.toLowerCase()=="m") {
				// return this.$options.filters.digitSpace1k(this.y2m(value))
				return this.y2m(value)
			}
			return value
		},
		setActual(value) {
			if(this.periodMode && this.periodMode.toLowerCase()=="m") {
				return this.m2y(value)
			}
			return value
		},

		...mapActions('clinicPatientCategories', [
			'loadPatientCategories',
		]),

		...mapActions('clinicQuestCommon', [
			'loadClinicQuestCommon',
		]),

		...mapActions('clinicProductSales', [
		    'loadClinicProductSales',
		]),

		...mapActions('calc', [
			'saveCalcAndGetUrl',
			'loadСalculations',
		]),

		...mapActions('ssi', [
			'loadSSI',
		]),
	},
}
</script>

<style>

</style>

<style scoped>

</style>