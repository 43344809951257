<template>
		<div class="row">
			<div class="col-md-6">
				<Widget
					title="о клинике"
					:isLoading="w_isLoading"
					:isError="w_isError"
					:errors="w_errors"
					loadingMinHeight="200px"
				>
					<div class="fsz-sm mb-4 pr-4">
						<div class="text-muted mb-2">Адрес</div>
						<div v-if="(cClinic.city || cClinic.address)">{{ cClinic.city }}, {{ cClinic.address }}</div>
						<div v-else>-</div>
					</div>
					<div class="fsz-sm mb-4 pr-4">
						<div class="text-muted mb-2">Телефон</div>
						<div v-if="cClinic.tel">{{ cClinic.tel }}</div>
						<div v-else>-</div>
					</div>
					<div class="fsz-sm mb-4 pr-4">
						<div class="text-muted mb-2">Parent ЛПУ</div>
						<div v-if="cClinic.medicalOrganistaionName">{{ cClinic.medicalOrganistaionName }} </div>
						<div v-else>-</div>
					</div>
					<div class="fsz-sm mb-4 pr-4">
						<div class="text-muted mb-2">Parent ID</div>
						<div v-if="cClinic.externalId">{{ cClinic.externalId }}</div>
						<div v-else>-</div>
					</div>
				</Widget>
			</div>
			<div class="col-md-6">
				<Widget
					title="Подразделение и лицензии"
					:isLoading="w_isLoading"
					:isError="w_isError"
					:errors="w_errors"
					loadingMinHeight="200px"
				>
					<div class="form-item-ui mb-7" :class="{ 'has-value': subjectType }">
						<div class="mt-3 mb-4">
							<div class="input-radio mb-1 input-radio-sm">
								<input
									:disabled="!role_manager_editor"
									type="radio"
									id="subject-type-head"
									name="subject-type"
									v-model="subjectType"
									value="head"
									>
								<label class="tt-upcase" for="subject-type-head">Головная организация</label>
							</div>
						</div>
						<div class="my-4">
							<div class="input-radio mb-1 input-radio-sm">
								<input
									:disabled="!role_manager_editor"
									type="radio"
									name="subject-type"
									v-model="subjectType"
									value="branch"
									id="subject-type-branch"
									>
								<label class="tt-upcase" for="subject-type-branch">Филиал</label>
							</div>
						</div>
					</div>

					<div v-if="divisionVaccines" class="form-item-ui"  :class="{ 'has-value': partners || vaccinationLicense }">
						<div class="my-3">
							<div class="input-check input-check-align-center input-check-sm">
								<input
									:disabled="!role_manager_editor"
									type="checkbox"
									name="partners"
									id="partner-msd"
									value="MSD"
									v-model="partners"
									>
								<label for="partner-msd">покрытие MSD</label>
							</div>
						</div>
						<div class="my-3">
							<div class="input-check input-check-align-center input-check-sm">
								<input
									:disabled="!role_manager_editor"
									type="checkbox"
									name="license"
									id="license"
									v-model="vaccinationLicense"
									value="1"
									>
								<label for="license">Лицензия на вакцинацию</label>
							</div>
						</div>
					</div>
				</Widget>
			</div>
		</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Widget from '../Widget'

export default {
    name: "PassportMainInfo",
	components: {
		Widget,
	},
	computed: {
		cClinic() {
			if(this.clinics && this.clinics[this.$route.params.id]) {
				return this.clinics[this.$route.params.id]
			}
			return {}
		},
		partners: {
			get() {
				return this.cClinic.partners === "1"
			},
			set(value) {
				// console.debug(`partners`, value)
				return this.savePassportData({id: this.cClinic.id, partners: value})
			}
		},
		subjectType: {
			get() {
				return this.cClinic.subjectType
			},
			set(value) {
				return this.savePassportData({id: this.cClinic.id, subjectType: value})
			}
		},
		vaccinationLicense: {
			get() {
				return this.cClinic.vaccinationLicense
			},
			set(value) {
				return this.savePassportData({id: this.cClinic.id, vaccinationLicense: value})
			}
		},
		...mapState('clinic', [
			'clinics',
			'w_errors',
		]),
		...mapGetters('clinic', [
			'w_isLoading',
			'w_isError',
		])
	},
	methods: {
		...mapActions('clinic', [
			'savePassportData',
		]),
		// ...mapMutations('clinic', [
		// 	'setSubjectType',
		// 	'setPartners'
		// ])
	}
}
</script>

<style>

</style>

<style scoped>

</style>