<template>
	<!-- :isLoading="w_isLoading" :isError="w_isError" :errors="w_errors" -->
	<div class="row">
		<div class="col-md-4 col-lg-6 my-2">
			<SortControl label="продажи" v-model="dosesDoneSort"></SortControl>
		</div>
		<div class="col-md-4 col-lg-6 my-2">
			<SortControl label="потенциал" v-model="potencialSort"></SortControl>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import SortControl from '../../blocks/SortControl'
import log from '../../../log'

export default {
	name: "ClinicsSortWidget",
	components: {
		SortControl
	},
	async beforeMount() {
	},
	data() {
		return {
		}
	},
	computed: {
		PVASort: {
			get() {
				return this.sortPVA
			},
			set(value) {
				return this.setPVASort(value)
			}
		},
		potencialSort: {
			get() {
				return this.sortPotential
			},
			set(value) {
				return this.setPotencialSort(value)
			}
		},
		dosesDoneSort: {
			get() {
				return this.sortDosesDone
			},
			set(value) {
				return this.setDosesDoneSort(value)
			}
		},
		
		...mapState('clinics', [
			'sortPVA',
			'sortPotential',
			'sortDosesDone',

			'w_errors',
		]),
		...mapGetters('clinics', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		...mapActions('clinics', [
			'setPVASort',
			'setPotencialSort',
			'setDosesDoneSort',
		]),
	},
}
</script>

<style>

</style>

<style scoped>

</style>