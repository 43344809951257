import router from '../../router'
import log from '../../log'
import commonApi from '../../api/common.api'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'
import Vue from 'vue'

const _store = 'Common'

// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],
	w_name: 'Common',

	regions: [],
	products: [],

	userRegions: [],
	selectedUserRegionsId: [],	// ИД выбранного региона, для страниц с выбором множества регионов
	selectedUserRegion: {},	// ИД выбранного региона, для страниц с выбором только 1 региона
	userProducts: {},
	
	headPositions: [],
	distributors: [],
	problems: {},

	systemMessages: [],

	inputSendTimeout: config.inputTimeout,
}

// getters
const getters = {
	w_isLoading,
	w_isError,

	userRegionsObj: state => {
		let obj = {}
		state.userRegions.forEach(el => {
			obj[el.id]=el
		})
		return obj
	},
	allRegionsObj: state => {
		let obj = {}
		state.regions.forEach(el => {
			obj[el.id]=el
		})
		return obj
	},
	userRegionsArr: state => {
		return state.userRegions
	},
	MSDuserProducts: state => {
		let resultProducts = {}
		for (let prId in state.userProducts) {
			let el = state.userProducts[prId]
			if (el.is_msd_product) {
				resultProducts[el.id] = el
			}
		}
		return resultProducts
	},
	orderedUserProducts: state => {
		let products = []
		for (let el in state.userProducts) {
			products.push(state.userProducts[el])
		}
		return products.sort((a, b) => (a.orderby > b.orderby) ? 1 : -1)
	},
	w_il: state => {
		if (state.w_loadingCount>0) {return true}
		return false
	},
}

// actions
const actions = {
	async getCommon({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | Common | Actions | getCommon`, reqObj)
		
		commit('w_loadingPlus')

		// Загрузка регионов
		if(Object.keys(state.userRegions).length === 0) {	// TODO: подумать над кэшированием
			let req = await commonApi.getRegions('user')

			if (!req.ok) {
				// TODO: throw error?
				commit('w_addError', 'Ошибка при загрузке данных')
				return
			}
			let data = req.json.data
			if(req.json.status !== 'ok') {
				// TODO: throw error?
			}

			commit('setUserRegions', data)
			commit('setSelectedUserRegionsId', data)
			commit('userListClinics/setSelectedUserRegionsId', data, {root:true})
			commit('setselectedUserRegion', data[0])
		}

		if(Object.keys(state.regions).length === 0) {	// TODO: подумать над кэшированием
			let req = await commonApi.getRegions('all')

			if (!req.ok) {
				// TODO: throw error?
				commit('w_addError', 'Ошибка при загрузке данных')
				return
			}
			let data = req.json.data
			if(req.json.status !== 'ok') {
				// TODO: throw error?
			}

			commit('setAllRegions', data)
		}

		// Загрузка продуктов
		if(Object.keys(state.userProducts).length === 0) {	// TODO: подумать над кэшированием
			let req = await commonApi.getProducts('user')

			if (!req.ok) {
				// TODO: throw error?
				commit('w_addError', 'Ошибка при загрузке данных')
				return
			}
			let data = req.json.data
			if(req.json.status !== 'ok') {
				// TODO: throw error?
			}
			commit('setUserProducts', data)
		}

		// Загрузка дистрибюторов и должностей
		if(state.headPositions.length === 0 || state.distributors.length === 0) {	// TODO: подумать над кэшированием
			let req = await commonApi.getFormObjects()

			if (!req.ok) {
				// TODO: throw error?
				commit('w_addError', 'Ошибка при загрузке данных')
				return
			}
			let data = req.json.data
			if(req.json.status !== 'ok') {
				// TODO: throw error?
			}

			if (data && data.headPositions) {
				commit('setHeadPositions', data.headPositions)
			}
			if (data && data.distributors) {
				commit('setDistributors', data.distributors)
			}
			if (data && data.problems) {
				commit('setProblems', data.problems)
			}
		}

		commit('w_loadingMinus')
	},
	async getSystemMessages({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | Common | Actions | getSystemMessages`, reqObj)
		commit('getSystemMessages', reqObj)
	},
	async getFormObjects({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | Common | Actions | getForms`, reqObj)
	},

	async setSelectedRegionsId({state, commit}, data) {
		log.d(`Store | ${state.w_name} | Actions | addSelectedRegionId`, data)
		commit('setSelectedUserRegionsId', data)
	},

	async wipeData({state, commit}, data) {
		log.d(`Store | ${state.w_name} | Actions | wipeData`, data)
		commit('wipeData')
	},
}

// mutations
const mutations = {
	setUserRegions(state, data) {
		log.d(`Store | Common | Mutations | setUserRegions` , data)
		state.userRegions = []
		data.forEach((el, i) => {
			state.userRegions[i]=el
		})
	},
	setAllRegions(state, data) {
		log.d(`Store | Common | Mutations | setAllRegions` , data)
		state.regions = []
		data.forEach((el, i) => {
			state.regions[i]=el
		})
	},
	setSelectedUserRegionsId(state, data) {
		log.d(`Store | Common | Mutations | setSelectedUserRegionsId` , data)
		
		state.selectedUserRegionsId = []
		data.forEach((el) => {
			let id = typeof el === 'number' ? el : el.id
			let i = state.selectedUserRegionsId.indexOf(id)

			if (i === -1) {
				state.selectedUserRegionsId.push(id)
			} else {
				state.selectedUserRegionsId.splice(i, 1)
			}
		})
	},

	setselectedUserRegion(state, data) {
		log.d(`Store | Common | Mutations | setselectedUserRegion` , data)

		state.selectedUserRegion = {}
		state.selectedUserRegion = data
	},

	setUserProducts(state, data) {
		log.d(`Store | Common | Mutations | setUserProducts` , data)
		state.userProducts = {}
		data.forEach(el => {
			state.userProducts[el.id]=el
		})
	},
	setHeadPositions(state, data) {
		log.d(`Store | Common | Mutations | setHeadPositions` , data)
		state.headPositions = []
		if (data) {
			data.forEach(el => {
				state.headPositions.push(el)
			})
		}
	},
	setDistributors(state, data) {
		log.d(`Store | Common | Mutations | setDistributors` , data)
		state.distributors = []
		data.forEach(el => {
			state.distributors.push(el)
		})
	},
	setProblems(state, data) {
		log.d(`Store | Common | Mutations | setProblems` , data)
		state.problems = {}
		data.forEach(el => {
			Vue.set(state.problems, el.id, el)
		})
	},

	getSystemMessages(state, data) {
		log.d(`Store | Common | Mutations | getSystemMessages` , data)
		state.systemMessages = []
		data.forEach(el => {
			state.systemMessages.push(el)
		})
	},

	wipeData(state, data) {
		log.d(`Store | ${state.w_name} | Mutations | wipeData` , data)
		state.regions = []
		state.products = []

		state.userRegions = []
		state.selectedUserRegionsId = []
		state.userProducts = {}

		state.headPositions = []
		state.distributors = []
	},

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}