<template>
  	<div class="page-content bg-white" id="pin-container">
    	<div class="container">
    		<h1 class="mb-4">Отчёты</h1>
			<div class="step-list" v-if="Object.keys(userRegionsObj).length>0">
				<div class="step" :class="steps[1].class">
					<div class="step__num">1</div>
					<div class="step__body">
						<div class="fw-bold mb-3">Тип отчёта:</div>
						<div class="row form-item-ui">
							<div class="col-lg-3 my-2" v-if="divisionVaccines">
								<div class="input-radio input-radio-sm">
									<input
										type="radio"
										name="report_type"
										id="report_type_summary"
										required=""
										v-model="selectedReportType"
										value="summary"
										>
									<label class="tt-upcase" for="report_type_summary">Общий</label>
								</div>
							</div>
							<div class="col-lg-3 my-2" v-if="divisionVaccines">
								<div class="input-radio input-radio-sm">
									<input
										type="radio"
										name="report_type"
										id="report_type_PVA"
										required=""
										v-model="selectedReportType"
										value="pva"
										>
									<label class="tt-upcase" for="report_type_PVA">PVA</label>
								</div>
							</div>
							<div class="col-lg-3 my-2" v-if="divisionOnko">
								<div class="input-radio input-radio-sm">
									<input
										type="radio"
										name="report_type"
										id="report_type_summary"
										required=""
										v-model="selectedReportType"
										value="onco"
										>
									<label class="tt-upcase" for="report_type_summary">Общий</label>
								</div>
							</div>
							<div class="col-lg-3 my-2" v-if="divisionVaccines">
								<div class="input-radio input-radio-sm">
									<input
										type="radio"
										name="report_type"
										id="report_type_plans"
										required=""
										v-model="selectedReportType"
										value="vx-plans"
										>
									<label class="tt-upcase" for="report_type_plans">План `22</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="step"
					:class="steps[2].class"
					>
					<div class="step__num">2</div>
					<div class="step__body">
						<div class="fw-bold mb-3">Регионы: {{selectedRegions.length}}</div>
						<ul class="tags-list">
							<li
								v-for="region of userRegionsObj"
								:key="region.id"
								:class="{active: selectedRegions.includes(region.id)}"
								>
								<span class="tag">
									<input
										type="checkbox" 
										v-model="selectedRegions"
										:id="'region_'+region.id"
										:value="region.id"
										:disabled="!steps[1].valid"
									/>
									<label :for="'region_'+region.id">{{region.nm}}</label>
								</span>
							</li>
						</ul>
						<!-- <button class="btn btn-link pl-3 pr-0" type="button">показать больше</button> -->
					</div>
				</div>

				<div class="step"
					:class="steps[3].class"
					>
					<div class="step__num">3</div>
					<div class="step__body">
						<div class="row">
							<div v-if="divisionVaccines" class="col-lg-3 border-right">
								<div class="fw-bold mb-3">Фильтры</div>
								<div class="mb-3">
									<div class="input-check input-check-sm input-check-align-center">
										<input
											type="checkbox"
											name="licence"
											id="licence"
											v-model="isVaccinationLiscense"
											:disabled="!(steps[2].valid && steps[1].valid)"
											>
										<label for="licence">Лицензия на вакцинацию</label>
									</div>
								</div>
								<div class="mb-3">
									<div class="input-check input-check-sm input-check-align-center">
										<input
											type="checkbox"
											name="msd_cover"
											id="msd_cover"
											v-model="isMSDPartner"
											:disabled="!(steps[2].valid && steps[1].valid)"
											>
										<label for="msd_cover">Покрытие MSD</label>
									</div>
								</div>
							</div>
							<div :class="{'col-md-10 col-lg-7' : divisionVaccines, 'col-12' : divisionOnko}">
								<div class="fw-bold mb-3" :class="{'pl-lg-6' : divisionVaccines}">Личные списки</div>
								<div class="row">
									<div
										:class="{'col-md-6 pl-lg-7' : divisionVaccines, 'col-md-4' : divisionOnko}"
										v-for="list in lists"
										:key="list.id"
									>
										<div class="mb-3 d-flex align-items-center">
											<div class="input-check input-check-sm input-check-align-center">
												<input
													type="checkbox"
													:id="'list_' + list.id"
													v-model="selectedUserList"
													:value="list.id"
													:disabled="!(steps[2].valid && steps[1].valid)"
												/>
												<label :for="'list_' + list.id">{{ list.nm }}</label>
											</div>
											<div class="pr-4 pr-lg-8 ml-auto">
												<div
													class="color-sample"
													v-if="list.color != 16777215"
													:style="{backgroundColor: '#' + list.color.toString(16)}"
													>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
			<div class="text-center" v-else>
				<ClinicsForReportNotFoundMessageBlock></ClinicsForReportNotFoundMessageBlock>
			</div>
		</div>

		<div v-scroll="pinToBottom" id="pottom-pin" class="pin py-2">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-auto py-2">
						<button
							class="btn btn-primary btn-download px-9"
							@click="downloadClickHandler"
							type="button"
							:disabled="!(clinicsCounter>0 && selectedReportType && selectedRegions.length>0 && !w_isLoading) || isChanged"
							data-tracking-datalayer='{ "event_category" : "document",  "event_action" : "download", "event_label":"clinics"}'
							>скачать отчет
						</button>
					</div>
					<div class="col-6 py-2">
						<span v-if="w_isLoading">...</span>
                        <span v-else-if="clinicsCounter != 0">выбрано {{clinicsCounter}} ЛПУ</span>
					</div>
				</div>
			</div>
		</div>

  	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ClinicsForReportNotFoundMessageBlock from './blocks/ClinicsForReportNotFoundMessageBlock'
import log from '../log'

export default {
	name: "Reports",
	components: {
		ClinicsForReportNotFoundMessageBlock,
	},
	async beforeMount() {
		await this.loadLists()
		// this.setSelectedRegionsId([])
		this.pinToBottom(null, document.getElementById('pottom-pin'))
	},
	data() {
		return {
			reportType: '',
			isChanged: false,
		}
	},
	computed: {
		steps() {
			return {
				1: {
					class: {
						'active': !this.selectedReportType,
						'completed': !!this.selectedReportType,
					},
					valid: !!this.selectedReportType
				},
				2: {
					class: {
						'active': this.selectedReportType && !(this.selectedRegions.length>0),
						'completed': this.selectedRegions.length>0,
					},
					valid: this.selectedRegions.length>0
				},
				3: {
					class: {
						'active': this.selectedReportType && this.selectedRegions.length>0 && (!(this.selectedUserList.length>0)  && !this.isVaccinationLiscense && !this.isMSDPartner),
						'completed': this.selectedUserList.length>0 || this.isVaccinationLiscense || this.isMSDPartner,
					},
					valid: this.selectedUserList.length>0 || this.isVaccinationLiscense || this.isMSDPartner
				}
			}
		},
		selectedReportType: {
			get() {
				return this.reportType
			},
			set(value) {
				this.isChanged = true
				this.setSearchTimeout()
				return this.reportType = value
			}
		},
		selectedRegions: {
			get() {
				return this.selectedUserRegionsId
			},
			set(value) {
				this.isChanged = true
				this.setSearchTimeout()
				return this.setSelectedRegionsId(value)
			}
		},

		isVaccinationLiscense: {
			get() {
				return this.filterIsVaccinationLicense
			},
			set(value) {
				this.isChanged = true
				return this.setVaccinationLicenseFilter(value)
			}
		},
		isMSDPartner: {
			get() {
				return this.filterIsMSDPartner
			},
			set(value) {
				this.isChanged = true
				return this.setMSDPartnerFilter(value)
			}
		},
		selectedUserList: {
			get() {
				return this.filterSelectedUserList
			},
			set(value) {
				this.isChanged = true
				return this.setSelectedUserListFilter(value)
			}
		},

		...mapState('common', [
			'selectedUserRegionsId',
		]),
		...mapGetters('common', [
			'userRegionsObj',
		]),

		...mapState('clinics', [
			'clinics',
			'clinicsCounter',

			'filterIsVaccinationLicense',
			'filterIsMSDPartner',
			'filterSelectedUserList',

			'w_errors',
		]),
		...mapGetters('clinics', [
			'w_isLoading',
			'w_isError',
		]),

		...mapState('userLists', [
			'lists',
		]),
	},
	methods: {
		pinToBottom(event, el) {
			var $el = $(el);
			var $container = $('#pin-container');

			if (!$container.length) return;

			if (($(window).scrollTop() + $(window).height()) > ($container.offset().top + $container.height() + 160)) {
				$el.addClass('absolute');
				$el.removeClass('fixed');
			} else {
				$el.addClass('fixed');
				$el.removeClass('absolute');
			}
		},

		async downloadClickHandler(e) {
			if (!(this.clinicsCounter>0 && this.selectedReportType && this.selectedRegions.length>0 && !this.w_isLoading)) {
				return
			}
			
			let url = await this.loadReport({
				type: this.selectedReportType,
				regions: this.selectedRegions,
				vaccinationLicense: this.isVaccinationLiscense,
				coverage: this.isMSDPartner,
				lists: this.selectedUserList,
			})

			this.downloadURI(url, '')

		},

		downloadURI(uri, name) {
			var link = document.createElement("a");
			link.setAttribute('download', name);
			link.href = uri;
			document.body.appendChild(link);
			link.click();
			link.remove();
		},

		...mapActions('common', [
			'setSelectedRegionsId'
		]),
		...mapActions('clinics', [
			'setVaccinationLicenseFilter',
			'setMSDPartnerFilter',
			'setSelectedUserListFilter',
			'setSearchTimeout'
		]),
		...mapActions('report', [
			'loadReport'
		]),
		...mapActions('userLists', [
			'loadLists'
		])
	},
	watch: {
		w_isLoading() {
			if (this.w_isLoading == false) {
				this.isChanged = false
			}
		}
	},
}
</script>

<style>

</style>

<style scoped>

</style>