<template>
	<Widget
		title="Когорты"
		:isLoading="w_isLoading || common_w_isLoading"
		:isError="w_isError"
		:errors="w_errors"
		url="quest#quizCohorts"
		>
		<ul class="box__tab-list mb-5">
			<li v-for="product of orderedUserProducts.filter(el=>el.is_msd_product)" :key="product.id">
				<button
					type="button"
					class="box__tab-list-item"
					:class="{'active': product.id == selectedTab }"
					@click="setActive(product.id)"
					>{{product.nm}}</button>
			</li>
		</ul>
		
		<div class="row" v-if="selectedProduct && Object.keys(cPatientCategoriesObj).length > 0 && selectedTab">
			<div class="col-10 col-sm-6 col-md-4">
				<img class="img-fluid bg-gray-100 mb-3 mb-md-0" v-bind:src="productImages[selectedTab]" alt="">
			</div>
			<div class="col-md-8">
				<h2 class="mb-5">
					<span>{{selectedProduct.nm}}</span>
					<div class="fsz-sm text-muted mt-1">{{selectedProduct.description}}</div>
				</h2>
				<div class="mb-2" v-if="cPatientCategoriesObj[selectedTab].categories && cPatientCategoriesObj[selectedTab].categories.length>0">
					<span class="h2 mb-0">{{categoriesSum(cPatientCategoriesObj[selectedTab].categories) | digitSpace1k}}</span>  чел. в месяц
				</div>
				<div class="row" v-if="cPatientCategoriesObj[selectedTab].categories.length > 0">
					<div
						class="col-6 col-sm-4 my-2"
						v-for="category of orderedCategories(cPatientCategoriesObj[selectedTab].categories)"
						:key="category.id"
						>
						<div
							class="fsz-sm opacity-8 mb-1"
							>{{category.nm}}
							<span v-if="category.sex!=null && category.sex==0">(Жен)</span>
							<span v-if="category.sex!=null && category.sex==1">(Муж)</span>
							</div>
						<div v-if="category.count>=0 && category.count != null">{{category.count | digitSpace1k}}</div>
						<div v-else>-</div>
					</div>
				</div>
				<div class="row" v-else>
					<div class="col-12">
						Данные о когортах отсутствуют
					</div>
				</div>
			</div>
		</div>
	</Widget>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Widget from '../Widget'
import productImages from '../../../assets/images/products/*.png';
import log from '../../../log'

export default {
    name: "PatientCategories",
	components: {
		Widget,
	},
	data() {
		return {
			productImages,
			selectedTab: 2,
		}
	},
	async beforeMount() {
		log.d(`PatientCategories | beforeMount`, this.$route.params.id)
		await this.loadPatientCategories({id: this.$route.params.id})
	},
	computed: {
		cPatientCategories() {
			if(this.patientCategories && this.patientCategories[this.$route.params.id]) {
				return this.patientCategories[this.$route.params.id]
			}
			return {}
		},
		cPatientCategoriesObj() {
			let result = {}
			for(let el in this.cPatientCategories){
				result[this.cPatientCategories[el].id] = this.cPatientCategories[el]
			}
			return result
		},
		selectedProduct() {
			return this.userProducts[this.selectedTab]
		},
		...mapState('clinicPatientCategories', [
			'patientCategories',

			'w_errors',
		]),
		...mapGetters('clinicPatientCategories', [
			'w_isLoading',
			'w_isError',
		]),


		...mapState('common', [
			'userProducts',
		]),
		...mapGetters('common', [
			'orderedUserProducts',
		]),
		...mapGetters('common', {
			common_w_isLoading: 'w_isLoading',
		}),
	},
	methods: {
		setActive(value) {
			this.selectedTab = value
		},
		categoriesSum(categories) {
			let sum = 0
			if (categories == undefined) {return 0}
			categories.forEach(el => {
				sum += el.count
			});
			return sum
		},
		orderedCategories(categories) {
			let result = [...categories]
			return result.sort((a, b) => (a.orderby > b.orderby) ? 1 : -1)
		},
		...mapActions('clinicPatientCategories', [
			'loadPatientCategories',
		]),
	}
}
</script>

<style>

</style>

<style scoped>

</style>