import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import clinicApi from '../../api/clinic.api'
import reportApi from '../../api/report.api'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

function mapCalculationNames(d) {
	return {
		id: d["id"],
		visit_id: d["visit_id"],
		product_id: d["product_id"],

		buyPrice: d["essential-price"],
		essentialPrice: d["essential-price"],
		wholesaleAllowance: d["wholesale-allowance"],
		retailAllowance: d["retail-allowance"],
		retailIncrease: d["retail-increase"],
		retailPriceWithVat: d["retail-price-with-vat"],
		
		purchasedDosesNow: d["purchased-doses-now-year"],
		vaccinationCoverageNow: d["vaccination-coverage-now-year"],
		vaccinedNumberNowYear: d["vaccined-number-now-year"],
		
		injectionPriceNow: d["vaccination-price-now-year"], //?
		vaccinationPriceNow: d["vaccination-price-now-year"], //?

		purchasedDosesAfter: d["purchased-doses-after-year"],
		vaccinationCoverageAfter: d["vaccination-coverage-after-year"],
		vaccinedNumberAfter: d["vaccined-number-after-year"],
		vaccinationPriceAfter: d["vaccination-price-after-year"],
		
		benefitNow: d["benefit-now-year"],
		benefitAfter: d["benefit-after-year"],
		lostBenefit: d["lost-benefit-year"],
	}
}

// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],
	w_name: 'Calc',
	w_netStatus: {},
	
	calc: {},
	calculations: {},	// Конкретные рассчеты по продуктам на клинику
}

// getters
const getters = {
	/**
	 * Получение списка последих расчетов из визита.
	 * Так как по каждому продукту может быть несолько расчетов в течение одного визита
	 * 
	 * @param {*} state 
	 * @returns 
	 */
	lastCalculations(state) {
		let calcs = {...state.calculations}
		for (let calcId in calcs) {
			let calc = calcs[calcId]
			for (let visitId in calc) {
				let visit = calc[visitId]
				let lastProducts = {}
				for (let productCalc of visit.products) {
					if (lastProducts[productCalc.product_id]) {
						if (lastProducts[productCalc.product_id].id && lastProducts[productCalc.product_id].id < productCalc.id) {
							lastProducts[productCalc.product_id] = mapCalculationNames(productCalc)
						}
					} else {
						lastProducts[productCalc.product_id] = mapCalculationNames(productCalc)
					}
				}
				calcs[calcId][visitId].lastProducts = lastProducts
			}
		}
		return calcs
	},
	calculationsObj(state) {
		let calcs = {...state.calculations}
		for (let calcId in calcs) {
			let calc = calcs[calcId]
			for (let visitId in calc) {
				let visit = calc[visitId]
				let productsObj = {}
				for (let productCalc of visit.products) {
					if (productsObj[productCalc.product_id]) {
						productsObj[productCalc.product_id][productCalc.id] = mapCalculationNames(productCalc)
					} else {
						productsObj[productCalc.product_id] = {}
						productsObj[productCalc.product_id][productCalc.id] = mapCalculationNames(productCalc)
					}
				}
				calcs[calcId][visitId].productsObj = productsObj
			}
		}
		return calcs
	},

	w_isLoading,
	w_isError,
}

// actions
const actions = {

	async saveCalcAndGetUrl({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | saveCalc`, reqObj)

		commit('w_loadingPlus')
		
		let req = await clinicApi.postCalcHistoryElement(reqObj.cid, reqObj.product.id, reqObj.calculations)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if (req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			if (req.json.error && req.json.error.code && req.json.error.code == '22003') {
				commit('common/w_addError', 'Ошибка при сохранении результата расчетов: Слишком большие числа.', {root: true})
			}
			if (req.json.error && req.json.error.code && req.json.error.code == '19') {
				commit('common/w_addError', 'Ошибка при сохранении результата расчетов: Слишком большие числа.', {root: true})
			}
			commit('w_loadingMinus')
			return
		}
		let visitId = data.visitId

		let url
		if (visitId) {
			url = await reportApi.getCalcUrl({clinicId:reqObj.cid, visitId:visitId, productId:reqObj.product.id})
		}

		// let req2 = await clinicApi.getCalcHistoryElement(visitId)
		// if (!req2.ok) {
		// 	// TODO: throw error?
		// 	commit('w_addError', 'Ошибка при загрузке данных')
		// 	return
		// }
		// let data2 = req2.json.data
		// if(req2.json.status !== 'ok') {
		// 	// TODO: throw error?
		// 	commit('w_addError', 'Ошибка при загрузке данных')
		// 	commit('w_loadingMinus')
		// 	return
		// }

	
		// commit('saveCalc', data)

		commit('w_loadingMinus')
		return url
	},

	async loadСalculations({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${state.w_name} | Actions | loadСalculations`, reqObj)

		commit('w_loadingPlus')
		
		let req = await clinicApi.getCalcHistoryElement(reqObj.clinicId, reqObj.visitId, reqObj.calcId)
		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			commit('w_loadingMinus')
			return
		}

		commit('loadСalculations', data[0])

		commit('w_loadingMinus')
	}

	// async getPDFUrl({state, commit, dispatch}, reqObj) {
	// 	log.d(`Store | ${state.w_name} | Actions | getPDFUrl`, reqObj)

	// 	let downloadUrl = await reportApi.getCalcUrl({clinicId:reqObj.cid, visitId:visitId, productId:reqObj.product.id})
	// }

	// async loadCalc({state, commit, dispatch}, reqObj={}) {
	// 	log.d(`Store | ${state.w_name} | Actions | loadCalc`, reqObj)

	// 	commit('w_loadingPlus')
		
	// 	let req = await clinicApi.getCalcHistoryList(reqObj.id)
	// 	if (!req.ok) {
	// 		// TODO: throw error?
	// 		commit('w_addError', 'Ошибка при загрузке данных')
	// 		return
	// 	}
	// 	let data = req.json.data
	// 	if(req.json.status !== 'ok') {
	// 		if (req.json.error.code == 17) {
	// 			// Расчеты не найдены
	// 			commit('w_loadingMinus')
	// 			return
	// 		}
	// 		// TODO: throw error?
	// 		commit('w_addError', 'Ошибка при загрузке данных')
	// 		commit('w_loadingMinus')
	// 		return
	// 	}

	// 	commit('loadCalcHistory', data)

	// 	commit('w_loadingMinus')
	// },

}


// mutations
const mutations = {
	loadСalculations(state, d) {
		log.d(`Store | ${state.w_name} | Mutations | loadСalculations`, d)

		let calcs = {...state.calculations}
		if(calcs[d.clinicId] == undefined) {
			calcs[d.clinicId] = {}
		}
		if(calcs[d.clinicId][d.visitId] == undefined) {
			calcs[d.clinicId][d.visitId] = {}
		}
		calcs[d.clinicId][d.visitId] = {
			visitId: d.visitId,
			products: d.products,
			visitTime: d.visitTime
		}
		state.calculations = calcs
	},
	
	// loadCalcHistory(state, d) {
	// 	log.d(`Store | ${state.w_name} | Mutations | loadCalcHistory`, d)

	// 	let visits = {}
	// 	d.forEach(el => {
	// 		visits[el.visitId] = {}
	// 		visits[el.visitId].visitId = el.visitId
	// 		visits[el.visitId].products = el.products
	// 		visits[el.visitId].visitTime = el.visitTime
	// 		Vue.set(state.calcHistory, el.clinicId, visits)
	// 	});
	// },

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}