<template>
	<a
		:data-placement="placement"
		:data-content="content"
		:data-html="html"
		tabindex="0"
		data-toggle="popover"
		data-trigger="focus"
		role="button"
		></a>
</template>

<script>
export default {
	name: "PopoverInfoButton",
	props: {
		content: {
			default: '',
		},
		placement: {
			default: 'auto',
		},
		html: {
			default: false,
		}
	},
}
</script>