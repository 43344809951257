<script>
import { Line, mixins } from 'vue-chartjs'
import log from '../../log'

export default {
	name: "VaccineSalesChart",
    extends: Line,
    data() {
        return {
            labels: ['янв', 'фев', 'мар', 'апр', 'май', 'июн'],
        }
    },
    props: {
        chartData: {
            type: Object,
            default: null
        },
        half: {
            type: String,
            default: '1',
        }
    },
    mounted () {
        this.addPlugin({
            id: 'labels-plugin',
            component: this,
            afterDatasetsDraw: function (chart) {
                let c = chart.canvas
                let ctx = c.getContext('2d')
                ctx.font = "11px Invention";
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                ctx.fillStyle = '#fff';
                
                var meta = chart.controller.getDatasetMeta(0);

                meta.data.forEach(function (line, index) {
                    var data = chart.data.datasets[0].data[index];
                    ctx.fillText(data, line._model.x, line._model.y + 18);
                })
            }
        });
        this.drawChart()
    },
    watch: {
        chartData: function() {
            // console.debug('chartData', this.chartData)
            this.dataChageHandler()
        },
        half: function() {
            this.dataChageHandler()
        },
    },
    methods: {
        drawChart() {
            let plan = {
                data: [0,0,0,0,0,0,0],
                borderColor: 'transparent',
                // backgroundColor: 'rgba(110, 206, 178, 0.56)',
                backgroundColor: 'rgba(0, 133, 124, 0.4)',
                pointBackgroundColor: 'transparent',
                pointBorderColor: 'transparent',
            }
            let actual = {
                data: [0,0,0,0,0,0,0],
                borderColor: 'transparent',
                // backgroundColor: 'rgb(12, 35, 64, 0.88)',
                backgroundColor: 'rgba(117, 117, 117, 0.8)',
                pointBackgroundColor: 'transparent',
                pointBorderColor: 'transparent',
            }
            this.renderChart(
                {  
                    labels: this.labels,
                    datasets: [actual, plan],
                },
                {
                    padding: 0,
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {
                        padding: 0
                    },
                    tooltips: {
                        xPadding: 20,
                        yPadding: 10,
                        caretPadding: 8,
                        displayColors: false,
                        backgroundColor: '#0C2340',
                        bodyFontSize: 15,
                        bodyFontColor: '#ffffff',
                        titleFontSize: 12,
                        titleFontColor: '#ffffff',
                        titleMarginBottom: 8,
                        cornerRadius: 4,
                        titleAlign: 'center',
                        bodyAlign: 'center'
                    },
                    legend: {
                        display: false,
                    },
                    elements: {
                        line: {
                            tension: 0.001
                        }
                    },
                    scales: {
                        y: {
                            min: 6
                        },
                        xAxes: [{
                            afterTickToLabelConversion: (scaleInstance) => {
                                scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;
                                // scaleInstance.ticks[0] = null;
                                // scaleInstance.ticksAsNumbers[0] = null;
                            },
                            gridLines: {
                                color: '#c5c5c5',
                                drawTicks: false,
                            },
                            ticks: {
                                align: 'end',
                                labelOffset: 10,
                                fontColor: 'rgba(12, 35, 64, 0.4)',
                                padding: 8,
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                color: '#c5c5c5',
                                drawTicks: false,
                                display: false
                            },
                            ticks: {
                                display: false,
                                count: 6,
                                fontColor: 'rgba(12, 35, 64, 0.4)',
                                padding: 8,
                                min: 1,
                                maxTicksLimit: 6,
                            }
                        }]
                    },
                }
            )
        },
        dataChageHandler() {
            let currentMonth = new Date().getMonth()

            if (this.half==1) {
                this.$data._chart.data.labels =  ['янв', 'фев', 'мар', 'апр', 'май', 'июн', ' ']
            } else {
                this.$data._chart.data.labels =  ['июл', 'авг', 'сен', 'окт', 'ноя', 'дек', ' ']
            }

            for (let monthId = 1; monthId<=12; monthId++) {
                this.$data._chart.data.datasets[0].data[monthId - 1] = null
                this.$data._chart.data.datasets[1].data[monthId - 1] = null
            }

            for (let monthId in this.chartData) {
                if (monthId=='null') {
                    log.e(`monthId=='null' in this.chartData`)
                    continue
                }
                
                let month = this.chartData[monthId]
                let plan = this.chartData.plan
                let planPoint = plan/11

                if ((parseInt(monthId) - 1) > currentMonth) {
                    this.$data._chart.data.datasets[0].data[monthId - 1] = null
                } else {
                    this.$data._chart.data.datasets[0].data[monthId - 1] = month.actual
                }
                // this.$data._chart.data.datasets[1].data[monthId - 1] = month.plan

                if (this.half==1) {
                    for (let el in this.labels) {
                        if (plan) {
                            this.$data._chart.data.datasets[1].data[el] = Math.round(planPoint * el)
                        } else {
                            this.$data._chart.data.datasets[1].data[el] = Math.round(((month.plan/(this.labels.length-1)) * el)/2)
                        }
                    }
                    if (plan) {
                        this.$data._chart.data.datasets[1].data[this.labels.length] = Math.round(plan/2)
                    } else {
                        this.$data._chart.data.datasets[1].data[this.labels.length] = Math.round(month.plan/2)
                    }
                } else {
                    if ((parseInt(monthId) - 1) > currentMonth) {
                        this.$data._chart.data.datasets[0].data[monthId-6 - 1] = null
                    } else {
                        this.$data._chart.data.datasets[0].data[monthId-6 - 1] = month.actual
                    }
                    let hplan = plan/2
                    for (let el in this.labels) {
                        if (plan) {
                            this.$data._chart.data.datasets[1].data[el] = Math.round((planPoint * el)+plan/2)
                        } else {
                            this.$data._chart.data.datasets[1].data[el] = Math.round(hplan+((hplan/(this.labels.length-1)) * el))
                        }
                    }
                    if (plan) {
                        this.$data._chart.data.datasets[1].data[this.labels.length] = Math.round(plan)
                    } else {
                        this.$data._chart.data.datasets[1].data[this.labels.length] = Math.round(month.plan)
                    }
                }
            }

            this.$data._chart.update()
        },
    }
}
</script>

<style>

</style>

<style scoped>
    
</style>