import Vue from 'vue'
import router from '../../router'
import log from '../../log'
import authApi from '../../api/auth.api'
import regionApi from '../../api/regions.api'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
} from '../widgetHelper'

const _name = 'regionsTenders'

// initial state
const state = {
	w_loadingCount: 0,
	w_errors: [],

	tenders: {},
	tendersSum: {},
	tendersCounter: 0,

	currentPage: 0,
}

// getters
const getters = {
	w_isLoading,
	w_isError,
}

// actions
const actions = {
	async loadTendersByRegions({state, commit, dispatch, rootState}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | loadTendersByRegions`, reqObj)
		
		let req = await regionApi.getTenders(reqObj.regions, { page: state.currentPage })

		if (!req.ok) {
			// TODO: throw error?
			commit('w_addError', 'Ошибка при загрузке данных')
			return
		}
		let data = req.json.data
		if(req.json.status !== 'ok') {
			// TODO: throw error?
		}

		commit('setTendersCounter', data[reqObj?.regions]?.length || 0)

		return data
	},

	async getTenders({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | getTenders`, reqObj)
		commit('w_loadingPlus')
		
		commit('setTendersCounter', 0)
		commit('wipeCurrentPage', data)
		let data = await dispatch('loadTendersByRegions', reqObj)
		commit('setTendersByRegions', data)

		commit('w_loadingMinus')
	},

	async getTendersToAdd({state, commit, dispatch}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | getTendersToAdd`, reqObj)
		commit('w_loadingPlus')

		commit('increaseCurrentPage')
		let data = await dispatch('loadTendersByRegions', reqObj)
		commit('addTenders', data)

		commit('w_loadingMinus')
	},

	async loadAllTendersSum({state, commit, dispatch, rootState}, reqObj={}) {
		log.d(`Store | ${_name} | Actions | loadAllTendersSum`, reqObj)
		
		// if (Object.keys(state.epidemiology).length === 0) {
			commit('w_loadingPlus')
			let req = await regionApi.getAllTendersSum()
			if (!req.ok) {
				// TODO: throw error?
				commit('w_addError', 'Ошибка при загрузке данных')
				return
			}
			let data = req.json.data
			if(req.json.status !== 'ok') {
				// TODO: throw error?
			}
			commit('setAllTendersSum', data)
			commit('w_loadingMinus')
		// }
		
	},
}

// mutations
const mutations = {
	setTendersByRegions(state, data) {
		log.d(`Store | ${_name} | Mutations | setTendersByRegions`, data)
		
		Object.keys(data).forEach(el => {
			let tenders = []
			let region = data[el]
			for(let product of region) {
				tenders.push(product)
			}
			Vue.set(state.tenders, el, tenders)
		})
	},

	addTenders(state, data) {
		log.d(`Store | ${_name} | Mutations | addTenders`, data)
		
		Object.keys(data).forEach(el => {
			let region = data[el]
			for(let product of region) {
				state.tenders[el].push(product)
			}
		})
	},

	setAllTendersSum(state, data) {
		log.d(`Store | ${_name} | Mutations | setTendersByRegions`, data)

		Object.keys(data.regions).forEach(el => {
			let resultRegion = {}
			let region = data.regions[el]
			for(let product of region) {
				resultRegion[product.mnm.toLowerCase()] = product
			}
			Vue.set(state.tendersSum, region[0].region_id, resultRegion)
		})
	},
	setTendersCounter(state, count) {
		state.tendersCounter = count
	},
	increaseCurrentPage(state) {
		log.d(`Store | ${_name} | Mutations | increaseCurrentPage`, state.currentPage)
		state.currentPage++
	},
	wipeCurrentPage(state) {
		log.d(`Store | ${_name} | Mutations | wipeCurrentPage`, state.currentPage)
		state.currentPage=0
	},

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}