<template>
	<div :class="{ 'box_loading' : w_isLoading == true, 'box_error' : w_isError == true }">
		
		<div class="box mb-4" v-if="divisionOnko" id="o-patients">
			<div class="box__body">
				<div class="row mt-2 mb-8">
					<div class="col-md-9">
						<h2 class="h1 m-0">Пациенты <span class="opacity-3">(Китруда)</span></h2>
					</div>
					<div class="col-md-3">
						<div class="h1 m-0">
							{{countAllOncoPatientCategory}}
							<span class="fsz-base"> чел.</span>
						</div>
					</div>
				</div>

				<h2 class="mb-5">Пациенты конкурентов</h2>
				<div class="row pr-lg-5">
					<InputBlock
						class="col mb-4"
						inputClass="numeric"
						:value="getPatientCategoryCnt(getCategoryByName('Опдиво').id)"
						@input="e=>{setPatientCategory(getCategoryByName('Опдиво').id, e)}"
						unit="чел."
						>
							<label
								class="text-accent mb-2"
								slot="label"
								:for="`category-Опдиво-m`"
								>Опдиво</label>
					</InputBlock>

					<InputBlock
						class="col mb-4"
						inputClass="numeric"
						:value="getPatientCategoryCnt(getCategoryByName('Бавенсио').id)"
						@input="e=>{setPatientCategory(getCategoryByName('Бавенсио').id, e)}"
						unit="чел."
						>
							<label
								class="text-accent mb-2"
								slot="label"
								:for="`category-Бавенсио-m`"
								>Бавенсио</label>
					</InputBlock>

					<InputBlock
						class="col mb-4"
						inputClass="numeric"
						:value="getPatientCategoryCnt(getCategoryByName('Тецентрик').id)"
						@input="e=>{setPatientCategory(getCategoryByName('Тецентрик').id, e)}"
						unit="чел."
						>
							<label
								class="text-accent mb-2"
								slot="label"
								:for="`category-Тецентрик-m`"
								>Тецентрик</label>
					</InputBlock>

					<InputBlock
						class="col mb-4"
						inputClass="numeric"
						:value="getPatientCategoryCnt(getCategoryByName('Имфинзи').id)"
						@input="e=>{setPatientCategory(getCategoryByName('Имфинзи').id, e)}"
						unit="чел."
						>
							<label
								class="text-accent mb-2"
								slot="label"
								:for="`category-Имфинзи-m`"
								>Имфинзи</label>
					</InputBlock>

					<InputBlock
						class="col mb-4"
						inputClass="numeric"
						:value="getPatientCategoryCnt(getCategoryByName('Фортека').id)"
						@input="e=>{setPatientCategory(getCategoryByName('Фортека').id, e)}"
						unit="чел."
						>
							<label
								class="text-accent mb-2"
								slot="label"
								:for="`category-Фортека-m`"
								>Фортека</label>
					</InputBlock>

				</div>

				<hr>
				<div class="row mb-3 align-items-center justify-content-center">
					<div class="col-md-9">
						<h2 class="m-0">Немелкоклеточный рак лёгкого</h2>
					</div>
					<div class="col-md-3">
						<div class="h2 m-0">
							{{countNMRLOncoPatientCategory}}
							<span class="fsz-base"> чел.</span>
						</div>
					</div>
				</div>
				<div class="row align-items-center mb-2">
					<div class="col-md-4 my-3">Неплоскоклеточный</div>
					<div class="col-md-4 my-3">
						<div class="row align-items-center">
							<div class="col-3 fsz-sm">Моно</div>
							<div class="col-9 pl-0">
								<InputBlock
									class="quiz-item"
									inputClass="numeric"
									:value="getPatientCategoryCnt(getCategoryByName('Немелкоклеточный рак лёгкого|Неплоскоклеточный|Моно').id)"
									@input="e=>{setPatientCategory(getCategoryByName('Немелкоклеточный рак лёгкого|Неплоскоклеточный|Моно').id, e)}"
									unit="чел."
									></InputBlock>
							</div>
						</div>
					</div>
					<div class="col-md-4 my-3">
						<div class="row align-items-center">
							<div class="col-3 fsz-sm">Комбо</div>
							<div class="col-9 pl-0">
								<InputBlock
									class="quiz-item"
									inputClass="numeric"
									:value="getPatientCategoryCnt(getCategoryByName('Немелкоклеточный рак лёгкого|Неплоскоклеточный|Комбо').id)"
									@input="e=>{setPatientCategory(getCategoryByName('Немелкоклеточный рак лёгкого|Неплоскоклеточный|Комбо').id, e)}"
									unit="чел."
									></InputBlock>
							</div>
						</div>
					</div>
				</div>
				<div class="row align-items-center mb-4">
					<div class="col-md-4 my-3">Плоскоклеточный</div>
					<div class="col-md-4 my-3">
						<div class="row align-items-center">
							<div class="col-3 fsz-sm">Моно</div>
							<div class="col-9 pl-0">
								<InputBlock
									class="quiz-item"
									inputClass="numeric"
									:value="getPatientCategoryCnt(getCategoryByName('Немелкоклеточный рак лёгкого|Плоскоклеточный|Моно').id)"
									@input="e=>{setPatientCategory(getCategoryByName('Немелкоклеточный рак лёгкого|Плоскоклеточный|Моно').id, e)}"
									unit="чел."
									></InputBlock>
							</div>
						</div>
					</div>
					<div class="col-md-4 my-3">
						<div class="row align-items-center">
							<div class="col-3 fsz-sm">Комбо</div>
							<div class="col-9 pl-0">
								<InputBlock
									class="quiz-item"
									inputClass="numeric"
									:value="getPatientCategoryCnt(getCategoryByName('Немелкоклеточный рак лёгкого|Плоскоклеточный|Комбо').id)"
									@input="e=>{setPatientCategory(getCategoryByName('Немелкоклеточный рак лёгкого|Плоскоклеточный|Комбо').id, e)}"
									unit="чел."
									></InputBlock>
							</div>
						</div>
					</div>
				</div>

				<hr>
				<div class="row mb-3 align-items-center justify-content-center">
					<div class="col-md-9">
						<h2 class="m-0">Почечноклеточный рак</h2>
					</div>
					<div class="col-md-3">
						<div class="h2 m-0">
							{{parseInt(getPatientCategoryCnt(getCategoryByName('Почечноклеточный рак').id))}}
							<span class="fsz-base"> чел.</span>
						</div>
					</div>
				</div>
				<div class="mb-6">
					<label>Пациентов</label>
					<InputBlock
						class="quiz-item"
						inputClass="numeric"
						:value="getPatientCategoryCnt(getCategoryByName('Почечноклеточный рак').id)"
						@input="e=>{setPatientCategory(getCategoryByName('Почечноклеточный рак').id, e)}"
						unit="чел."
						></InputBlock>
				</div>
				<hr>
				<div class="row mb-3 align-items-center justify-content-center">
					<div class="col-md-9">
						<h2 class="m-0">Рак эндометрия</h2>
					</div>
					<div class="col-md-3">
						<div class="h2 m-0">
							{{countREOncoPatientCategory}}
							<span class="fsz-base"> чел.</span>
						</div>
					</div>
				</div>
				<div class="row mb-4">
					<div class="col-md-4 mb-3">
						<div class="">
							<label>Китруда (MSI-H/dMMR+)</label>
							<InputBlock
								class="quiz-item"
								inputClass="numeric"
								:value="getPatientCategoryCnt(getCategoryByName('Рак эндометрия|Китруда (MSI-H/dMMR+)').id)"
								@input="e=>{setPatientCategory(getCategoryByName('Рак эндометрия|Китруда (MSI-H/dMMR+)').id, e)}"
								unit="чел."
								></InputBlock>
						</div>
					</div>
					<div class="col-md-6 mb-3">
						<div class="">
							<label>Китруда + Ленвантиниб (MSI-S/dMMR)</label>
							<InputBlock
								class="quiz-item"
								inputClass="numeric"
								:value="getPatientCategoryCnt(getCategoryByName('Рак эндометрия|Китруда + Ленвантиниб (MSI-S/dMMR)').id)"
								@input="e=>{setPatientCategory(getCategoryByName('Рак эндометрия|Китруда + Ленвантиниб (MSI-S/dMMR)').id, e)}"
								unit="чел."
								></InputBlock>
						</div>
					</div>
				</div>

				<hr>
				<div class="row mb-3 align-items-center justify-content-center">
					<div class="col-md-9">
						<h2 class="m-0">Рак шейки матки</h2>
					</div>
					<div class="col-md-3">
						<div class="h2 m-0">
							{{parseInt(getPatientCategoryCnt(getCategoryByName('Рак шейки матки').id))}}
							<span class="fsz-base"> чел.</span>
						</div>
					</div>
				</div>
				<div class="mb-6">
					<label>Пациентов</label>
					<InputBlock
						class="quiz-item"
						inputClass="numeric"
						:value="getPatientCategoryCnt(getCategoryByName('Рак шейки матки').id)"
						@input="e=>{setPatientCategory(getCategoryByName('Рак шейки матки').id, e)}"
						unit="чел."
						></InputBlock>
				</div>

				<hr>
				<div class="row mb-3">
					<div class="col-md-9">
						<h2 class="m-0">Опухоли головы и шеи</h2>
					</div>
					<div class="col-md-3">
						<div class="h2 m-0">
							{{
								parseInt(getPatientCategoryCnt(getCategoryByName('Опухоли головы и шеи|Моно').id))+
								parseInt(getPatientCategoryCnt(getCategoryByName('Опухоли головы и шеи|Комбо').id))
							}}
						<span class="fsz-base"> чел.</span></div>
					</div>
				</div>
				<div class="row align-items-center mb-4">
					<div class="col-md-4 my-3">Терапия</div>
					<div class="col-md-4 my-3">
						<div class="row align-items-center">
							<div class="col-3 fsz-sm">Моно</div>
							<div class="col-9 pl-0">
								<InputBlock
									class="quiz-item"
									inputClass="numeric"
									:value="getPatientCategoryCnt(getCategoryByName('Опухоли головы и шеи|Моно').id)"
									@input="e=>{setPatientCategory(getCategoryByName('Опухоли головы и шеи|Моно').id, e)}"
									unit="чел."
									></InputBlock>
							</div>
						</div>
					</div>
					<div class="col-md-4 my-3">
						<div class="row align-items-center">
							<div class="col-3 fsz-sm">Комбо</div>
							<div class="col-9 pl-0">
								<InputBlock
									class="quiz-item"
									inputClass="numeric"
									:value="getPatientCategoryCnt(getCategoryByName('Опухоли головы и шеи|Комбо').id)"
									@input="e=>{setPatientCategory(getCategoryByName('Опухоли головы и шеи|Комбо').id, e)}"
									unit="чел."
									></InputBlock>
							</div>
						</div>
					</div>
				</div>

				<hr>
				<div class="row mb-3 align-items-center justify-content-center">
					<div class="col-md-9">
						<h2 class="m-0">Меланома</h2>
					</div>
					<div class="col-md-3">
						<div class="h2 m-0">
							{{countMelOncoPatientCategory}}
							<span class="fsz-base"> чел.</span>
						</div>
					</div>
				</div>
				<div class="row mb-4">
					<div class="col-md-4 mb-3">
						<div class="">
							<label>Метастатические</label>
							<InputBlock
								class="quiz-item"
								inputClass="numeric"
								:value="getPatientCategoryCnt(getCategoryByName('Меланома|Метастатические').id)"
								@input="e=>{setPatientCategory(getCategoryByName('Меланома|Метастатические').id, e)}"
								unit="чел."
								></InputBlock>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="">
							<label>Адъювант</label>
							<InputBlock
								class="quiz-item"
								inputClass="numeric"
								:value="getPatientCategoryCnt(getCategoryByName('Меланома|Адъювант').id)"
								@input="e=>{setPatientCategory(getCategoryByName('Меланома|Адъювант').id, e)}"
								unit="чел."
								></InputBlock>
						</div>
					</div>
				</div>

				<hr class="mt-2">
				<div class="row mb-3 align-items-center justify-content-center">
					<div class="col-md-9">
						<h2 class="m-0">Другие показания</h2>
					</div>
					<div class="col-md-3">
						<div class="h2 m-0">
							{{parseInt(getPatientCategoryCnt(getCategoryByName('Другие показания').id))}}
							<span class="fsz-base"> чел.</span>
						</div>
					</div>
				</div>
				<div class="mb-4">
					<label>Пациентов</label>
					<InputBlock
						class="quiz-item"
						inputClass="numeric"
						:value="getPatientCategoryCnt(getCategoryByName('Другие показания').id)"
						@input="e=>{setPatientCategory(getCategoryByName('Другие показания').id, e)}"
						unit="чел."
						></InputBlock>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../../../log'
import BoxCollapse from "../../blocks/BoxCollapse"
import InputBlock from '../../blocks/InputBlock'

export default {
	name: "QuestOncoPatientCategories",
	components: {
		BoxCollapse,
		InputBlock,
	},
	data() {
		return {
			ccId: this.$route.params.id,
		}
	},
	async beforeMount() {
		log.d(`QuestOncoPatientCategories | beforeMount`, this.$route.params.id)
		
		await this.loadPatientCategories({id: this.$route.params.id})
	},
	computed: {
		...mapState('clinicQuestPatientCategory', [
			'patientCategories',

			'w_errors',
		]),
		...mapGetters('clinicQuestPatientCategory', [
			'w_isLoading',
			'w_isError',
		]),

		...mapState('common', [
			'inputSendTimeout',
		]),
		...mapGetters('common', [

		]),

		/**
		 * Получение категорий для текущей клиники
		 */
		cPatientCategories() {
			if(this.patientCategories && this.patientCategories[this.ccId]) {
				return this.patientCategories[this.ccId]
			}
			return {}
		},
		
		/**
		 * Получение объекта patients анкеты для текущей клиники
		 */
		orderedCPatientCategories() {
			let result = []
			for (let el in this.cPatientCategories) {
				result.push(this.cPatientCategories[el])
			}
			return result.sort((a, b) => (a.orderby > b.orderby) ? 1 : -1)
		},

		countAllOncoPatientCategory() {
			return parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Немелкоклеточный рак лёгкого|Неплоскоклеточный|Моно').id)) +
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Немелкоклеточный рак лёгкого|Неплоскоклеточный|Комбо').id)) + 
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Немелкоклеточный рак лёгкого|Плоскоклеточный|Моно').id)) + 
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Немелкоклеточный рак лёгкого|Плоскоклеточный|Комбо').id)) +
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Почечноклеточный рак').id)) +
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Рак эндометрия|Китруда (MSI-H/dMMR+)').id)) +
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Рак эндометрия|Китруда + Ленвантиниб (MSI-S/dMMR)').id)) +
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Рак шейки матки').id)) +
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Опухоли головы и шеи|Моно').id)) +
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Опухоли головы и шеи|Комбо').id)) +
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Меланома|Метастатические').id)) +
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Меланома|Адъювант').id)) +
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Другие показания').id))
		},

		countNMRLOncoPatientCategory() {
			return parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Немелкоклеточный рак лёгкого|Неплоскоклеточный|Моно').id)) +
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Немелкоклеточный рак лёгкого|Неплоскоклеточный|Комбо').id)) + 
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Немелкоклеточный рак лёгкого|Плоскоклеточный|Моно').id)) + 
				parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Немелкоклеточный рак лёгкого|Плоскоклеточный|Комбо').id))
		},

		countREOncoPatientCategory() {
			return parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Рак эндометрия|Китруда (MSI-H/dMMR+)').id)) +
			parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Рак эндометрия|Китруда + Ленвантиниб (MSI-S/dMMR)').id))
		},

		countMelOncoPatientCategory() {
			return parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Меланома|Метастатические').id)) +
			parseInt(this.getPatientCategoryCnt(this.getCategoryByName('Меланома|Адъювант').id))
		},


		cQuestOnco() {
			if(this.questOnco && this.questOnco[this.$route.params.id]) {
				return this.questOnco[this.$route.params.id]
			}
			return {}
		},

		/**
		 * Уровень вакцинации в школе
		 */
		schoolVaccinationLevel: {
			get() {
				return this.cQuestCommonPatients.schoolVaccinationLevel
			},
			set(value) {
				return this.saveSchoolVaccinationLevel({clinicId: this.ccId, value: value})
			}
		},
	},

	methods: {
		...mapActions('clinicQuestPatientCategory', [
			'loadPatientCategories',
			'savePatientCategory',
		]),
		getPatientCategoryCnt(categoryId) {
			let cnt =  this.orderedCPatientCategories?.find(category => category.id === categoryId)?.cnt

			return cnt ? cnt : 0
			// return this.$options.filters.digitSpace1k(cnt)
		},

		getCategoryByName(name) {
			for (let cid in this.cPatientCategories) {
				if (this.cPatientCategories[cid].nm.toLowerCase() == name.toLowerCase()) {
					return this.cPatientCategories[cid]
				}
			}
			return false
		},

		getCategoryIdByName(name) {
			return this.getCategoryByName(name)?.id
		},

		/**
		 * Сохранение данных о категории пациентов (когорты) в клинике
		 */
		setPatientCategory(categoryId, value) {
			this.savePatientCategory({clinicId: this.ccId, categoryId: categoryId, value: value.replace(/\s+/g,'')})
		},
	}
}
</script>

<style>

</style>

<style scoped>

</style>