<template>
	<div class="">
		<div class="mt-3 mb-6">
			<h1 class="mt-10 mb-4">Тендерная статистика</h1>
			<div class="table-responsive">
				<!-- <LoadingBlock :isEnabled='w_isLoading'></LoadingBlock> -->
				<transition name="fade">
					<div class="loader" v-if="w_isLoading">
						<div class="loader-spinner">
							<svg class="spinner" width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill="#6ECEB2" fill-rule="evenodd" clip-rule="evenodd" d="M10.185 13.32c-.335.5-.202 1.177.296 1.513a1.085 1.085 0 001.509-.297 9.764 9.764 0 018.13-4.354c5.405 0 9.786 4.396 9.786 9.818s-4.381 9.818-9.785 9.818c-.6 0-1.088.489-1.088 1.091 0 .603.487 1.091 1.088 1.091 6.605 0 11.959-5.373 11.959-12S26.726 8 20.12 8c-4.034 0-7.729 2.021-9.935 5.32z"/>
							</svg>
						</div>
					</div>
				</transition>
				<table
					v-if="cTenders.length>0"
					class="table table-tender-stats" :class="tableClass">
					<tr>
						<th>Молекула</th>
						<th>Номер тендера</th>
						<th>Дата (отыграно)</th>
						<th>Заказчик</th>
						<th>Адрес заказа</th>
						<th>Отыграно</th>
						<th></th>
					</tr>
					<tr v-for="(tender, index) of cTenders" :key="tender.id">
						<td class="tt-upcase">{{tender.mnm}}</td>
						<td>{{tender.number}}</td>
						<td>{{tender.dt | moment("DD.MM.YY")}}</td>
						<td>{{tender.customer_nm}}</td>
						<td>{{tender.customer_address}}</td>
						<td class="fsz-base">{{tender.cnt | digitSpace1k}}</td>
						<td>
							<a :href="tender.source" target="_blank" class="btn-link-lg text-primary tt-upcase d-flex align-items-center">
								показать
								<svg class="ml-2" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path  fill="#00857C" d="M17.657 16.656a1 1 0 01-2.001 0V9.758l-7.899 7.899a1 1 0 01-1.414-1.414l7.899-7.899H7.344a1 1 0 010-2h10.313v10.312z"/>
								</svg>
							</a>
						</td>
					</tr>
				</table>
				<div
					v-if="cTenders.length==0"
					class="text-center no-data"
					>Данных пока нет</div>
			</div>
			<a
				v-if="tendersCounter >= 15"
				class="page-btn"
				@click="getTendersToAdd({regions: selectedUserRegion.id})"
				>Показать ещё</a>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../../log'
import LoadingBlock from './LoadingBlock.vue'

export default {
	name: "RegionTenderStat",

	components: {
		LoadingBlock
	},

	async beforeMount() {
		log.d(`RegionOncoSales | beforeMount`)
		if (this.selectedUserRegion?.id) {
			await this.getTenders({regions: this.selectedUserRegion.id})
		} else if (this.userRegions && this.userRegions[0]) {
			await this.getTenders({regions: this.userRegions[0].id})
		}
	},
	data() {
		return {
			activeTab: 0,
			planAll: 0,
			actualAll: 0,
			selectedHalfYear: (new Date().getMonth()>5)?1:0,
		}
	},
	props: {
		tableClass: {
			type: String,
			default: null
		}
	},
	computed: {
		...mapState('common', [
			'userRegions',
			'selectedUserRegion',
		]),
		...mapState('regionsTenders', [
			'tenders',
			'tendersCounter'
		]),
		...mapGetters('regionsTenders', [
			'w_isLoading',
			'w_isError'
		]),
		...mapState('auth', {
			userId :'id',
		}),
		selectedRegion: {
			get() {
				return this.selectedUserRegion
			},
			set(value) {
				return this.setselectedUserRegion(value)
			}
		},
		cTenders() {
			if (this.selectedUserRegion && this.tenders[this.selectedUserRegion.id]) {
				return this.tenders[this.selectedUserRegion.id]
			}
			return []
		},
	},
	methods: {
		...mapMutations('common', [
			'setselectedUserRegion',
		]),
		...mapActions('regionsTenders', [
			'getTenders',
			'getTendersToAdd'
		]),
	},
	watch: {
        selectedUserRegion() {
			if (this.selectedUserRegion && this.selectedUserRegion.id) {
				this.getTenders({regions: this.selectedUserRegion.id})
			}
        },
    },
	filters: {
		formatDate1: d => d.toLocaleString('ru-RU').replace(',', '').slice(0, -3),
	},
}
</script>

<style>

</style>

<style scoped>
	.table-responsive {
		position: relative;
	}
	.loader {
		position: absolute;
		top:0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.8);
		backdrop-filter: blur(4px);
	}
	.loader-spinner {
		position: sticky;
		top: 100px;
		left: 50px;
		z-index: 50;
		text-align: center;
		overflow: hidden;
	}

	.fade-leave-active {
		transition: opacity .2s;
	}
	.fade-leave-to {
		opacity: 0;
	}
	.no-data {
		min-height: 70px;
		overflow: hidden;
	}
</style>