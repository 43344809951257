import authApi from './../../api/auth.api'
import router from './../../router'
import log from './../../log'
import tealium from '../../tealium'
import errorApi from '../../api/error.api'

import config from '../../config.loader'
import {
	w_addError, w_clearError, w_loadingPlus, w_loadingMinus,
	w_isLoading, w_isError,
	w_wipeTimer, w_setTimer, 
} from '../widgetHelper'

// initial state
const state = {
	loadingCount: 0,

	w_loadingCount: 0,
	w_errors: [],
	w_name: 'Auth',
	w_netStatus: {},


	id: 0,
	KiloEchoYankee: '',
	name: '',
	email: '',
	role: '',
	msdRole: '',
	unit: '',
	divisions: [],
	roles: [],
	message: '',
}

// getters
const getters = {
	w_isLoading,
	w_isError,
	
	displayName: (state, getters) => {
		if (getters.isAuth) {
			return state.name
		}
		return 'Неавторизован'
	},

	isAuth: state => {
		if (state.id > 0 && state.KiloEchoYankee !== '' && state.name !== '' && state.email !== '') {return true}
		return false
	},
	
	isLoading: state => {
		if (state.loadingCount>0) {return true}
		return false
	}
}

// actions
const actions = {
	async loginByEmail({commit, dispatch, state}, loginData) {
		log.d(`Store | ${state.w_name} | Actions | loginByEmail`)
		commit('loadingPlus')
		let req = await authApi.loginByEmail(loginData.email, loginData.password)
		if(req && req.ok) {
			let data = req.json.data
			if(data && (req.json.status === 'ok' || req.json.status === 'success')) {
				if(data.user == undefined || data.user.id == undefined) {
					commit('setMessage', 'Ошибка при авторизации')
					commit('loadingMinus')
					tealium.sendForData({ "event_category" : "login",  "event_action" : "fail", "event_label" : "Ошибка при авторизации"})
					return
				}
				tealium.sendForData({ "event_category" : "login",  "event_action" : "success"})
				await dispatch('saveAuthData', {
					id: data.user.id, 
					KiloEchoYankee: data.token, 
					name: data.user.name,
					email: data.user.email,
					role: data.user.role,
					msdRole: data.user.roleMSDmed,
					divisions: data.user.divisions,
					roles: data.user.roles,
				})
				await dispatch('common/getCommon', {}, {root:true})
				router.push({path:'/'}).catch(()=>{})
			} else {
				tealium.sendForData({ "event_category" : "login",  "event_action" : "fail", "event_label" : "Почта или пароль не совпадают"})
				commit('setMessage', 'Почта или пароль не совпадают. Пожалуйста, попробуйте ещё раз')
				if (req.json.status=='error' && req.json.error && req.json.error.message) {
					errorApi.sendError('auth error', `Status: ${req.json.status}. Message: ${req.json.error.message}.`, loginData.email)
				}
			}
		} else {
			tealium.sendForData({ "event_category" : "login",  "event_action" : "fail", "event_label" : "Непредвиденная ошибка при авторизации"})
			commit('setMessage', 'Непредвиденная ошибка при авторизации')
		}
		commit('loadingMinus')
	},

	async signupToMSD({commit, dispatch, state}, loginData) {
		log.d(`Store | ${state.w_name} | Actions | signupToMSD`)

		commit('loadingPlus')

		let req = await authApi.signupToMSD(
			loginData.email,
			loginData.first_name,
			loginData.patronymic,
			loginData.last_name,
			loginData.password)

		if(req && req.ok) {
			let data = req.json.data
			if(data && (req.json.status === 'ok' || req.json.status === 'success')) {
				// router.push({path:'/register-confirm'}).catch(()=>{})
				commit('loadingMinus')
				tealium.sendForData({ "event_category" : "registration",  "event_action" : "success"})
				return 'confirm'
			} else {
				if (req.json.error.code==18) {
					commit('setMessage', 'Извините, это имя пользователя уже существует')
					errorApi.sendError('error', `Ошибка при регистрации. Имя пользователя уже существует. Status: ${req.json?.status}. Message: ${req.json?.error?.message}.`)
				} else {
					commit('setMessage', 'Ошибка при регистрации')
					errorApi.sendError('error', `Ошибка при регистрации. Status: ${req.json?.status}. Message: ${req.json?.error?.message}.`)
				}
				tealium.sendForData({ "event_category" : "registration",  "event_action" : "fail"})
			}
		} else {
			commit('setMessage', 'Ошибка при регистрации')
			tealium.sendForData({ "event_category" : "registration",  "event_action" : "fail"})
		}

		commit('loadingMinus')
	},
	async sendConfirmAgainToMSD({dispatch, getters, state, commit}, email) {
		log.d(`Store | ${state.w_name} | Actions | sendConfirmAgainToMSD`)

		commit('loadingPlus')
		let req = await authApi.sendConfirmAgainToMSD(email)

		if(req && req.ok) {
			let data = req.json.data
			if(data && (req.json.status === 'ok' || req.json.status === 'success')) {
				commit('loadingMinus')
				return true
			} else {
				commit('setMessage', 'Ошибка при повторной отправке подтверждения')
				errorApi.sendError('error', `Ошибка при повторной отправке подтверждения. Status: ${req.json?.status}. Message: ${req.json?.error?.message}.`)
			}
		} else {
			commit('setMessage', 'Ошибка при повторной отправке подтверждения')
		}

		commit('loadingMinus')
	},


	async recoverPasswordToMSD({dispatch, getters, state, commit}, email) {
		log.d(`Store | ${state.w_name} | Actions | recoverPasswordToMSD`)
		
		commit('loadingPlus')
		let req = await authApi.recoverPasswordToMSD(email)

		if(req && req.ok) {
			let data = req.json.data
			if(data && (req.json.status === 'ok' || req.json.status === 'success')) {
				commit('loadingMinus')
				return true
			} else {
				commit('setMessage', 'Ошибка при восстановлении пароля')
				errorApi.sendError('error', `Ошибка при восстановлении пароля. Status: ${req.json?.status}. Message: ${req.json?.error?.message}.`)
			}
		} else {
			commit('setMessage', 'Ошибка при восстановлении пароля')
		}

		commit('loadingMinus')
	},

	async checkIsAuth({dispatch, getters, state, commit}) {
		log.d(`Store | ${state.w_name} | Actions | checkIsAuth`)
		if (getters.isAuth && state.KiloEchoYankee && state.KiloEchoYankee!=='undefined') {
			commit('loadingPlus')
			let req = await authApi.getUser(state.KiloEchoYankee)
			if(req.ok) {
				let data = req.json.data
				if(data && req.json.status === 'ok') {
					await dispatch('saveAuthData', {
						id: data.user.id, 
						KiloEchoYankee: state.KiloEchoYankee, 
						name: data.user.name,
						email: data.user.email,
						role: data.user.role,
						msdRole: data.user.roleMSDmed,
						divisions: data.user.divisions,
						roles: data.user.roles,
					})
					await dispatch('common/getCommon', {}, {root:true})
					commit('loadingMinus')
					return true
				}
			}
		}
		await dispatch('setAuthData')
		commit('loadingMinus')
		return false
	},

	async logout({commit, dispatch, state}) {
		log.d(`Store | ${state.w_name} | Actions | logout`)
		commit('loadingPlus')
		await dispatch('saveAuthData')
		//TODO: Отправить запрос на удаление токена
		
		await dispatch('common/wipeData', {}, {root:true})

		commit('loadingMinus')
		router.push({path:'/login'}).catch(()=>{})
	},

	async loadFromStorage({commit, state, dispatch}) {
		log.d(`Store | ${state.w_name} | Actions | loadFromStorage`)
		commit('loadingPlus')
		let storagedData = await authApi.loadFromStorage()
		await dispatch('setAuthData', {id: storagedData.id, KiloEchoYankee: storagedData.KiloEchoYankee, name: storagedData.name, email: storagedData.email, divisions: storagedData.divisions})
		commit('loadingMinus')
	},

	async setAuthData({commit, dispatch, state}, data) {
		log.d(`Store | ${state.w_name} | Actions | setAuthData`, data)
		if (data) {
			commit('setId', data.id)
			commit('setKiloEchoYankee', data.KiloEchoYankee)
			commit('setName', data.name)
			commit('setEmail', data.email)
			commit('setAdditionalData', data)
		} else {
			commit('wipeData')
		}
	},

	async saveAuthData({commit, dispatch, state}, data) {
		log.d(`Store | ${state.w_name} | Actions | saveAuthData`, data)
		if (data) {
			await dispatch('setAuthData', {
				id: data.id,
				KiloEchoYankee: data.KiloEchoYankee,
				name: data.name,
				email: data.email,
				role: data.role,
				msdRole: data.msdRole,
				divisions: data.divisions,
				roles: data.roles,
			})
			await authApi.saveToStorage(data.id, data.KiloEchoYankee, data.name, data.email, data.divisions)
		} else {
			commit('wipeData')
			await authApi.saveToStorage(0, '', '', '', '')
		}
	},

	// async loadingPlus({commit}) {
	// 	commit('loadingPlus')
	// },
	
	// async loadingMinus({commit}) {
	// 	commit('loadingMinus')
	// },

}

// mutations
const mutations = {
	setId(state, id) {
		state.id = id
	},
	setName(state, name) {
		state.name = name
	},
	setKiloEchoYankee(state, KiloEchoYankee) {
		state.KiloEchoYankee = KiloEchoYankee
	},
	setEmail(state, email) {
		state.email = email
	},
	setRole(state, role) {
		state.role = role
	},
	setUnit(state, unit) {
		state.unit = unit
	},
	setMessage(state, message) {
		state.message = message
	},

	setAdditionalData(state, data) {
		state.role = data.role
		state.roles = data.roles
		state.msdRole = data.msdRole
		state.divisions = data.divisions
	},

	loadingPlus(state) {
		state.loadingCount++;
	},
	loadingMinus(state) {
		state.loadingCount--;
		if (state.loadingCount<0) {state.loadingCount = 0}
	},
	wipeData(state) {
		state.id = 0
		state.name = ''
		state.KiloEchoYankee = ''
		state.role = ''
		state.msdRole = ''
		state.email = ''
		state.unit = ''
		state.divisions = []
		state.roles = []
	},

	w_wipeTimer,
	w_setTimer,

	w_addError,
	w_clearError,
	w_loadingPlus,
	w_loadingMinus,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}