<template>
	<div class="page-content pt-0">
		<div class="page-intro pt-3">
			<div class="container">
				<BtnBackArrow></BtnBackArrow>
				<div class="row align-items-center justify-content-between mt-3 mb-4">
					<div class="col-md-6">
						<h1 class="my-2">Продажи</h1>
					</div>
					<div class="col-md-6 col-lg-5 my-2">
						<RegionsSelectSingle v-model="selectedRegion" :options="userRegions" customClass="multiselect-single"></RegionsSelectSingle>
					</div>
				</div>
				<div class="fw-bold mb-5">2021 на текущий момент (упаковки)</div>
				<div class="row">
					<div class="col-lg-10">
						<div class="row">
							<div class="col-md-4 col-lg-3 my-2">
								<div>Китруда&nbsp;(факт)</div>
								<div class="h1 mt-1 mb-3 text-nowrap">{{sales.actual | digitSpace1k}}</div>
							</div>
							<div class="col-md-4 col-lg-3 my-2 text-primary-light">
								<div>Китруда&nbsp;(план)</div>
								<div class="h1 mt-1 mb-3 text-nowrap">{{sales.plan | digitSpace1k}}</div>
							</div>
							<div class="col-md-4 col-lg-3 my-2 opacity-24">
								<div>Остаток</div>
								<div class="h1 mt-1 mb-3 text-nowrap">{{sales.plan - sales.actual | digitSpace1k}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="mt-4">
				<div v-if="!w_isLoading">
					<UserSalesBlock :plans="plans" :sales="sales" :users="users" :selectedUser="{id: userId, nm: userName}"></UserSalesBlock>
				</div>
				<div v-else>
					<LoadingBlock :isEnabled='w_isLoading'></LoadingBlock>
				</div>
			</div>

			<RegionTenderStat></RegionTenderStat>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../log'
import BtnBackArrow from './blocks/BtnBackArrow'
import SalesTable from './blocks/SalesTable'
import RegionsSelectSingle from './blocks/RegionsSelectSingle.vue'
import RegionTenderStat from './blocks/RegionTenderStat.vue'
import LoadingBlock from './blocks/LoadingBlock.vue'
import UserSalesBlock from './blocks/UserSalesBlock.vue'


export default {
	name: "RegionOncoSales",

	components: {
		BtnBackArrow,
        SalesTable,
		RegionsSelectSingle,
		RegionTenderStat,
		LoadingBlock,
		UserSalesBlock
	},

	async beforeMount() {
		log.d(`RegionOncoSales | beforeMount`)
		if (this.selectedUserRegion?.id) {
			await this.loadRegionUserCustomSalesPlan({regions: this.selectedUserRegion.id})
		} else if (this.userRegions && this.userRegions[0]) {
			await this.loadRegionUserCustomSalesPlan({regions: this.userRegions[0].id})
		}
		await this.loadRegionsPVA()
	},
	data() {
		return {
			planAll: 0,
			actualAll: 0,
		}
	},
	computed: {
		...mapState('common', [
			'userRegions',
			'selectedUserRegion',
		]),
		...mapState('regionUserCustomSalesPlan', [
			'users',
			'plans',
		]),
		...mapGetters('regionUserCustomSalesPlan', [
			'w_isLoading',
			'w_isError'
		]),
		...mapGetters('regionsRegionsPVA', {
			regionPVA_w_isLoading: 'w_isLoading',
			regionPVA_w_isError: 'w_isError',
		}),
		...mapState('regionsRegionsPVA', [
			'regionsPVA',
		]),
		...mapState('auth', {
			userId :'id',
			userName :'name',
		}),
		selectedRegion: {
			get() {
				return this.selectedUserRegion
			},
			set(value) {
				return this.setselectedUserRegion(value)
			}
		},
		sales() {
			let obj = {}
			if (this.selectedUserRegion && this.selectedUserRegion.id) {
				this.planAll = 0
				this.actualAll = 0
	
				if (this.regionsPVA[7] && this.regionsPVA[7].regions[this.selectedUserRegion.id]) {
					this.planAll += parseFloat(this.regionsPVA[7].regions[this.selectedUserRegion.id].plan)
					this.actualAll += parseFloat(this.regionsPVA[7].regions[this.selectedUserRegion.id].actual)

					if (this.regionsPVA[7].regions[this.selectedUserRegion.id].months) { // TODO: проверить на наличие ключей
						let months = this.regionsPVA[7].regions[this.selectedUserRegion.id].months;
						for (let monthId of Object.keys(months)) {
							let month = months[monthId]

							if (!obj.hasOwnProperty(monthId)) {
	
								obj[monthId] = {
									plan: 0,
									actual: 0,
									actual_month: 0,
								}
							}
	
							if (month.plan) obj[monthId].plan += parseFloat(month.plan)
							if (month.actual) obj[monthId].actual += parseFloat(month.actual)
							if (month.actual_month) obj[monthId].actual_month += parseFloat(month.actual_month)
							// obj[monthId].plan = this.planAll 
						}
					}
	
				}
	
				obj.actual = this.actualAll
				obj.plan = this.planAll 
			}

			return obj
		},
	},
	methods: {
		...mapMutations('common', [
			'setselectedUserRegion',
		]),
		...mapActions('regionUserCustomSalesPlan', [
			'loadRegionUserCustomSalesPlan',
		]),
		...mapActions('regionsRegionsPVA', [
			'loadRegionsPVA'
		])
	},
	watch: {
        selectedUserRegion() {
			if (this.selectedUserRegion && this.selectedUserRegion.id) {
				this.loadRegionUserCustomSalesPlan({regions: this.selectedUserRegion.id})
			}
        },
    }

}
</script>

<style>

</style>

<style scoped>
.sales-table__btn {
	right: 15px;
}
</style>