<template>
	<div class="container">
		<div v-if="divisionVaccines">
			<PassportMainInfo></PassportMainInfo>
			<ClinicSales :offset-right=true></ClinicSales>
			<div class="row">
				<div class="col-lg-3">
					<ClinicPros></ClinicPros>
				</div>
				<div class="col-lg-9">
					<PatientCategories></PatientCategories>
				</div>
			</div>
			<CalcHistoryList></CalcHistoryList>
		</div>

		<div v-if="divisionOnko">
			<PassportMainInfo></PassportMainInfo>
			<ClinicSales :offset-right=true widgetTitle="Продажи китруды"></ClinicSales>
			<div class="row free-height-boxes">
				<div class="col-lg-5">
					<ClinicPros titleClass="text-muted"></ClinicPros>
				</div>
				<div class="col-lg-7">
					<ClinicLoyalty></ClinicLoyalty>
					<ClinicStatus></ClinicStatus>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Widget from './widgets/Widget'
import PassportMainInfo from './widgets/Clinic/PassportMainInfo.widget'
import PatientCategories from './widgets/Clinic/PatientCategories.widget'
import CalcHistoryList from './widgets/Clinic/CalcHistoryList.widget'
import ClinicSales from './widgets/Clinic/ClinicSales.widget'
import ClinicPros from './widgets/Clinic/ClinicPros.widget'
import ClinicMainInfo from './widgets/Clinic/ClinicMainInfo.widget'
import ClinicStatus from './widgets/Clinic/ClinicStatus.widget'
import ClinicLoyalty from './widgets/Clinic/ClinicLoyalty.widget'
import VaccineSales from './widgets/Clinic/VaccineSales.widget'

export default {
    name: "ClinicPassport",
	components: {
		Widget,
		PassportMainInfo,
		PatientCategories,
		CalcHistoryList,
		ClinicSales,
		ClinicPros,
		ClinicStatus,
		ClinicLoyalty,
		ClinicMainInfo,
		VaccineSales
	},
	computed: {
		partners: {
			get() {
				return this.clinicPartners
			},
			set(value) {
				return this.setPartners(value)
			}
		},
		subjectType: {
			get() {
				return this.clinicSubjectType
			},
			set(value) {
				return this.setSubjectType(value)
			}
		},
		...mapState('clinic', {
			clinicExternalId: state => state.clinic.externalid,
			clinicSubjectType: state => state.clinic.subjectType,
			clinicPartners: state => state.clinic.partners,
			clinicCity: state => state.clinic.city,
			clinicAddress: state => state.clinic.address,
			clinicTel: state => state.clinic.tel,
		}),
		...mapGetters('clinic', [
			'w_isLoading',
		])
	},
	methods: {
		...mapMutations('clinic', [
			'setSubjectType',
			'setPartners'
		])
	}
}
</script>

<style>

</style>

<style scoped>

</style>