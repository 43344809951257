<template>
	<div class="page-auth">
		<div id="loginForm" class="page-auth__section">
			<div id="register-form" class="page-form  pb-7 validate justify-content-between">
				<!--autocomplete="off" action="/register.php" method="POST"-->
				<div class="page-form-header">
					<img src="./../assets/images/logo-msd-commerce.svg" />
					<!-- <a href="#" class="btn-close"></a> -->
				</div>
				<div class="page-form-body mb-8">
					<h1 class="page-form-title mt-2 mb-6">Данные профиля на проверке</h1>
					<p>Проверка данных займёт до 7 дней.</p>
					<p>Как только она будет завершена, мы уведомим вас по электронной почте. По любым вопросам обращайтесь в службу поддерки <a href="mailto:support@msdmed.ru">support@msdmed.ru</a></p>
				</div>
				<div class="page-form-footer mt-6">
					<router-link to="/login" class="btn btn-primary w-100">Хорошо</router-link>
					<div class="text-center mt-12 pt-6">
						<a href="mailto:support@msdmed.ru" class="page-form-link">Техподдержка: <span class="td-underline">support@msdmed.ru</span></a>
					</div>
				</div>
			</div>
		</div>
  	</div>
</template>

<script>
export default {
	name: "RegisterFinish",
	data() {
		return {
		}
	},
}
</script>

<style>

</style>

<style scoped>

</style>