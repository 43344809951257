<template>
<Widget
	title="Пациенты <span class='opacity-3'>(из анкеты)</span>"
	:isLoading="w_isLoading"
	:isError="w_isError"
	:showEmptyMessage="Object.keys(allRegionProductPatientCategory).length == 0"
	>
    <div class="row mb-4">
        <div class="col-lg-3">
            <div class="mb-2">Китруда</div>
            <div class="h1 m-0">{{countAllPatientCategoriesByProductName('Китруда')}}</div>
        </div>
        <div class="col-lg-9">
            <div class="row text-accent">
                <div class="col pl-0">
                    <div class="fsz-sm mb-3">Опдиво</div>
                    <div class="h2">{{countAllPatientCategoriesByProductName('Опдиво')}}</div>
                </div>
                <div class="col pl-0">
                    <div class="fsz-sm mb-3">Бавенсио</div>
                    <div class="h2">{{countAllPatientCategoriesByProductName('Бавенсио')}}</div>
                </div>
                <div class="col pl-0">
                    <div class="fsz-sm mb-3">Тецентрик</div>
                    <div class="h2">{{countAllPatientCategoriesByProductName('Тецентрик')}}</div>
                </div>
                <div class="col pl-0">
                    <div class="fsz-sm mb-3">Имфинзи</div>
                    <div class="h2">{{countAllPatientCategoriesByProductName('Имфинзи')}}</div>
                </div>
                <div class="col pl-0">
                    <div class="fsz-sm mb-3">Фортека</div>
                    <div class="h2">{{countAllPatientCategoriesByProductName('Фортека')}}</div>
                </div>
            </div>
        </div>
    </div>
    <hr class="m-0">
    <table class="table mb-2">
        <tr>
            <th>Локализации (Китруда)</th>
            <th>Пациенты</th>
        </tr>
        <tr v-for="oneCase of countOncoCategoriesByProductName('Китруда')" :key="oneCase.nm">
            <td class="fsz-sm">{{oneCase.nm}}</td>
            <td class="fsz-base">{{oneCase.count}}</td>
        </tr>
    </table>
</Widget>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import log from '../../../log'
import Widget from '../Widget'

export default {
	name: "PatientsWidget",
	components: {Widget},
	async beforeMount() {
		log.d(`PatientsWidget | beforeMount`)
        await this.getRegionProductPatientCategory()
		this.setCohorts()
	},
	data() {
		return {
            cohorts: []
		}
	},
    props: ['regions'],
    watch:{
		regions: function() {
			if (!this.w_isLoading) {
				this.setCohorts()
			}
		}
	},
    computed: {
        ...mapState('regions', [
            'regionProductPatientCategory',
        ]),
        ...mapState('common', [
            'selectedUserRegionsId',
            'userProducts'
        ]),
        ...mapGetters('regions', [
            'w_isLoading',
            'w_isError',
        ]),

        MSDuserProducts() {
			let resultProducts = {}
			for (let prId in this.userProducts) {
				let el = this.userProducts[prId]
				if (el.is_msd_product) {
					resultProducts[el.id] = el
				}
			}
			return resultProducts
		},
		allRegionProductPatientCategory() {
			let obj = {}
			this.cohorts.forEach(el => {
				el.products.forEach(product => {
					if (!obj.hasOwnProperty(product.id)) {
						obj[product.id] = {
							id: product.id,
							name: product.name,
							nameTM: product.nameTM,
							categories: {},
							categoryCountAll: 0
						}
					}

					product.categories.forEach((category) => {
						if (!obj[product.id].categories[category.id]) {
							obj[product.id].categories[category.id] = {
								count: null,
								id: category.id,
								nm: category.nm,
								sex: category.sex,
								orderby: category.orderby,
							}
						}
					})

					product.categories.forEach((category) => {
						let initObj = obj[product.id].categories[category.id]

						if (category.count != null) {
							obj[product.id].categories[category.id] = Object.assign(initObj, {
								count: initObj.count + parseInt(category.count),
							})
							obj[product.id].categoryCountAll += parseInt(category.count)
						}
					})

				}, 0)
			})

			return obj
		},
        getKeytrudaSum() {
            return 909
        }
	},
	methods: {
        ...mapActions('regions', [
			'getRegionProductPatientCategory'
		]),
		...mapMutations('regions', [
			'w_loadingPlus',
			'w_loadingMinus'
		]),
        setCohorts() {
			this.cohorts = []
			this.regions.forEach(regionId => {
				this.cohorts.push(this.regionProductPatientCategory[regionId])
			})
		},
        getProductByName(name) {
            for (let productId of Object.keys(this.allRegionProductPatientCategory)) {
                if (this.allRegionProductPatientCategory[productId].name.toLowerCase() == name.toLowerCase()) {
                    return this.allRegionProductPatientCategory[productId]
                }
            }
            return null
        },
        countAllPatientCategoriesByProductName(name) {
            let product = this.getProductByName(name)
            if (product) {
                return product.categoryCountAll
            }
            return 0
        },
        getPatientCategoriesByProductName(name) {
            let product = this.getProductByName(name)
            let categories = []
            if (product) {
                for (let categoryId of Object.keys(product.categories)) {
                    categories[categoryId] = product.categories[categoryId]
                }
            }
            return categories
        },

        countOncoCategoriesByProductName(name) {
            let categories = this.getPatientCategoriesByProductName(name)
            let result = {}
            
            result['НМРЛ'] = {nm: 'НМРЛ', count: 0}
            result['Почечноклеточный рак'] = {nm: 'Почечноклеточный рак', count: 0}
            result['Рак эндометрия'] = {nm: 'Рак эндометрия', count: 0}
            result['Рак шейки матки'] = {nm: 'Рак шейки матки', count: 0}
            result['Опухоли головы и шеи'] = {nm: 'Опухоли головы и шеи', count: 0}
            result['Меланома'] = {nm: 'Меланома', count: 0}
            result['Другие показания'] = {nm: 'Другие показания', count: 0}

            if (categories) {
                for (let categoryId of Object.keys(categories)) {
                    let category = categories[categoryId]
                    if (category.nm.startsWith('Немелкоклеточный рак лёгкого')) {
                        result['НМРЛ'].count += parseInt(category.count)
                    } else if (category.nm.startsWith('Почечноклеточный рак')) {
                        result['Почечноклеточный рак'].count += parseInt(category.count)
                    } else if (category.nm.startsWith('Рак эндометрия')) {
                        result['Рак эндометрия'].count += parseInt(category.count)
                    } else if (category.nm.startsWith('Рак шейки матки')) {
                        result['Рак шейки матки'].count += parseInt(category.count)
                    } else if (category.nm.startsWith('Опухоли головы и шеи')) {
                        result['Опухоли головы и шеи'].count += parseInt(category.count)
                    } else if (category.nm.startsWith('Меланома')) {
                        result['Меланома'].count += parseInt(category.count)
                    } else if (category.nm.startsWith('Другие показания')) {
                        result['Другие показания'].count += parseInt(category.count)
                    }
                }
            }
            return result
        },

	}
}
</script>

<style>

</style>

<style scoped>

</style>