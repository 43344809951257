<template>
    <Widget
        title="Специалисты"
        :isLoading="w_isLoading"
        :isError="w_isError"
        :errors="w_errors"
        :showEmptyMessage="prosSum==0"
        >
        <div class="h1">{{ prosSum }} <span class="h2">чел.</span></div>
        <div
            v-for="pro in clinicPros"
            :key="pro.name"
            class="d-flex align-items-center my-5"
            >
            <div class="fsz-sm">{{ pro.name }}</div>
            <div class="h2 mb-0 ml-auto">{{ pro.count | digitSpace1k }}</div>
        </div>
    </Widget>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Widget from '../Widget'
import log from '../../../log'

export default {
    name: "ClinicPros",
	components: {
		Widget,
	},
    async beforeMount() {
		log.d(`ClinicPros | beforeMount`, this.$route.params.id)
		await this.loadСlinicPros({id: this.$route.params.id})
	},
    props: {
        titleClass: {
			type: String,
			default: null
		}
    },
    data() {
        return {
        }
    },
	computed: {
        prosSum() {
            return this.clinicPros.reduce((sum, pro) => {
                return sum + pro.count
            }, 0)
        },
		...mapState('clinicPros', [
			'clinicPros',
            'w_errors',
		]),
        ...mapGetters('clinicPros', [
			'w_isLoading',
			'w_isError',
		]),
	},
	methods: {
		...mapActions('clinicPros', [
			'loadСlinicPros',
		]),
	}
}
</script>