<template>
<Widget
	title="Продажи"
	:isLoading="w_isLoading"
	:isError="w_isError"
	:errors="w_errors"
	:isHiddenLoading="topRegions && Object.keys(topRegions).length>0"
	loadingMinHeight="240px"
	:url="divisionOnko?'/userSales':''"
	>
	<div>Факт <span class="text-muted">/ План</span></div>
	<div class="h1 mt-3">{{allActual(products) | digitSpace1k}} <span class="text-muted">/ {{allPlan(products) | digitSpace1k}} <span v-if="divisionOnko" class="h2">уп.</span><span v-else class="h2">доз</span></span></div>

	<div class="d-flex align-items-center">
		<div class="progress">
			<div class="progress-bar" role="progressbar" :style="{width: allPercentPVALimited+'%'}" aria-valuenow="92" aria-valuemin="0" aria-valuemax="100"></div>
		</div>
		<div class="h2 mb-0 ml-3 text-primary-light">{{allPercentPVALimited | toInt}}%</div>
	</div>
	<hr>
	<div class="row h-scroll">
		<div class="col-4" v-for="region in topRegions" :key="region.id">
			<div v-if="userRegionsObj[region.id]">
				<div class="fsz-sm mt-2 mb-3 text-truncate">{{userRegionsObj[region.id].nm}}</div>
				<span class="h2 mr-2">{{region.allActual | digitSpace1k}}</span>
				<span class="text-primary-light">{{countAllPercentPVALimited(region.allPlan, region.allActual)}}%</span>
			</div>
		</div>
	</div>
	<div v-if="Object.keys(MSDuserProducts).length>1">
		<hr>
		<div class="row">
			<div
				v-for="product in MSDuserProducts"
				:key="product.id"
				class="col-6 col-md-4 my-2"
			>
				<div class="input-check input-check-sm input-check-align-center mr-5 mb-1">
					<input v-model="products" :value="product.id" :id="product.alias" type="checkbox">
					<label :for="product.alias" class="text-nowrap">{{product.nm}}</label>
				</div>
			</div>
		</div>
	</div>
</Widget>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import log from '../../../log'
import Widget from '../Widget'

export default {
	name: "UserSellsWidget",
	components: {Widget},
	async beforeMount() {
		log.d(`UserSellsWidget | beforeMount`)
		await this.loadUserPVA()

		if (this.products.length == 0) {
			this.products = Object.keys(this.MSDuserProducts)
		}
	},
	data() {
		return {
			products: []
		}
	},
	computed: {
		allPercentPVA() {
			return Number.parseFloat((this.allActual(this.products)/this.allPlan(this.products))*100) || 0
		},
		allPercentPVALimited() {
			return Math.max(Math.min(Number.parseInt(this.allPercentPVA), 100), 0) || 0
		},
		topRegions() {
			var sorted = [];
			for (var regionId in this.regions(this.products)) {
				sorted.push([regionId, this.regions(this.products)[regionId]]);
			}
			sorted.sort(function(a, b) {
				return (a[1].allActual/a[1].allPlan) - (b[1].allActual/b[1].allPlan);
			});
			sorted = sorted.slice(0,3)
			let sortedObj = {}
			sorted.forEach(el=>{
				sortedObj[el[0]] = el[1]
			})
			return sortedObj
		},
		...mapState('userUserPVA', [
			'w_errors',
		]),
		...mapGetters('userUserPVA', [
			'regions',
			'allActual',
			'allPlan',
			'w_isLoading',
			'w_isError',
		]),
		...mapGetters('common', [
			'userRegionsObj',
			'MSDuserProducts'
		]),
	},
	methods: {
		countAllPercentPVALimited(plan, actual) {
			return Math.max(Math.min(Number.parseInt((actual/plan)*100), 100), 0) || 0
		},
		...mapActions('userUserPVA', [
			'loadUserPVA'
		]),
	},
	filters: {
		toInt(val) {
			return Number.parseInt(val)
		},
		toPersentMaxMin(val) {
			return Math.max(Math.min(val, 100), 0)
		}
	},
	watch: {
        MSDuserProducts() {
            if(Object.keys(this.MSDuserProducts).length>0 && this.products.length==0) {
                this.products = Object.keys(this.MSDuserProducts)
            }
        },
    }
}
</script>

<style>

</style>

<style scoped>

</style>