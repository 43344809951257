<template>
    <div>
        <label
            v-if="label"
            class="fsz-sm"
            :class="{ 'text-muted' : !isOpen, 'text-primary' : isOpen}">
            {{ label }}
        </label>
        <multiselect
            :class="customClass"
            v-model="model"
            :options="options"
            @input="handleInput"
            :showNoResults="false"
            label="nm"
            track-by="id"
            :multiple="true"
            :close-on-select="false"
            :preselect-first="false"
            placeholder=""
            @open="handleOpen"
            @close="handleOpen"
        >
            <template slot="selection" slot-scope="{ values, isOpen }">
                <span v-if="!isOpen" class="multiselect__single">{{ selectedLabel(values) }}</span>
            </template>
            <template v-slot:caret>
                <div class="multiselect__select">
                    <svg width="12" height="6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h12L6 6 0 0Z"/>
                    </svg>
                </div>
            </template>
            <template v-slot:beforeList v-if="Object.keys(options).length">
                <!-- <button v-if="model.length === 0" @click="toggleAll" class="multiselect__before" type="button">Выбрать все</button> -->
                <!-- <button v-else @click="toggleAll" class="multiselect__before" type="button">Снять все</button> -->
            </template>
            <template slot="option" slot-scope="props">
                <div :class="{'selected' : selectedOption(props.option.id)}">{{ props.option.nm }}</div>
            </template>
        </multiselect>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import config from '../../config.loader'

export default {
	name: "SelectMultiple",
	props: {
		value: {
            type: Array,
            default: [],
        },
		options: {
            type: Array,
            default: [],
        },
        customClass: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        }
    },
  	data() {
	  	return {
            model: this.value,
            isOpen: false
	  	}
  	},
	methods: {
        handleInput(e) {
			this.$emit('input', this.model)
		},
        // toggleAll(e) {
        //     if (this.model.length === 0) {
        //         this.model = this.options.map(el => el.id)
        //     } else {
        //         this.model = []
        //     }
        //     this.$emit('input', this.model)
        // },
        handleOpen() {
            this.isOpen = !this.isOpen
        },
        selectedLabel(values) {
            if (values.length == 0) {
                let label = 'Не выбрано';

                this.options.forEach(item => {
                    if (item.id == values[0]) {
                        label = item.nm
                    }
                })
                
                return label
            }
            // return 'Выбрано: все'
            return values.map(el=>el.nm).join(', ')
        },
        selectedOption(optionId) {
            return this.model.map(el => el.id).includes(optionId)
        }
	},
  	computed: {
        
  	},
    watch: {
		value(value) {
			if (this.model != value) {
				this.model = value.map(el => el.id)
			}
		}
	}
}
</script>

<style>

</style>

<style scoped>
    .multiselect__option--selected {
        background-color: #920000;
    }
</style>